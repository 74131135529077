/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { requestStatusType, Member } from '../../../types';
import { TeamSpaceUserRoleType } from '../../../constants/companySharedConstants';
import { FolderItem } from '../../../types/state';

interface Invitation {
  members: Member[];
  // users to invite
  newUserEmails: string[];
  role: TeamSpaceUserRoleType;
  folders: FolderItem[];
}

export interface InviteState {
  status: requestStatusType;
  invitation: Invitation;
}

const initialState: InviteState = {
  status: 'idle',
  invitation: {
    members: [],
    newUserEmails: [],
    role: 'Member',
    folders: [],
  },
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    setMembersAndNewUserEmails: (
      state,
      action: PayloadAction<{
        members: Member[];
        newUserEmails: string[];
      }>
    ) => {
      state.invitation.members = action.payload.members;
      state.invitation.newUserEmails = action.payload.newUserEmails;
    },
    setRole: (state, action: PayloadAction<TeamSpaceUserRoleType>) => {
      state.invitation.role = action.payload;
    },
    setFolders: (state, action: PayloadAction<FolderItem[]>) => {
      state.invitation.folders = action.payload;
    },
    reset: (state) => {
      state.status = 'idle';
      state.invitation = initialState.invitation;
    },
  },
});

export const {
  setMembersAndNewUserEmails,
  setRole,
  setFolders,
  reset,
} = inviteSlice.actions;
export default inviteSlice.reducer;
