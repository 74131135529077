/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { requestStatusType } from '../../types';
import {
  finalizeUploads,
  getUploadUrls,
} from '../../services/fileSystemService';
import { ContentType } from '../../constants/mediaFormatProfiles';
import { addFile } from '../library/librarySlice';
import { setAlertMessage } from '../dashboard/dashboardSlice';

interface UploadProgress {
  folderId: string;
  fileId: string;
  fileName: string;
  progress: number; // Progress in percentage
}

interface UploadState {
  status: requestStatusType;
  progress: number; // Total progress
  activeUploads: UploadProgress[];
}

const initialState: UploadState = {
  status: 'idle',
  progress: 0,
  activeUploads: [],
};

// Custom action for progress
export const updateProgress = createAction<UploadProgress>('upload/progress');

// Define the thunk for uploading files
interface UploadFilesThunkArg {
  teamId: string;
  folderId: string;
  files: File[];
}
export const uploadFiles = createAsyncThunk(
  'upload/uploadFiles',
  async (
    { teamId, folderId, files }: UploadFilesThunkArg,
    { dispatch, rejectWithValue }
  ) => {
    try {
      // Step 1: Fetch presigned upload URLs for each file
      const response = await getUploadUrls({
        teamId,
        folderId,
        files: files.map((file) => ({
          name: file.name,
          contentType: (file.type as ContentType) || 'video/x-matroska',
        })),
      });

      const { uploadParams } = response.data.data;

      // Step 2: Upload files using the fetched URLs and update progress
      await Promise.all(
        files.map(async (file, index) => {
          const { fileId, uploadUrl } = uploadParams[index];
          const { name, type: contentType } = file;

          await axios.put(uploadUrl, file, {
            headers: {
              'Content-Type': contentType || 'video/x-matroska',
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent || {};
              if (!loaded || !total) return;
              const progress = Math.round((loaded * 100) / total);
              dispatch(
                updateProgress({ folderId, fileId, fileName: name, progress })
              );
            },
          });
          const finalizeResponse = await finalizeUploads({
            teamId,
            folderId,
            fileId,
            contentType: contentType || 'video/x-matroska',
            name,
          });
          const uploadedFile = finalizeResponse.data.data.file;
          dispatch(addFile(uploadedFile));
        })
      );
    } catch (error) {
      dispatch(setAlertMessage({ message: error.message, type: 'error' }));
      rejectWithValue(error);
    }
  }
);

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    resetUploadState: (state) => {
      state.status = 'idle';
      state.progress = 0;
      state.activeUploads = [];
    },
    // Used in recorder
    addFileToUploads: (
      state,
      action: PayloadAction<{
        folderId: string;
        fileId: string;
        fileName: string;
        progress: number;
      }>
    ) => {
      state.activeUploads.push(action.payload);
    },
    // Used in recorder
    removeFileFromUploads: (
      state,
      action: PayloadAction<{
        folderId: string;
        fileId: string;
      }>
    ) => {
      state.activeUploads = state.activeUploads.filter(
        (up) => up.fileId !== action.payload.fileId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFiles.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(uploadFiles.fulfilled, (state) => {
      state.status = 'succeeded';
      state.progress = 100; // All uploads complete
      state.progress = 0;
      state.activeUploads = [];
    });
    builder.addCase(uploadFiles.rejected, (state) => {
      state.status = 'failed';
      state.progress = 0;
      state.activeUploads = [];
    });
    builder.addCase(updateProgress, (state, action) => {
      const { folderId, fileId, progress } = action.payload;
      const existingProgress = state.activeUploads.find(
        (up) => up.fileId === fileId
      );

      if (existingProgress) {
        existingProgress.progress = progress;
      } else {
        state.activeUploads.push({
          folderId,
          fileId,
          fileName: action.payload.fileName,
          progress,
        });
      }

      // Calculate total progress
      const totalProgress = state.activeUploads.reduce(
        (total, file) => total + file.progress,
        0
      );
      state.progress = totalProgress / state.activeUploads.length;
    });
  },
});

export const {
  resetUploadState,
  addFileToUploads,
  removeFileFromUploads,
} = uploadSlice.actions;

export default uploadSlice.reducer;
