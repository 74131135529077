import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Collapse,
  CircularProgress,
  Link as MuiLink,
  useTheme,
  keyframes,
} from '@mui/material';
import { ChevronDown, ChevronUp, Volume2 as AudioIcon } from 'lucide-mui';
import { FileType } from '../../../types';
import defaultThumbnail from '../../../assets/recording/DefaultThumbnail.svg';
import { getFileUrl } from '../../../lib/routingUtils';

// Define the wave animation
const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

interface FileListProps {
  files: FileType[];
  isExpanded: boolean;
  onToggleExpand: () => void;
  fileThumbnails: Array<{
    fileId: string;
    thumbnailUrl: string | null;
    isLoading: boolean;
  }>;
  setFileThumbnails: React.Dispatch<
    React.SetStateAction<
      Array<{
        fileId: string;
        thumbnailUrl: string | null;
        isLoading: boolean;
      }>
    >
  >;
  isLoading?: boolean;
}

const FileList: React.FC<FileListProps> = ({
  files,
  isExpanded,
  onToggleExpand,
  fileThumbnails,
  setFileThumbnails,
  isLoading = false,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 1, px: 0 }}>
      <Button
        onClick={onToggleExpand}
        fullWidth
        variant="text"
        size="small"
        sx={{
          justifyContent: 'flex-start',
          color: 'text.secondary',
          py: 0.5,
          px: 1.5,
          borderRadius: '9999px',
          display: 'flex',
          gap: 1,
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark' ? 'action.hover' : 'white',
          },
        }}
      >
        {isExpanded ? (
          <ChevronUp width={16} height={16} />
        ) : (
          <ChevronDown width={16} height={16} />
        )}
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            display: 'inline-block',
            borderRadius: '4px',
          }}
        >
          <Typography
            variant="caption"
            component="span"
            sx={{
              fontSize: '0.75rem',
              px: '4px',
              position: 'relative',
              zIndex: 1,
            }}
          >
            {files.length} Sources
          </Typography>

          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(90deg, transparent, rgba(255,255,255,0.05), rgba(255,255,255,0.15), rgba(255,255,255,0.05), transparent)'
                    : 'linear-gradient(90deg, transparent, rgba(255,255,255,0.4), rgba(255,255,255,0.8), rgba(255,255,255,0.4), transparent)',
                animation: `${shimmer} 1.5s infinite`,
                zIndex: 2,
              }}
            />
          )}
        </Box>
      </Button>

      <Collapse
        in={isExpanded}
        timeout={200}
        easing="cubic-bezier(0.4, 0, 0.2, 1)"
        unmountOnExit
      >
        <List dense disablePadding sx={{ px: 0 }}>
          {files.map((file: FileType) => {
            // Find thumbnail for this file
            const thumbnailData = fileThumbnails.find(
              (t) => t.fileId === file._id
            );

            // Check if it's an audio-only file
            const isAudioOnly =
              file.streams?.length === 1 && file.streams[0] === 'audio';
            const { _id: fileId, teamId, parentId } = file;
            const fileUrl = getFileUrl({
              fileId,
              teamId,
              view: 'list',
              parentId,
              withBasePath: true,
            });

            return (
              <ListItem
                key={file._id}
                component="div"
                sx={{
                  borderRadius: '16px',
                  my: 0.5,
                  p: 1,
                  px: 1.5,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor:
                      theme.palette.mode === 'dark' ? 'action.hover' : 'white',
                  },
                }}
              >
                {/* Thumbnail or placeholder */}
                <MuiLink
                  href={fileUrl}
                  key={fileId}
                  target="_blank"
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      minWidth: 48,
                      mr: 1.5,
                      borderRadius: '12px',
                      overflow: 'hidden',
                      bgcolor:
                        theme.palette.mode === 'dark'
                          ? 'rgba(42, 43, 44, 0.5)'
                          : 'action.hover',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    {/* Show thumbnail if available and not audio-only */}
                    {((thumbnailData?.thumbnailUrl && !isAudioOnly) ||
                      (!thumbnailData?.isLoading && !isAudioOnly)) && (
                      <img
                        src={thumbnailData?.thumbnailUrl || defaultThumbnail}
                        alt={`Thumbnail for ${file.name}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          filter:
                            theme.palette.mode === 'dark' &&
                            (!thumbnailData?.thumbnailUrl ||
                              thumbnailData?.thumbnailUrl === defaultThumbnail)
                              ? 'invert(1)'
                              : 'none',
                        }}
                        onError={(e) => {
                          // If the thumbnail URL fails, use default thumbnail
                          const target = e.target as HTMLImageElement;
                          if (target.src !== defaultThumbnail) {
                            target.src = defaultThumbnail;
                            // Also update in the state to avoid repeated failures
                            setFileThumbnails((prev) =>
                              prev.map((t) =>
                                t.fileId === file._id
                                  ? {
                                      ...t,
                                      thumbnailUrl: defaultThumbnail,
                                      isLoading: false,
                                    }
                                  : t
                              )
                            );
                          }
                        }}
                      />
                    )}
                    {/* Show loading state */}
                    {!thumbnailData?.thumbnailUrl && thumbnailData?.isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          width: '100%',
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? 'rgba(60, 60, 60, 0.6)'
                              : 'rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        <CircularProgress size={24} />
                      </Box>
                    )}
                    {/* Show audio icon only for audio files with no thumbnail */}
                    {!thumbnailData?.thumbnailUrl &&
                      !thumbnailData?.isLoading &&
                      isAudioOnly && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            color: 'text.secondary',
                          }}
                        >
                          <AudioIcon width={22} height={22} />
                        </Box>
                      )}
                  </Box>

                  <ListItemText
                    primary={file.name}
                    secondary={
                      file.duration
                        ? `${Math.floor(file.duration / 60)}:${String(
                            Math.floor(file.duration % 60)
                          ).padStart(2, '0')}`
                        : null
                    }
                    primaryTypographyProps={
                      {
                        noWrap: true,
                        variant: 'body2',
                        fontWeight: 500,
                      } as any
                    }
                    secondaryTypographyProps={
                      {
                        noWrap: true,
                        variant: 'caption',
                        color: 'text.secondary',
                      } as any
                    }
                  />
                </MuiLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

export default FileList;
