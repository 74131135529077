/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AlertProps } from '@mui/material';

type DrawerState = 'open' | 'closed';

interface AlertMessage {
  message: string;
  type: AlertProps['severity'];
  buttonText?: string;
  link?: string;
  // Add more properties if needed
}

type NotificationType =
  | 'transcription'
  | 'processing'
  | 'error'
  | 'tag'
  | 'team';
interface Notification {
  id: string;
  type: NotificationType;
  title: string;
  message: string;
  link?: string;
  read: boolean;
  timestamp: number;
}

interface DashboardState {
  drawerState: DrawerState;
  alertMessageStack: AlertMessage[]; // Alert message shown on the top of the dashboard
  notifications: Notification[];
}
const initialState: DashboardState = {
  drawerState: 'open',
  alertMessageStack: [],
  notifications: [
    // {
    //   id: '1',
    //   type: 'transcription',
    //   title: 'File Processed',
    //   message: 'File "test.pdf" has been processed',
    //   link: 'https://www.google.com',
    //   read: false,
    //   timestamp: Date.now(),
    // },
  ],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDrawerState: (state, action: PayloadAction<DrawerState>) => {
      state.drawerState = action.payload;
    },
    setAlertMessage: (state, action: PayloadAction<AlertMessage>) => {
      state.alertMessageStack.push({
        buttonText: 'Dismiss',
        ...action.payload,
      });
    },
    removeAlertMessage: (state) => {
      state.alertMessageStack.shift();
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    markNotificationAsRead: (state, action: PayloadAction<string>) => {
      const notification = state.notifications.find(
        (n) => n.id === action.payload
      );
      if (notification) {
        notification.read = true;
      }
    },
    clearAllNotifications: (state) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read: true,
      }));
    },
  },
});

export const {
  setDrawerState,
  setAlertMessage,
  removeAlertMessage,
  setNotifications,
  addNotification,
  markNotificationAsRead,
  clearAllNotifications,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
