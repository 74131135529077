import type { Middleware } from 'redux';
import { posthog } from 'posthog-js';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ModalActionPayLoadType } from '../features/globalModals/globalModalsSlice';
import { UserSettings } from '../features/settings/settingsSlice';
import { pushModal } from '../features/globalModals/globalModalsSlice';
import config from '../../config';
import { RootState } from '../reducers';
import { GetSubscriptionResponseData } from '../services/subscriptionServices';
import { createOrUpdateSubscription } from '../features/plan/thunk';
import { trackMediaAction } from '../features/library/actions/mediaActions/mediaActionsSlice';
import type { MediaActionPayload } from '../features/library/actions/mediaActions/mediaActionsSlice';
import { signOut } from '../features/auth/authSlice';
import {
  setSubscriptionState,
  trackCancelPlanModal,
  trackCancelPlanButton,
} from '../features/plan/planSlice';

// Initialize PostHog (make sure you replace 'YOUR_POSTHOG_API_KEY' and 'YOUR_POSTHOG_API_HOST' with actual values)
const { posthogApiKey, posthogApiHost } = config;
posthog.init(posthogApiKey, {
  api_host: posthogApiHost,
  capture_pageview: false, // disable automatic pageview tracking
  capture_pageleave: false, // disable automatic pageleave tracking
});

// Action types for onboarding tracking
const TRACK_ONBOARDING_GOAL = 'onboarding/trackGoal';
const TRACK_ONBOARDING_IMPORT_OPTIONS = 'onboarding/trackImportOptions';
const TRACK_ASSISTANT_QUERY = 'assistant/trackQuery';

interface OnboardingGoalPayload {
  goal: string;
}

interface OnboardingImportOptionsPayload {
  importOptions: string[];
}

interface AssistantQueryPayload {
  query: string;
}

interface ActionMenuPayload {
  actionType: string;
  fileId: string;
  status?: string;
}

interface TemplateSelectionPayload {
  templateId: string;
  templateTitle: string;
}

// Add subscription action payload type
interface SubscriptionActionPayload {
  plan: string;
  term: 'annual' | 'monthly';
  totalAmount: number;
}

// Track when user clicks the upgrade button with price
interface UpgradeButtonClickPayload {
  plan: string;
  term: 'annual' | 'monthly';
  amount: number;
  discountedAmount?: number;
}

const TRACK_UPGRADE_BUTTON_CLICK = 'upgrade/trackButtonClick';

const posthogMiddleware: Middleware<{}, RootState> = ({ getState }) => (
  next
) => (
  action: PayloadAction<
    | UserSettings
    | ModalActionPayLoadType
    | GetSubscriptionResponseData
    | MediaActionPayload
    | OnboardingGoalPayload
    | OnboardingImportOptionsPayload
    | ActionMenuPayload
    | TemplateSelectionPayload
    | SubscriptionActionPayload
    | UpgradeButtonClickPayload
    | AssistantQueryPayload
  >
) => {
  const result = next(action);
  const { plan: planState } = getState();
  const { selectedPlan, sourceFeatureKey } = planState.upgrade;

  if (action.type === TRACK_ONBOARDING_GOAL) {
    const { goal } = action.payload as OnboardingGoalPayload;
    posthog.capture('onboarding-goal', {
      goal,
    });
    // Set this as a user property only if it hasn't been set before
    posthog.people.set_once({
      user_goal: goal,
    });
  }

  if (action.type === TRACK_ONBOARDING_IMPORT_OPTIONS) {
    const { importOptions } = action.payload as OnboardingImportOptionsPayload;

    // Create an object with each import option as a boolean property
    const importProperties = importOptions.reduce(
      (acc, option) => ({
        ...acc,
        [`import_${option.toLowerCase()}`]: true,
      }),
      {}
    );

    posthog.capture('onboarding-import-options', {
      ...importProperties,
      total_import_options: importOptions.length,
    });

    // Set these as user properties only once during onboarding
    posthog.people.set_once({
      ...importProperties,
      total_import_options: importOptions.length,
    });
  }

  if (action.type === setSubscriptionState.type) {
    const state = getState();
    const { settings } = state;
    const { name, email } = settings.user;
    const { subscription, marketing, usage, packageProperties } = planState;
    const { price, term } = subscription;
    const { intent } = marketing;

    posthog.identify(email);
    posthog.people.set({
      email,
      name,
    });
    posthog.people.set_once({
      intent,
    });
    posthog.register({
      subscription: {
        package: selectedPlan,
        price,
        term,
      },
      AIUsage: usage?.askAICount,
      AILimit: packageProperties?.askAICount,
      TranscriptionUsage: usage?.transcriptionCount,
      TranscriptionLimit: packageProperties?.transcriptionCount,
    });
  }

  if (action.type === pushModal.type) {
    const {
      type,
      source,
    } = (action as PayloadAction<ModalActionPayLoadType>).payload;
    if (
      type === 'upsell-modal' ||
      type === 'stripe-pricing-table-upgrade-modal'
    ) {
      const isFromLimits = source === 'limits';
      posthog.capture('upgrade-modal', {
        selectedPlan,
        sourceFeatureKey,
        modalType: type,
        triggeredByLimits: isFromLimits,
        AIUsage: planState.usage?.askAICount,
        AILimit: planState.packageProperties?.askAICount,
      });
    }
  }
  if (action.type === createOrUpdateSubscription.fulfilled.type) {
    const {
      subscription,
    } = (action as PayloadAction<GetSubscriptionResponseData>).payload;
    const { package: subscriptionPackage, price, term } = subscription;
    posthog.capture('post-payment', {
      subscription: {
        package: subscriptionPackage,
        price,
        term,
      },
      sourceFeatureKey,
      AIUsage: planState.usage?.askAICount,
    });
    posthog.register({
      subscription: {
        package: subscriptionPackage,
        price,
        term,
      },
    });
  }
  if (action.type === trackMediaAction.type) {
    const {
      actionKey,
      actionLabel,
      intentKey,
      intentLabel,
      teamId,
      folderId,
    } = (action as PayloadAction<MediaActionPayload>).payload;
    posthog.capture('media-action-click', {
      actionKey,
      actionLabel,
      intentKey,
      intentLabel,
      teamId,
      folderId,
    });
  }
  if (action.type === signOut.type) {
    posthog.reset();
  }

  if (action.type === trackCancelPlanModal.type) {
    posthog.capture('cancel-plan-modal', {
      selectedPlan,
      sourceFeatureKey,
    });
  }

  if (action.type === trackCancelPlanButton.type) {
    posthog.capture('cancel-plan-button', {
      selectedPlan,
      sourceFeatureKey,
    });
  }

  // Track when user clicks the upgrade button with price
  if (action.type === TRACK_UPGRADE_BUTTON_CLICK) {
    const {
      plan,
      term,
      amount,
      discountedAmount,
    } = action.payload as UpgradeButtonClickPayload;
    posthog.capture('upgrade-approve', {
      plan,
      term,
      amount,
      discountedAmount,
      sourceFeatureKey,
    });
  }

  // Track template selection
  if (action.type === 'templates/trackSelection') {
    const {
      templateId,
      templateTitle,
    } = action.payload as TemplateSelectionPayload;
    posthog.capture('template-selected', {
      templateId,
      templateTitle,
    });
  }

  // Track action menu interactions
  if (action.type === 'actionMenu/trackAction') {
    const { actionType, fileId, status } = action.payload as ActionMenuPayload;
    posthog.capture('action-menu-click', {
      actionType,
      fileId,
      status: status || 'completed',
    });
  }

  // Track assistant queries
  if (action.type === TRACK_ASSISTANT_QUERY) {
    const { query } = action.payload as AssistantQueryPayload;
    posthog.capture('assistant-query', {
      query,
      timestamp: new Date().toISOString(),
    });
  }

  return result;
};

export default posthogMiddleware;
