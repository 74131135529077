import React from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { RootState } from '../../reducers';
import MultiAskAI from '../library/files/MultiAskAI';
import { DRAWER_WIDTH } from '../../constants/ui';

interface Props {
  open: boolean;
  onClose: () => void;
}

const MultiAskAIManager: React.FC<Props> = ({ open, onClose }) => {
  const theme = useTheme();
  const drawerOpen = useSelector(
    (state: RootState) => state.dashboard.drawerState === 'open'
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  // Calculate left position based on drawer state and screen size
  const getLeftPosition = () => {
    // On mobile screens, always at edge
    if (isMobileScreen) return 0;

    // On medium screens, factor in drawer only if it's showing
    if (isMediumScreen) {
      return drawerOpen ? DRAWER_WIDTH : 0;
    }

    // On larger screens, always factor in drawer (either full or collapsed)
    return drawerOpen ? DRAWER_WIDTH : theme.spacing(7);
  };

  // Calculate width based on drawer state and screen size
  const getWidth = () => {
    // On mobile screens, full width
    if (isMobileScreen) return '100%';

    // On medium screens, adjust width only if drawer is showing
    if (isMediumScreen) {
      return drawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%';
    }

    // On larger screens, always adjust width for drawer (either full or collapsed)
    return `calc(100% - ${drawerOpen ? DRAWER_WIDTH : theme.spacing(7)}px)`;
  };

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: {
          xs: 56, // Height of toolbar on mobile
          sm: 64, // Height of toolbar on desktop
        },
        left: getLeftPosition(),
        right: 0,
        bottom: 0,
        bgcolor: 'background.default',
        zIndex: {
          xs: theme.zIndex.drawer - 1, // Below drawer on mobile
          sm: theme.zIndex.drawer - 1,
        },
        transition: theme.transitions.create(['left', 'width']),
        width: getWidth(),
        px: {
          xs: 0,
          sm: isMediumScreen && !drawerOpen ? 0 : '16px', // Consistent margins on medium when drawer is hidden
          md: '16px',
        },
        pt: 0, // No top padding on any viewport
        pb: {
          xs: 0,
          sm: isMediumScreen && !drawerOpen ? 0 : '16px', // Consistent margins on medium when drawer is hidden
          md: '16px',
        },
      }}
    >
      {open && <MultiAskAI open={open} onClose={onClose} />}
    </Box>
  );
};

export default MultiAskAIManager;
