import { GrowthBook } from '@growthbook/growthbook-react';
import debug from 'debug';
import { config } from '../../config';

const log = debug('app:growthBook');

// eslint-disable-next-line import/prefer-default-export
export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: config.growthBookClientKey,
  // enableDevMode: true,
  trackingCallback: (experiment: any, result: any) => {
    // TODO: Use your real analytics tracking system
    log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});
