import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Heart } from 'lucide-mui';

import { RootState } from '../../../reducers';
import ActionButton from '../../plan/upgrade/ActionButton';
import usePlan from '../../plan/hooks/plan';
import { useTags } from '../../../hooks/useTags';
import ContactSalesDialog from '../../sales/ContactSalesDialog';

const BUTTON_TEXTS = {
  tooltipMessage: 'Request a Trial of Our Pro Features',
  buttonText: 'Request Trial',
};

const RequestTrialButton = () => {
  const { plan } = useSelector((state: RootState) => state);
  const { getTag } = useTags();
  const { isPlan } = usePlan();
  const [salesDialogOpen, setSalesDialogOpen] = React.useState(false);

  const userGoal = getTag('userGoal');
  const hasBusinessGoal = userGoal === 'business';
  const isFreePlan =
    !plan.subscription?.package ||
    plan.subscription.package === 'FREE_LOGGEDIN';
  const upgraded = isPlan(['GROWTH', 'BUSINESS', 'ENTERPRISE']);

  // Only show for free plan users with business goal who haven't upgraded
  if (!isFreePlan || !hasBusinessGoal || upgraded) {
    return null;
  }

  const handleClick = () => {
    setSalesDialogOpen(true);
  };

  const handleSalesDialogClose = () => {
    setSalesDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ActionButton
        startIcon={<Heart />}
        variant="outlined"
        color="secondary"
        size="medium"
        onClick={handleClick}
        sx={{ minWidth: 'unset', height: '40px' }}
        tooltipMessage={BUTTON_TEXTS.tooltipMessage}
      >
        {BUTTON_TEXTS.buttonText.replace(/ /g, '\u00A0')}
      </ActionButton>
      {salesDialogOpen && (
        <ContactSalesDialog
          onClose={handleSalesDialogClose}
          formSource="Dashboard - Request Trial"
        />
      )}
    </Box>
  );
};

export default RequestTrialButton;
