import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Tooltip,
  Box,
  Menu,
  Typography,
  Divider,
  List,
  ListItem,
  Drawer,
  useTheme as useMuiTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  LogOut,
  Settings,
  Blocks,
  Sun,
  Moon,
  Users,
  Gift,
  Monitor,
  Video,
  Bot,
  Sparkles,
  CreditCard,
  UserPlus,
  Key,
  Crown,
} from 'lucide-mui';
import { RootState } from '../../../reducers';
import { getSignoutUrl, getReferAndEarnUrl } from '../../../lib/routingUtils';
import TeamSelectMenu from '../drawer/TeamSelectMenu';
import { useTheme } from '../../../theme/ThemeContext';

// Menu item type definition
type MenuItem = {
  path: string;
  icon: React.ReactElement;
  label: React.ReactNode;
  target?: '_blank';
};

// Core Settings
const mainSettings: MenuItem[] = [
  { path: '/settings/user', icon: <Settings />, label: 'Settings' },
  { path: '/settings/display', icon: <Monitor />, label: 'Display' },
  { path: '/settings/recorder', icon: <Video />, label: 'Recording' },
];

// Team & Workspace Settings
const workspaceSettings: MenuItem[] = [
  { path: '/settings/team', icon: <Users />, label: 'Team' },
  { path: '/settings/integrations', icon: <Blocks />, label: 'Integrations' },
  { path: '/settings/billing', icon: <CreditCard />, label: 'Billing' },
  { path: '/settings/ai', icon: <Sparkles />, label: 'AI Settings' },
  { path: '/settings/meetingbot', icon: <Bot />, label: 'Meeting Bot' },
];

// Additional Options
const additionalOptions: MenuItem[] = [
  {
    path: getReferAndEarnUrl(),
    icon: <Gift />,
    label: 'Refer & Earn',
    target: '_blank',
  },
  { path: getSignoutUrl(), icon: <LogOut />, label: 'Logout' },
];

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { name, picture, email } = useSelector(
    (state: RootState) => state.settings?.user || {}
  );
  const subscription = useSelector(
    (state: RootState) => state.plan?.subscription
  );
  const { isDarkMode, toggleTheme } = useTheme();
  const muiTheme = useMuiTheme();
  const isMobileOrTablet = useMediaQuery(muiTheme.breakpoints.down('lg'));

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNavigation = useCallback(
    (path: string, target?: string) => {
      if (target === '_blank') {
        window.open(path, '_blank');
      } else {
        navigate(path);
      }
      handleClose();
    },
    [navigate, handleClose]
  );

  const formatPlanName = (plan: string) => {
    if (plan.toLowerCase().includes('free')) {
      return 'Free Plan';
    }
    return `${plan.charAt(0).toUpperCase()}${plan.slice(1).toLowerCase()} Plan`;
  };

  const renderMenuItems = (items: MenuItem[]) =>
    items.map(({ path, icon, label, target }) => (
      <ListItem
        key={path}
        disablePadding
        onClick={() => handleNavigation(path, target)}
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            bgcolor:
              path === getSignoutUrl() ? 'error.lighter' : 'action.hover',
          },
          ...(path === getSignoutUrl() && {
            color: 'error.main',
          }),
        }}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'inherit',
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="body1"
          sx={{
            ml: 1.5,
            flex: 1,
          }}
        >
          {label}
        </Typography>
      </ListItem>
    ));

  const menuContent = (
    <>
      {/* User Profile Section */}
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Avatar
            alt={name}
            src={picture}
            sx={{
              width: 40,
              height: 40,
              cursor: 'pointer',
              flexShrink: 0,
              '&:hover': {
                opacity: 0.8,
              },
            }}
            onClick={() => handleNavigation('/settings/user')}
          />
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontWeight: 500,
                lineHeight: 1.5,
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="caption"
              noWrap
              sx={{
                color: 'text.secondary',
                display: 'block',
              }}
            >
              {email}
            </Typography>
          </Box>
        </Box>
      </Box>

      {subscription?.package && (
        <List sx={{ py: 0 }}>
          <ListItem
            disablePadding
            onClick={() => handleNavigation('/settings/billing')}
            sx={{
              px: 2,
              py: 1,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {subscription.package.toLowerCase().includes('free') ? (
                <Key />
              ) : (
                <Crown />
              )}
            </Box>
            <Typography
              variant="body1"
              sx={{
                ml: 1.5,
                flex: 1,
              }}
            >
              {formatPlanName(subscription.package)}
            </Typography>
          </ListItem>
        </List>
      )}

      <Divider />

      {/* Team Selection */}
      <TeamSelectMenu />

      <Divider />

      {/* Main Settings */}
      <List sx={{ py: 0 }}>
        {renderMenuItems(mainSettings)}
        {renderMenuItems(workspaceSettings)}
      </List>

      <Divider />

      {/* Additional Options */}
      <List sx={{ py: 0 }}>
        <ListItem
          disablePadding
          onClick={() => handleNavigation('/settings/team')}
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UserPlus />
          </Box>
          <Typography
            variant="body1"
            sx={{
              ml: 1.5,
              flex: 1,
            }}
          >
            Add User
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          onClick={toggleTheme}
          sx={{
            px: 2,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isDarkMode ? <Sun /> : <Moon />}
          </Box>
          <Typography
            variant="body1"
            sx={{
              ml: 1.5,
              flex: 1,
            }}
          >
            {isDarkMode ? 'Light Mode' : 'Dark Mode'}
          </Typography>
        </ListItem>
        {renderMenuItems(additionalOptions)}
      </List>
    </>
  );

  return (
    <Box
      onClick={(e) => {
        // Only close if clicking the root container (not its children)
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <Tooltip title="Account settings">
        <Avatar
          onClick={handleClick}
          alt={name}
          src={picture}
          sx={{
            width: 40,
            height: 40,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        />
      </Tooltip>

      {isMobileOrTablet ? (
        <Drawer
          anchor="right"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="temporary"
          sx={{
            zIndex: muiTheme.zIndex.drawer + 3,
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            '& .MuiDrawer-paper': {
              width: '280px',
              boxSizing: 'border-box',
            },
          }}
        >
          {menuContent}
        </Drawer>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 280,
              borderRadius: 1,
              mt: 1,
            },
          }}
        >
          {menuContent}
        </Menu>
      )}
    </Box>
  );
};

export default UserAvatar;
