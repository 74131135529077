import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { BellOff } from 'lucide-mui';

const NotificationsEmptyState: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        minWidth: 300,
      }}
    >
      <BellOff
        sx={{
          width: 32,
          height: 32,
          color: theme.palette.text.secondary,
          mb: 2,
        }}
      />
      <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
        No notifications yet
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        We&apos;ll notify you when there&apos;s something new
      </Typography>
    </Box>
  );
};

export default NotificationsEmptyState;
