/* eslint-disable import/prefer-default-export */

/**
 * A type-safe capitalize function. Makes first letter upper case.
 * @param s
 * @returns
 */
export function capitalize<S extends string>(s: S): Capitalize<S> {
  return (s.charAt(0).toUpperCase() + s.slice(1)) as Capitalize<S>;
}
export function unCapitalize<S extends string>(s: S): Uncapitalize<S> {
  return (s.charAt(0).toLowerCase() + s.slice(1)) as Uncapitalize<S>;
}
export function addNonBreakingSpace(label?: string): string {
  if (!label) return '';
  // Replace spaces with non-breaking space character
  const labelWithNonBreakingSpace = label.replace(/ /g, '\u00A0');
  return labelWithNonBreakingSpace;
}
export function getValidEmail(email: string): string | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email) ? email : null;
}

export const truncateToByLength = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  return `${str.slice(0, maxLength)}...`;
};

const stringToColor = (str: string) => {
  const colors = [
    '#FFC107',
    '#3F51B5',
    '#E91E63',
    '#4CAF50',
    '#9C27B0',
    '#FF5722',
    '#03A9F4',
  ];
  const charCode = str.charCodeAt(0) % colors.length;
  return colors[charCode];
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.slice(0, 1).toUpperCase()}`,
  };
};
