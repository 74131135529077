import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Lucide icon imports
import {
  FileText,
  AlertCircle,
  Users,
  Tag,
  Bell,
  Settings,
  Check,
} from 'lucide-mui';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../reducers';
import {
  markNotificationAsRead,
  clearAllNotifications,
} from '../dashboardSlice';
import NotificationsEmptyState from './NotificationsEmptyState';
import ActionDialog from '../../globalModals/ActionDialog';

interface NotificationsProps {
  open: boolean;
  onClose: () => void;
}

const Notifications: React.FC<NotificationsProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state: RootState) => state);
  const { notifications } = dashboard;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const currentTitle = document.title;
    const baseTitle = currentTitle.replace(/^\(\d+\)\s/, '');
    const unreadCount = notifications.filter((n) => !n.read).length;

    if (unreadCount > 0) {
      document.title = `(${unreadCount}) ${baseTitle}`;
    } else {
      document.title = baseTitle;
    }
  }, [notifications]);

  const handleNotificationClick = (id: string, link?: string) => {
    if (link) {
      window.location.href = link;
    }
    dispatch(markNotificationAsRead(id));
    setSnackbarMessage('Notification marked as read');
    setSnackbarOpen(true);
  };

  const handleDismissAll = () => {
    dispatch(clearAllNotifications());
    setSnackbarMessage('All notifications marked as read');
    setSnackbarOpen(true);
    onClose();
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'transcription':
        return <FileText style={{ width: 16, height: 16 }} />;
      case 'tag':
        return <Tag style={{ width: 16, height: 16 }} />;
      case 'team':
        return <Users style={{ width: 16, height: 16 }} />;
      case 'error':
        return <AlertCircle style={{ width: 16, height: 16 }} />;
      case 'processing':
        return <Settings style={{ width: 16, height: 16 }} />;
      default:
        return <Bell style={{ width: 16, height: 16 }} />;
    }
  };

  const getIconBackground = (type: string) => {
    switch (type) {
      case 'error':
        return `${theme.palette.error.main}15`;
      case 'transcription':
        return `${theme.palette.primary.main}15`;
      case 'team':
        return `${theme.palette.success.main}15`;
      case 'tag':
        return `${theme.palette.warning.main}15`;
      case 'processing':
        return `${theme.palette.info.main}15`;
      default:
        return `${theme.palette.primary.main}15`;
    }
  };

  const getIconColor = (type: string) => {
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'transcription':
        return theme.palette.primary.main;
      case 'team':
        return theme.palette.success.main;
      case 'tag':
        return theme.palette.warning.main;
      case 'processing':
        return theme.palette.info.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const hasAnyUnreadNotifications = notifications.some((n) => !n.read);
  const unreadCount = notifications.filter((n) => !n.read).length;

  // Sort notifications by timestamp in descending order (newest first)
  const sortedNotifications = [...notifications].sort(
    (a, b) => b.timestamp - a.timestamp
  );

  const dialogTitle =
    unreadCount > 0 ? `Notifications (${unreadCount})` : 'Notifications';

  return (
    <ActionDialog
      title={dialogTitle}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      actions={
        hasAnyUnreadNotifications
          ? [
              {
                key: 'mark-all-read',
                label: 'Mark all as read',
                onClick: handleDismissAll,
                startIcon: <Check style={{ width: 16, height: 16 }} />,
                color: 'primary',
                variant: 'contained',
              },
            ]
          : []
      }
      sx={{
        '& .MuiDialogActions-root': {
          p: '8px 16px 16px',
        },
        '& .MuiDialogContent-root': {
          pb: 1,
        },
      }}
    >
      <Box sx={{ m: 0, p: 0 }}>
        {notifications.length > 0 ? (
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              py: 0,
              px: 0,
              m: 0,
            }}
          >
            {sortedNotifications.map(
              ({ id, type, title, message, timestamp, read, link }, index) => (
                <React.Fragment key={id}>
                  <ListItem
                    alignItems="flex-start"
                    onClick={() => handleNotificationClick(id, link)}
                    disablePadding
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      py: 1.5,
                      px: 2,
                      opacity: read ? 0.7 : 1,
                      transition: 'all 0.2s',
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                        opacity: 1,
                        '& .dismiss-button': {
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 1.5,
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          backgroundColor: getIconBackground(type),
                          color: getIconColor(type),
                          flexShrink: 0,
                          mt: 0.25,
                        }}
                      >
                        {getIcon(type)}
                      </Box>

                      <Box sx={{ flex: 1, minWidth: 0 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={!read ? 600 : 500}
                            sx={{ color: 'text.primary' }}
                          >
                            {title}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              ml: 1,
                              height: '100%',
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                opacity: 0.7,
                                fontSize: '0.75rem',
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {moment(timestamp).calendar(null, {
                                sameDay: 'h:mm A',
                                lastDay: 'Yesterday',
                                lastWeek: 'ddd',
                                sameElse: 'MMM D',
                              })}
                            </Typography>

                            <IconButton
                              className="dismiss-button"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(markNotificationAsRead(id));
                                setSnackbarMessage(
                                  'Notification marked as read'
                                );
                                setSnackbarOpen(true);
                              }}
                              sx={{
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                color: 'text.secondary',
                                padding: '2px',
                                ml: 0.5,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                  backgroundColor: 'action.hover',
                                },
                              }}
                            >
                              <Check style={{ width: 12, height: 12 }} />
                            </IconButton>
                          </Box>
                        </Box>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            fontSize: '0.75rem',
                            lineHeight: 1.4,
                            mt: 0.25,
                          }}
                        >
                          {message}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  {index < sortedNotifications.length - 1 && (
                    <Divider
                      variant="fullWidth"
                      component="li"
                      sx={{ my: 0 }}
                    />
                  )}
                </React.Fragment>
              )
            )}
          </List>
        ) : (
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <NotificationsEmptyState />
          </Box>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ActionDialog>
  );
};

export default Notifications;
