/* eslint-disable camelcase */
import { apiV2 } from '../lib/baseAxios';

import {
  FileType,
  SortBy,
  SortOrderBy,
  Pagination,
  FolderType,
  Speaker,
  SharePermission,
  TimeStampedLine,
  SegmentEditRecord,
  CompactSegment,
  FolderPermission,
  Word,
} from '../types';
import { MoveFileData } from '../features/selectedVideo/selectedVideoSlice';
import {
  FolderActionsParams,
  FolderActionsType,
} from '../features/library/librarySlice';
import { ProfileType, ContentType } from '../constants/mediaFormatProfiles';

export interface IVFSResponse<T> {
  success: boolean;
  data: T;
  message?: string;
}

interface FetchFilesParams {
  teamId?: string;
  folderId?: string;
  ownerId?: string;
  sortBy?: SortBy;
  sortOrder?: SortOrderBy;
  page?: number;
  limit?: number;
  searchQuery?: string;
}

export interface FetchFileSystemResponseBody {
  fileSystem: FileType[];
  fileSystemVersion: number;
  librarySize: number;
  pagination: Pagination;
  freeUserTimeLimit: Date | null;
}

export const fetchFileSystem = async (
  params: FetchFilesParams | undefined = {}
) => {
  return apiV2.get<IVFSResponse<FetchFileSystemResponseBody>>(`/files`, {
    params,
  });
};

interface CreateNewFolderArgs {
  teamId: string;
  name: string;
}
interface CreateNewFolderResponse {
  folder: FolderType;
}

export const createNewFolder = async ({
  teamId,
  name,
}: CreateNewFolderArgs) => {
  return apiV2.post<IVFSResponse<CreateNewFolderResponse>>(
    `/folders/${teamId}`,
    {
      name,
    }
  );
};

interface ShareFileV2Params {
  fileId: string;
  permissions?: SharePermission[];
}

interface ShareFileResponseData {
  shareId: string;
}
export const shareFile = async ({ fileId, permissions }: ShareFileV2Params) => {
  const response = await apiV2.post<IVFSResponse<ShareFileResponseData>>(
    `/files/actions/${fileId}/share`,
    {
      permissions,
    }
  );
  const shareId = response?.data.data.shareId;
  return { shareId };
};

export const unshareFile = async (fileId: string) => {
  return apiV2.delete<IVFSResponse<{}>>(`/files/actions/${fileId}/share`);
};

// Used by the public share view page. Not by logged in users
export const getSharedFile = async (shareId: string) => {
  const response = await apiV2.get<IVFSResponse<GetFileReturnType>>(
    `/files/shared/${shareId}`
  );
  return response?.data?.data;
};

export interface ConversationMessage {
  role: 'user' | 'assistant';
  content: string;
}

export interface PromptResponses {
  [key: string]: {
    promptTitle: string;
    responseText: string;
  };
}

export interface GetFileReturnType {
  file: FileType;
  jobs: {
    transcribe?: {
      recordingId: string;
      position: number;
      timeToComplete: number;
      updatedAt: string;
    };
  };
  transcript: {
    words?: Word[];
    compact_segments?: CompactSegment[];
    edit_records?: Record<string, SegmentEditRecord>;
    program_version: string;
    file_version: number;
  };
  chat: {
    sessionId: string;
    suggestions: string[];
    conversation: ConversationMessage[];
  };
  postProcess: {
    systemPromptResponses: PromptResponses;
    userPromptResponses: PromptResponses;
  };
}
export const getFile = async (fileId: string) => {
  const response = await apiV2.get<IVFSResponse<GetFileReturnType>>(
    `/files/${fileId}`
  );
  return response?.data?.data;
};

export const updateFile2 = async (
  id: string,
  changes: {
    name?: string;
    description?: string;
  }
) => {
  return apiV2.post<IVFSResponse<{ file: FileType }>>(`/files/${id}`, changes);
};

export interface UpdateSpeakerProps {
  teamId: string;
  fileId: string;
  speakerId: string;
  speaker: {
    name: string;
    picture?: string;
    userId?: string;
  };
}

export interface UpdateSpeakerResponseData {
  speakers: {
    [key: string]: Speaker;
  };
}

export const updateSpeaker = async (data: UpdateSpeakerProps) => {
  const { speaker, fileId, speakerId } = data;
  const response = await apiV2.post<IVFSResponse<UpdateSpeakerResponseData>>(
    `/files/actions/${fileId}/speaker/${speakerId}`,
    speaker
  );
  return response?.data?.data?.speakers;
};

interface GetDownloadUrlResponseBody {
  url: string;
}
export const getDownloadUrl = async ({
  fileId,
  profile,
}: {
  fileId: string;
  profile: ProfileType;
}): Promise<GetDownloadUrlResponseBody> => {
  const response = await apiV2.post<IVFSResponse<GetDownloadUrlResponseBody>>(
    `/files/actions/${fileId}/download/${profile}`
  );
  return response.data.data;
};

interface AskAIArgs {
  fileId: string;
  question: string;
}

export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  typing?: boolean;
}

interface AskAIResponse {
  sessionId: string;
  answer: ChatMessage;
}

// 11 Feb 2025: This will be deprecated in favor of the new askAIMultimodal
export const askAIV2 = async ({ fileId, question }: AskAIArgs) => {
  return apiV2.post<IVFSResponse<AskAIResponse>>(`/files/${fileId}/ask`, {
    question,
  });
};

export interface AnalysisSegment {
  start: number;
  end: number;
}

export interface MediaAnalysisOptions {
  transcript?: {
    segments: AnalysisSegment[];
  };
  video: {
    segments: AnalysisSegment[];
  };
  screenshots?: {
    timestamps: number[];
  };
}

export interface MultimodalAskArgs {
  fileId: string;
  promptText: string;
  mediaAnalysisOptions?: MediaAnalysisOptions;
}

export const askAIMultimodal = async ({
  fileId,
  promptText,
  mediaAnalysisOptions,
}: MultimodalAskArgs) => {
  return apiV2.post<IVFSResponse<AskAIResponse>>(
    `/files/${fileId}/ask/multimodal`,
    {
      promptText,
      mediaAnalysisOptions,
    }
  );
};

export const deleteAskAISessionV2 = async (
  fileId: string,
  sessionId: string
) => {
  return apiV2.delete(`/files/${fileId}/ask/${sessionId}`);
};

export const deleteTemplateOutputV2 = async (
  fileId: string,
  templateId: string
) => {
  return apiV2.delete(
    `/files/actions/${fileId}/template-outputs/${templateId}`
  );
};

export interface SaveEnhancedNotesParams {
  fileId: string;
  responseText: string;
}

export const saveEnhancedNotes = async ({
  fileId,
  responseText,
}: SaveEnhancedNotesParams) => {
  return apiV2.put<IVFSResponse<{ success: boolean }>>(
    `/files/actions/${fileId}/template-outputs/system/ENHANCED_NOTES`,
    {
      responseText,
    }
  );
};

export interface SaveNotesParams {
  fileId: string;
  notes: TimeStampedLine[];
}

export const saveNotes = async ({ fileId, notes }: SaveNotesParams) => {
  return apiV2.put<IVFSResponse<{ success: boolean }>>(
    `/files/${fileId}/notes`,
    { notes }
  );
};

export interface FolderActionsResponse {
  folders: FolderType[];
  folderAction: FolderActionsType;
}

export const folderActionsV2 = async ({
  folderId: fileId,
  action,
  data,
}: FolderActionsParams) => {
  return apiV2.post<IVFSResponse<FolderActionsResponse>>(
    `/folders/${fileId}/${action}`,
    {
      data,
    }
  );
};

export interface GetFoldersParams {
  teamId: string;
}

export interface GetFoldersResponse {
  folders: FolderType[];
}

export const getFolders = async ({ teamId }: GetFoldersParams) => {
  return apiV2.get<IVFSResponse<GetFoldersResponse>>(`/folders/${teamId}`);
};

export interface TrimParams {
  fileId: string;
  startMS: number;
  endMS: number;
}
export const trim = async ({
  fileId,
  startMS,
  endMS,
}: TrimParams): Promise<
  IVFSResponse<{
    data: {
      file: FileType;
    };
    success: boolean;
  }>
> => {
  return apiV2.delete(`/files/actions/${fileId}/trim`, {
    params: { startMS, endMS },
  });
};

export const moveFile2 = async ({
  fileId,
  toTeamId,
  toFolderId,
}: MoveFileData): Promise<
  IVFSResponse<{
    data: any;
    success: boolean;
  }>
> => {
  return apiV2.post(`/files/actions/${fileId}/move`, { toTeamId, toFolderId });
};

export const deleteFile2 = async (
  fileId: string
): Promise<
  IVFSResponse<{
    data: any;
    success: boolean;
  }>
> => {
  return apiV2.delete(`/files/actions/${fileId}/delete`);
};

interface GetUploadUrlsOptions {
  teamId: string;
  folderId: string;
  files: { name: string; contentType: ContentType }[];
}
interface GetUploadUrlsResponse {
  uploadParams: { fileId: string; uploadUrl: string }[];
}
export const getUploadUrls = async ({
  teamId,
  folderId,
  files,
}: GetUploadUrlsOptions) => {
  return apiV2.post<IVFSResponse<GetUploadUrlsResponse>>(
    `/files/upload/urls/${teamId}/${folderId}/`,
    {
      files,
    }
  );
};

interface ImportUrlResponseBody {
  file: FileType;
}

export const importFile = async (
  teamId: string,
  folderId: string,
  url: string,
  options: {} // Not in use atm
) => {
  return apiV2.post<IVFSResponse<ImportUrlResponseBody>>(
    `/files/import/${teamId}/${folderId}`,
    { url, options }
  );
};

interface FinalizeUploadOptions {
  teamId: string;
  folderId: string;
  fileId: string;
  contentType: string;
  name: string;
  description?: string;
}

interface FinalizeUploadResponseBody {
  file: FileType;
}

export const finalizeUploads = async ({
  teamId,
  folderId,
  ...file
}: FinalizeUploadOptions) => {
  return apiV2.post<IVFSResponse<FinalizeUploadResponseBody>>(
    `/files/upload/finalize/${teamId}/${folderId}`,
    { file }
  );
};

interface ReprocessV2ResponseBody {
  success: boolean;
  message: string;
  file: FileType;
}

export const reprocessV2 = async (fileId: string) => {
  const response = await apiV2.post<IVFSResponse<ReprocessV2ResponseBody>>(
    `/files/actions/${fileId}/reprocess`
  );
  return response.data;
};

export interface SaveTemplateOutputParams {
  fileId: string;
  templateId: string;
  responseText: string;
  promptTitle?: string;
}

export const saveTemplateOutput = async ({
  fileId,
  templateId,
  responseText,
  promptTitle,
}: SaveTemplateOutputParams) => {
  return apiV2.put<IVFSResponse<{ success: boolean }>>(
    `/files/actions/${fileId}/template-outputs/${templateId}`,
    {
      responseText,
      promptTitle,
    }
  );
};

export const addFileTag = async ({
  fileId,
  tag,
}: {
  fileId: string;
  tag: { key: string; value: string };
}) => {
  return apiV2.post(`/files/${fileId}/tag`, tag);
};

export interface GetThumbnailByTimestampParams {
  fileId: string;
  timestamp: number;
}

interface GetThumbnailByTimestampResponseBody {
  url: string;
}

export const getThumbnailByTimestamp = async ({
  fileId,
  timestamp,
}: GetThumbnailByTimestampParams) => {
  const response = await apiV2.post<
    IVFSResponse<GetThumbnailByTimestampResponseBody>
  >(`/files/${fileId}/thumb/${timestamp}`, {
    timestamp,
  });
  return response.data.data.url;
};

export interface UpdateTranscriptSegmentProps {
  fileId: string;
  segmentId: string | number;
  newText: string;
  teamId?: string;
}

export const updateTranscriptSegment = async (
  data: UpdateTranscriptSegmentProps
) => {
  const { fileId, segmentId, newText, teamId } = data;

  const response = await apiV2.post<
    IVFSResponse<{
      editRecord: SegmentEditRecord;
      compactSegment: CompactSegment;
    }>
  >(`/files/${fileId}/transcript/compact-segments/${segmentId}/update`, {
    text: newText,
    teamId,
  });

  // Find the updated segment and its edit record from the response
  const { editRecord, compactSegment } = response.data.data;

  if (!editRecord) {
    throw new Error('Failed to get updated segment or edit record');
  }

  return {
    editRecord,
    compactSegment,
  };
};

export const restoreTranscriptSegment = async (data: {
  fileId: string;
  segmentId: string | number;
  versionId: string;
  teamId?: string;
}) => {
  const { fileId, segmentId, versionId, teamId } = data;

  const response = await apiV2.post<
    IVFSResponse<{
      editRecord: SegmentEditRecord;
      compactSegment: CompactSegment;
    }>
  >(`/files/${fileId}/transcript/compact-segments/${segmentId}/restore`, {
    versionId,
    teamId,
  });

  // Find the updated segment and its edit record from the response
  const { editRecord, compactSegment } = response.data.data;

  if (!editRecord) {
    throw new Error('Failed to get updated segment or edit record');
  }

  return {
    editRecord,
    compactSegment,
  };
};

export interface UpdateFolderPermissionsResponse {
  folder: FolderType;
}

export const updateFolderPermissions = async (
  folderId: string,
  permissions: FolderPermission[] | null
): Promise<IVFSResponse<UpdateFolderPermissionsResponse>> => {
  if (permissions === null) {
    // DELETE request to remove all permissions (make public)
    const response = await apiV2.delete(`/folders/${folderId}/permissions`);
    return response.data;
  }

  // PUT request to update permissions
  const response = await apiV2.put(
    `/folders/${folderId}/permissions`,
    permissions
  );
  return response.data;
};
