import React, {
  useState,
  useEffect,
  useRef,
  lazy,
  Suspense,
  memo,
} from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { ChevronDown, Check } from 'lucide-mui';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import planFeatures, { FeatureKey } from './featureLimits';
import ContactSalesDialog from '../../../sales/ContactSalesDialog';
import type { FeatureVideoRef } from './FeatureVideo';

// Lazy load the FeatureVideo component
const FeatureVideo = lazy(() => import('./FeatureVideo'));

interface AccordionItemProps {
  feature: FeatureKey;
  isExpanded: boolean;
  onToggle: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  selectedBillingOption: 'monthly' | 'annual';
  featureData:
    | {
        title: string;
        description: string;
        annualtitle?: string;
        annualDescription?: string;
        showContactSales?: boolean;
      }
    | undefined;
  hasVideo: boolean;
  videoUrl: string;
  onVideoPlay: (feature: FeatureKey) => void;
  videoRef: (el: any) => void;
  onContactSales: (feature: FeatureKey) => void;
}

// Memoized AccordionItem component
const AccordionItem = memo(
  ({
    feature,
    isExpanded,
    onToggle,
    selectedBillingOption,
    featureData,
    hasVideo,
    videoUrl,
    onVideoPlay,
    videoRef,
    onContactSales,
  }: AccordionItemProps) => {
    if (!featureData) return null;

    const { title, description, annualtitle, annualDescription } = featureData;

    return (
      <Accordion
        expanded={isExpanded}
        onChange={onToggle}
        sx={{
          boxShadow: 'none',
          border: 'none',
          borderRadius: '8px !important',
          backgroundColor: 'transparent',
          transition: 'background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
          '&::before': {
            display: 'none',
          },
          margin: '0 !important',
          willChange: 'background-color',
        }}
      >
        <AccordionSummary
          expandIcon={
            <ChevronDown
              sx={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                transition: 'transform 0.15s cubic-bezier(0.4, 0, 0.6, 1)',
                willChange: 'transform',
              }}
            />
          }
          aria-controls={`${feature}-content`}
          id={`${feature}-header`}
          sx={{
            padding: 0,
            minHeight: '40px',
            '& .MuiAccordionSummary-content': {
              margin: '6px 0',
              display: 'flex',
              alignItems: 'center',
              transition: 'margin 0.15s cubic-bezier(0.4, 0, 0.6, 1)',
              willChange: 'margin',
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 'unset',
              marginRight: '8px',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                fontSize: isExpanded ? '1.2rem' : '1rem',
                color: isExpanded ? '#2e7d32 !important' : 'text.secondary',
                transition:
                  'font-size 0.15s cubic-bezier(0.4, 0, 0.6, 1), color 0.15s cubic-bezier(0.4, 0, 0.6, 1)',
                willChange: 'font-size, color',
              },
            }}
          >
            <Check />
          </ListItemIcon>
          <Box
            component="span"
            sx={{
              fontSize: isExpanded ? '0.975rem' : '0.9rem',
              fontWeight: isExpanded ? 600 : 400,
              color: isExpanded ? 'text.primary' : 'text.secondary',
              transition: 'all 0.15s cubic-bezier(0.4, 0, 0.6, 1)',
              willChange: 'font-size, font-weight, color',
            }}
          >
            {selectedBillingOption === 'annual' && annualtitle
              ? annualtitle
              : title}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0 0 12px 28px',
            color: 'text.secondary',
            fontSize: '0.875rem',
            lineHeight: 1.5,
          }}
        >
          <Box>
            {selectedBillingOption === 'annual' && annualDescription
              ? annualDescription
              : description}
          </Box>

          {hasVideo && isExpanded && (
            <Suspense
              fallback={
                <Box
                  sx={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Loading video...
                </Box>
              }
            >
              <FeatureVideo
                feature={feature}
                videoUrl={videoUrl}
                onPlay={onVideoPlay}
                ref={videoRef}
              />
            </Suspense>
          )}

          {featureData?.showContactSales && (
            <Box sx={{ mt: 2 }}>
              <Typography
                component="button"
                onClick={() => onContactSales(feature)}
                sx={{
                  color: 'primary.main',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  p: 0,
                  fontWeight: 400,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Contact Sales to Learn More →
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

interface PlanDetailsProps {
  selectedBillingOption: 'monthly' | 'annual';
}

const PlanDetails: React.FC<PlanDetailsProps> = ({ selectedBillingOption }) => {
  const { plan } = useSelector((state: RootState) => state);
  const { sourceFeatureKey, selectedPlan } = plan.upgrade;
  const [expandedItemKey, setExpandedItemKey] = useState<FeatureKey | null>(
    sourceFeatureKey || 'AISummaries'
  );
  const [salesDialogOpen, setSalesDialogOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<FeatureKey | null>(
    null
  );
  const [featureVideos, setFeatureVideos] = useState<Record<string, string>>(
    {}
  );
  const videoRefs = useRef<Record<string, FeatureVideoRef | null>>({});

  // Memoized video loading function
  const loadVideos = React.useCallback(async () => {
    const allFeatures = Object.keys(
      planFeatures[selectedPlan] || {}
    ) as FeatureKey[];

    const videoResults = await Promise.all(
      allFeatures.map(async (feature) => {
        const videoUrl = `https://screenapp.io/screenapp-resources/upgrade-view/videos/v1/${feature}.mp4`;
        try {
          const response = await fetch(videoUrl, {
            method: 'HEAD',
          });
          return response.ok ? { feature, url: videoUrl } : null;
        } catch (error) {
          console.warn(
            `Failed to check video existence for ${feature}:`,
            error.message
          );
          return null;
        }
      })
    );

    const videos = videoResults.reduce((acc, result) => {
      if (result) {
        acc[result.feature] = result.url;
      }
      return acc;
    }, {} as Record<FeatureKey, string>);

    setFeatureVideos(videos);
  }, [selectedPlan]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  // Handle when a video starts playing
  const handleVideoPlay = (feature: FeatureKey) => {
    // Pause all other videos
    Object.entries(videoRefs.current).forEach(([key, videoRef]) => {
      if (key !== feature && videoRef) {
        videoRef.pause();
      }
    });
  };

  const handleContactSales = (feature: FeatureKey) => {
    setSelectedFeature(feature);
    setSalesDialogOpen(true);
  };

  const toggleExpand = (feature: FeatureKey) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedItemKey(isExpanded ? feature : null);
  };

  const allFeatures = Object.keys(
    planFeatures[selectedPlan] || {}
  ) as FeatureKey[];

  const reorderedFeatures = sourceFeatureKey
    ? [
        sourceFeatureKey,
        ...allFeatures.filter((feature) => feature !== sourceFeatureKey),
      ]
    : allFeatures;

  const topRankedFeatures = reorderedFeatures
    .filter((feature) => feature !== sourceFeatureKey)
    .sort((a, b) => {
      const rankA = planFeatures[selectedPlan]?.[a]?.ranking || 100;
      const rankB = planFeatures[selectedPlan]?.[b]?.ranking || 100;
      return rankA - rankB;
    })
    .slice(0, 8);

  const displayedFeatures = sourceFeatureKey
    ? [sourceFeatureKey, ...topRankedFeatures]
    : topRankedFeatures;

  return (
    <Box
      sx={{
        width: '100%',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {displayedFeatures
        .filter((feature) => !!planFeatures[selectedPlan]?.[feature])
        .map((feature: FeatureKey) => (
          <AccordionItem
            key={feature}
            feature={feature}
            isExpanded={expandedItemKey === feature}
            onToggle={toggleExpand(feature)}
            selectedBillingOption={selectedBillingOption}
            featureData={planFeatures[selectedPlan]?.[feature]}
            hasVideo={!!featureVideos[feature]}
            videoUrl={featureVideos[feature]}
            onVideoPlay={handleVideoPlay}
            videoRef={(el) => {
              videoRefs.current[feature] = el;
            }}
            onContactSales={handleContactSales}
          />
        ))}
      {salesDialogOpen && (
        <ContactSalesDialog
          onClose={() => setSalesDialogOpen(false)}
          formSource={`Upgrade Modal - ${selectedFeature} Feature Enquiry`}
        />
      )}
    </Box>
  );
};

export default memo(PlanDetails);
