import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import { addTagToUserThunk } from '../features/settings/settingsSlice';
import { addTeamTagThunk } from '../features/team/teamSlice';
import { addFileTag } from '../features/library/librarySlice';

export const useTags = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state: RootState) => state.settings.user.tags);

  const getTag = useCallback(
    (key: string) => {
      return tags[key] || null;
    },
    [tags]
  );

  const setTag = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (tags[key] !== value) {
        dispatch(addTagToUserThunk({ key, value }));
      }
    },
    [dispatch, tags]
  );

  return { getTag, setTag };
};

export const useTeamTags = () => {
  const dispatch = useDispatch();
  const selectedTeam = useSelector(
    (state: RootState) => state.team.selectedTeam
  );

  const getTeamTag = useCallback(
    (key: string) => {
      return selectedTeam?.tags?.[key] || null;
    },
    [selectedTeam]
  );

  const setTeamTag = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (selectedTeam && selectedTeam.tags?.[key] !== value) {
        dispatch(
          addTeamTagThunk({ teamId: selectedTeam.teamId, tag: { key, value } })
        );
      }
    },
    [dispatch, selectedTeam]
  );

  return { getTeamTag, setTeamTag };
};

export const useFileTags = (fileId: string) => {
  const dispatch = useDispatch();
  const selectedFiles = useSelector(
    (state: RootState) => state.library.selectedFiles
  );
  const file = selectedFiles[fileId];

  const getFileTag = useCallback(
    (key: string) => {
      return file?.tags?.[key] || null;
    },
    [file]
  );

  const setFileTag = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (file && file.tags?.[key] !== value) {
        dispatch(addFileTag({ fileId, tag: { key, value } }));
      }
    },
    [dispatch, file, fileId]
  );

  return { getFileTag, setFileTag };
};

export default useTags;
