import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useTheme, SvgIcon, useMediaQuery } from '@mui/material';
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Menu as MenuIcon } from 'lucide-mui';
import { RootState } from '../../../reducers';
import { setDrawerState } from '../dashboardSlice';
import { DRAWER_WIDTH } from '../../../constants/ui';
import UpgradeButton from './UpgradeButton';
import UserAvatar from './UserAvatar';
import UpsellButton from './UpsellButton';
import RequestTrialButton from './RequestTrialButton';
import EducationalDiscount from './EducationalDiscount';
import AssistantToolbar from '../../assistant';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  [theme.breakpoints.up('md')]: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

// Add a new styled component for the white background area
const MenuBackgroundArea = styled('div')<{ open?: boolean }>(
  ({ theme, open }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: `calc(${theme.spacing(7)})`,
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: open ? 'none' : 'block',
    },
  })
);

export const CustomToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector(
    (state: RootState) => state.dashboard.drawerState === 'open'
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAssistantFocused, setIsAssistantFocused] = useState(false);

  const handleDrawerOpen = () => {
    dispatch(setDrawerState('open'));
  };

  // Determine if we should hide sidebar and avatar buttons
  const shouldHideButtons = isMobile && isAssistantFocused;

  return (
    <StyledAppBar position="fixed" open={open}>
      <MenuBackgroundArea open={open} />
      <Toolbar disableGutters>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            ml: { xs: 1, md: open ? 1 : 1.5 },
            mr: 1,
            zIndex: 1,
            ...(open && { display: 'none' }),
            ...(shouldHideButtons && { display: 'none' }),
            '&:hover': {
              opacity: 0.7,
            },
          }}
        >
          <SvgIcon component={MenuIcon} inheritViewBox />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            pr: shouldHideButtons ? 0 : 1,
            ...(shouldHideButtons && {
              position: 'absolute',
              left: 0,
              right: 0,
              padding: 0,
            }),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              ml: { xs: -0.5, md: 0 },
              mr: shouldHideButtons ? 0 : { xs: 0.5, sm: 1 },
              ...(shouldHideButtons && {
                padding: '0 8px',
              }),
            }}
          >
            <AssistantToolbar onFocusChange={setIsAssistantFocused} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: { xs: 0.5, sm: 1 },
              flexShrink: 0,
              ...(shouldHideButtons && { display: 'none' }),
            }}
          >
            <UpsellButton />
            <RequestTrialButton />
            <EducationalDiscount />
            <UpgradeButton />
            <Box sx={{ pr: 1 }}>
              <UserAvatar />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export default CustomToolbar;
