import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';
import { ArrowUpRight } from 'lucide-mui';
import { FileType } from '../../../types';

interface VideoPlayerProps {
  file: FileType;
  timestamp: string;
  onClick: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  file,
  timestamp,
  onClick,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const timestampSeconds = parseInt(timestamp, 10);
  const [isPlaying, setIsPlaying] = useState(true); // Default to playing state
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    // Set the current time and auto-play whenever the component mounts or timestamp changes
    if (videoRef.current) {
      videoRef.current.currentTime = timestampSeconds;
      // Auto-play when loaded
      const playPromise = videoRef.current.play();

      // Handle potential play() promise rejection (browsers may block autoplay)
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.warn('Autoplay was prevented:', error);
            setIsPlaying(false);
          });
      }
    }
  }, [timestamp, timestampSeconds]);

  // Play/pause toggle handler
  const togglePlayPause = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating to parent

    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Handle play/pause events from the video element
  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
      }
    };
  }, []);

  // Determine if the file is a video or audio based on streams property
  const isVideo = file.streams?.includes('video');

  // Common icon style to ensure whiteness
  const iconStyle = {
    color: '#FFFFFF',
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        bgcolor: 'background.default',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isVideo ? (
        <>
          {/* Video element without native controls */}
          <video
            ref={videoRef}
            src={file.url}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          >
            <source src={file.url} type="video/mp4" />
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>

          {/* Custom minimal controls overlay - only visible on hover */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.3)',
              opacity: isHovering ? 1 : 0,
              transition: 'opacity 0.2s',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            {/* Play/Pause button - only shown when hovering */}
            {isHovering && (
              <IconButton
                onClick={togglePlayPause}
                sx={{
                  color: '#FFFFFF',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& svg': {
                    color: '#FFFFFF',
                  },
                }}
              >
                {isPlaying ? (
                  <Pause style={iconStyle} fontSize="large" />
                ) : (
                  <PlayArrow style={iconStyle} fontSize="large" />
                )}
              </IconButton>
            )}

            {/* Jump to button - only shown when hovering */}
            {isHovering && (
              <IconButton
                onClick={onClick}
                sx={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  color: '#FFFFFF',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& svg': {
                    color: '#FFFFFF',
                    stroke: '#FFFFFF',
                  },
                }}
                size="small"
              >
                <ArrowUpRight width={16} height={16} style={iconStyle} />
              </IconButton>
            )}
          </Box>
        </>
      ) : (
        <>
          {/* Audio files - simpler UI */}
          <audio
            ref={videoRef}
            src={file.url}
            style={{
              display: 'none',
            }}
          >
            <source src={file.url} type="audio/mp3" />
            <track kind="captions" />
            Your browser does not support the audio tag.
          </audio>

          {/* Audio player UI */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.7)',
            }}
          >
            {/* Show controls only when hovering for audio too */}
            {isHovering && (
              <IconButton
                onClick={togglePlayPause}
                sx={{
                  color: '#FFFFFF',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& svg': {
                    color: '#FFFFFF',
                  },
                }}
              >
                {isPlaying ? (
                  <Pause style={iconStyle} fontSize="large" />
                ) : (
                  <PlayArrow style={iconStyle} fontSize="large" />
                )}
              </IconButton>
            )}

            {/* Jump to button - only shown when hovering */}
            {isHovering && (
              <IconButton
                onClick={onClick}
                sx={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  color: '#FFFFFF',
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  padding: '4px',
                  '& svg': {
                    color: '#FFFFFF',
                    stroke: '#FFFFFF',
                  },
                }}
                size="small"
              >
                <ArrowUpRight width={16} height={16} style={iconStyle} />
              </IconButton>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default VideoPlayer;
