import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Upload } from 'lucide-mui';
import Fade from '@mui/material/Fade';
import { keyframes } from '@mui/system';
import { URLParamsType } from '../../types';
import { uploadFiles } from '../upload/uploadSlice';
import validateFiles from '../upload/fileValidation';
import { setAlertMessage } from '../dashboard/dashboardSlice';
import logger from '../../lib/logger';
import useLimits from '../plan/hooks/limits';
import type { RootState } from '../../reducers';

const zoomIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const startUploadAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

interface UploadDropZoneProps {
  children: React.ReactNode;
}

export const UploadDropZone: React.FC<UploadDropZoneProps> = ({ children }) => {
  const { teamId, folderId } = useParams<URLParamsType>();
  const dispatch = useDispatch();
  const guardLimit = useLimits();
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [draggedFileCount, setDraggedFileCount] = useState(0);
  const [showUploadAnimation, setShowUploadAnimation] = useState(false);
  const uploadStatus = useSelector((state: RootState) => state.upload.status);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  const resetStates = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsUploading(false);
    setIsDragging(false);
    setShowUploadAnimation(false);
    setDraggedFileCount(0);
  };

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      setDraggedFileCount(0);

      if (!teamId || !folderId) return;

      const droppedFiles = Array.from(e.dataTransfer.files);
      if (droppedFiles.length === 0) return;

      setShowUploadAnimation(true);
      // Wait for animation to complete before showing upload state
      timeoutRef.current = setTimeout(() => {
        setShowUploadAnimation(false);
        setIsUploading(true);
      }, 400);

      const { isValid, errorMessage } = await validateFiles(droppedFiles);

      if (!isValid) {
        logger.error(new Error(errorMessage));
        dispatch(
          setAlertMessage({
            message: errorMessage || 'One or more files are invalid',
            type: 'error',
          })
        );
        resetStates();
        return;
      }

      try {
        if (droppedFiles.length > 1) {
          guardLimit('bulkUpload', () => {
            dispatch(uploadFiles({ teamId, folderId, files: droppedFiles }));
          });
        } else {
          dispatch(uploadFiles({ teamId, folderId, files: droppedFiles }));
        }
      } finally {
        resetStates();
      }
    },
    [teamId, folderId, dispatch, guardLimit]
  );

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
    const fileCount = e.dataTransfer.items.length;
    if (fileCount > 0) {
      setDraggedFileCount(fileCount);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    setDraggedFileCount(0);
  }, []);

  const renderContent = () => {
    if (showUploadAnimation) {
      return (
        <Upload
          sx={{
            fontSize: 80,
            mb: 3,
            color: 'rgba(0, 0, 0, 0.6)',
            animation: `${startUploadAnimation} 0.4s ease-in-out`,
          }}
        />
      );
    }

    if (isUploading) {
      return (
        <>
          <CircularProgress
            size={60}
            sx={{
              mb: 3,
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          />
          <Typography
            variant="h5"
            sx={{
              color: 'rgba(0, 0, 0, 0.7)',
              fontWeight: 500,
              letterSpacing: '0.5px',
              opacity: 0,
              animation: `${zoomIn} 0.3s ease-out forwards`,
              animationDelay: '0.15s',
            }}
          >
            Uploading files...
          </Typography>
        </>
      );
    }

    return (
      <>
        <Upload
          sx={{
            fontSize: 80,
            mb: 3,
            color: 'rgba(0, 0, 0, 0.6)',
            animation: `${floatAnimation} 2s ease-in-out infinite`,
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: 'rgba(0, 0, 0, 0.7)',
            fontWeight: 500,
            letterSpacing: '0.5px',
            opacity: 0,
            animation: `${zoomIn} 0.3s ease-out forwards`,
            animationDelay: '0.15s',
          }}
        >
          Drop {draggedFileCount} file{draggedFileCount !== 1 ? 's' : ''} to
          upload
        </Typography>
      </>
    );
  };

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {children}
      <Fade
        in={
          (isDragging || isUploading || showUploadAnimation) &&
          uploadStatus === 'idle'
        }
        timeout={300}
      >
        <Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              marginTop: '-0.5rem',
              zIndex: 1001,
              pointerEvents: 'none',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              animation: `${zoomIn} 0.3s ease-out`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50vh',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              zIndex: 1002,
              pointerEvents: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              animation: `${zoomIn} 0.3s ease-out`,
            }}
          >
            {renderContent()}
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

export default UploadDropZone;
