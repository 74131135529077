import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../reducers';
import Loader from '../features/app/loader/LoaderFullScreen';
import { getInitialState } from '../features/app/appSlice';
import { getToken, setToken } from '../lib/localStorageHandler';
import { setHeader } from '../lib/baseAxios';
import { addNotification } from '../features/dashboard/dashboardSlice';
import { useQuery } from '../hooks/urlHooks';
import AccountDeletingCover from '../features/auth/AccountDeletingCover';

const PrivateRoute: React.FC = () => {
  const navigate = useNavigate();
  const {
    token: tokenFromQuery,
    emailVerification,
    joinCode,
    zoomCode: zoomCodeFromQuery,
    redirectUrl,
    intent,
  } = useQuery();

  // Helper to extract zoom code directly from URL if not in query params
  const getZoomCodeFromURL = (): string | undefined => {
    const { hash } = window.location;
    const codeMatch = hash.match(/code=([^&]*)/);
    return codeMatch ? codeMatch[1] : undefined;
  };

  const zoomCode = zoomCodeFromQuery || getZoomCodeFromURL();
  console.log('zoomCode from URL:', zoomCode);

  const isEmailVerification = !!emailVerification;
  const tokenFromLocalStorage = getToken();
  const token = tokenFromQuery || tokenFromLocalStorage;
  const { app } = useSelector((state: RootState) => state) || {};
  const { status: appStatus } = app;
  const deleteRequestedAt = useSelector(
    (state: RootState) => state.settings.user?.deleteRequestedAt
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Set token from query only if it's new, to avoid clearing the token unexpectedly
    if (tokenFromQuery && tokenFromQuery !== tokenFromLocalStorage) {
      setToken(tokenFromQuery);
    }

    if (appStatus === 'idle' && token) {
      setHeader(token);

      // Only dispatch getInitialState if `joinCode` or `zoomCode` is provided
      if (joinCode || zoomCode) {
        dispatch(getInitialState({ joinCode, zoomCode }));
      } else {
        dispatch(getInitialState({}));
      }

      if (isEmailVerification) {
        dispatch(
          addNotification({
            id: Date.now().toString(),
            type: 'transcription',
            title: 'Email Verified',
            message: 'Your email has been successfully verified. Welcome!',
            link: '',
            read: false,
            timestamp: Date.now(),
          })
        );
      }

      // Redirect based on joinCode or zoomCode
      if (joinCode) {
        navigate(`/dashboard?joinCode=${joinCode}`);
      } else if (zoomCode) {
        navigate(`/dashboard?code=${zoomCode}`);
      }
    }
  }, [
    tokenFromQuery,
    appStatus,
    token,
    joinCode,
    zoomCode,
    isEmailVerification,
    dispatch,
    navigate,
  ]);

  if (!token) {
    const currentLocation = window.location.href;
    const referrerUrl = window.location.hash
      ? window.location.hash.substring(1)
      : currentLocation;
    const queryParams = new URLSearchParams({
      redirectUrl: currentLocation,
      referrerUrl,
      intent: intent || '',
    });
    return <Navigate to={`/signup?${queryParams}`} />;
  }

  if (deleteRequestedAt) {
    return <AccountDeletingCover />;
  }

  const redirectHashUrl = redirectUrl?.includes('#')
    ? redirectUrl.split('#').pop()
    : '';

  if (redirectHashUrl) {
    return <Navigate to={redirectHashUrl} />;
  }

  if (appStatus !== 'succeeded') return <Loader />;

  return <Outlet />;
};

export default PrivateRoute;
