import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import FileCard from './FileCard';
import { RootState } from '../../../../reducers';
import { toggleSelectFile, fetchFiles } from '../../librarySlice';
import { FileType } from '../../../../types';
import { RouteParams } from '../../types';
import { getFileUrl } from '../../../../lib/routingUtils';
import PendingUploads from '../../../upload/PendingUploads';
import PendingImport from '../../../import/PendingImport';
import FilesSkeleton from './FilesSkeleton';
import FilesEmpty from '../FilesEmpty';
import { useQuery } from '../../../../hooks/urlHooks';

export const FileGrid = () => {
  const { library, upload, import: importState } = useSelector(
    (state: RootState) => state
  );
  const { activeUploads } = upload;
  const dispatch = useDispatch();
  const { teamId, folderId } = useParams<RouteParams>();
  const { searchQuery, sortBy = 'createdAt', sortOrder = 'desc' } = useQuery();
  const { files } = library;
  const { selectedFiles } = library;
  const showCheckbox = Object.keys(selectedFiles).length > 0;
  const { status } = files;
  const [isNearBottom, setIsNearBottom] = useState(false);

  const onFileSelect = useCallback(
    (file: FileType) => {
      dispatch(toggleSelectFile(file));
    },
    [dispatch]
  );

  const handleScroll = useCallback(() => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    const threshold = window.innerHeight; // Start loading when within one viewport height

    // Update near-bottom state for early skeleton display
    setIsNearBottom(distanceFromBottom < threshold);

    if (
      distanceFromBottom < threshold &&
      files.status !== 'pending' &&
      files.pagination.total > files.data.length
    ) {
      const nextPage = files.pagination.page + 1;
      dispatch(
        fetchFiles({
          teamId,
          folderId,
          page: nextPage,
          limit: 20,
          searchQuery,
          sortBy,
          sortOrder,
        })
      );
    }
  }, [files, dispatch, teamId, folderId, searchQuery, sortBy, sortOrder]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!teamId || !folderId) {
    return null;
  }

  if (
    files.data?.length === 0 &&
    activeUploads.length === 0 &&
    importState.status !== 'pending' &&
    status !== 'pending'
  ) {
    return <FilesEmpty />;
  }

  const showSkeleton =
    status === 'pending' ||
    (isNearBottom && files.pagination.total > files.data.length);

  return (
    <Grid container spacing={2} sx={{ minHeight: '200px' }}>
      <PendingUploads view="grid" />
      <PendingImport view="grid" />
      {files.data.map((file) => {
        const isSelected = !!selectedFiles[file._id];
        return (
          <Grid
            data-testid="video-grid-tile"
            item
            key={file._id}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
          >
            <FileCard
              file={file}
              isSelected={isSelected}
              showCheckbox={showCheckbox}
              onSelect={onFileSelect}
              freeUserTimeLimit={files.freeUserTimeLimit}
              fileUrl={getFileUrl({
                teamId,
                parentId: folderId,
                fileId: file._id,
                withBasePath: true,
              })}
            />
          </Grid>
        );
      })}
      {showSkeleton && <FilesSkeleton />}
    </Grid>
  );
};

export default FileGrid;
