export const onOffFeatureFlags = {
  'show-upgrade-modal-at-login-for-non-upgraded-users': true,
};

export const stringFeatureFlags = {
  'global-alert-message': undefined,
};

export const featureFlags = {
  ...onOffFeatureFlags,
  ...stringFeatureFlags,
};

export type OnOffFeatureFlagsType = Partial<typeof onOffFeatureFlags>;
export type StringFeatureFlagsType = Partial<typeof stringFeatureFlags>;
export type OnOffFeatureFlagsKeyType = keyof typeof onOffFeatureFlags;
export type StringFeatureFlagsKeyType = keyof typeof stringFeatureFlags;
export type FeatureFlagsType = Partial<typeof featureFlags>;
export type FeatureFlagsKeyType = keyof typeof featureFlags;

export default {};
