import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  ListItemIcon,
} from '@mui/material';
import { MoreVertical, Pencil, Trash2, Layers2, Lock } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { pushModal } from '../../../globalModals/globalModalsSlice';
import { ActionType, FolderType } from '../../../../types';
import FolderIcon from './FolderIcon';
import FolderPermissionsModal from './FolderPermissionsModal';
import useTags from '../../../../hooks/useTags';
import { useRole } from '../../../settings/hooks';
import type { RootState } from '../../../../reducers';

type FolderMenuProps = {
  folder: FolderType;
};

type FolderMenuKeys = 'rename' | 'delete' | 'change-icon' | 'permissions';
type FolderMenuType = ActionType<FolderMenuKeys> & {
  icon?: React.ReactNode;
};

const baseActions: FolderMenuType[] = [
  {
    key: 'rename',
    label: 'Rename',
    'data-testid': 'rename-folder-option',
    icon: <Pencil style={{ width: 18, height: 18 }} />,
  },
  {
    key: 'change-icon',
    label: 'Change Icon',
    'data-testid': 'change-folder-icon-option',
    icon: <Layers2 style={{ width: 18, height: 18 }} />,
  },
  {
    key: 'permissions',
    label: 'Manage Access',
    'data-testid': 'folder-permissions-option',
    icon: <Lock style={{ width: 18, height: 18 }} />,
  },
  {
    key: 'delete',
    label: 'Delete',
    'data-testid': 'delete-folder-option',
    icon: <Trash2 style={{ width: 18, height: 18 }} />,
  },
];

const FolderMenu: React.FC<FolderMenuProps> = ({ folder }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [iconDialogOpen, setIconDialogOpen] = useState(false);
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const { getTag } = useTags();
  const { isRole } = useRole();
  const userId = useSelector((state: RootState) => state.settings.user?._id);

  const folderIconsTag = getTag('folder_icons');
  const folderIcons = folderIconsTag ? JSON.parse(folderIconsTag) : {};
  const currentIcon = folderIcons[folder._id] || 'Folder';

  const isOwnerOrAdmin =
    isRole(['Owner', 'Admin']) || folder.ownerId === userId;

  const actions = baseActions.filter((action) => {
    if (action.key === 'permissions') {
      return isOwnerOrAdmin;
    }
    return true;
  });

  const handleMoreIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (key: FolderMenuKeys) => {
    switch (key) {
      case 'rename':
        dispatch(pushModal({ type: 'rename-folder-modal', files: [folder] }));
        handleClose();
        break;
      case 'change-icon':
        setIconDialogOpen(true);
        handleClose();
        break;
      case 'permissions':
        setPermissionsDialogOpen(true);
        handleClose();
        break;
      case 'delete':
        dispatch(pushModal({ type: 'delete-folder-modal', files: [folder] }));
        handleClose();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Tooltip title="Options" arrow>
        <IconButton
          aria-label="more"
          aria-controls="folder-menu"
          aria-haspopup="true"
          onClick={handleMoreIconClick}
          data-testid="folder-more-option"
          sx={{ borderRadius: 20 }}
        >
          <MoreVertical />
        </IconButton>
      </Tooltip>
      <Menu
        id="folder-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: 1400 }}
      >
        {actions.map(({ key, label, icon }) => (
          <MenuItem key={key} onClick={() => handleActionClick(key)}>
            <ListItemIcon sx={{ minWidth: 36 }}>{icon}</ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Menu>
      <FolderIcon
        open={iconDialogOpen}
        onClose={() => setIconDialogOpen(false)}
        folderId={folder._id}
        currentIcon={currentIcon}
      />
      <FolderPermissionsModal
        open={permissionsDialogOpen}
        onClose={() => setPermissionsDialogOpen(false)}
        folder={folder}
      />
    </>
  );
};

export default FolderMenu;
