// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../../lib/localStorageHandler';
import { signOut } from './authSlice';

const Signout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(signOut());
    removeToken();
    // Navigate to signin page
    navigate('/signin', { replace: true });
  }, [dispatch, navigate]);

  return null;
};

export default Signout;
