export interface Discount {
  code: string;
  percentage: number;
  minimumAmount: number;
}

export const discounts: Discount[] = [
  {
    code: 'EDU50',
    percentage: 50,
    minimumAmount: 80,
  },
];

export function getDiscount(code: string): Discount | undefined {
  return discounts.find(
    (discount) => discount.code.toUpperCase() === code.toUpperCase()
  );
}
