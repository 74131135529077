import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Search as SearchIcon } from 'lucide-mui';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { setDrawerState } from '../dashboardSlice';
import { clearSearch } from '../../search/searchSlice';
import { useTeamTags } from '../../../hooks/useTags';

interface SearchProps {
  open: boolean;
}

const Search: React.FC<SearchProps> = ({ open }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getTeamTag } = useTeamTags();
  const hasRagIndex = getTeamTag('rag-index-v1') !== null;

  const handleSearchClick = () => {
    // Clear search state first
    dispatch(clearSearch());

    // Close drawer if on mobile
    if (isMobile) {
      dispatch(setDrawerState('closed'));
    }

    // Navigate directly to search page
    navigate('/search');
  };

  if (!hasRagIndex) {
    return null;
  }

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <Tooltip title="Search" placement="right" arrow>
        <ListItemButton
          onClick={handleSearchClick}
          sx={{
            justifyContent: open ? 'initial' : 'center',
            px: open ? 2.5 : 1.25,
            borderRadius: 2,
            mx: 0.5,
            minHeight: '36px',
            position: 'relative',
            overflow: 'hidden',
            bgcolor: 'transparent',
            '&.Mui-selected': {
              bgcolor: 'transparent',
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: 'secondary.light',
              opacity: 0,
              borderRadius: 'inherit',
            },
            '&:hover': {
              '&::before': {
                opacity: 1,
              },
            },
            '& > *': {
              position: 'relative',
              zIndex: 1,
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              pl: open ? 0 : 0.5,
            }}
          >
            <SearchIcon />
          </ListItemIcon>
          <ListItemText
            primary="Search"
            sx={{
              opacity: open ? 1 : 0,
            }}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export default Search;
