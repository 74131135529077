/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FileType } from '../../types';

interface SearchState {
  query: string;
  answer: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  results: {
    data: any[];
    error: string | null;
  };
  sources: Record<string, FileType>;
  previewError: string;
}

const initialState: SearchState = {
  query: '',
  answer: '',
  status: 'idle',
  results: {
    data: [],
    error: null,
  },
  sources: {},
  previewError: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setSearchStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'succeeded' | 'failed'>
    ) => {
      state.status = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<any[]>) => {
      state.results.data = action.payload;
    },
    setSearchError: (state, action: PayloadAction<string>) => {
      state.results.error = action.payload;
    },
    clearSearch: (state) => {
      state.query = '';
      state.results = initialState.results;
    },
    clearSearchAnswer: (state) => {
      state.answer = initialState.answer;
    },
    setSearchAnswer: (state, action: PayloadAction<string>) => {
      state.answer += action.payload;
    },
    putSource: (state, action: PayloadAction<FileType>) => {
      state.sources = {
        ...state.sources,
        [action.payload._id]: action.payload,
      };
    },
    setPreviewError: (state, action: PayloadAction<string>) => {
      state.previewError = action.payload;
    },
  },
});

export const {
  setSearchQuery,
  setSearchStatus,
  setSearchResults,
  setSearchError,
  clearSearch,
  clearSearchAnswer,
  setSearchAnswer,
  putSource,
  setPreviewError,
} = searchSlice.actions;

export default searchSlice.reducer;
