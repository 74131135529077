/* eslint no-param-reassign: "error" */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  requestStatusType,
  FileType,
  FolderType,
  Pagination,
  Creator,
  FolderPermission,
} from '../../types';
import {
  FolderActionsResponse,
  IVFSResponse,
  createNewFolder,
  fetchFileSystem,
  folderActionsV2,
  getDownloadUrl,
  getFolders,
  updateFolderPermissions,
  UpdateFolderPermissionsResponse,
} from '../../services/fileSystemService';
import { RootState } from '../../reducers';
import { deleteFile } from '../selectedVideo/selectedVideoSlice';
import downloadVideo from '../../lib/videoUtils';
import { ProfileType } from '../../constants/mediaFormatProfiles';

export interface LibraryState {
  status: requestStatusType;
  selectedFolder: FolderType | null;
  folders: {
    status: requestStatusType;
    data: FolderType[];
  };
  files: {
    status: requestStatusType;
    data: FileType[]; // Files of selected folder
    pagination: Pagination;
    librarySize: number;
    freeUserTimeLimit: Date | null;
  };
  selectedFiles: {
    [key: string]: FileType;
  };
  actions: {
    add: {
      status: requestStatusType;
    };
    rename: {
      status: requestStatusType;
    };
    manage: {
      status: requestStatusType;
    };
    delete: {
      status: requestStatusType;
    };
    permissions: {
      status: requestStatusType;
    };
  };
  creators: Creator[];
}

const initialState: LibraryState = {
  status: 'idle',
  selectedFolder: null,
  folders: {
    status: 'idle',
    data: [],
  },
  files: {
    status: 'idle',
    data: [],
    librarySize: 0,
    freeUserTimeLimit: null,
    pagination: {
      total: 0,
      page: 0,
      limit: 10,
    },
  },
  selectedFiles: {},
  actions: {
    add: {
      status: 'idle',
    },
    rename: {
      status: 'idle',
    },
    manage: {
      status: 'idle',
    },
    delete: {
      status: 'idle',
    },
    permissions: {
      status: 'idle',
    },
  },
  creators: [],
};

export type FolderActionsType = 'add' | 'rename' | 'manage' | 'delete';

export interface FolderActionsParams {
  folderId: string;
  action: FolderActionsType;
  data?: {
    newName?: string;
  };
}

export const folderActions = createAsyncThunk<
  IVFSResponse<FolderActionsResponse>,
  FolderActionsParams
>('library/folderActions', async ({ folderId, action, data }, thunkAPI) => {
  switch (action) {
    case 'rename':
      if (!data?.newName) {
        throw new Error('New name is required for rename action');
      }
      break;
    default:
  }
  try {
    const response = await folderActionsV2({
      folderId,
      action,
      data,
    });
    if (response.data.success) {
      return response.data;
    }
    throw new Error('server error');
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const createFolder = createAsyncThunk<
  FolderType,
  string,
  { state: RootState }
>('library/createFolder', async (name, { getState, rejectWithValue }) => {
  try {
    const { team } = getState();
    const teamId = team.selectedTeam?.teamId;
    if (!teamId) {
      throw new Error('Team ID is not available.');
    }
    const response = await createNewFolder({
      name,
      teamId,
    });
    return response.data.data.folder;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

type FetchFilesReturnType = {
  files: FileType[];
  pagination: Pagination;
  librarySize: number;
  freeUserTimeLimit: Date | null;
};
export const fetchFiles = createAsyncThunk<
  FetchFilesReturnType,
  Parameters<typeof fetchFileSystem>[0],
  { state: RootState }
>('library/fetchFiles', async (params) => {
  const data = await fetchFileSystem(params);
  const {
    fileSystem,
    pagination,
    librarySize,
    freeUserTimeLimit,
  } = data.data.data;
  return {
    files: fileSystem,
    librarySize,
    pagination,
    freeUserTimeLimit,
  };
});

interface FetchFoldersParams {
  teamId: string;
}
interface FetchFoldersReturnType {
  folders: FolderType[];
}

export const fetchFolders = createAsyncThunk<
  FetchFoldersReturnType,
  FetchFoldersParams
>('library/fetchFolders', async ({ teamId }) => {
  const response = await getFolders({ teamId });
  const { folders } = response.data.data;
  return { folders };
});

export const downloadMultipleFiles = createAsyncThunk(
  'library/downloadMultipleFiles',
  async (files: FileType[], { rejectWithValue }) => {
    try {
      const downloadResults = await Promise.all(
        files.map(async (fileData) => {
          try {
            const { url } = await getDownloadUrl({
              fileId: fileData._id,
              profile: fileData.defaultProfile as ProfileType,
            });
            if (url) {
              downloadVideo(url, fileData._id);
            }
            return { success: true, url, fileId: fileData._id };
          } catch (error) {
            return {
              success: false,
              fileId: fileData._id,
              error: error.message,
            };
          }
        })
      );

      const failedDownloads = downloadResults.filter(
        (result) => !result.success
      );

      if (failedDownloads.length > 0) {
        return rejectWithValue({
          message: 'Some files failed to download',
          failedDownloads,
        });
      }

      return downloadResults;
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const addFileTag = createAsyncThunk(
  'library/addFileTag',
  async ({
    fileId,
    tag,
  }: {
    fileId: string;
    tag: { key: string; value: string };
  }) => {
    await addFileTag({ fileId, tag });
  }
);

export const updatePermissions = createAsyncThunk(
  'library/updatePermissions',
  async (
    {
      folderId,
      permissions,
    }: {
      folderId: string;
      permissions: FolderPermission[] | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateFolderPermissions(folderId, permissions);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    selectFolder: (state, action: PayloadAction<FolderType>) => {
      state.selectedFolder = action.payload;
    },
    setFolders: (state, action: PayloadAction<FolderType[]>) => {
      state.folders.data = action.payload;
    },
    selectFile: (state, action: PayloadAction<FileType>) => {
      const file = action.payload;
      state.selectedFiles = {
        [file._id]: file,
      };
    },
    selectSingleFile: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      state.selectedFiles = {};
      const file = state.files.data.find(
        (f) => f._id === action.payload.fileId
      );
      if (file) {
        state.selectedFiles[file._id] = file;
      }
    },
    deselectFile: (state, action: PayloadAction<FileType>) => {
      const file = action.payload;
      delete state.selectedFiles[file._id];
    },
    deselectAllFiles: (state) => {
      state.selectedFiles = {};
    },
    toggleSelectFile: (state, action: PayloadAction<FileType>) => {
      const file = action.payload;
      if (state.selectedFiles[file._id]) {
        delete state.selectedFiles[file._id];
      } else {
        state.selectedFiles[file._id] = file;
      }
    },
    addFile: (state, action: PayloadAction<FileType>) => {
      state.files.data.unshift(action.payload);
    },
    updateFile: (
      state,
      action: PayloadAction<Pick<FileType, '_id' | 'name'>>
    ) => {
      const { _id, name } = action.payload;
      state.files.data = state.files.data.map((f) =>
        f._id === _id ? { ...f, name } : f
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createFolder.pending, (state) => {
      state.folders.status = 'pending';
    });
    builder.addCase(
      createFolder.fulfilled,
      (state, action: PayloadAction<FolderType>) => {
        state.folders.status = 'idle';
        state.folders.data.push(action.payload);
      }
    );
    builder.addCase(createFolder.rejected, (state) => {
      state.folders.status = 'failed';
    });
    // Files
    builder.addCase(fetchFiles.pending, (state) => {
      state.files.status = 'pending';
    });
    builder.addCase(fetchFiles.fulfilled, (state, action) => {
      state.files.status = 'succeeded';
      if (action.meta.arg?.page && action.meta.arg.page > 1) {
        state.files.data = [...state.files.data, ...action.payload.files];
      } else {
        state.files.data = action.payload.files;
      }
      state.files.pagination = action.payload.pagination;
      state.selectedFiles = {};
      state.files.freeUserTimeLimit = action.payload.freeUserTimeLimit;
    });
    builder.addCase(fetchFiles.rejected, (state) => {
      state.files.status = 'failed';
    });
    // Folders
    builder.addCase(fetchFolders.pending, (state) => {
      state.folders.status = 'pending';
    });
    builder.addCase(fetchFolders.fulfilled, (state, action) => {
      state.folders.status = 'succeeded';
      state.folders.data = action.payload.folders;
    });
    builder.addCase(fetchFolders.rejected, (state) => {
      state.folders.status = 'failed';
    });
    // Folder Actions
    builder.addCase(folderActions.pending, (state, action) => {
      const folderActionType = action.meta.arg.action;
      state.actions[folderActionType].status = 'pending';
    });
    builder.addCase(
      folderActions.fulfilled,
      (state, action: PayloadAction<IVFSResponse<FolderActionsResponse>>) => {
        const folderActionType = action.payload.data.folderAction;
        state.actions[folderActionType].status = 'succeeded';
        state.folders.data = action.payload.data.folders;
      }
    );
    builder.addCase(folderActions.rejected, (state, action) => {
      const folderActionType = action.meta.arg.action;
      state.actions[folderActionType].status = 'failed';
    });
    builder.addCase(deleteFile.pending, (state, action) => {
      state.files.data = state.files.data.filter(
        (file) => file._id !== action.meta.arg
      );
    });
    builder.addCase(updatePermissions.pending, (state) => {
      state.actions.permissions.status = 'pending';
    });
    builder.addCase(
      updatePermissions.fulfilled,
      (state, action: PayloadAction<UpdateFolderPermissionsResponse>) => {
        state.actions.permissions.status = 'succeeded';
        const index = state.folders.data.findIndex(
          (folder) => folder._id === action.payload.folder._id
        );
        if (index !== -1) {
          state.folders.data[index] = action.payload.folder;
        }
        if (state.selectedFolder?._id === action.payload.folder._id) {
          state.selectedFolder = action.payload.folder;
        }
      }
    );
    builder.addCase(updatePermissions.rejected, (state) => {
      state.actions.permissions.status = 'failed';
    });
  },
});

export const {
  selectFolder,
  setFolders,
  toggleSelectFile,
  deselectAllFiles,
  addFile,
  selectSingleFile,
  updateFile,
} = librarySlice.actions;
export default librarySlice.reducer;

export const selectTotalFiles = (state: RootState) => {
  return state.library.files.data.length;
};
