import React from 'react';
import { Typography } from '@mui/material';
import { FileType } from '../../../types';
import FileList from './FileList';

interface SearchResultsProps {
  results: {
    data: FileType[];
    error: string | null;
  };
  isFileListExpanded: boolean;
  toggleFileList: () => void;
  fileThumbnails: Array<{
    fileId: string;
    thumbnailUrl: string | null;
    isLoading: boolean;
  }>;
  setFileThumbnails: React.Dispatch<
    React.SetStateAction<
      Array<{
        fileId: string;
        thumbnailUrl: string | null;
        isLoading: boolean;
      }>
    >
  >;
  handleClose: () => void;
  isLoading?: boolean;
}

/**
 * SearchResults component displays the search results, including a file list
 * and handles related functionality
 */
const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  isFileListExpanded,
  toggleFileList,
  fileThumbnails,
  setFileThumbnails,
  isLoading = false,
}) => {
  return (
    <>
      {/* File List Section with Toggle */}
      {results.data.length > 0 && (
        <FileList
          files={results.data}
          isExpanded={isFileListExpanded}
          onToggleExpand={toggleFileList}
          fileThumbnails={fileThumbnails}
          setFileThumbnails={setFileThumbnails}
          isLoading={isLoading}
        />
      )}

      {/* Display message when no results found */}
      {results.data.length === 0 && (
        <Typography variant="subtitle2" color="text.secondary" sx={{ p: 1 }}>
          No files found
        </Typography>
      )}
    </>
  );
};

export default SearchResults;
