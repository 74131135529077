import moment from 'moment';
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  RecorderSettings,
  RecoverySettings,
  TeamRole,
  UISettings,
} from '../../types/state';
import { requestStatusType, IntegrationsSettings } from '../../types';
import {
  getSettings,
  newUpdateSettings,
  updateProfile,
  addUserTag,
  V2SettingsKeys,
} from '../../services/authService';
import {
  BackupRecord,
  deleteBackupRecord,
  deleteChunks,
  getBackupRecords,
  listChunks,
  loadChunk,
} from '../../lib/recovery';
import { RootState } from '../../reducers';
import {
  createPartUploadUrl,
  initializeMultipartUpload,
  uploadChunkToS3,
  finalizeUpload,
} from '../../recorder/uploadService';
import { getInitialState } from '../app/appSlice';
import {
  addUserWebhookUrl,
  removeUserWebhookUrl,
  disconnectZoomIntegration,
} from '../../services/integrationService';
import { addNotification } from '../dashboard/dashboardSlice';

export const SETTINGS = [
  'user',
  'team',
  'recorder',
  'plugin',
  'billing',
  'ai',
  'recovery',
  'usage',
  'ui',
  'integrations',
  'meetingbot',
  'display',
] as const;

export type SettingsKeys = typeof SETTINGS[number];

export interface UserSettings {
  _id: string;
  name: string;
  email: string;
  picture: string;
  role: TeamRole;
  deleteRequestedAt?: string;
  createdAt: string;
  tags: {
    [key: string]: string;
  };
  integrations: IntegrationsSettings;
}

interface UserSettingsResponse {
  name: string;
  email: string;
  picture: string;
}

// interface UserSettingsResponse {
//   name: string;
//   email: string;
//   picture: string;
// }

interface SettingsState {
  status: requestStatusType;
  updateSettings: {
    status: requestStatusType;
  };
  user: UserSettings;
  recorder: RecorderSettings;
  plugin: RecorderSettings;
  recovery: RecoverySettings;
  ui: UISettings;
}

interface FetchSettingsResponse {
  key: SettingsKeys;
  data: RecorderSettings | UserSettingsResponse | UISettings;
}

export type NewSettings = RecorderSettings | UserSettings | UISettings;

const initialSettings: RecorderSettings = {
  audioSettings: {
    browserAudio: false,
    microphoneAudio: false,
    systemAudio: false,
  },
  videoSettings: {
    webCam: false,
    screen: true,
  },
  deviceSettings: {
    webcamDeviceId: '',
    microphoneDeviceId: '',
  },
  recordingControlSettings: {
    startRecordingAuto: false,
    autoDownload: true,
    defaultRecordingFormat: 'mkv',
    maxRecordingTimeMins: 10,
  },
  uiSettings: {
    hideLogo: false,
    recorderButtonText: 'Share Your Screen',
    position: 'BOTTOM_LEFT',
    helpText: 'Feedbacks',
    identificationForm: true,
  },
  otherSettings: {
    webhookUrl: '',
    hideTitleAndDescriptionDialog: true,
    defaultTranscriptionLanguage: undefined,
    formatProfile: 'mkv',
    generatePublicUrl: false,
    downloadSharedVideos: false,
    emailAlerts: false,
  },
};

const initialState: SettingsState = {
  status: 'idle',
  user: {
    _id: '',
    name: '',
    email: '',
    picture: '',
    role: 'Guest',
    createdAt: '',
    tags: {},
    integrations: {
      google: {
        calendar: {
          isActive: false,
          reconnect: false,
          personName: null,
          personEmail: null,
          upcomingEvent: {
            joined: false,
            eventUrl: null,
            startTime: null,
            eventId: null,
            eventName: null,
          },
        },
        googleMeet: {
          token: null,
          refreshToken: null,
          channelId: null,
          expirationTime: null,
          fileId: null,
        },
      },
      zoom: {
        accessToken: '',
        refreshToken: '',
        expiresAt: '',
        zoomUserId: '',
        email: '',
        isConnected: false,
      },
      webhooks: [],
    },
  },
  recorder: initialSettings,
  plugin: initialSettings,
  ui: {
    folderListExpanded: false,
  },
  recovery: {
    backupRecords: [],
    recoveryState: {},
    status: 'idle',
  },
  updateSettings: {
    status: 'idle',
  },
};

export const updateUserProfile = createAsyncThunk(
  'settings/updateUserProfile',
  async (
    data: Parameters<typeof updateProfile>[0],
    { rejectWithValue, getState }
  ) => {
    try {
      const response = await updateProfile(data);
      if (response?.status === 200) {
        // If we have picture data in the request, construct the full picture URL
        // This ensures we have the updated picture in the state immediately
        const state = getState() as RootState;
        const currentUser = state.settings.user;

        // Handle profile picture update
        if (data.picture === '') {
          // For profile picture removal, use empty string
          return {
            ...response.data.data,
            picture: '', // Empty string to remove the picture on the server
            name: data.name || currentUser.name,
          };
        }

        if (data.picture && data.pictureMime) {
          // For profile picture update with new image
          return {
            ...response.data.data,
            picture: `data:${data.pictureMime};base64,${data.picture}`,
            name: data.name || currentUser.name,
          };
        }

        // For name update only
        return {
          ...response.data.data,
          name: data.name || currentUser.name,
        };
      }
      throw new Error('Failed to update profile');
    } catch (error) {
      return rejectWithValue(
        error.response?.data || { message: 'Failed to update profile' }
      );
    }
  }
);

export const loadBackupRecordsThunk = createAsyncThunk(
  'settings/recovery/loadBackupRecords',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const files = new Set(state.library.files.data.map((f) => f._id));
    const teamId = state.team.selectedTeam?.teamId;
    try {
      const backupRecords = await getBackupRecords(teamId);
      backupRecords.sort((a, b) => b.startedAt - a.startedAt);
      const recoveryState = Object.fromEntries(
        backupRecords.map((r) => [
          r.fileId,
          {
            status: (files.has(r.fileId)
              ? 'success'
              : 'pending') as RecoverySettings['recoveryState'][string]['status'],
          },
        ])
      );
      return { backupRecords, recoveryState };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Do not use this directly, use useTags hook instead
export const addTagToUserThunk = createAsyncThunk(
  'settings/user/tag/add',
  async (
    { key, value }: { key: string; value: string },
    { rejectWithValue }
  ) => {
    try {
      await addUserTag({ key, value });
      return { key, value };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadBackupRecordProgress = createAction<{
  total: number;
  loaded: number;
  oldFileId: string;
}>('settings/recovery/uploadBackupFile/progress');

export const uploadBackupRecordThunk = createAsyncThunk(
  'settings/recovery/uploadBackupFile',
  async (
    backupRecord: BackupRecord,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;

      const teamId =
        backupRecord.teamId ||
        state.team.selectedTeam?.teamId ||
        state.team.teams[0].teamId;
      const folderId =
        backupRecord.folderId || state.library.selectedFolder?._id || '';

      const response = await initializeMultipartUpload({
        teamId,
        folderId,
        contentType: 'video/x-matroska',
      });
      const { fileId: newFileId, uploadId: newUploadId } = response;
      const { fileId: oldFileId, uploadId: oldUploadId } = backupRecord;

      const chunks = await listChunks(oldFileId);
      dispatch(
        uploadBackupRecordProgress({
          oldFileId,
          total: chunks.length,
          loaded: 0,
        })
      );

      for (let i = 0; i < chunks.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        const partNumber = i + 1;
        const chunk = await loadChunk(oldFileId, partNumber);
        const url = await createPartUploadUrl({
          teamId,
          folderId,
          fileId: newFileId,
          uploadId: newUploadId,
          partNumber,
          contentType: 'video/x-matroska',
        });
        await uploadChunkToS3({ uploadUrl: url, chunk });
        dispatch(
          uploadBackupRecordProgress({
            oldFileId,
            total: chunks.length,
            loaded: i + 1,
          })
        );
        /* eslint-enable no-await-in-loop */
      }

      const title = `Recovered ${moment(backupRecord.startedAt).format(
        'h:mm a MMM D YYYY'
      )}`;

      await finalizeUpload({
        teamId,
        folderId,
        uploadId: newUploadId,
        fileId: newFileId,
        contentType: 'video/x-matroska',
        notes: [],
        title,
      });

      // Add success notification
      dispatch(
        addNotification({
          id: Date.now().toString(),
          type: 'transcription',
          title: 'Recording Recovered',
          message: `"${title}" has been recovered successfully`,
          link: `/library/${teamId}/file/${newFileId}`,
          read: false,
          timestamp: Date.now(),
        })
      );

      return {
        newFileId,
        newUploadId,
        oldFileId,
        oldUploadId,
        status: 'success',
      };
    } catch (error) {
      // Add error notification
      dispatch(
        addNotification({
          id: Date.now().toString(),
          type: 'error',
          title: 'Recovery Failed',
          message: `Failed to recover recording: ${error.message}`,
          read: false,
          timestamp: Date.now(),
        })
      );
      return rejectWithValue({ error, backupRecord });
    }
  }
);

export const deleteBackupRecordThunk = createAsyncThunk(
  'settings/recovery/deleteBackupRecord',
  async (fileId: string, { rejectWithValue }) => {
    try {
      await deleteChunks(fileId);
      await deleteBackupRecord(fileId);

      return fileId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSettings = createAsyncThunk<
  FetchSettingsResponse,
  V2SettingsKeys
>(
  'settings/fetchSettings',
  async (key: V2SettingsKeys, { rejectWithValue }) => {
    try {
      const settings = await getSettings(key);
      return { key, data: settings };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (
    { key, newSettings }: { key: SettingsKeys; newSettings: NewSettings },
    { rejectWithValue }
  ) => {
    try {
      const response = await newUpdateSettings(key, newSettings);
      if (response?.status === 200) {
        return { key, data: response.data.data };
      }
      throw new Error('Failed to update settings');
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addWebhookUrlThunk = createAsyncThunk(
  'settings/integrations/webhook/add',
  async (
    {
      name,
      url,
      templateId,
    }: { name: string; url: string; templateId: string },
    { rejectWithValue }
  ) => {
    try {
      await addUserWebhookUrl({ name, url, templateId });
      return { name, url, templateId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeWebhookUrlThunk = createAsyncThunk(
  'settings/integrations/webhook/remove',
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      await removeUserWebhookUrl({ url });
      return { url };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const disconnectZoom = createAsyncThunk(
  'settings/disconnectZoom',
  async (_, { dispatch }) => {
    try {
      const message = await disconnectZoomIntegration();
      dispatch(
        addNotification({
          id: Date.now().toString(),
          title: 'Zoom Integration',
          message: message || 'Zoom account disconnected successfully',
          type: 'team',
          read: false,
          timestamp: Date.now(),
        })
      );
      return true;
    } catch (error) {
      dispatch(
        addNotification({
          id: Date.now().toString(),
          title: 'Zoom Integration',
          message: 'Failed to disconnect Zoom account',
          type: 'error',
          read: false,
          timestamp: Date.now(),
        })
      );
      throw error;
    }
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserSettings>>) => {
      // This is called during initialization with full user data
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    makeUpdateSettingsStatusIdle: (state) => {
      state.updateSettings.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialState.fulfilled, (state, action) => {
        // Settings from /init endpoint
        state.ui = action.payload.settings.ui;
        state.recorder = action.payload.settings.recorder;
        state.plugin = action.payload.settings.plugin;
      })
      .addCase(fetchSettings.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        const { key, data } = action.payload;
        if (key === 'recorder' || key === 'plugin') {
          state[key] = data as RecorderSettings;
        } else if (key === 'user') {
          // Only update specific user fields from settings endpoint
          const userSettings = data as UserSettingsResponse;
          state.user = {
            ...state.user,
            name: userSettings.name,
            email: userSettings.email,
            picture: userSettings.picture,
          };
        } else if (key === 'ui') {
          state.ui = data as UISettings;
        }
        state.status = 'succeeded';
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateSettings.pending, (state, action) => {
        const { key, newSettings } = action.meta.arg;
        state.updateSettings.status = 'pending';
        if (key === 'recorder' || key === 'plugin') {
          state[key] = newSettings as RecorderSettings;
        } else if (key === 'ui' || key === 'meetingbot') {
          state.ui = newSettings as UISettings;
        } else if (key === 'user') {
          // Only update specific user fields
          const userSettings = newSettings as UserSettingsResponse;
          state.user = {
            ...state.user,
            name: userSettings.name,
            email: userSettings.email,
            picture: userSettings.picture,
          };
        }
      })
      .addCase(updateSettings.fulfilled, (state) => {
        state.updateSettings.status = 'succeeded';
      })
      .addCase(updateSettings.rejected, (state) => {
        state.updateSettings.status = 'failed';
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.updateSettings.status = 'succeeded';
        if (action.payload) {
          // Update the user profile with the response data
          state.user = {
            ...state.user,
            ...action.payload,
          };

          // Ensure the picture field is properly updated
          if (
            action.payload &&
            typeof action.payload === 'object' &&
            'picture' in action.payload
          ) {
            state.user.picture = action.payload.picture as string;
          }

          // Ensure the name field is properly updated
          if (
            action.payload &&
            typeof action.payload === 'object' &&
            'name' in action.payload
          ) {
            state.user.name = action.payload.name as string;
          }
        }
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.updateSettings.status = 'failed';
      })
      .addCase(loadBackupRecordsThunk.fulfilled, (state, action) => {
        state.recovery.backupRecords = action.payload.backupRecords;
        state.recovery.recoveryState = action.payload.recoveryState;
        state.recovery.status = 'succeeded';
      })
      .addCase(loadBackupRecordsThunk.rejected, (state) => {
        state.recovery.status = 'failed';
      })
      .addCase(deleteBackupRecordThunk.fulfilled, (state, action) => {
        state.recovery.backupRecords = state.recovery.backupRecords.filter(
          (r) => r.fileId !== action.payload
        );
        delete state.recovery.recoveryState[action.payload];
      })
      .addCase(deleteBackupRecordThunk.rejected, () => {
        // do nothing
      })
      .addCase(uploadBackupRecordThunk.fulfilled, (state, action) => {
        const fileId = action.payload.oldFileId;
        state.recovery.recoveryState[fileId].status = 'success';
        state.recovery.recoveryState[fileId].loaded = 0;
        state.recovery.recoveryState[fileId].total = 0;
      })
      .addCase(uploadBackupRecordThunk.rejected, (state, action) => {
        const { fileId } = action.meta.arg;
        state.recovery.recoveryState[fileId].status = 'error';
        state.recovery.recoveryState[fileId].loaded = 0;
        state.recovery.recoveryState[fileId].total = 0;
      })
      .addCase(uploadBackupRecordProgress, (state, action) => {
        const fileId = action.payload.oldFileId;
        state.recovery.recoveryState[fileId].status = 'ongoing';
        state.recovery.recoveryState[fileId].loaded = action.payload.loaded;
        state.recovery.recoveryState[fileId].total = action.payload.total;
      })
      .addCase(addTagToUserThunk.pending, (state, action) => {
        state.updateSettings.status = 'pending';
        state.user.tags[action.meta.arg.key] = action.meta.arg.value;
      })
      .addCase(addWebhookUrlThunk.fulfilled, (state, action) => {
        const { name, url } = action.payload;
        state.user.integrations.webhooks.push({ name, url });
      })
      .addCase(removeWebhookUrlThunk.fulfilled, (state, action) => {
        const { url } = action.payload;
        state.user.integrations.webhooks = state.user.integrations.webhooks.filter(
          (webhook) => webhook.url !== url
        );
      })
      .addCase(disconnectZoom.fulfilled, (state) => {
        state.user.integrations.zoom = {
          accessToken: '',
          refreshToken: '',
          expiresAt: '',
          zoomUserId: '',
          email: '',
          isConnected: false,
        };
      });
  },
});

export const { setUser, makeUpdateSettingsStatusIdle } = settingsSlice.actions;
export default settingsSlice.reducer;
