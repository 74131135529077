import { IUsage, PackageProperties } from '../features/plan/userPackage';
import { apiV2 } from '../lib/baseAxios';
import { TeamSubscription } from '../types/state';
import { IVFSResponse } from './fileSystemService';

interface ChurnkeyData {
  authHash: string;
  customerId: string;
  subscriptionId: string;
}

export const getChurnkeyData = async ({ teamId }: { teamId: string }) => {
  const response = await apiV2.delete<IVFSResponse<ChurnkeyData>>(
    `/billing/subscriptions/${teamId}/cancel/churnkey`
  );
  return response.data.data;
};

export interface GetSubscriptionResponseData {
  subscription: TeamSubscription;
  packageProperties: PackageProperties;
  usage: IUsage;
}

export const getSubscriptionDetails = async ({
  teamId,
}: {
  teamId: string;
}): Promise<GetSubscriptionResponseData> => {
  const response = await apiV2.get<IVFSResponse<GetSubscriptionResponseData>>(
    `/billing/subscriptions/${teamId}`
  );
  return response.data.data;
};

export const createOrUpdateSubscription = async ({
  teamId,
  totalAmount,
  plan,
  term,
  promoCode,
  trialLabel,
  metadata,
}: {
  teamId: string;
  totalAmount: number;
  plan: string;
  term: string;
  promoCode?: string;
  trialLabel?: string;
  metadata?: {
    toltReferral?: string;
  };
}): Promise<GetSubscriptionResponseData> => {
  const response = await apiV2.put<IVFSResponse<GetSubscriptionResponseData>>(
    `/billing/subscriptions/${teamId}`,
    {
      amount: totalAmount,
      plan,
      term,
      promoCode,
      metadata,
      trialLabel,
    }
  );

  return response.data.data;
};

export const getSubscriptionUpgradeStatus = async (
  teamId: string
): Promise<GetSubscriptionResponseData | undefined> => {
  const response = await apiV2.post<
    IVFSResponse<GetSubscriptionResponseData> | undefined
  >(`/billing/subscriptions/${teamId}/upgrade/status`);
  const { subscription, packageProperties, usage } = response?.data?.data || {};
  if (subscription && packageProperties && usage) {
    return { subscription, packageProperties, usage };
  }
  return undefined;
};

export const getUsage = async (teamId: string): Promise<IUsage> => {
  const response = await apiV2.get<IVFSResponse<IUsage>>(
    `/billing/subscriptions/${teamId}/usage`
  );
  return response.data.data;
};

export const setupPayments = async ({
  teamId,
  ...data
}: {
  teamId: string;
  metadata: {
    toltReferral?: string;
  };
}): Promise<{ clientSecret: string }> => {
  const response = await apiV2.post(
    `/billing/subscriptions/${teamId}/setup-payments`,
    data
  );
  return response.data.data;
};
