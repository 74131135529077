/* eslint-disable no-bitwise */
import React from 'react';
import { Box, keyframes } from '@mui/material';
import { styled } from '@mui/system';

const calculateNewHeight = (height: number, factor: number) => height * factor;

const animloader1 = (factor: number) => keyframes`
  0% { height: 1.287rem; }
  100% { height: ${calculateNewHeight(1.287, factor)}rem; }
`;

const animloader2 = (factor: number) => keyframes`
  0% { height: 4.1095rem; }
  100% { height: ${calculateNewHeight(4.1095, factor)}rem; }
`;

const animloader3 = (factor: number) => keyframes`
  0% { height: 4.2475rem; }
  100% { height: ${calculateNewHeight(4.2475, factor)}rem; }
`;

const animloader4 = (factor: number) => keyframes`
  0% { height: 3.6215rem; }
  100% { height: ${calculateNewHeight(3.6215, factor)}rem; }
`;

const animloader5 = (factor: number) => keyframes`
  0% { height: 1.239rem; }
  100% { height: ${calculateNewHeight(1.239, factor)}rem; }
`;

const LoaderBar = styled(Box)(() => ({
  width: '1.0225rem',
  borderRadius: '20px',
  position: 'relative',
  boxSizing: 'border-box',
}));

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const rgbToHex = (r: number, g: number, b: number) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
};

const interpolateColor = (color1: string, color2: string, factor: number) => {
  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);
  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));
  return rgbToHex(r, g, b);
};

const getColor = (index: number, totalBars: number) =>
  interpolateColor('#DF1B7D', '#FF4757', index / (totalBars - 1));

const LoaderComponent = () => {
  const bars = [
    { factor: 0.5, duration: '0.2s', marginTop: '0rem' },
    { factor: 0.6, duration: '0.3s', marginTop: '0rem' },
    { factor: 0.5, duration: '0.5s', marginTop: '1.563rem' },
    { factor: 0.5, duration: '0.4s', marginTop: '0rem' },
    { factor: 0.65, duration: '0.25s', marginTop: '0rem' },
  ];

  const animations = [
    animloader1,
    animloader2,
    animloader3,
    animloader4,
    animloader5,
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        id="screenapp-loader"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '3.125rem',
          gap: '0.469rem',
        }}
        data-testid="loader-component"
      >
        {bars.map((bar, index) => (
          <LoaderBar
            key={index}
            sx={{
              animation: `${animations[index](bar.factor)} ${
                bar.duration
              } linear infinite alternate`,
              marginTop: bar.marginTop,
              background: getColor(index, bars.length),
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LoaderComponent;
