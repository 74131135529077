import React, {
  useState,
  useEffect,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { MenuItem, Tooltip, CircularProgress, Box } from '@mui/material';
import { Download } from '@mui/icons-material';

export interface ActionMenuItemProps {
  tooltipMessage: string;
  flashTooltip?: boolean;
  loading?: boolean;
  startIcon?: ReactNode;
  onClick?: () => void;
}

const ActionMenuItem: FC<PropsWithChildren<ActionMenuItemProps>> = ({
  tooltipMessage,
  flashTooltip = false,
  loading = false,
  onClick,
  startIcon = <Download />,
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (flashTooltip) {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
    }
  }, [flashTooltip]);

  return (
    <Tooltip
      title={tooltipMessage}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      disableFocusListener={!flashTooltip}
      disableHoverListener={!flashTooltip}
      disableTouchListener={!flashTooltip}
    >
      <MenuItem
        onClick={onClick}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        {loading && <CircularProgress size={16} sx={{ mr: 1 }} />}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            mr: 1,
          }}
        >
          {!loading && startIcon}
        </Box>
        {children}
      </MenuItem>
    </Tooltip>
  );
};

export default ActionMenuItem;
