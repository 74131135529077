import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import type { SagaMiddleware } from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import type { Middleware, AnyAction } from 'redux';
import ReduxThunk from 'redux-thunk';
import type { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './reducers';
import { config } from '../config';
import posthogMiddleware from './middleware/posthogMiddleware';
import socketMiddleware from './middleware/socketMiddleware';
import chartMogulMiddleware from './middleware/chartMogulMiddleware';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware: Array<
  Middleware<{}, any> | ThunkMiddleware<any, AnyAction> | SagaMiddleware<object>
> = [
  sagaMiddleware,
  ReduxThunk,
  posthogMiddleware,
  socketMiddleware,
  chartMogulMiddleware,
];
if (config.debug) {
  middleware.push(
    createLogger({
      diff: true,
    }) as Middleware<{}, any> // Ensure the logger middleware matches the expected type
  );
}
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export type AppDispatch = typeof store.dispatch;
export default store;
