import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Box,
  MenuItem,
  Select,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FolderInput,
  Folder,
  Users,
  Lock,
  Rocket,
  Video,
  Youtube,
  Mic2,
  Music2,
  Radio,
  Headphones,
  Speaker,
  Podcast,
  Code,
  DollarSign,
  BarChart,
  Settings,
  PhoneCall,
  Layout,
  FileText,
  CalendarDays,
  GraduationCap,
  Briefcase,
  Building2,
  Lightbulb,
  Microscope,
  Activity,
  Plane,
  Film,
  Home,
  Utensils,
  Laptop,
  Trophy,
  ShoppingCart,
  Target,
  UsersRound,
  Scale,
  ClipboardList,
  BarChart3,
} from 'lucide-mui';
import { RootState } from '../../../../reducers';
import { getTeamFolders, moveFile } from '../../selectedVideoSlice';
import useLimits from '../../../plan/hooks/limits';
import useTags from '../../../../hooks/useTags';

const ICON_MAP = {
  Folder,
  Video,
  Youtube,
  Mic: Mic2,
  Music: Music2,
  Radio,
  Headphones,
  Speaker,
  Podcast,
  Code,
  DollarSign,
  BarChart,
  Settings,
  PhoneCall,
  Layout,
  FileText,
  CalendarDays,
  GraduationCap,
  Briefcase,
  Building2,
  Users,
  Lightbulb,
  Microscope,
  Activity,
  Plane,
  Film,
  Home,
  Utensils,
  Laptop,
  Trophy,
  ShoppingCart,
  Target,
  UsersRound,
  Scale,
  ClipboardList,
  BarChart3,
};

const MoveFileModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const guardLimits = useLimits();
  const { getTag } = useTags();
  const { team, selectedVideo } = useSelector((state: RootState) => ({
    team: state.team,
    selectedVideo: state.selectedVideo,
  }));
  const { selectedTeam, teams } = team;
  const { actions } = selectedVideo;
  const { globalModals } = useSelector((state: RootState) => state);
  const { files } = globalModals.actionData || {};
  const { folders } = actions.move;

  const folderIconsTag = getTag('folder_icons');
  const folderIcons = folderIconsTag ? JSON.parse(folderIconsTag) : {};

  const [selectedTeamId, setSelectedTeamId] = useState<string>(
    selectedTeam?.teamId || ''
  );
  const [selectedFolderId, setSelectedFolderId] = useState<string>('PRIVATE');

  const noFolders = folders.length === 0;
  const disableMoveButton = !selectedTeamId || noFolders;

  useEffect(() => {
    if (selectedTeamId) {
      dispatch(getTeamFolders(selectedTeamId));
    }
  }, [selectedTeamId]);

  const handleTeamChange = (event: any) => {
    const teamId = event.target.value;
    setSelectedFolderId('PRIVATE');
    setSelectedTeamId(teamId);
    dispatch(getTeamFolders(teamId));
  };

  const handleFolderChange = (event: any) => {
    setSelectedFolderId(event.target.value);
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleUpgradeClick = () => {
    guardLimits('folderManagement', () => {
      // Handle upgrade logic here, if needed.
    });
  };

  const handleMove = async () => {
    if (!files?.length || disableMoveButton) return;
    await Promise.all(
      files.map((file) =>
        dispatch(
          moveFile({
            fileId: file._id,
            toTeamId: selectedTeamId,
            toFolderId:
              selectedFolderId === 'PRIVATE' ? 'private' : selectedFolderId,
          })
        )
      )
    );
    navigate(
      `/library/${selectedTeamId}/${
        selectedFolderId === 'PRIVATE' ? 'private' : selectedFolderId
      }`
    );
    handleClose();
  };

  const getFolderIcon = (folderId: string) => {
    const iconName = folderIcons[folderId] || 'Folder';
    const IconComponent = ICON_MAP[iconName as keyof typeof ICON_MAP] || Folder;
    return IconComponent;
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '28px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 4,
          pt: 3,
          pb: 2,
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        Move
      </DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box
          sx={{
            color: 'text.secondary',
            typography: 'body1',
          }}
        >
          Recordings are stored in your private folder by default. To share
          videos with your team, move recordings to a folder.
        </Box>
        <Box>
          <InputLabel
            variant="standard"
            htmlFor="team-select"
            sx={{
              mb: 1,
              color: 'text.secondary',
            }}
          >
            Select a team
          </InputLabel>
          <Select
            id="team-select"
            data-testid="team-select"
            value={selectedTeamId}
            onChange={handleTeamChange}
            fullWidth
            size="small"
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '24px',
              },
            }}
          >
            {teams.map(({ teamId, name }) => (
              <MenuItem key={teamId} value={teamId}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Users fontSize="small" />
                  {name}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <InputLabel
            variant="standard"
            htmlFor="folder-select"
            sx={{
              mb: 1,
              color: 'text.secondary',
            }}
          >
            Select a folder
          </InputLabel>
          <Select
            id="folder-select"
            data-testid="folder-select"
            value={selectedFolderId}
            onChange={handleFolderChange}
            fullWidth
            size="small"
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 200,
                  overflowY: 'auto',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '24px',
              },
            }}
          >
            <MenuItem value="PRIVATE">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Lock fontSize="small" />
                Private Folder
              </Box>
            </MenuItem>
            {folders.map((folder) => {
              const FolderIcon = getFolderIcon(folder._id);
              return (
                <MenuItem key={folder._id} value={folder._id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FolderIcon fontSize="small" />
                    {folder.name}
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {!guardLimits('folderManagement') && (
          <Alert
            severity="info"
            icon={<Rocket />}
            action={
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={handleUpgradeClick}
                sx={{
                  borderRadius: '24px',
                  textTransform: 'none',
                }}
              >
                Upgrade Now
              </Button>
            }
            sx={{
              borderRadius: '24px',
              '& .MuiAlert-icon': {
                color: 'primary.main',
              },
            }}
          >
            Upgrade to create & organize folders
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          px: 4,
          pb: 3,
          gap: 1,
        }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          data-testid="cancel-button-move-modal"
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleMove}
          variant="contained"
          color="primary"
          disabled={disableMoveButton}
          data-testid="move-button-move-modal"
          startIcon={<FolderInput />}
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveFileModal;
