import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addTagToUserThunk } from '../settings/settingsSlice';
import type { RootState } from '../../reducers';

const MAINTENANCE_START = '2024-12-29T04:00:00Z'; // 9:30 IST Sunday
const MAINTENANCE_END = '2024-12-29T10:00:00Z'; // 15:30 IST Sunday

const MaintenanceBanner: React.FC = () => {
  const dispatch = useDispatch();
  const userTags = useSelector((state: RootState) => state.settings.user.tags);

  const maintenanceStartDate = new Date(MAINTENANCE_START);
  const maintenanceEndDate = new Date(MAINTENANCE_END);
  const now = new Date();

  // Create a unique key for this maintenance window
  const dismissalKey = `maintenance_${MAINTENANCE_START.slice(0, 10)}`;

  // Show banner if maintenance starts in less than 24 hours or if not dismissed
  const timeUntilMaintenance = maintenanceStartDate.getTime() - now.getTime();
  const isWithin24Hours =
    timeUntilMaintenance > 0 && timeUntilMaintenance < 24 * 60 * 60 * 1000;
  const lastDismissed = userTags[dismissalKey];
  const showBanner =
    isWithin24Hours || (!lastDismissed && timeUntilMaintenance > 0);

  if (!showBanner) {
    return null;
  }

  const handleDismiss = () => {
    dispatch(
      addTagToUserThunk({
        key: dismissalKey,
        value: new Date().toISOString(),
      })
    );
  };

  const formatUTCTime = (date: Date) => {
    return date
      .toUTCString()
      .replace(/^.*?, /, '')
      .replace(/ GMT$/, '');
  };

  const formatLocalTime = (start: Date, end: Date) => {
    const formatOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    };

    const startDay = start.getDate();
    const endDay = end.getDate();
    const sameDay = startDay === endDay;

    if (sameDay) {
      return `${start.toLocaleDateString(undefined, {
        month: 'long',
        day: 'numeric',
      })} from ${start.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })} to ${end.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}`;
    }

    return `${start.toLocaleString(
      undefined,
      formatOptions
    )} to ${end.toLocaleString(undefined, formatOptions)}`;
  };

  return (
    <Alert
      severity="warning"
      onClose={handleDismiss}
      title={`Scheduled Maintenance Notice: ${formatUTCTime(
        maintenanceStartDate
      )} - ${formatUTCTime(maintenanceEndDate)} UTC`}
    >
      <AlertTitle sx={{ fontWeight: 700 }}>Scheduled Maintenance</AlertTitle>
      <Typography
        variant="body2"
        sx={{
          color: 'text.secondary',
        }}
      >
        Our system will be undergoing maintenance on{' '}
        {formatLocalTime(maintenanceStartDate, maintenanceEndDate)}
      </Typography>
    </Alert>
  );
};

export default MaintenanceBanner;
