import React from 'react';
import { Typography, Box, Paper, keyframes } from '@mui/material';
import {
  CheckCircle,
  UploadCloud,
  Sparkles,
  FileText,
  Rocket,
  CalendarClock,
  Crown,
  ThumbsUp,
  Video,
  Chrome,
} from 'lucide-mui';
import ActionDialog from '../../globalModals/ActionDialog';
import type { ActionType } from '../../globalModals/ActionDialog';

const zoomIn = keyframes`
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); opacity: 1; }
`;

interface FeatureRow {
  icon: React.ReactNode;
  title: string;
  description: string;
  bgColor: string;
}

interface ActionRow {
  icon: React.ReactNode;
  title: string;
  description: string;
  bgColor: string;
  href: string;
}

const featureRows: FeatureRow[] = [
  {
    icon: <UploadCloud sx={{ color: 'common.white' }} />,
    title: 'Upload or Import URLs',
    description: 'Process more recordings, faster',
    bgColor: 'info.light',
  },
  {
    icon: <Sparkles sx={{ color: 'common.white' }} />,
    title: 'Enhanced AI Features',
    description: 'More AI credits for deeper insights and analysis',
    bgColor: 'warning.light',
  },
  {
    icon: <FileText sx={{ color: 'common.white' }} />,
    title: 'Full Transcripts',
    description: 'Get complete transcripts with speaker detection',
    bgColor: 'info.light',
  },
  {
    icon: <Rocket sx={{ color: 'common.white' }} />,
    title: 'Priority Processing',
    description: 'Get faster results with priority queue',
    bgColor: 'warning.main',
  },
];

const actionRows: ActionRow[] = [
  {
    icon: <CalendarClock sx={{ color: 'common.white' }} />,
    title: 'Book a Demo',
    description: 'Get the most out of your upgraded account',
    bgColor: 'error.light',
    href: 'https://calendar.app.google/XjVqNwLPtLKbvYn29',
  },
  {
    icon: <Video sx={{ color: 'common.white' }} />,
    title: 'Check out a recorded demo now',
    description: 'Watch a quick overview of the features',
    bgColor: 'info.light',
    href: 'https://www.youtube.com/watch?v=R5jR9hly8kA',
  },
  {
    icon: <Chrome sx={{ color: 'common.white' }} />,
    title: 'Install Chrome Extension',
    description: 'Record and analyze your meetings directly in Chrome',
    bgColor: 'success.light',
    href: 'https://screenapp.io/chrome',
  },
];

interface UpgradeSuccessDialogProps {
  onClose: () => void;
  onExploreFeatures?: () => void;
}

const UpgradeSuccessDialog: React.FC<UpgradeSuccessDialogProps> = ({
  onClose,
  onExploreFeatures,
}) => {
  const renderFeatureRow = ({
    icon,
    title,
    description,
    bgColor,
  }: FeatureRow) => (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
      <Box
        sx={{
          p: 1,
          borderRadius: 2,
          bgcolor: bgColor,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5, textAlign: 'left' }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: 'left' }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );

  const renderActionRow = ({
    icon,
    title,
    description,
    bgColor,
    href,
  }: ActionRow) => (
    <Box
      component="a"
      href={href}
      target="_blank"
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer',
        '&:hover': { '& .MuiTypography-root': { color: 'primary.main' } },
      }}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: 2,
          bgcolor: bgColor,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5, textAlign: 'left' }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: 'left' }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );

  const actions: ActionType[] = [
    ...(onExploreFeatures
      ? [
          {
            key: 'explore',
            label: 'Start Using New Features',
            onClick: onExploreFeatures,
            variant: 'contained' as const,
            color: 'primary' as const,
            startIcon: <Crown />,
            'data-testid': 'explore-features-button',
          },
        ]
      : []),
    {
      key: 'close',
      label: 'Got it',
      onClick: onClose,
      variant: onExploreFeatures
        ? ('outlined' as const)
        : ('contained' as const),
      color: 'primary' as const,
      startIcon: <ThumbsUp />,
      'data-testid': 'upgrade-success-close-button',
    },
  ];

  return (
    <ActionDialog
      open
      onClose={onClose}
      title="You're All Set! 🎉"
      maxWidth="sm"
      actions={actions}
      data-testid="upgrade-success-dialog"
      showCloseIcon
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 3,
          pb: 1,
          textAlign: 'center',
          maxWidth: 480,
          mx: 'auto',
        }}
      >
        <CheckCircle
          sx={{
            fontSize: 48,
            color: 'success.main',
            mb: 2,
            animation: `${zoomIn} 0.5s ease-out`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 360,
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ mb: 2, textAlign: 'center' }}
          >
            Check out your new powerful features
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            p: 2,
            bgcolor: 'background.default',
            borderRadius: 2,
            width: '100%',
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {featureRows.map((row) => renderFeatureRow(row))}
          </Box>
        </Paper>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ mb: 2, mt: 0, textAlign: 'center' }}
        >
          What&apos;s next?
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: 2,
            bgcolor: 'background.default',
            borderRadius: 2,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {actionRows.map((row) => renderActionRow(row))}
          </Box>
        </Paper>
      </Box>
    </ActionDialog>
  );
};

export default UpgradeSuccessDialog;
