import React, { Suspense } from 'react';

import MultiAskAI from './MultiAskAILazy';
import LoaderComponent from '../../../app/loader/LoaderReact';

export default ({ open, onClose }: { open: boolean; onClose: () => void }) => (
  <Suspense fallback={<LoaderComponent />}>
    <MultiAskAI open={open} onClose={onClose} />
  </Suspense>
);
