const ALLOWED_TYPES = [
  // Video MIME types
  'video/mp4',
  'video/x-m4v',
  'video/quicktime', // .mov
  'video/x-msvideo', // .avi
  'video/webm',
  'video/x-matroska', // .mkv
  'video/x-flv',
  'video/MP2T', // .ts, .mts, .m2ts
  'video/3gpp',
  'video/x-ms-wmv',
  'video/x-ms-asf',
  'video/vnd.vob',
  'video/ogg',
  'video/x-realvideo', // .rm, .rmvb
  'video/mpeg',

  // Audio MIME types
  'audio/mpeg', // .mp3
  'audio/mp4',
  'audio/aac',
  'audio/wav',
  'audio/ogg',
  'audio/opus',
  'audio/flac',
  'audio/aiff',
  'audio/x-ms-wma',
  'audio/x-matroska', // .mka
  'audio/x-m4a',
  'audio/x-m4b',
  'audio/ac3',
  'audio/eac3',
  'audio/wavpack', // .wv

  // File extensions (for types that might not be recognized by MIME)
  '.mp4',
  '.m4v',
  '.mov',
  '.avi',
  '.webm',
  '.mkv',
  '.flv',
  '.ts',
  '.mts',
  '.m2ts',
  '.3gp',
  '.wmv',
  '.asf',
  '.vob',
  '.ogv',
  '.rm',
  '.rmvb',
  '.mpg',
  '.mpeg',
  '.m2v',
  '.mp3',
  '.m4a',
  '.aac',
  '.wav',
  '.ogg',
  '.opus',
  '.flac',
  '.aiff',
  '.wma',
  '.mka',
  '.m4b',
  '.ac3',
  '.eac3',
  '.wv',

  // Empty string for unrecognized types (e.g., mkv on Mac)
  '',
];

export default ALLOWED_TYPES;
