import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Container,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SendHorizontal, CheckCircle } from 'lucide-mui';
import { RootState } from '../../reducers';
import { sendEmail } from '../../services/emailService';
import { wrapWithTheme } from '../email/templates';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: 16,
  boxShadow: 'none',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 24,
    backgroundColor: theme.palette.background.default,
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  textTransform: 'none',
  fontWeight: 600,
  borderRadius: 24,
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(1),
  },
}));

const SuccessMessage = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
  '& .icon': {
    color: theme.palette.success.main,
    width: 64,
    height: 64,
    marginBottom: theme.spacing(2),
  },
  '& .title': {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(1),
    fontWeight: 600,
  },
  '& .subtitle': {
    color: theme.palette.text.secondary,
  },
}));

interface FormData {
  name: string;
  email: string;
  company: string;
  teamSize: string;
  trialPurpose: string;
  whyScreenApp: string;
  phone: string;
}

interface LocationInfo {
  country?: string;
  city?: string;
}

interface ContactSalesFormProps {
  formSource?: string;
}

const ContactSalesForm: React.FC<ContactSalesFormProps> = ({ formSource }) => {
  const { user } = useSelector((state: RootState) => state.settings);
  const { subscription, usage, marketing } = useSelector(
    (state: RootState) => state.plan
  );
  const { selectedTeam } = useSelector((state: RootState) => state.team);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [formData, setFormData] = useState<FormData>({
    name: user?.name || '',
    email: user?.email || '',
    company: '',
    teamSize: '',
    trialPurpose: '',
    whyScreenApp: '',
    phone: '',
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [locationInfo, setLocationInfo] = useState<LocationInfo>({});

  useEffect(() => {
    // Get location information when component mounts
    fetch('https://ipapi.co/json/')
      .then((res) => res.json())
      .then((data) => {
        setLocationInfo({
          country: data.country_name,
          city: data.city,
        });
      })
      .catch((error) => {
        console.error('Failed to get location info:', error);
      });
  }, []);

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email';
    }

    if (!formData.teamSize.trim()) {
      newErrors.teamSize = 'Team size is required';
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    }

    // Only validate trial purpose if it's from the Request Trial button
    if (
      formSource === 'Dashboard - Request Trial' &&
      !formData.trialPurpose.trim()
    ) {
      newErrors.trialPurpose = 'Please tell us what you want to test';
    }

    // Make whyScreenApp required when not showing trial purpose
    if (
      formSource !== 'Dashboard - Request Trial' &&
      !formData.whyScreenApp.trim()
    ) {
      newErrors.whyScreenApp = 'Please tell us what you love about ScreenApp';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const emailContent = `
          <h2>New Lead from Contact Form</h2>
          
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Form Source:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formSource || 'Direct Contact'
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Name:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.name
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Email:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.email
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Phone:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.phone
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Company:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.company || 'Not provided'
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Team Size:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.teamSize
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Trial Purpose:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.trialPurpose
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Why ScreenApp:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                formData.whyScreenApp || 'Not provided'
              }</td>
            </tr>
            ${
              locationInfo.country || locationInfo.city
                ? `
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Location:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${[
                locationInfo.city,
                locationInfo.country,
              ]
                .filter(Boolean)
                .join(', ')}</td>
            </tr>
            `
                : ''
            }
            ${
              marketing?.intent
                ? `
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Initial Intent:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${marketing.intent}</td>
            </tr>
            `
                : ''
            }

            <tr>
              <td colspan="2" style="padding: 24px 0 12px 0;"><strong>User Information</strong></td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Current Plan:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                subscription?.package || 'No subscription'
              } (${subscription?.term || 'N/A'})</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Current Price:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">$${
                subscription?.price || 0
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Subscription Since:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                subscription?.currentPeriodStartedAt
                  ? new Date(
                      subscription.currentPeriodStartedAt
                    ).toLocaleDateString()
                  : 'N/A'
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Stripe ID:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                subscription?.providerId || 'N/A'
              }</td>
            </tr>

            <tr>
              <td colspan="2" style="padding: 24px 0 12px 0;"><strong>Usage Statistics</strong></td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Total Uploads:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                usage?.uploadCount || 0
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>AI Interactions:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                usage?.askAICount || 0
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Transcriptions:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                usage?.transcriptionCount || 0
              }</td>
            </tr>

            <tr>
              <td colspan="2" style="padding: 24px 0 12px 0;"><strong>Team Information</strong></td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Team Name:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                selectedTeam?.name || 'No team'
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Team Members:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                selectedTeam?.teamSpaceMembers?.length || 0
              }</td>
            </tr>
            <tr>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;"><strong>Team Created:</strong></td>
              <td style="padding: 12px 0; border-bottom: 1px solid #eee;">${
                selectedTeam?.createdAt
                  ? new Date(selectedTeam.createdAt).toLocaleDateString()
                  : 'N/A'
              }</td>
            </tr>

            <tr>
              <td colspan="2" style="padding: 24px 0 12px 0;"><strong>System Information</strong></td>
            </tr>
            <tr>
              <td style="padding: 12px 0;"><strong>OS:</strong></td>
              <td style="padding: 12px 0;">${window.navigator.platform}</td>
            </tr>
            <tr>
              <td style="padding: 12px 0;"><strong>Browser:</strong></td>
              <td style="padding: 12px 0;">${window.navigator.userAgent}</td>
            </tr>
          </table>
        `;

        // Send to both Andre and Aditya
        await Promise.all([
          sendEmail({
            template: 'genericEmail',
            toEmail: 'andre@screenapp.io',
            data: {
              content: wrapWithTheme(emailContent),
              subject: `New Lead: ${formData.name} from ${
                formData.company || 'Unknown Company'
              }`,
            },
          }),
          sendEmail({
            template: 'genericEmail',
            toEmail: 'aditya@screenapp.io',
            data: {
              content: wrapWithTheme(emailContent),
              subject: `New Lead: ${formData.name} from ${
                formData.company || 'Unknown Company'
              }`,
            },
          }),
        ]);

        setIsSuccess(true);
        // Reset form after successful submission
        setFormData({
          name: '',
          email: '',
          company: '',
          teamSize: '',
          trialPurpose: '',
          whyScreenApp: '',
          phone: '',
        });
      } catch (error) {
        console.error('Failed to submit form:', error);
        setSnackbar({
          open: true,
          message: 'Failed to submit form. Please try again.',
          severity: 'error',
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name as keyof FormData]) {
      setErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  if (isSuccess) {
    return (
      <Container maxWidth="md">
        <StyledPaper>
          <SuccessMessage>
            <CheckCircle className="icon" />
            <Typography variant="h4" className="title">
              Thank You for Reaching Out!
            </Typography>
            <Typography variant="body1" className="subtitle">
              We&apos;re excited to help you get the most out of ScreenApp. Our
              team will be in touch with you very soon to discuss your needs and
              how we can best support you.
            </Typography>
          </SuccessMessage>
        </StyledPaper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <StyledPaper>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                fullWidth
                label="Full Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                fullWidth
                label="Work Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                fullWidth
                label="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                label="Company Name"
                name="company"
                value={formData.company}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                required
                fullWidth
                label="Team Size"
                name="teamSize"
                value={formData.teamSize}
                onChange={handleChange}
                error={!!errors.teamSize}
                helperText={errors.teamSize}
                size="small"
              />
            </Grid>

            {formSource === 'Dashboard - Request Trial' && (
              <Grid item xs={12}>
                <StyledTextField
                  required
                  fullWidth
                  label="What would you like to trial?"
                  name="trialPurpose"
                  value={formData.trialPurpose}
                  onChange={handleChange}
                  error={!!errors.trialPurpose}
                  helperText={errors.trialPurpose}
                  size="small"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                multiline
                rows={2}
                required={formSource !== 'Dashboard - Request Trial'}
                label="What do you love about ScreenApp?"
                name="whyScreenApp"
                value={formData.whyScreenApp}
                onChange={handleChange}
                error={!!errors.whyScreenApp}
                helperText={errors.whyScreenApp}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                endIcon={<SendHorizontal style={{ width: 18, height: 18 }} />}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactSalesForm;
