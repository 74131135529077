import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../reducers';
import { pushModal } from '../../globalModals/globalModalsSlice';
import planFeatures, {
  FeatureKey,
  FeatureMap,
} from '../upgrade/upgradeView/featureLimits';
import { IUsage } from '../userPackage';
import { selectPlan, setSourceFeature } from '../planSlice';
import { Gen2PackageType } from '../../../types';
import logger from '../../../lib/logger';

type UseLimitReturnType = (
  feature: FeatureKey,
  guardedAction?: Function
) => number;

// This is used to check if the limit is reached for a feature
// If a callback is provided, it is assumed that a function is to be executed and the limit is to be checked. If the limit is reached, a modal is opened.
// If no callback is provided, the limit is checked and the remaining usage is returned.
export const useLimits = (features = planFeatures): UseLimitReturnType => {
  const { plan } = useSelector((state: RootState) => state);
  const { packageProperties, usage, subscription } = plan;
  const { package: teamPlan } = subscription || {};
  const dispatch = useDispatch();
  const guardLimit = (featureKey: FeatureKey, callback?: Function) => {
    if (!teamPlan) {
      logger.debug('GuardLimits: No team plan found');
      return 0;
    }
    const feature = (features[teamPlan] as FeatureMap)[featureKey];
    const openModalOrReturn = (returnValue: number = 0) => {
      if (!callback) {
        return returnValue;
      }
      // if there is a callback, open modal and return remaining usage
      if (callback) {
        dispatch(
          setSourceFeature({
            sourceFeature: featureKey,
          })
        );
        // Find the most suitable plan for the user
        const selectedPlan = ([
          'GROWTH',
          'BUSINESS',
          'ENTERPRISE',
        ] as Gen2PackageType[]).find((p) => features[p]?.[featureKey]);
        if (selectedPlan) {
          dispatch(
            selectPlan({
              plan: selectedPlan,
            })
          );
        }
        // Will open the UpSell modal for the GROWTH plan users
        if (subscription.package === 'GROWTH') {
          dispatch(pushModal({ type: 'upsell-modal', source: 'limits' }));
          return returnValue;
        }

        dispatch(
          pushModal({
            type: 'stripe-pricing-table-upgrade-modal',
            source: 'limits',
          })
        );
        return returnValue;
      }
      return returnValue;
    };
    if (!feature) {
      // If the feature is not found in the plan, that means feature is not available in the plan
      logger.log(`Feature ${featureKey} not found in plan: ${teamPlan}`);
      return openModalOrReturn(0);
    }
    const { usageKey } = feature || {};
    if (!usageKey) {
      // If the feature is there, but there is no usage key, that means the feature is unlimited
      if (callback) {
        callback();
      }
      return Infinity;
    }
    if (!Object.keys(usage).includes(usageKey)) {
      logger.debug(`Usage key ${usageKey} not found in usage`);
      return openModalOrReturn(0);
    }
    const currentFeatureUsage = usage[usageKey as keyof IUsage] || 0;
    const maxFeatureUsage = packageProperties[usageKey] || 0;
    if (
      usageKey &&
      typeof currentFeatureUsage === 'number' &&
      typeof maxFeatureUsage === 'number'
    ) {
      const remainingFeatureUsage = maxFeatureUsage - currentFeatureUsage;
      if (remainingFeatureUsage > 0) {
        // If limit is not reached,
        // if there is no callback, return remaining usage
        if (!callback) {
          return remainingFeatureUsage;
          // If there is a callback, execute the callback and return remaining usage
        }
        if (callback) {
          try {
            callback();
          } catch (error) {
            logger.error(`Error in callback: ${feature} guard`, error);
          }
          return Math.max(remainingFeatureUsage - 1, 0);
        }
      } else if (remainingFeatureUsage <= 0) {
        // If limit is reached
        // if there is no callback, return remaining usage
        return openModalOrReturn(0);
      }
    }
    // If limit is not reached, update usage and return remaining usage
    return 0;
  };
  return guardLimit as UseLimitReturnType;
};

export default useLimits;
