import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { TeamRole } from '../../types/state';

export const useRole = () => {
  const { settings } = useSelector((state: RootState) => state);
  const { role } = settings.user || {};
  const isRole = (roles: TeamRole[]) => roles.includes(role);
  return { role, isRole };
};

export default useRole;
