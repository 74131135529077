import React, { useState, useRef } from 'react';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Box,
  Avatar,
  Link,
  useTheme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Lock, Users } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DefaultThumbnail from '../../../../assets/recording/WhiteThumbnail.svg';
import type { FileType, FolderType } from '../../../../types';
import FileActionMenu from '../FileActionMenu';
import { pushModal } from '../../../globalModals/globalModalsSlice';
import { RootState } from '../../../../reducers';

interface FileProps {
  file: FileType;
  isSelected: boolean;
  showCheckbox: boolean;
  fileUrl: string;
  onSelect: (file: FileType) => void;
  freeUserTimeLimit: Date | null;
}

const getCreatedAtString = (createdAt: Date) => {
  const now = moment();
  const created = moment(createdAt);

  if (!created.isValid()) {
    return createdAt;
  }

  if (created.isSame(now, 'day')) {
    return 'Today';
  }

  if (created.isSame(now.subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  }

  if (created.isSame(now.subtract(2, 'days'), 'day')) {
    return '2 days ago';
  }

  if (created.isSame(now.subtract(3, 'days'), 'day')) {
    return '3 days ago';
  }

  return created.format('MMM D, YYYY');
};

const formatDuration = (seconds: number | undefined): string => {
  if (typeof seconds !== 'number') return '0:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return hours > 0
    ? `${hours}:${minutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
    : `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const File = ({ file, isSelected, fileUrl, freeUserTimeLimit }: FileProps) => {
  const { name, thumbUrl, createdAt, owner, duration, parentId } = file;
  const { name: ownerName, picture: ownerPicture } = owner;
  const [thumbUrlValid, setThumbUrlValid] = useState<string>(
    thumbUrl || DefaultThumbnail
  );
  const [showMenu, setShowMenu] = useState(false);
  const longPressTimer = useRef<ReturnType<typeof setTimeout>>();
  const touchStartTime = useRef<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const handleTouchStart = () => {
    touchStartTime.current = Date.now();
    longPressTimer.current = setTimeout(() => {
      setShowMenu(true);
    }, 500);
  };

  const handleTouchEnd = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  const handleTouchMove = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  const parentFolder = useSelector((state: RootState) => {
    return state.library.folders.data.find(
      (folder: FolderType) => folder._id === parentId
    );
  });

  const handleMoveClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(pushModal({ type: 'move-file-modal', files: [file] }));
    },
    [dispatch, file]
  );

  const hasFolder = parentId !== null;

  const { folderId } = useParams<{ folderId: string }>();
  const isInRecentsView = folderId === 'recents';

  let hiddenForFreeUser = false;

  if (freeUserTimeLimit) {
    hiddenForFreeUser =
      new Date(createdAt).getTime() < new Date(freeUserTimeLimit).getTime();
  }

  return (
    <Card
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onMouseEnter={() => !isMobile && setShowMenu(true)}
      onMouseLeave={() => !isMobile && setShowMenu(false)}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: hiddenForFreeUser ? 0.5 : 1,
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        transform: hiddenForFreeUser ? 'none' : 'scale(1)',
        '&:hover': {
          transform: {
            xs: 'none',
            sm: hiddenForFreeUser ? 'none' : 'scale(1.02)',
          },
          '& .hover-menu': {
            opacity: {
              xs: 1,
              sm: hiddenForFreeUser ? 0 : 1,
            },
            visibility: {
              xs: 'visible',
              sm: hiddenForFreeUser ? 'hidden' : 'visible',
            },
          },
          '& .hover-play, & .hover-duration': {
            opacity: hiddenForFreeUser ? 0 : 1,
            visibility: hiddenForFreeUser ? 'hidden' : 'visible',
          },
          '& .card-media': {
            boxShadow: hiddenForFreeUser ? 'none' : 'none',
          },
        },
        '& .hover-menu': {
          opacity: {
            xs: showMenu ? 1 : 0,
            sm: isSelected ? 1 : 0,
          },
          visibility: {
            xs: showMenu ? 'visible' : 'hidden',
            sm: isSelected ? 'visible' : 'hidden',
          },
          display: isMobile && !showMenu ? 'none' : 'block',
          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(42, 43, 44, 0.9)'
              : 'rgba(255, 255, 255, 0.9)',
          borderRadius: '50%',
          backdropFilter: 'blur(4px)',
        },
      }}
      onClick={() => {
        if (hiddenForFreeUser) {
          dispatch(pushModal({ type: 'upsell-modal' }));
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Link
          href={fileUrl}
          color="secondary.contrastText"
          underline="none"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            pointerEvents: hiddenForFreeUser ? 'none' : 'auto',
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <Tooltip
              title="Not yet transcribed"
              arrow
              placement="top"
              enterDelay={200}
              leaveDelay={0}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#FF9800',
                  opacity: !file.textData?.transcriptCompletedAt ? 1 : 0,
                  visibility: !file.textData?.transcriptCompletedAt
                    ? 'visible'
                    : 'hidden',
                }}
              />
            </Tooltip>
            <CardMedia
              component="img"
              image={thumbUrlValid || DefaultThumbnail}
              className="card-media"
              sx={{
                width: '100%',
                aspectRatio: '16/9',
                objectFit: 'cover',
                borderRadius: '8px',
                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                filter:
                  theme.palette.mode === 'dark' &&
                  thumbUrlValid === DefaultThumbnail
                    ? 'invert(1)'
                    : 'none',
              }}
              onError={() => {
                if (thumbUrlValid !== DefaultThumbnail) {
                  setThumbUrlValid(DefaultThumbnail);
                }
              }}
            />
            <Box
              className="hover-duration"
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                padding: '4px 8px',
                backgroundColor:
                  thumbUrlValid === DefaultThumbnail
                    ? 'background.default !important'
                    : 'rgba(0, 0, 0, 0.3) !important',
                color:
                  thumbUrlValid === DefaultThumbnail
                    ? 'text.primary !important'
                    : '#FFFFFF !important',
                borderRadius: '999px !important',
                fontSize: '0.75rem',
                fontWeight: 500,
                backdropFilter: 'blur(4px)',
                border: 'none',
                opacity: {
                  xs: 1,
                  sm: hiddenForFreeUser ? 0 : 0,
                },
                visibility: {
                  xs: hiddenForFreeUser ? 'hidden' : 'visible',
                  sm: hiddenForFreeUser ? 'hidden' : 'visible',
                },
                '.MuiCard-root:hover &': {
                  opacity: hiddenForFreeUser ? 0 : 1,
                  visibility: hiddenForFreeUser ? 'hidden' : 'visible',
                },
              }}
            >
              {formatDuration(duration)}
            </Box>
          </Box>
        </Link>
        <CardContent
          sx={{
            '&:last-child': {
              paddingBottom: '16px',
              paddingLeft: 0,
              paddingRight: 0,
            },
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{ position: 'relative' }}
          >
            <Link
              href={fileUrl}
              color="secondary.contrastText"
              underline="none"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                overflow: 'hidden',
                pointerEvents: hiddenForFreeUser ? 'none' : 'auto',
              }}
            >
              <Tooltip title={ownerName} arrow placement="top">
                <Avatar
                  alt={ownerName}
                  src={ownerPicture}
                  sx={{
                    width: '2rem',
                    height: '2rem',
                    backgroundColor: theme.palette.secondary.main,
                    transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '&:hover': {
                      transform: hiddenForFreeUser ? 'none' : 'scale(1.1)',
                    },
                    '& svg': {
                      fill: theme.palette.background.paper,
                    },
                  }}
                />
              </Tooltip>
              <Box
                ml={2}
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body1"
                  data-testid="video-grid-tile-name"
                  sx={{
                    fontWeight: 600,
                    width: '100%',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    lineHeight: '1.5em',
                    minHeight: '1.5em',
                    maxHeight: '2.8em',
                    pr: '40px',
                  }}
                >
                  {name}
                </Typography>
                <Box sx={{ pl: '1px' }}>
                  {isInRecentsView ? (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '0.6rem',
                          color: theme.palette.text.secondary,
                          opacity: 0.8,
                          mt: '6px',
                          mb: '2px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          onClick={
                            hiddenForFreeUser ? undefined : handleMoveClick
                          }
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            opacity: 0.8,
                            cursor: hiddenForFreeUser
                              ? 'not-allowed'
                              : 'pointer',
                            '&:hover': {
                              opacity: hiddenForFreeUser ? 0.8 : 1,
                              textDecoration: hiddenForFreeUser
                                ? 'none'
                                : 'underline',
                            },
                          }}
                        >
                          {hasFolder ? (
                            <Users
                              sx={{
                                fontSize: '0.5rem',
                                mr: 0.3,
                                opacity: 0.7,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            />
                          ) : (
                            <Lock
                              sx={{
                                fontSize: '0.5rem',
                                mr: 0.3,
                                opacity: 0.7,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            />
                          )}
                          <span>
                            {parentFolder && parentFolder.name.length > 15
                              ? `${parentFolder.name.slice(0, 15)}...`
                              : parentFolder?.name || 'Private'}
                          </span>
                        </Box>
                        <span>&nbsp;•&nbsp;</span>
                        <span data-testid="video-grid-tile-date">
                          {getCreatedAtString(createdAt).toString()}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.6rem',
                        color: theme.palette.text.secondary,
                        opacity: 0.8,
                        mt: '6px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {getCreatedAtString(createdAt).toString()}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Link>
            <Box
              className="hover-menu"
              sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                opacity: hiddenForFreeUser ? 0 : 1,
                visibility: hiddenForFreeUser ? 'hidden' : 'visible',
                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                zIndex: 1,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(42, 43, 44, 0.9)'
                    : 'rgba(255, 255, 255, 0.9)',
                borderRadius: '50%',
                backdropFilter: 'blur(4px)',
              }}
              onClick={(e) => e.preventDefault()}
            >
              <FileActionMenu file={file} />
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default React.memo(File);
