import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Box, Typography } from '@mui/material';
import { CloudUpload } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { RouteParams } from '../library/types';
import { uploadFiles } from './uploadSlice';
import { RootState } from '../../reducers';
import ALLOWED_TYPES from './AllowedTypes';
import useLimits from '../plan/hooks/limits';
import validateFiles from './fileValidation';
import logger from '../../lib/logger';
import { setAlertMessage } from '../dashboard/dashboardSlice';
import { trackMediaAction } from '../library/actions/mediaActions/mediaActionsSlice';
import { DRAWER_WIDTH } from '../../constants/ui';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const buttonHeight = 100;
const buttonMargin = 4;
const minWidth = buttonHeight * (16 / 9); // Maintain 16:9 ratio minimum
const gradientAngle = Math.atan2(buttonHeight, 180) * (180 / Math.PI) + 90;
const gradient = { startColor: '#4169E1', endColor: '#87CEFA' };

export default function InputFileUpload() {
  const dispatch = useDispatch();
  const { upload } = useSelector((state: RootState) => state);
  const { status } = upload;
  const { teamId, folderId } = useParams<RouteParams>();
  const guardLimits = useLimits();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const { open } = useSelector((state: RootState) => ({
    open: state.dashboard.drawerState === 'open',
  }));

  const getFileExtension = (mimeType: string): string => {
    const extensions: { [key: string]: string } = {
      'video/mp4': '.mp4',
      'video/quicktime': '.mov',
      'video/webm': '.webm',
      'video/x-msvideo': '.avi',
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
    };
    return extensions[mimeType] || '';
  };

  // Handle shared files
  React.useEffect(() => {
    const handleSharedFile = async () => {
      if (searchParams.get('share') === 'true' && teamId && folderId) {
        try {
          const cache = await caches.open('shared-files');
          const response = await cache.match('shared-file');

          if (response) {
            const file = await response.blob();
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            const fileName = `shared-file-${timestamp}${getFileExtension(
              file.type
            )}`;
            const filesArray = [
              new File([file], fileName, { type: file.type }),
            ];

            const { isValid, errorMessage } = await validateFiles(filesArray);
            if (!isValid) {
              logger.error(new Error(errorMessage));
              dispatch(
                setAlertMessage({
                  message: errorMessage || 'Shared file is invalid',
                  type: 'error',
                })
              );
              return;
            }

            dispatch(uploadFiles({ teamId, folderId, files: filesArray }));
            // Clean up the cached file
            await cache.delete('shared-file');
          }
        } catch (error) {
          logger.error(error);
          dispatch(
            setAlertMessage({
              message: 'Failed to process shared file',
              type: 'error',
            })
          );
        }
      }
    };

    handleSharedFile();
  }, [searchParams, teamId, folderId, dispatch]);

  const getButtonWidth = React.useMemo(
    () => ({
      xs: `max(35vw, ${minWidth}px)`,
      sm: `max(20vw, ${minWidth}px)`,
      md: `max(calc((100vw - ${open ? DRAWER_WIDTH : 56}px) / 4 - ${
        buttonMargin * 2
      }px), ${minWidth}px)`,
      lg: `max(12vw, ${minWidth}px)`,
      xl: `max(10vw, ${minWidth}px)`,
    }),
    [open]
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;
    if (teamId && folderId && files) {
      const filesArray = Array.from(files);
      const { isValid, errorMessage } = await validateFiles(filesArray);

      if (!isValid) {
        logger.error(new Error(errorMessage));
        dispatch(
          setAlertMessage({
            message: errorMessage || 'One or more files are invalid',
            type: 'error',
          })
        );
        return;
      }
      if (filesArray.length > 1) {
        guardLimits('bulkUpload', () => {
          dispatch(uploadFiles({ teamId, folderId, files: filesArray }));
        });
      } else {
        dispatch(uploadFiles({ teamId, folderId, files: filesArray }));
      }
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(
      trackMediaAction({
        actionKey: 'upload',
        actionLabel: 'Upload File',
        teamId: teamId?.toString(),
        folderId: folderId?.toString(),
      })
    );
    guardLimits('uploadButton', () => {
      fileInputRef.current?.click();
    });
  };

  return (
    <Button
      component="label"
      variant="text"
      color="secondary"
      size="medium"
      onClick={handleButtonClick}
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: `${buttonHeight}px`,
        minWidth: getButtonWidth,
        flex: 1,
        padding: '8px',
        margin: `0 ${buttonMargin}px`,
        flexShrink: 0,
        overflow: 'hidden',
        position: 'relative',
        willChange: 'transform',
        background: `linear-gradient(${gradientAngle}deg, ${gradient.startColor}, ${gradient.endColor})`,
        '&:hover': {
          background: `linear-gradient(${gradientAngle}deg, ${gradient.startColor}, ${gradient.endColor})`,
          '& .icon': {
            transform: 'translateY(-200%) translateZ(0)',
            opacity: 0,
          },
          '& .label': {
            transform: 'translateY(-250%) translateZ(0)',
          },
          '& .tooltip': {
            transform: 'translateY(0) translateZ(0)',
            opacity: 1,
          },
        },
      }}
    >
      <Box
        className="icon"
        sx={{
          transition:
            'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '28px',
          mb: 2,
          position: 'absolute',
          top: '8px',
          left: '8px',
          color: 'white',
          '& svg': {
            color: 'white !important',
            fontSize: '28px',
            animation:
              status === 'pending' ? 'pulse 1s ease-in-out infinite' : 'none',
          },
          '@keyframes pulse': {
            '0%, 100%': { transform: 'translateY(0)' },
            '50%': { transform: 'translateY(-10px)' },
          },
        }}
      >
        <CloudUpload sx={{ fontSize: 28 }} />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          className="label"
          variant="body2"
          sx={{
            fontWeight: 'bold',
            transition:
              'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            textAlign: 'left',
            mb: 1,
            position: 'absolute',
            bottom: '8px',
            left: '8px',
            right: '8px',
            color: 'white',
          }}
        >
          {status === 'pending' ? 'Uploading..' : 'Upload File'}
        </Typography>
        <Typography
          className="tooltip"
          variant="caption"
          sx={{
            textAlign: 'left',
            transition:
              'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            padding: '0',
            position: 'absolute',
            bottom: '8px',
            left: '8px',
            right: '8px',
            transform: 'translateY(100%)',
            opacity: 0,
            whiteSpace: 'normal',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            height: '2.4em',
            lineHeight: '1.2em',
            color: 'white',
          }}
        >
          {status === 'pending'
            ? 'Upload another file'
            : 'Upload any Video or Audio file'}
        </Typography>
      </Box>
      <VisuallyHiddenInput
        ref={fileInputRef}
        type="file"
        multiple
        accept={ALLOWED_TYPES.join(',')}
        onChange={handleFileChange}
        onClick={(e) => e.stopPropagation()}
      />
    </Button>
  );
}
