import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import { Trash2 } from 'lucide-mui';
import { deleteFile } from '../../selectedVideoSlice';
import { RootState } from '../../../../reducers';
import { getFolderUrl } from '../../../../lib/routingUtils';
import { FilePreviewRouteParams } from '../../types';
import { deselectAllFiles } from '../../../library/librarySlice';

interface DeleteFilesModalProps {
  onClose?: () => void;
}

export const DeleteFilesModal = ({ onClose }: DeleteFilesModalProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId, folderId, fileId } = useParams<FilePreviewRouteParams>();
  const { globalModals } = useSelector((state: RootState) => state);
  const { files = [], status } = globalModals.actionData || {};
  const isLoading = status === 'pending';

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleDeleteFiles = async () => {
    files.forEach((file) => {
      dispatch(deleteFile(file._id));
    });
    dispatch(deselectAllFiles());
    handleClose();
    // If fileId is present, it means we are in selected video view
    if (teamId && folderId && fileId) {
      navigate(getFolderUrl({ teamId, folderId }));
    }
  };

  const fileCount = files.length;
  const title =
    fileCount === 1
      ? `Delete "${files[0]?.name}"`
      : `Delete ${fileCount} Files`;
  const getDeleteButtonLabel = () => {
    if (isLoading) {
      return fileCount === 1 ? 'Deleting File...' : 'Deleting Files...';
    }
    return fileCount === 1 ? 'Delete File' : 'Delete Files';
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '28px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 4,
          pt: 3,
          pb: 2,
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Alert
          severity="warning"
          sx={{
            borderRadius: '24px',
          }}
        >
          {fileCount === 1
            ? 'This action cannot be undone. This file will be permanently deleted.'
            : `This action cannot be undone. These files will be permanently deleted.`}
        </Alert>
        <Typography variant="body1" color="text.secondary">
          {fileCount === 1
            ? 'Once deleted, you will no longer have access to this file or its contents. Make sure you have backed up any important content before continuing.'
            : `Once deleted, you will no longer have access to these ${fileCount} files or their contents. Make sure you have backed up any important content before continuing.`}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          px: 4,
          pb: 3,
          gap: 1,
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          disabled={isLoading}
          data-testid="cancel-button-delete-modal"
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteFiles}
          variant="contained"
          color="error"
          disabled={isLoading}
          startIcon={<Trash2 />}
          data-testid="delete-button-delete-modal"
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
            '& .MuiButton-startIcon svg': {
              transition: 'color 0.2s',
            },
            '&:hover .MuiButton-startIcon svg': {
              color: 'white',
            },
          }}
        >
          {getDeleteButtonLabel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFilesModal;
