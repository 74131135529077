import { lazy } from 'react';

// Lazy loaded components (non-critical path)
export const SignUp = lazy(() => import('../features/auth/SignUp'));
export const SignIn = lazy(() => import('../features/auth/SignIn'));
export const ForgotPassword = lazy(
  () => import('../features/auth/ForgotPassword')
);
export const ResetPassword = lazy(
  () => import('../features/auth/ResetPassword')
);
export const RecorderDemo = lazy(() => import('../recorder/Demo'));
export const MicroTool = lazy(() => import('../features/microtools'));
export const SharedVideo = lazy(() => import('../features/sharedVideo'));
export const ContactSalesPage = lazy(
  () => import('../features/sales/ContactSalesPage')
);
export const Redirect = lazy(() => import('../features/app/RedirectToLegacy'));
export const Settings = lazy(() => import('../features/settings'));
// If SelectedVideo lazy loading is bothering users, revert just that.
export const SelectedVideo = lazy(() => import('../features/selectedVideo'));
export const SearchPage = lazy(() => import('../features/search'));
export const Error = lazy(() => import('../features/app/error'));
export const SharedTemplate = lazy(() => import('../features/sharedTemplate'));
