import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Upload } from 'lucide-mui';
import ALLOWED_TYPES from './AllowedTypes';
import { RouteParams } from '../library/types';
import { uploadFiles } from './uploadSlice';

const UploadModal = ({ onClose }: { onClose: () => void }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { teamId, folderId } = useParams<RouteParams>();
  const dispatch = useDispatch();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    if (teamId && folderId && files) {
      const filesArray = Array.from(files);
      const filteredFiles = filesArray.filter((file) =>
        ALLOWED_TYPES.includes(file.type)
      );
      dispatch(uploadFiles({ teamId, folderId, files: filteredFiles }));
      onClose();
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: isSmallScreen ? '15rem' : '20rem',
          borderRadius: '8px',
          textAlign: 'center',
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Upload
          sx={{
            fontSize: isSmallScreen ? 32 : 40,
            marginBottom: theme.spacing(2),
            color: theme.palette.secondary.dark,
          }}
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            maxWidth: '100%',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          Drag and Drop a file here or Upload a File
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: theme.spacing(2) }}
          onClick={() => document.getElementById('file-input')?.click()}
          data-testid="upload-file-button-upload-modal"
        >
          Upload File
        </Button>
        <input
          id="file-input"
          data-testid="file-input"
          type="file"
          multiple
          accept={ALLOWED_TYPES.join(',')}
          style={{ display: 'none' }}
          onChange={(event) => {
            if (teamId && folderId && event.target.files) {
              const filesArray = Array.from(event.target.files);
              const filteredFiles = filesArray.filter((file) =>
                ALLOWED_TYPES.includes(file.type)
              );
              dispatch(uploadFiles({ teamId, folderId, files: filteredFiles }));
              onClose();
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UploadModal;
