import { createHashHistory, createBrowserHistory } from 'history';
import type { History } from 'history';
import { config } from '../config';

let history: History;
let historySet = false;

// Workaround for URL additional hash issue https://screenapp.atlassian.net/browse/SA-2021
export const getHistory = (): History => {
  if (historySet) {
    return history;
  }

  // https://screenapp.atlassian.net/browse/SA-2021
  if (
    !config.appBaseUrl.startsWith(window?.location?.origin) ||
    window?.location?.pathname.endsWith('plugin-test.html')
  ) {
    history = createBrowserHistory();
  } else {
    history = createHashHistory();
  }
  historySet = true;

  return history;
};

export default getHistory;
