import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import {
  GraduationCap,
  UploadCloud,
  SendHorizontal,
  CheckCircle as CheckCircleIcon,
  Rocket,
  Scissors,
} from 'lucide-mui';

import { RootState } from '../../../reducers';
import ActionButton from '../../plan/upgrade/ActionButton';
import usePlan from '../../plan/hooks/plan';
import { useTags } from '../../../hooks/useTags';
import ActionDialog from '../../globalModals/ActionDialog';
import { GlobalDialogProps } from '../../../types';
import { config } from '../../../../config';
import logger from '../../../lib/logger';
import { GEMINI_MODEL } from '../../microtools/common';
import { pushModal } from '../../globalModals/globalModalsSlice';

// Button text constants
const BUTTON_TEXTS = {
  tooltipMessage: 'Get Educational Discount on our Premium Features',
  buttonText: 'Get Discount',
};

// List of known education domain endings
const EDUCATION_DOMAINS = [
  '.edu',
  '.ac.uk',
  '.edu.au',
  '.ac.nz',
  '.edu.cn',
  '.edu.hk',
  '.ac.jp',
  '.edu.sg',
  '.ac.za',
  '.edu.mx',
  '.edu.br',
  // Add more as needed
];

interface EducationalDiscountDialogProps extends GlobalDialogProps {
  formSource?: string;
  onClose: () => void;
}

// Dialog component for educational discount verification
const EducationalDiscountDialog: React.FC<EducationalDiscountDialogProps> = ({
  onClose,
  formSource,
}) => {
  const { settings } = useSelector((state: RootState) => state);
  const userEmail = settings.user.email || '';
  const { setTag } = useTags();
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageVerificationStatus, setImageVerificationStatus] = useState<
    'idle' | 'loading' | 'verified' | 'failed'
  >('idle');
  const [verificationMessage, setVerificationMessage] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Check if email domain is an educational institution
  const isEducationalEmail = EDUCATION_DOMAINS.some((domain) =>
    userEmail.toLowerCase().endsWith(domain)
  );

  const handleVerifyByEmail = () => {
    // Set the user tag for educational discount
    setTag({ key: 'educationalDiscount', value: 'verified' });
    setImageVerificationStatus('verified');
    setVerificationMessage(
      'Your email has been verified as an educational email. You are eligible for the educational discount!'
    );
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      setSelectedImage(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleUpgradeWithDiscount = () => {
    // Close the current dialog
    onClose();

    // Add the promo code to the URL
    const url = window.location.pathname;
    const hasParams = window.location.search.length > 0;
    const newUrl = hasParams
      ? `${url}${window.location.search}&promo=EDU50`
      : `${url}?promo=EDU50`;

    window.history.replaceState({}, document.title, newUrl);

    // Open the upgrade modal directly
    dispatch(
      pushModal({
        type: 'stripe-pricing-table-upgrade-modal',
        source: 'educational-discount',
      })
    );
  };

  const handleVerifyByImage = async () => {
    if (!selectedImage || imageVerificationStatus === 'loading') return;

    setImageVerificationStatus('loading');

    try {
      // Use Gemini API to verify if the uploaded image is an educational ID or document
      logger.info('Attempting to verify educational document with Gemini API', {
        formSource,
      });

      const response = await fetch(config.geminiProxyUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: GEMINI_MODEL,
          contents: [
            {
              parts: [
                {
                  inlineData: {
                    mimeType: 'image/jpeg',
                    data: selectedImage.split(',')[1], // Remove the data URL prefix
                  },
                },
                {
                  text:
                    "Is this a valid educational ID, student card, or educational institution document? Please just respond with 'YES' if it is educational, or 'NO' if it's not clearly educational. If YES, briefly note what educational institution or document type it is.",
                },
              ],
            },
          ],
        }),
      });

      if (!response.ok) {
        logger.error('API error response', {
          status: response.status,
          statusText: response.statusText,
        });
        throw new Error(
          `API Error: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();

      if (data.candidates && data.candidates[0]) {
        const aiResponse = data.candidates[0].content.parts[0].text;

        // Check if the AI detected it as an educational document
        if (aiResponse.toLowerCase().startsWith('yes')) {
          // Set verification status and tag for educational discount
          logger.info('Document verified as educational');
          setImageVerificationStatus('verified');
          setVerificationMessage(
            `Verification successful! ${aiResponse.substring(3)}`
          );
          setTag({ key: 'educationalDiscount', value: 'verified' });
        } else {
          logger.info('Document not verified as educational', { aiResponse });
          setImageVerificationStatus('failed');
          setVerificationMessage(
            'The uploaded image does not appear to be a valid educational document. Please try again with a clearer image of your student ID or educational document.'
          );
        }
      } else {
        throw new Error('Invalid API response format');
      }
    } catch (error) {
      logger.error('Error verifying educational document', error);
      setImageVerificationStatus('failed');
      setVerificationMessage(
        'An error occurred while verifying your document. Please try again later.'
      );
    }
  };

  // If email is from an educational domain, automatically verify
  useEffect(() => {
    if (isEducationalEmail) {
      handleVerifyByEmail();
    }
  }, [isEducationalEmail]);

  // Message text based on email type
  const getEmailMessage = () => {
    if (isEducationalEmail) {
      return ' We&apos;ve detected you&apos;re using an educational email.';
    }
    return ' Verify your educational status with your student ID or other documentation.';
  };

  // Render the success view
  const renderSuccessView = () => (
    <Box sx={{ textAlign: 'center', maxWidth: 400, mx: 'auto' }}>
      <CheckCircleIcon
        style={{ color: 'green', width: 48, height: 48, margin: '0 auto 16px' }}
      />
      <Typography variant="h6" color="success.main" gutterBottom>
        Verification Successful!
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Your educational status has been verified. You can now access a 50%
        discount on any annual plan.
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        We&apos;re committed to making our premium tools accessible to students
        and educators.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Rocket />}
        onClick={handleUpgradeWithDiscount}
        size="large"
        sx={{ py: 1, px: 4, minWidth: 200, height: '40px', mb: 3 }}
      >
        Upgrade&nbsp;with&nbsp;Discount
      </Button>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 2, fontSize: '0.8rem', mt: 1 }}
      >
        Or save this code for later:
      </Typography>
      <Box
        sx={{
          border: '1px dashed #ccc',
          borderRadius: 1,
          p: 1,
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: 180,
          mx: 'auto',
          position: 'relative',
        }}
      >
        <Typography variant="body1" fontWeight="medium" sx={{ mx: 'auto' }}>
          EDU50
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: -8,
            right: 10,
            color: 'text.secondary',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Scissors sx={{ width: 16, height: 16 }} />
        </Box>
      </Box>
    </Box>
  );

  // Render the educational email verification
  const renderEmailVerification = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={handleVerifyByEmail}
      size="large"
      sx={{ py: 1, px: 4, minWidth: 200 }}
    >
      Apply My Discount
    </Button>
  );

  // Render the image verification
  const renderImageVerification = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        src={selectedImage || ''}
        alt="Uploaded verification document"
        sx={{
          width: '100%',
          height: 180,
          objectFit: 'contain',
          mb: 2,
        }}
      />

      <Button
        variant="contained"
        color="primary"
        startIcon={
          imageVerificationStatus === 'loading' ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <SendHorizontal />
          )
        }
        onClick={handleVerifyByImage}
        disabled={imageVerificationStatus === 'loading'}
        size="large"
        sx={{ py: 1, px: 4, minWidth: 200 }}
      >
        {imageVerificationStatus === 'loading'
          ? 'Verifying...'
          : 'Verify Document'}
      </Button>

      {imageVerificationStatus === 'failed' && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {verificationMessage}
        </Alert>
      )}
    </Box>
  );

  // Render the image upload
  const renderImageUpload = () => (
    <>
      <input
        type="file"
        accept="image/*"
        hidden
        ref={fileInputRef}
        onChange={handleImageUpload}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => fileInputRef.current?.click()}
        startIcon={<UploadCloud />}
        size="large"
        sx={{ py: 1, px: 4, minWidth: 200 }}
      >
        Upload Verification
      </Button>

      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ mt: 3, display: 'block' }}
      >
        Student ID cards, enrollment verification, or any educational document
      </Typography>
    </>
  );

  // Determine which verification UI to render
  const renderVerificationUI = () => {
    if (isEducationalEmail) {
      return renderEmailVerification();
    }

    if (selectedImage) {
      return renderImageVerification();
    }

    return renderImageUpload();
  };

  return (
    <ActionDialog
      title="Students & Educators"
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      actions={[]}
    >
      <Box
        sx={{
          p: 3,
          minHeight: 250,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {imageVerificationStatus === 'verified' ? (
          renderSuccessView()
        ) : (
          <Box sx={{ textAlign: 'center', maxWidth: 400, mx: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Get 50% Off already discounted Annual Plans!
            </Typography>

            <Typography variant="body1" sx={{ mb: 3 }}>
              We&apos;re committed to making our premium tools accessible to
              students and educators.
              {getEmailMessage()}
            </Typography>

            {renderVerificationUI()}
          </Box>
        )}
      </Box>
    </ActionDialog>
  );
};

// Main component for the educational discount button
const EducationalDiscount = () => {
  const { plan } = useSelector((state: RootState) => state);
  const { getTag } = useTags();
  const { isPlan } = usePlan();
  const [discountDialogOpen, setDiscountDialogOpen] = useState(false);

  const userGoal = getTag('userGoal');
  const hasEducationGoal = userGoal === 'education';
  const isFreePlan =
    !plan.subscription?.package ||
    plan.subscription.package === 'FREE_LOGGEDIN';
  const upgraded = isPlan(['GROWTH', 'BUSINESS', 'ENTERPRISE']);

  // Only show for free plan users with education goal who haven't upgraded
  if (!isFreePlan || !hasEducationGoal || upgraded) {
    return null;
  }

  const handleClick = () => {
    setDiscountDialogOpen(true);
  };

  const handleDiscountDialogClose = () => {
    setDiscountDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ActionButton
        startIcon={<GraduationCap />}
        variant="outlined"
        color="secondary"
        size="medium"
        onClick={handleClick}
        sx={{ minWidth: 'unset', height: '40px' }}
        tooltipMessage={BUTTON_TEXTS.tooltipMessage}
      >
        {BUTTON_TEXTS.buttonText.replace(/ /g, '\u00A0')}
      </ActionButton>
      {discountDialogOpen && (
        <EducationalDiscountDialog
          onClose={handleDiscountDialogClose}
          formSource="Dashboard - Educational Discount"
        />
      )}
    </Box>
  );
};

export default EducationalDiscount;
