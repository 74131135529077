import type { Middleware } from 'redux';
import { enrichChartMogul } from '../services/trackingService';
import { RootState } from '../reducers';

const chartMogulMiddleware: Middleware<{}, RootState> = ({ getState }) => (
  next
) => (action) => {
  const result = next(action);
  const { plan } = getState();
  const { marketing } = plan;

  const { intent } = marketing;

  if (intent) {
    enrichChartMogul([{ type: 'String', key: 'Intent', value: intent }]);
  }

  return result;
};

export default chartMogulMiddleware;
