/* eslint no-param-reassign: "error" */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getInitialDataV2 } from '../../services/authService';
import { requestStatusType } from '../../types';
import { setFolders } from '../library/librarySlice';
import { setUser } from '../settings/settingsSlice';
import { setSubscriptionState } from '../plan/planSlice';
import { setTeamState } from '../team/teamSlice';
import { removeToken } from '../../lib/localStorageHandler';
import logger from '../../lib/logger';
import { config } from '../../../config';
import { getChurnkeyData } from '../../services/subscriptionServices';
import { getPluginData } from '../plugin/pluginSlice';
import { sendEmail } from '../../services/emailService';

/**
 * Churnkey Paywall
 * Try to open churnkey failed payment wall for the selected team.
 *
 * The function will fetch the churnkey data for the team, and if the data
 * is available and the config is set up correctly, it will call the
 * churnkey.check function to open the failed payment wall.
 *
 * The onError callback will be called if there is an error while calling
 * churnkey.check.
 *
 * @param {TeamSpace} selectedTeam - The selected team
 */
async function tryChurnkeyFailedPaymentWall(selectedTeamId: string) {
  const { customerId, subscriptionId, authHash } =
    (await getChurnkeyData({
      teamId: selectedTeamId,
    }).catch((error) => {
      logger.error(error);
      return {
        customerId: null,
        subscriptionId: null,
        authHash: null,
      };
    })) || {};
  if (config.churnkeyAppId && authHash && customerId && subscriptionId) {
    window.churnkey?.check('failed-payment', {
      subscriptionId,
      customerId,
      authHash,
      appId: config.churnkeyAppId,
      mode: config.churunkeyMode,
      provider: 'stripe',
      softWall: false,
      forceCheck: false,
      gracePeriodDays: 0,
      ignoreInvoicesWithoutAttempt: false,
      onError(error, type) {
        logger.error('churnkey error', error, type);
      },
    });
  }
}

export const getInitialState = createAsyncThunk(
  'app/getInitialState',
  async (
    options: { joinCode?: string; zoomCode?: string } = {},
    { dispatch }
  ) => {
    try {
      const { joinCode, zoomCode } = options;

      // Only pass `joinCode` or `zoomCode` to the API if provided
      const apiOptions: { joinCode?: string; zoomCode?: string } = {};
      if (joinCode) {
        apiOptions.joinCode = joinCode;
      }
      if (zoomCode) {
        apiOptions.zoomCode = zoomCode;
      }

      // Fetch initial data based on the options
      const {
        folders,
        user,
        team,
        packageProperties,
        usage,
        settings,
        integrations,
      } = (await getInitialDataV2(apiOptions)) || {};

      const {
        _id,
        email,
        profile,
        role,
        deleteRequestedAt,
        marketing = {},
        createdAt,
        tags = {},
      } = user || {};
      const { name, picture } = profile || {};
      const { teams, selectedTeam } = team;

      // Dispatch data to respective slices
      await dispatch(setFolders(folders));
      await dispatch(
        setUser({
          _id,
          email,
          name,
          picture,
          role,
          deleteRequestedAt,
          createdAt,
          tags,
          integrations,
        })
      );
      await dispatch(
        setTeamState({
          status: 'succeeded',
          teams,
          selectedTeam,
        })
      );
      await dispatch(
        setSubscriptionState({
          usage,
          packageProperties,
          plan: selectedTeam.subscription,
          selectedPlan: ['BUSINESS', 'GROWTH'].includes(
            selectedTeam.subscription.package
          )
            ? (selectedTeam.subscription.package as 'BUSINESS' | 'GROWTH')
            : 'GROWTH',
          marketing,
        })
      );

      await dispatch(getPluginData());

      // Initialize Tolt if applicable
      if (window.tolt_referral) {
        window.tolt?.signup(user.email);
      }

      // Try to handle Churnkey payment wall
      if (selectedTeam.subscription.state === 'inactive') {
        await tryChurnkeyFailedPaymentWall(selectedTeam.teamId);
      }

      // Return settings for further usage
      return { settings };
    } catch (error) {
      logger.error('initial data error', error);
      removeToken();
      throw error;
    }
  }
);

export const sendEmailThunk = createAsyncThunk(
  'app/sendEmail',
  async (emailData: {
    template: string;
    toEmail: string;
    data: {
      content: string;
      [key: string]: any;
    };
  }) => {
    try {
      const response = await sendEmail(emailData);
      return response.data;
    } catch (error) {
      logger.error('send email error', error);
      throw error;
    }
  }
);

export interface AppState {
  status: requestStatusType;
}

const initialState: AppState = {
  status: 'idle',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInitialState.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getInitialState.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(getInitialState.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(sendEmailThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(sendEmailThunk.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(sendEmailThunk.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default appSlice.reducer;
