import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Home, Lock, Sparkles, X } from 'lucide-mui';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import type { ListProps } from '@mui/material';
import type { ActionType, URLParamsType } from '../../../types';
import type { RootState } from '../../../reducers';
import { getFolderUrl } from '../../../lib/routingUtils';
import FreeTrial from '../../plan/upgrade/FreeTrial';
import { useTags } from '../../../hooks/useTags';
import { MultiAskAIContext } from '../index';
import { setDrawerState } from '../dashboardSlice';
import SearchComponent from './Search';

interface DrawerActionType extends ActionType<DrawerActionKeyType> {
  tooltip: string;
}

type DrawerActionKeyType = 'recents' | 'private';

const drawerActions: DrawerActionType[] = [
  {
    key: 'recents',
    label: 'Home',
    startIcon: <Home />,
    tooltip: 'Home',
  },
  {
    key: 'private',
    label: 'My Recordings',
    startIcon: <Lock />,
    tooltip: 'Your Personal Private Recordings',
  },
];

export const DrawerActions = (listProps: ListProps) => {
  const [showTrialModal, setShowTrialModal] = useState(false);
  const { setShowMultiAskAI, showMultiAskAI } = React.useContext(
    MultiAskAIContext
  );
  const { dashboard, team } = useSelector((state: RootState) => state);
  const { setTag } = useTags();
  const { folderId } = useParams<URLParamsType>();
  const { selectedTeam, teams } = team;
  const teamId = selectedTeam?.teamId || (teams[0]?.teamId as string);
  const open = dashboard.drawerState === 'open';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const handleQuickActionClick = () => {
    // Set default view and sorting preferences using tags
    setTag({ key: 'viewMode', value: 'grid' });
    setTag({ key: 'sortBy', value: 'createdAt' });
    setTag({ key: 'sortOrder', value: 'desc' });

    if (isMobile) {
      dispatch(setDrawerState('closed'));
    }
  };

  const actions: DrawerActionType[] = drawerActions.map((action) => ({
    ...action,
    href: getFolderUrl({
      teamId,
      folderId: action.key,
      withBasePath: false,
    }),
  }));

  // Add state to track if Ask AI panel is open
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);

  // Update the state when MultiAskAI visibility changes
  React.useEffect(() => {
    setIsGenerateOpen(showMultiAskAI);
  }, [showMultiAskAI]);

  return (
    <>
      <List
        {...listProps}
        sx={{
          display: 'block',
          pt: 0.5,
          pb: 0,
          '& .MuiListItem-root:not(:last-child)': {
            mb: 0.5,
          },
          mb: 0,
        }}
      >
        {actions.map(({ key, label, startIcon, href, tooltip }) => (
          <ListItem key={key} disablePadding sx={{ display: 'block' }}>
            <Tooltip title={open ? tooltip : label} placement="right" arrow>
              <ListItemButton
                component={Link}
                to={href || ''}
                onClick={() => handleQuickActionClick()}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  px: open ? 2.5 : 1.25,
                  borderRadius: 20,
                  mx: 0.5,
                  minHeight: '36px',
                  position: 'relative',
                  overflow: 'hidden',
                  bgcolor: 'transparent',
                  '&.Mui-selected': {
                    bgcolor: 'transparent',
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor:
                      folderId === key
                        ? theme.palette.action.hover
                        : theme.palette.action.hover,
                    opacity: folderId === key ? 1 : 0,
                    borderRadius: 'inherit',
                  },
                  '&:hover': {
                    '&::before': {
                      opacity: 1,
                    },
                  },
                  '& > *': {
                    position: 'relative',
                    zIndex: 1,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    pl: open ? 0 : 0.5,
                  }}
                >
                  {startIcon}
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': {
                      fontWeight: folderId === key ? 700 : 400,
                    },
                  }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        ))}

        <ListItem disablePadding sx={{ display: 'block' }}>
          <Tooltip title="Generate" placement="right" arrow>
            <ListItemButton
              onClick={() => {
                const newState = !isGenerateOpen;
                setIsGenerateOpen(newState);
                setShowMultiAskAI(newState);
                if (isMobile && newState) {
                  dispatch(setDrawerState('closed'));
                }
              }}
              sx={{
                justifyContent: open ? 'initial' : 'center',
                px: open ? 2.5 : 1.25,
                borderRadius: 20,
                mx: 0.5,
                minHeight: '36px',
                position: 'relative',
                overflow: 'hidden',
                bgcolor: 'transparent',
                '&.Mui-selected': {
                  bgcolor: 'transparent',
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: isGenerateOpen
                    ? theme.palette.action.hover
                    : theme.palette.action.hover,
                  opacity: isGenerateOpen ? 1 : 0,
                  borderRadius: 'inherit',
                },
                '&:hover': {
                  '&::before': {
                    opacity: 1,
                  },
                },
                '& > *': {
                  position: 'relative',
                  zIndex: 1,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  pl: open ? 0 : 0.5,
                }}
              >
                {isGenerateOpen ? <X /> : <Sparkles />}
              </ListItemIcon>
              <ListItemText
                primary="Generate"
                sx={{
                  opacity: open ? 1 : 0,
                  '& .MuiTypography-root': {
                    fontWeight: isGenerateOpen ? 700 : 400,
                  },
                }}
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>

        <SearchComponent open={open} />
      </List>

      {showTrialModal && <FreeTrial onClose={() => setShowTrialModal(false)} />}
    </>
  );
};

export default DrawerActions;
