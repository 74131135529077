/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FeatureKey } from './upgrade/upgradeView/featureLimits';
import {
  IUsage,
  PackageProperties,
  DEFAULT_PACKAGE_CONFIG,
} from './userPackage';
import { Gen2PackageType, PackageType, requestStatusType } from '../../types';
import { createOrUpdateSubscription, fetchSubscription } from './thunk';
import type { TeamSubscription, Marketing } from '../../types/state';

interface PlanState {
  status: requestStatusType;
  upgrade: {
    sourceFeatureKey: FeatureKey;
    selectedPlan: PackageType;
  };
  usage: IUsage;
  packageProperties: PackageProperties;
  subscription: TeamSubscription;
  marketing: Marketing;
}
const initialState: PlanState = {
  status: 'idle',
  upgrade: {
    sourceFeatureKey: 'askAI',
    selectedPlan: 'GROWTH',
  },
  usage: {
    recordingRequestsCount: 0,
    askAICount: 0,
    transcriptionCount: 0,
    copyTranscriptCount: 0,
    uploadCount: 0,
    mp4DownloadCount: 0,
    webmDownloadCount: 0,
    mkvDownloadCount: 0,
    mp3DownloadCount: 0,
  },
  packageProperties: DEFAULT_PACKAGE_CONFIG,
  subscription: {
    package: 'FREE_LOGGEDIN',
    provider: 'stripe',
    providerId: '',
    upgradeStatus: 'idle',
    packageUpdatedAt: '',
    term: 'annual',
    price: 0,
    currentPeriodStartedAt: '',
    state: 'active',
  },
  marketing: {
    referrerUrl: '',
    intent: '',
  },
};
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSourceFeature: (
      state,
      action: PayloadAction<{
        sourceFeature: FeatureKey;
      }>
    ) => {
      state.upgrade.sourceFeatureKey = action.payload.sourceFeature;
    },
    selectPlan: (
      state,
      action: PayloadAction<{
        plan: Gen2PackageType;
      }>
    ) => {
      state.upgrade.selectedPlan = action.payload.plan;
    },
    setSubscriptionState: (
      state,
      action: PayloadAction<{
        usage: IUsage;
        packageProperties: PackageProperties;
        plan: TeamSubscription;
        selectedPlan: Gen2PackageType;
        marketing: Marketing;
      }>
    ) => {
      state.usage = action.payload.usage;
      state.packageProperties = action.payload.packageProperties;
      state.subscription = action.payload.plan;
      state.marketing = action.payload.marketing;
    },
    setUsage: (state, action: PayloadAction<IUsage>) => {
      state.usage = action.payload;
    },
    setPackageProperties: (state, action: PayloadAction<PackageProperties>) => {
      state.packageProperties = action.payload;
    },
    trackCancelPlanModal: (
      state,
      action: PayloadAction<{
        modal: string;
      }>
    ) => {
      state.marketing.intent = action.payload.modal;
    },
    trackCancelPlanButton: (
      state,
      action: PayloadAction<{
        button: string;
      }>
    ) => {
      state.marketing.intent = action.payload.button;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.subscription = action.payload.subscription;
      state.usage = action.payload.usage;
      state.packageProperties = action.payload.packageProperties;
      // In legacy code, the upgradeStatus set to null after upgrading
      if (!state.subscription.upgradeStatus) {
        state.subscription.upgradeStatus = 'idle';
      }
    });
    builder.addCase(fetchSubscription.rejected, (state) => {
      state.status = 'failed';
    });
    // Check upgrade status
    builder.addCase(createOrUpdateSubscription.pending, (state) => {
      state.subscription.upgradeStatus = 'pending';
    });
    builder.addCase(createOrUpdateSubscription.fulfilled, (state, action) => {
      if (action.payload) {
        state.subscription = action.payload.subscription;
        state.packageProperties = action.payload.packageProperties;
        state.usage = action.payload.usage;
      }
      // In legacy code, the upgradeStatus set to null after upgrading
      if (!state.subscription.upgradeStatus) {
        state.subscription.upgradeStatus = 'succeeded';
      }
    });
    builder.addCase(createOrUpdateSubscription.rejected, (state) => {
      state.subscription.upgradeStatus = 'failed';
    });
  },
});

export const {
  setSourceFeature,
  setUsage,
  selectPlan,
  setSubscriptionState,
  trackCancelPlanModal,
  trackCancelPlanButton,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
