import React, { useState } from 'react';
import { Typography, Box, Paper, CircularProgress } from '@mui/material';
import {
  Rocket,
  PiggyBank,
  Sparkles,
  Receipt,
  Star,
  Cloud,
  Link2,
  Video,
} from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../reducers';
import { planPricesPerMonth } from '../../../constants/prices';
import { usePlan } from '../../plan/hooks/plan';
import { createOrUpdateSubscription } from '../../plan/thunk';
import { pushModal } from '../../globalModals/globalModalsSlice';
import planFeatures from '../../plan/upgrade/upgradeView/featureLimits';
import ActionDialog from '../../globalModals/ActionDialog';
import type { ActionType } from '../../globalModals/ActionDialog';
import { setAlertMessage } from '../dashboardSlice';

const UpSellModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();

  const source = useSelector(
    (state: RootState) => state.globalModals.actionData.source
  );

  const { term, price: currentPrice } = useSelector(
    (state: RootState) => state.plan.subscription
  );
  const { packageProperties, upgrade } = useSelector(
    (state: RootState) => state.plan
  );
  const { sourceFeatureKey } = upgrade;
  const currentPricePerMonth =
    term === 'monthly' ? currentPrice : currentPrice / 12;
  const { team } = useSelector((state: RootState) => state);
  const teamId = team.selectedTeam?.teamId || team.teams[0].teamId;
  const { teamPlan } = usePlan();

  const [isLoading, setIsLoading] = useState(false);

  const dialogType =
    // eslint-disable-next-line no-nested-ternary
    source === 'limits'
      ? 'unlimited'
      : term === 'monthly'
      ? 'annual'
      : 'unlimited';

  // Get the feature info if this is a feature-specific upsell
  const featureInfo =
    sourceFeatureKey && source === 'limits'
      ? planFeatures.BUSINESS?.[sourceFeatureKey]
      : null;

  const dialogContent = [
    {
      type: 'annual',
      title: 'Switch to Annual Billing',
      content: (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 3,
              color: 'text.secondary',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            Simplify your billing with our annual plan
          </Typography>
          {currentPricePerMonth && currentPricePerMonth > 0 && (
            <Typography
              variant="h5"
              color="success.main"
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 3,
              }}
            >
              Save{' '}
              {Math.round(
                ((currentPricePerMonth -
                  (teamPlan in planPricesPerMonth
                    ? planPricesPerMonth[
                        teamPlan as keyof typeof planPricesPerMonth
                      ].annual
                    : 0)) /
                  currentPricePerMonth) *
                  100
              )}
              %
            </Typography>
          )}
          <Box
            sx={{
              display: 'grid',
              gap: 3,
              mb: 3,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: 'none',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <PiggyBank sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Annual Discount
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Get a significant discount with annual billing
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Sparkles sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Flexible Credits
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Pool your monthly credits into one annual allowance
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Receipt sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Simplified Billing
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      One annual invoice instead of monthly payments
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Star sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Same Great Features
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Keep all your current features and benefits
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </>
      ),
      newPrice:
        teamPlan in planPricesPerMonth
          ? planPricesPerMonth[teamPlan as keyof typeof planPricesPerMonth]
              .annual
          : 0,
    },
    {
      type: 'unlimited',
      title: featureInfo ? `Unlock ${featureInfo.title}` : 'Get Unlimited',
      content: (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 3,
              color: 'text.secondary',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            {featureInfo
              ? featureInfo.description
              : 'Remove limits and unlock the full potential of our platform'}
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gap: 3,
              mb: 3,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: 'none',
              }}
            >
              {featureInfo && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 2,
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  You will also get
                </Typography>
              )}

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
                {/* Always show these core benefits */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Cloud sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Unlimited Storage
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Store as many videos as you want, up from{' '}
                      {packageProperties?.librarySize} limit
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Sparkles sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Unlimited AI Credits
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Use AI features at high volume, up from your current limit
                      of {packageProperties?.askAICount || 'limited'} credits
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Link2 style={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Integrations
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Seamlessly connect with your existing tools via Zapier,
                      API, or Webhooks. Automate your workflow.
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: 1,
                      bgcolor: 'primary.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Video sx={{ color: '#fff' }} />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Google Meet Bot
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Automatically join and record your Google Meet calls
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </>
      ),
      newPrice: planPricesPerMonth.BUSINESS[term],
    },
  ];

  const currentDialog = dialogContent.find(
    (dialog) => dialog.type === dialogType
  );

  const handleUpgrade = async () => {
    setIsLoading(true);
    try {
      const newPlan = dialogType === 'annual' ? teamPlan : 'BUSINESS';
      const newTerm = dialogType === 'annual' ? 'annual' : term;
      const newPrice =
        (currentDialog?.newPrice ?? 0) * (dialogType === 'annual' ? 12 : 1);

      const result = await dispatch(
        createOrUpdateSubscription({
          teamId,
          plan: newPlan,
          term: newTerm,
          totalAmount: newPrice,
        })
      );
      if (createOrUpdateSubscription.rejected.match(result as never)) {
        onClose();
        const { message = '' } = (result as any).error || {};
        throw new Error(message);
      }

      onClose();
      dispatch(
        pushModal({
          type: 'upgradeSuccess',
        })
      );
    } catch (error) {
      console.error('Upgrade failed:', error);
      dispatch(
        setAlertMessage({
          type: 'error',
          message: `Failed to upgrade. Please try again later. Error: ${error.message}`,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const calculateSavings = () => {
    if (currentDialog && 'newPrice' in currentDialog) {
      if (dialogType === 'annual') {
        const monthlySavings =
          (currentPricePerMonth ?? 0) - (currentDialog.newPrice ?? 0);
        const yearlySavings = monthlySavings * 12;
        return (
          <Box
            sx={{
              mt: 3,
              mb: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: 'none',
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="body2"
                  color="success.main"
                  sx={{ mb: 0.5, fontWeight: 'medium' }}
                >
                  YEARLY SAVINGS
                </Typography>
                <Typography
                  variant="h4"
                  color="success.main"
                  sx={{ fontWeight: 'bold' }}
                >
                  ${yearlySavings}
                </Typography>
              </Box>

              <Box sx={{ mt: 2.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 1,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Current Monthly Price
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    ${currentPricePerMonth} p/m
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    New Monthly Price
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                    ${currentDialog.newPrice} p/m
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Typography
              variant="caption"
              color="text.secondary"
              align="center"
              sx={{ width: '100%' }}
            >
              Billed annually at ${currentDialog.newPrice * 12}/year • Prorated
              to your billing date
            </Typography>
          </Box>
        );
      }
      if (dialogType === 'unlimited') {
        return (
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                border: 'none',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography variant="subtitle2">Current Plan</Typography>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="h6">
                    ${currentPricePerMonth}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ ml: 0.5, color: 'text.secondary' }}
                    >
                      /month
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">Unlimited Plan</Typography>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="h6" color="primary.main">
                    ${currentDialog.newPrice}
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ ml: 0.5, color: 'text.secondary' }}
                    >
                      /month
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Paper>
            {term === 'annual' && (
              <Typography
                variant="caption"
                color="text.secondary"
                align="center"
              >
                Billed annually at ${currentDialog.newPrice * 12}/year •
                Prorated to your billing date
              </Typography>
            )}
          </Box>
        );
      }
    }
    return null;
  };

  const getButtonText = () => {
    if (dialogType === 'annual') {
      return 'Switch to Annual Plan';
    }
    if (dialogType === 'unlimited') {
      return 'Upgrade to Unlimited';
    }
    return '';
  };

  const getDialogTitle = () => {
    if (currentDialog && 'newPrice' in currentDialog) {
      if (dialogType === 'annual') {
        return `Switch to Annual Billing`;
      }
      return currentDialog.title;
    }
    return '';
  };

  const actions: ActionType[] = [
    {
      key: 'cancel',
      label: 'Cancel',
      onClick: onClose,
      variant: 'outlined',
      color: 'secondary',
      disabled: isLoading,
    },
    {
      key: 'upgrade',
      label: getButtonText(),
      onClick: handleUpgrade,
      variant: 'contained',
      color: 'primary',
      disabled: isLoading,
      loading: isLoading,
      startIcon: isLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        <Rocket />
      ),
    },
  ];

  return (
    <ActionDialog
      open
      onClose={onClose}
      title={getDialogTitle()}
      actions={actions}
      maxWidth="sm"
    >
      {currentDialog?.content}
      {calculateSavings()}
    </ActionDialog>
  );
};

export default UpSellModal;
