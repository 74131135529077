/* eslint-disable import/prefer-default-export */
import api, { apiV2 } from '../lib/baseAxios';
import { IVFSResponse } from './fileSystemService';
import { TeamSpace } from '../types/state';

export const saveLastSelectedTeamSpace = async (teamId: string) => {
  return api.post(`/company-shared-space/selected-team-space`, { teamId });
};

// V2 endpoints
interface GetInviteIdResponseData {
  joinCode: string;
}
export const getJoinCode = async (teamId: string) => {
  const response = await apiV2.put<IVFSResponse<GetInviteIdResponseData>>(
    `/team/${teamId}/member/join/code`
  );
  const { joinCode } = response.data.data || {};
  return joinCode;
};

export const removeTeamMember = async ({
  teamId,
  userId,
}: {
  teamId: string;
  userId: string;
}) => {
  return apiV2.delete(`/team/${teamId}/member/${userId}`);
};

interface TeamSettings {
  fileOptions: TeamSpace['fileOptions'];
}
export const updateTeamSettings = async ({
  teamId,
  settings,
}: {
  teamId: string;
  settings: TeamSettings;
}) => {
  return apiV2.put(`/team/${teamId}/settings`, settings);
};

// Schedule team deletion
export const scheduleTeamDeletion = async (teamId: string) => {
  return apiV2.put(`/team/${teamId}/deletion-schedule`);
};

// Unschedule team deletion
export const unscheduleTeamDeletion = async (teamId: string) => {
  return apiV2.delete(`/team/${teamId}/deletion-schedule`);
};

export const addWebhookUrl = async ({
  teamId,
  name,
  url,
  templateId,
}: {
  teamId: string;
  name: string;
  url: string;
  templateId: string;
}) => {
  return apiV2.post(`/team/${teamId}/integrations/webhook`, {
    name,
    url,
    templateId,
  });
};

export const removeWebhookUrl = async ({
  teamId,
  url,
}: {
  teamId: string;
  url: string;
}) => {
  return apiV2.delete(`/team/${teamId}/integrations/webhook`, {
    params: { url },
  });
};

export const addTeamTag = ({
  teamId,
  tag,
}: {
  teamId: string;
  tag: { key: string; value: string };
}) => {
  return apiV2.post(`/team/${teamId}/tag`, tag);
};
