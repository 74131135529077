import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Skeleton,
  ListItem,
  Avatar,
} from '@mui/material';
import { Upload } from 'lucide-mui';
import { RootState } from '../../reducers';

interface PendingImportProps {
  view?: 'grid' | 'list';
}

const GridViewItem = ({ message }: { message: string }) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        transform: 'scale(1)',
        '&:hover': {
          transform: 'scale(1.02)',
        },
        containerType: 'inline-size',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          animation="wave"
          sx={{
            paddingTop: 'calc(9 / 16 * 100%)',
            borderRadius: '8px',
            backgroundColor: 'background.paper',
            '&::after': {
              background:
                'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.08), transparent)',
              animation: 'wave 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              textAlign: 'center',
              px: 2,
            }}
          >
            <Upload
              sx={{
                fontSize: '3rem',
                animation: 'float 2s ease-in-out infinite',
                '@keyframes float': {
                  '0%, 100%': { transform: 'translateY(0)' },
                  '50%': { transform: 'translateY(-5px)' },
                },
                opacity: 0.9,
                color: 'secondary.main',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                '@container (max-width: 250px)': {
                  display: 'none',
                },
              }}
            >
              {message}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: 'text.primary',
                opacity: 0.8,
                '@container (max-width: 250px)': {
                  display: 'none',
                },
              }}
            >
              We&apos;ll email you when ready
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          py: '16px',
          px: 0,
          '&:last-child': { pb: '16px' },
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              backgroundColor: 'secondary.main',
              transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <Upload sx={{ fontSize: '1.2rem' }} />
          </Avatar>
          <Box sx={{ ml: 2, flexGrow: 1, overflow: 'hidden' }}>
            <Skeleton
              variant="text"
              width="90%"
              sx={{
                height: '16px',
                transform: 'scale(1, 0.9)',
                mb: '4px',
              }}
            />
            <Skeleton
              variant="text"
              width="60%"
              sx={{
                height: '16px',
                transform: 'scale(1, 0.9)',
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

const ListViewItem = ({ message }: { message: string }) => (
  <ListItem
    disablePadding
    sx={{
      borderBottom: 1,
      borderColor: 'secondary.light',
      position: 'relative',
      py: 1.5,
      pr: 2,
      opacity: 0.8,
    }}
  >
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'minmax(200px, 1fr) 80px',
          sm: 'minmax(200px, 1.5fr) 120px',
          md: 'minmax(200px, 1.5fr) 200px',
          lg: 'minmax(250px, 1.5fr) 400px',
        },
        alignItems: 'center',
        width: '100%',
        gap: { xs: 1, sm: 2 },
        pl: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 0 }}>
        <Avatar
          sx={{
            width: { xs: 24, sm: 32 },
            height: { xs: 24, sm: 32 },
            backgroundColor: 'secondary.main',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Upload sx={{ fontSize: '1.2rem' }} />
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              minWidth: 0,
              flex: 1,
            }}
          >
            {message}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              opacity: 0.7,
            }}
          >
            We&apos;ll email you when ready
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Upload
          sx={{
            fontSize: '1.5rem',
            animation: 'float 2s ease-in-out infinite',
            '@keyframes float': {
              '0%, 100%': { transform: 'translateY(0)' },
              '50%': { transform: 'translateY(-5px)' },
            },
            opacity: 0.9,
            color: 'secondary.main',
          }}
        />
      </Box>
    </Box>
  </ListItem>
);

const PendingImport = ({ view = 'list' }: PendingImportProps) => {
  const { import: importState } = useSelector((state: RootState) => state);
  const [message, setMessage] = useState('Preparing your import..');

  useEffect(() => {
    if (importState.status !== 'pending') return;

    const messageSequence = async () => {
      setTimeout(() => setMessage('Importing your file..'), 2000);

      setTimeout(() => {
        const cyclingMessages = [
          'This may take a few moments..',
          'Processing your file..',
          'Importing..',
          'Still Working..',
        ];

        let messageIndex = 0;
        const interval = setInterval(() => {
          setMessage(cyclingMessages[messageIndex]);
          messageIndex = (messageIndex + 1) % cyclingMessages.length;
        }, 5000);

        return () => clearInterval(interval);
      }, 5000);
    };

    messageSequence();
  }, [importState.status]);

  if (importState.status !== 'pending') return null;

  if (view === 'grid') {
    return <GridViewItem message={message} />;
  }

  return <ListViewItem message={message} />;
};

export default PendingImport;
