import React from 'react';
import { Skeleton, ListItem, Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';

function FileListItemSkeleton() {
  const theme = useTheme();
  const { folderId } = useParams<{ folderId: string }>();
  const isInRecentsView = folderId === 'recents';

  return (
    <ListItem
      disablePadding
      data-testid="file-list-skeleton"
      sx={{
        borderBottom: 1,
        borderColor: theme.palette.secondary.light,
        position: 'relative',
        py: 1.5,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'minmax(200px, 1fr) 65px 70px',
            sm: isInRecentsView
              ? 'minmax(200px, 1.5fr) 80px 100px 80px 24px'
              : 'minmax(200px, 1.5fr) 80px 80px 24px',
            md: isInRecentsView
              ? 'minmax(200px, 1.5fr) 80px 100px 120px 80px 24px'
              : 'minmax(200px, 1.5fr) 80px 120px 80px 24px',
            lg: isInRecentsView
              ? 'minmax(250px, 1.5fr) 100px 120px 120px 80px 24px'
              : 'minmax(250px, 1.5fr) 100px 150px 80px 24px',
          },
          alignItems: 'center',
          flexGrow: 1,
          gap: { xs: 1, sm: 2 },
          px: 2,
          minWidth: 0,
        }}
      >
        {/* Name Column */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            minWidth: 0,
            flex: 1,
          }}
        >
          <Skeleton
            variant="rectangular"
            width={32}
            height={32}
            sx={{ borderRadius: 1, flexShrink: 0 }}
          />
          <Skeleton variant="text" sx={{ flex: 1, minWidth: 0 }} />
        </Box>

        {/* Duration Column */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="text" width={60} />
        </Box>

        {/* Folder Column */}
        {isInRecentsView && (
          <Box
            sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}
          >
            <Skeleton variant="text" width={80} />
          </Box>
        )}

        {/* Creator Column */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          <Skeleton variant="text" width={100} />
        </Box>

        {/* Date Column */}
        <Box sx={{ display: 'block', alignItems: 'center' }}>
          <Skeleton variant="text" width={80} />
        </Box>

        {/* Action Menu placeholder */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="circular" width={24} height={24} />
        </Box>
      </Box>
    </ListItem>
  );
}

export default FileListItemSkeleton;
