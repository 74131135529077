interface EmailTemplateData {
  subject?: string;
  content: string;
}

interface ShareVideoTemplateProps {
  senderName: string;
  fileName: string;
  shareUrl: string;
  recipientName?: string;
}

interface TeamInviteTemplateProps {
  teamName: string;
  inviteUrl: string;
}

// Simple wrapper for email content
export const wrapWithTheme = (content: string) => `
  <div>
    ${content}
  </div>
`;

const emailTemplates = {
  shareVideo: ({
    senderName,
    fileName,
    shareUrl,
    recipientName = 'there',
  }: ShareVideoTemplateProps): EmailTemplateData => ({
    subject: `${senderName} shared a video with you: "${fileName}"`,
    content: `
      <p>Hi ${recipientName},</p>
      <p>${senderName} has shared a video with you: <strong>${fileName}</strong></p>
      <p>Click below to watch it securely in your browser – no account required.</p>
      <div>
        <a href="${shareUrl}" class="button">Watch Video</a>
      </div>
      <div>
        <div>Alternatively, copy and paste this link in your browser:</div>
        <a href="${shareUrl}">${shareUrl}</a>
      </div>
    `,
  }),

  shareWithTeam: ({
    senderName,
    fileName,
    shareUrl,
    recipientName = 'there',
  }: ShareVideoTemplateProps): EmailTemplateData => ({
    subject: `${senderName} shared "${fileName}" with you on ScreenApp`,
    content: `
      <p>Hi ${recipientName},</p>
      <p>${senderName} has shared <strong>${fileName}</strong> with you on ScreenApp.</p>
      <p>Open it in ScreenApp to watch, comment, and collaborate with your team.</p>
      <div>
        <a href="${shareUrl}" class="button">Open in ScreenApp</a>
      </div>
      <div>
        <div>Alternatively, copy and paste this link in your browser:</div>
        <a href="${shareUrl}">${shareUrl}</a>
      </div>
    `,
  }),

  teamInvite: ({
    teamName,
    inviteUrl,
  }: TeamInviteTemplateProps): EmailTemplateData => ({
    subject: `You're invited to join ${teamName} on ScreenApp`,
    content: `
      <p>Hi there,</p>
      <p>You've been invited to join <strong>${teamName}</strong> on ScreenApp – where your team collaborates on video content.</p>
      <p>Join now to start:</p>
      <ul>
        <li>Watching and sharing team videos</li>
        <li>Collaborating with comments and feedback</li>
        <li>Accessing shared video content</li>
      </ul>
      <div>
        <a href="${inviteUrl}" class="button">Join ${teamName}</a>
      </div>
      <div>
        <div>Alternatively, copy and paste this link in your browser:</div>
        <a href="${inviteUrl}">${inviteUrl}</a>
      </div>
    `,
  }),
};

export default emailTemplates;
