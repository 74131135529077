import React, { useState, useMemo } from 'react';
import {
  useElements,
  useStripe,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Box, useTheme, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import logger from '../../../../lib/logger';
import { requestStatusType } from '../../../../types';
import { RootState } from '../../../../reducers';
import ActionButton from '../ActionButton';
import { setupPayments } from '../../../../services/subscriptionServices';
import { popModal, pushModal } from '../../../globalModals/globalModalsSlice';
import config from '../../../../../config';
import { getDiscount } from './discounts';
import { setAlertMessage } from '../../../dashboard/dashboardSlice';
import { createOrUpdateSubscription } from '../../thunk';

// Action creator for tracking upgrade button click
const trackUpgradeButtonClick = (payload: {
  plan: string;
  term: 'annual' | 'monthly';
  amount: number;
  discountedAmount?: number;
}) => ({
  type: 'upgrade/trackButtonClick',
  payload,
});

interface PaymentFormProps {
  totalAmount: number;
  plan: 'GROWTH' | 'BUSINESS' | 'ENTERPRISE';
  term: 'annual' | 'monthly';
  promoCode: string;
  annualSaving: number;
}

const PaymentForm = ({
  totalAmount,
  plan,
  term,
  promoCode,
}: PaymentFormProps) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { team, settings, plan: currentPlanState } = useSelector(
    (state: RootState) => state
  );
  const teamId = team.selectedTeam?.teamId || team.teams[0].teamId;
  const alreadySubscribed =
    currentPlanState.subscription.package !== 'FREE_LOGGEDIN' &&
    !!currentPlanState.subscription.providerId;
  const { email } = settings.user;

  const [upgradeStatus, setUpgradeStatus] = useState<requestStatusType>('idle');

  const discountedAmount = useMemo(() => {
    const discount = getDiscount(promoCode);
    if (discount && totalAmount >= discount.minimumAmount) {
      return totalAmount * (1 - discount.percentage / 100);
    }
    return totalAmount;
  }, [totalAmount, promoCode]);

  const handleError = (error: any) => {
    logger.error(error);
    setUpgradeStatus('failed');
  };

  const handleUpgrade = async () => {
    if (!stripe || !elements) {
      logger.error('Something is wrong with stripejs or elements');
      return;
    }

    // Track the upgrade button click
    dispatch(
      trackUpgradeButtonClick({
        plan,
        term,
        amount: totalAmount,
        discountedAmount:
          discountedAmount !== totalAmount ? discountedAmount : undefined,
      })
    );

    setUpgradeStatus('pending');

    // XXX Avoids "IntegrationError: elements.submit() must be called before stripe.confirmPayment().
    //            Call elements.submit() as soon as your customer presses pay, prior to any asynchronous work."
    // Caveats:
    //   1. Have to enter card details even if 100% discount code applied.
    //   2. Will fail if payment information is needed again, for some reason.
    // TODO Ideally, check whether the promo code is a 100% discount, or the payment details are already collected.
    if (!alreadySubscribed) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }
    }

    try {
      // Collect and verify the payment method
      const { clientSecret } = await setupPayments({
        // userId is implicitly passed with the jwt token
        teamId,
        metadata: {
          toltReferral: window.tolt_referral,
        },
      });

      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${config.appBaseUrl}`,
        },
        redirect: 'if_required',
      });

      if (error) {
        throw error;
      }

      const result = await dispatch(
        createOrUpdateSubscription({
          teamId,
          plan,
          term,
          promoCode,
          totalAmount: discountedAmount,
          metadata: {
            toltReferral: window.tolt_referral,
          },
        })
      );
      if (createOrUpdateSubscription.rejected.match(result as never)) {
        dispatch(popModal());
        const { message = '' } = (result as any).error || {};
        throw new Error(message);
      }

      setUpgradeStatus('succeeded');
      dispatch(popModal());
      dispatch(
        pushModal({
          type: 'upgradeSuccess',
        })
      );
    } catch (error) {
      setUpgradeStatus('failed');
      dispatch(
        setAlertMessage({
          type: 'error',
          message: `Failed to upgrade. Please try again later. Error: ${error.message}`,
        })
      );
      logger.error(error);
    }
  };

  const formatAmount = (amount: number) => {
    return amount % 1 === 0 ? `$${amount.toFixed(0)}` : `$${amount.toFixed(2)}`;
  };

  return (
    <>
      <Box sx={{ marginTop: 1 }}>
        <Box>
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  email,
                },
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '8px',
              gap: '4px',
            }}
          />
        </Box>
      </Box>
      <ActionButton
        loading={upgradeStatus === 'pending'}
        variant="contained"
        sx={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
        onClick={handleUpgrade}
      >
        Upgrade for{' '}
        {discountedAmount < totalAmount ? (
          <>
            {formatAmount(discountedAmount)}{' '}
            <Typography
              component="span"
              sx={{ textDecoration: 'line-through', marginLeft: 1 }}
            >
              {formatAmount(totalAmount)}
            </Typography>
          </>
        ) : (
          formatAmount(totalAmount)
        )}
      </ActionButton>
    </>
  );
};

export default PaymentForm;
