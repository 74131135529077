import React from 'react';
import { Box } from '@mui/material';
import ActionDialog from '../globalModals/ActionDialog';
import ContactSalesForm from './ContactSalesForm';
import { GlobalDialogProps } from '../../types';

interface ContactSalesDialogProps extends GlobalDialogProps {
  formSource?: string;
}

const ContactSalesDialog: React.FC<ContactSalesDialogProps> = ({
  onClose,
  formSource = 'Contact Sales Dialog',
}) => {
  return (
    <ActionDialog
      title="Get in touch"
      open
      onClose={onClose}
      maxWidth="md"
      fullWidth
      actions={[]}
    >
      <Box>
        <ContactSalesForm formSource={formSource} />
      </Box>
    </ActionDialog>
  );
};

export default ContactSalesDialog;
