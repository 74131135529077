import React, { useState, useCallback } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { FolderPlus } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import ActionDialog from '../../globalModals/ActionDialog';
import type { GlobalDialogProps } from '../../../types';
import { createFolder } from '../librarySlice';
import type { ActionType } from '../../globalModals/ActionDialog';
import { RootState } from '../../../reducers';

const MAX_FOLDER_NAME_LENGTH = 50;
const MIN_FOLDER_NAME_LENGTH = 1;

export default function CreateFolderDialog({ onClose }: GlobalDialogProps) {
  const dispatch = useDispatch();
  const { status } = useSelector((state: RootState) => state.library.folders);
  const [folderName, setFolderName] = useState('');
  const [error, setError] = useState('');
  const isLoading = status === 'pending';

  const validateFolderName = useCallback((name: string) => {
    const trimmedName = name.trim();
    if (trimmedName.length < MIN_FOLDER_NAME_LENGTH) {
      setError('Folder name cannot be empty');
      return false;
    }
    if (trimmedName.length > MAX_FOLDER_NAME_LENGTH) {
      setError(`Maximum length is ${MAX_FOLDER_NAME_LENGTH} characters`);
      return false;
    }
    if (!/^[a-zA-Z0-9\s-_()]+$/.test(trimmedName)) {
      setError(
        'Only letters, numbers, spaces, and basic punctuation (-_()) are allowed'
      );
      return false;
    }
    setError('');
    return true;
  }, []);

  const handleFolderNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(value);
    validateFolderName(value);
  };

  const handleCreateFolder = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    const trimmedName = folderName.trim();
    if (!validateFolderName(trimmedName) || isLoading) {
      return;
    }
    dispatch(createFolder(trimmedName));
    onClose();
  };

  const actions: ActionType[] = [
    {
      key: 'cancel',
      label: 'Cancel',
      onClick: onClose,
      disabled: isLoading,
      variant: 'text',
      color: 'inherit',
      'data-testid': 'cancel-create-folder-button',
    },
    {
      key: 'create',
      label: isLoading ? 'Creating...' : 'Create Folder',
      onClick: handleCreateFolder,
      startIcon: <FolderPlus />,
      disabled: !folderName.trim() || !!error || isLoading,
      loading: isLoading,
      variant: 'contained',
      color: 'primary',
      type: 'submit',
      'data-testid': 'create-folder-submit-button',
    },
  ];

  return (
    <ActionDialog
      title="New Folder"
      open
      maxWidth="xs"
      loading={isLoading}
      actions={actions}
      onClose={onClose}
    >
      <Box
        component="form"
        onSubmit={handleCreateFolder}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pt: 1,
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Enter a name for your new folder. Use a clear and descriptive name to
          help organize your content.
        </Typography>
        <TextField
          autoFocus
          id="folder-name"
          label="Folder Name"
          type="text"
          fullWidth
          value={folderName}
          onChange={handleFolderNameChange}
          error={!!error}
          helperText={
            error || `${folderName.length}/${MAX_FOLDER_NAME_LENGTH} characters`
          }
          disabled={isLoading}
          inputProps={{
            maxLength: MAX_FOLDER_NAME_LENGTH,
            'aria-label': 'Folder name',
            'data-testid': 'folder-name-input',
          }}
        />
      </Box>
    </ActionDialog>
  );
}
