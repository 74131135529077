import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import PlanDetails from './PlanDetails';
import BillingOptions from './BillingOptions';

const Billing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedBillingOption, setSelectedBillingOption] = useState<
    'monthly' | 'annual'
  >('monthly');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? '100%' : '50%',
            padding: isSmallScreen ? '5px' : '10px',
            order: isSmallScreen ? 2 : 1,
          }}
        >
          <PlanDetails selectedBillingOption={selectedBillingOption} />
        </Box>
        <Box
          sx={{
            width: isSmallScreen ? '100%' : '50%',
            padding: isSmallScreen ? '5px' : '10px',
            order: isSmallScreen ? 1 : 2,
          }}
        >
          <BillingOptions onOptionChange={setSelectedBillingOption} />
        </Box>
      </Box>
    </Box>
  );
};

export default Billing;
