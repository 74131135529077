import getHistory from '../history';
import { ErrorPageTypes } from '../types';
import type { SettingsKeys } from '../features/settings/settingsSlice';
import { config } from '../../config';

const history = getHistory();

interface WithBasePathType {
  withBasePath?: boolean;
}

const nullStringToNull = (str: string | null) => {
  if (str === 'null' || !str) {
    return null;
  }
  return str;
};

// We use hash router. By default, we append hash to the url.
const mayBeAppendHash = (url: string, withBasePath = false): string => {
  if (withBasePath) {
    const basePath = window.location.pathname;
    return `${basePath}#${url}`;
  }
  return url;
};

export const getPluginDemoUrl = (token: string) => {
  return `${config.appBaseUrl}/plugin-test.html?token=${token}`;
};

interface GetTeamUrlType extends WithBasePathType {
  teamId: string | string[] | null;
}
export const getTeamUrl = ({ teamId, withBasePath }: GetTeamUrlType): string =>
  mayBeAppendHash(`/library/${teamId}/recents`, withBasePath);

export const goToTeamUrl = (options: GetTeamUrlType) =>
  history.push(getTeamUrl(options));

interface GetFolderUrlType extends WithBasePathType {
  teamId: string;
  folderId: string;
}
export const getFolderUrl = ({
  teamId,
  folderId,
  withBasePath,
}: GetFolderUrlType) =>
  mayBeAppendHash(`/library/${teamId}/${folderId}`, withBasePath);

export const goToFolderUrl = (options: GetFolderUrlType) => {
  history.push(getFolderUrl(options));
};

interface GetFileUrlType {
  teamId: string;
  parentId: string | null;
  fileId: string;
}
export const getFileUrl = ({
  teamId,
  parentId = 'default',
  fileId,
  withBasePath,
}: GetFileUrlType & {
  view?: 'list' | 'grid';
} & WithBasePathType) => {
  return mayBeAppendHash(
    `/library/${teamId}/${nullStringToNull(parentId) || 'default'}/${fileId}`,
    withBasePath
  );
};

export const goToFileUrl = (options: GetFileUrlType) => {
  history.push(getFileUrl({ ...options }));
};

export const goToManageSpaceUrl = () => {
  history.push('/settings/spaces');
};

export const goToSignupUrl = () => {
  history.push('/signup');
};

export const goToSinginUrl = () => {
  history.push('/signin');
};

export const goToDashboardUrl = () => {
  history.push('/dashboard');
};

export const goToRecordingUrl = () => {
  history.push('/recording');
};

export const getSignoutUrl = () => {
  return '/signout';
};

export const goToContactUsUrl = () => {
  goToContactUsUrl();
};

export const getContactSalesUrl = ({ withBasePath }: WithBasePathType = {}) =>
  mayBeAppendHash('/contact-sales', withBasePath);

export const goToContactSales = () => {
  history.push(getContactSalesUrl({}));
};

export const getFogottenPasswordUrl = ({ withBasePath }: WithBasePathType) => {
  return mayBeAppendHash('/password/forgot', withBasePath);
};
export const goToForgotPassword = (options: WithBasePathType) => {
  history.push(
    getFogottenPasswordUrl({ withBasePath: !!options.withBasePath })
  );
};

export const getSettingsUrl = ({
  settingType = 'user',
  withBasePath,
}: WithBasePathType & {
  settingType?: SettingsKeys;
}) => {
  return mayBeAppendHash(`/settings/${settingType}`, withBasePath);
};

export const goToResetPasswordUrl = (token: string) => {
  history.push(`/password/reset?token=${token}`);
};

interface GoToErrorPageType {
  errorType: ErrorPageTypes;
}
export const goToErrorPage = ({ errorType }: GoToErrorPageType) => {
  switch (errorType) {
    case 'resetPasswordTokenExpiredError':
      history.push('/resetpasswordtoken_expired');
      break;
    case 'verificationTokenExpiredError':
      history.push('/verificationtoken_expired');
      break;
    case 'requestExpiredError':
      history.push('/expired-request');
      break;
    default:
      history.push('/');
  }
};

export const getReferAndEarnUrl = () => {
  return 'https://screenapp.tolt.io/';
};

export const goToLandingPage = () => {
  history.push('/');
};

export const goToReferralUrl = () => {
  window.open(getReferAndEarnUrl(), '_blank');
};

export const goToNewDashboardUrl = () => {
  history.push('/library');
};

export const getSearchUrl = ({ withBasePath }: WithBasePathType = {}) =>
  mayBeAppendHash('/search', withBasePath);

export const goToSearchUrl = ({
  searchParams,
}: {
  searchParams: URLSearchParams;
}) => {
  history.push({
    pathname: getSearchUrl(),
    search: searchParams.toString(),
  });
};

export const getJoinUrl = (joinCode: string) => {
  return `${config.appBaseUrl}/#/dashboard?joinCode=${joinCode}`;
};

export const getShareUrl = (shareId: string) => {
  return `${config.appBaseUrl}/#/shared/${shareId}`;
};
