import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { Copy } from 'lucide-mui';
import { RootState } from '../../../../reducers';
import { shareThunk } from '../../selectedVideoSlice';
import { getShareUrl } from '../../../../lib/routingUtils';

interface EmbedModalProps {
  onClose: () => void;
}

const EmbedModal: React.FC<EmbedModalProps> = ({ onClose }) => {
  const { selectedVideo } = useSelector((state: RootState) => state);
  const {
    file,
    actions: {
      share: { status },
    },
  } = selectedVideo;
  const { _id, share: shareDetails } = file;
  const { shareId } = shareDetails || {};
  const [copied, setCopied] = useState(false);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('960');

  const dispatch = useDispatch();
  useEffect(() => {
    if (!shareId) {
      dispatch(
        shareThunk({
          fileId: _id,
          copyUrlToClipboard: false,
        })
      );
    }
  }, []);

  const generateEmbedCode = (w: string, h: string) => {
    if (!shareId) {
      return 'Generating share link...';
    }
    if (status === 'failed') {
      return 'Failed to generate share link';
    }
    return `<iframe src="${getShareUrl(
      shareId || ''
    )}?embed=true" width="${w}" height="${h}" frameborder="0" allowfullscreen></iframe>`;
  };

  const [embedCode, setEmbedCode] = useState('Generating share link...');

  useEffect(() => {
    setEmbedCode(generateEmbedCode(width, height));
  }, [shareId]);

  const handleCopy = () => {
    navigator.clipboard.writeText(embedCode).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleDimensionChange = (
    dimension: 'width' | 'height',
    value: string
  ) => {
    if (dimension === 'width') {
      setWidth(value);
    } else {
      setHeight(value);
    }
    setEmbedCode(
      generateEmbedCode(
        dimension === 'width' ? value : width,
        dimension === 'height' ? value : height
      )
    );
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '28px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 4,
          pt: 3,
          pb: 2,
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        Embed
      </DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box
          sx={{
            color: 'text.secondary',
            typography: 'body1',
          }}
        >
          Copy the embed code below to add this video to your website or blog.
        </Box>
        <TextField
          multiline
          rows={4}
          value={embedCode}
          fullWidth
          variant="outlined"
          InputProps={{
            readOnly: true,
            sx: {
              borderRadius: '28px',
              bgcolor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <Box>
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color: 'text.secondary',
            }}
          >
            Custom dimensions:
          </Typography>
          <Box display="flex" gap={2}>
            <TextField
              label="Width"
              value={width}
              onChange={(e) => handleDimensionChange('width', e.target.value)}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '24px',
                },
              }}
            />
            <TextField
              label="Height"
              value={height}
              onChange={(e) => handleDimensionChange('height', e.target.value)}
              size="small"
              type="number"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '24px',
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: 4,
          pb: 3,
          gap: 1,
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCopy}
          startIcon={<Copy />}
          color="primary"
          variant="contained"
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          {copied ? 'Copied!' : 'Copy'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmbedModal;
