import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import { theme } from '../theme/default';
import AuthenticatedRoute from './AuthenticatedRoute';
import { Library } from '../features/library';
import DefaultRoute from '../features/app/DefaultRoute';
import Signout from '../features/auth/Signout';
import Loader from '../features/app/loader/LoaderFullScreen';

import * as LazyComponents from './LazyComponents';

/**
 * TODO
 * /recover
 * recording request: /recording/for/:id
 * recording request expired: /expired-request
 * shared request: /shared/:shareId
 * emailverify
 * emailroute
 *
 */

const LoadingFallback = () => (
  <Box
    sx={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: theme.palette.background.default,
      margin: 0,
      // Add GPU acceleration
      transform: 'translateZ(0)',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      willChange: 'transform',
    }}
  >
    <Box
      sx={{
        margin: 0,
        // Add GPU acceleration for child element
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        willChange: 'transform',
      }}
    >
      <CircularProgress size={40} color="secondary" />
    </Box>
  </Box>
);

const AppRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        {/* All authenticated routes will render after fetching initial data */}
        <Route element={<AuthenticatedRoute />}>
          <Route index element={<DefaultRoute />} />
          <Route path="library">
            <Route index element={<Library />} />
            <Route path=":teamId/:folderId" element={<Library />} />
            <Route
              path=":teamId/:folderId/:fileId"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponents.SelectedVideo />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="search"
            element={
              <Suspense fallback={<LoadingFallback />}>
                <LazyComponents.SearchPage />
              </Suspense>
            }
          />
          <Route
            path="settings/:settingKey"
            element={
              <Suspense fallback={<LoadingFallback />}>
                <LazyComponents.Settings />
              </Suspense>
            }
          />
          <Route path="dashboard" element={<DefaultRoute />} />
        </Route>
        <Route
          path="/signup"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.SignUp />
            </Suspense>
          }
        />
        <Route
          path="/signin"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.SignIn />
            </Suspense>
          }
        />
        <Route path="/signout" element={<Signout />} />
        <Route
          path="/password/reset"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/password/forgot"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.ForgotPassword />
            </Suspense>
          }
        />
        <Route path="/error" element={<LazyComponents.Error />} />
        <Route path="/loader" element={<Loader />} />
        <Route
          path="/recorder"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.RecorderDemo />
            </Suspense>
          }
        />
        <Route
          path="/contact-sales"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.ContactSalesPage />
            </Suspense>
          }
        />
        {/* Public tools and shared content */}
        <Route
          path="/shared/:shareId"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.SharedVideo />
            </Suspense>
          }
        />
        <Route
          path="/shared-template/:shareId"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.SharedTemplate />
            </Suspense>
          }
        />
        <Route
          path="/tool"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.MicroTool />
            </Suspense>
          }
        />
        <Route
          path="/tool/:toolName"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.MicroTool />
            </Suspense>
          }
        />
        <Route
          path="/emailroute"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.Redirect />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <LazyComponents.Error />
            </Suspense>
          }
        />
      </Routes>
    </HashRouter>
  );
};

export default AppRoutes;
