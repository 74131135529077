import React, { useEffect, useRef } from 'react';
import { Box, Typography, Avatar, ListItem, List, Chip } from '@mui/material';
import { Check } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeam } from '../../team/teamSlice';
import { fetchFolders } from '../../library/librarySlice';
import { RootState } from '../../../reducers';
import { stringAvatar } from '../../../lib/textUtils';
import { getTeamUrl } from '../../../lib/routingUtils';
import { fetchSubscription } from '../../plan/thunk';
import { saveLastSelectedTeamSpace } from '../../../services/teamService';

interface TeamSubscription {
  provider: string;
  providerId: string;
  package: string;
  pluginPackage: string;
  unsubscribeModal: string | null;
  upgradeStatus: string;
  packageUpdatedAt: string;
  term: string;
  price: number;
  currentPeriodStartedAt?: string;
  state: 'active' | 'inactive';
}

interface TeamMember {
  _id: string;
  email: string;
  name: string;
  picture: string | null;
  status: boolean;
  role: string;
  createdAt: string;
  inviteAccepted: boolean;
  lastActiveAt: string;
  updatedAt: string;
}

interface TeamBase {
  _id: string;
  teamId: string;
  name: string;
  ownerId: string;
  email: string;
  teamSpaceMembers: TeamMember[];
  spaceMembers: any[];
  createdAt: string;
  updatedAt: string;
  __v?: number;
  joinCode?: string;
  subscription?: TeamSubscription;
}

const TeamSelectMenu = () => {
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state);
  const { teams, selectedTeam } = team;

  const isInitialRender = useRef(true);

  const handleSelect = (teamId: string) => {
    const newSelectedTeam = teams.find((t) => t.teamId === teamId);
    if (!newSelectedTeam) return;
    dispatch(selectTeam(newSelectedTeam));
    dispatch(fetchFolders({ teamId }));
    // XXX Some features need this.
    saveLastSelectedTeamSpace(teamId);
  };

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first render. Subscription is set from initial data fetch in app slice
      isInitialRender.current = false;
    } else if (selectedTeam) {
      const { teamId } = selectedTeam;
      dispatch(
        fetchSubscription({
          teamId,
        })
      );
    }
  }, [selectedTeam]);

  const getPlanBadge = (plan?: string) => {
    const isFree = !plan || plan.toLowerCase().includes('free');
    if (isFree) return null;

    const planName = plan.toLowerCase().includes('business')
      ? 'BUSINESS'
      : 'GROWTH';

    return (
      <Chip
        label={planName}
        size="small"
        sx={{
          height: 16,
          fontSize: '0.625rem',
          fontWeight: 600,
          px: 0.5,
          backgroundColor: (theme) => theme.palette.secondary.dark,
          color: (theme) => theme.palette.common.white,
          '& .MuiChip-label': {
            px: 0.5,
            py: 0,
          },
          borderRadius: 0.75,
        }}
      />
    );
  };

  return (
    <List sx={{ py: 0 }}>
      {(teams as TeamBase[]).map(({ name, teamId, subscription }) => {
        const { sx, children } = stringAvatar(name);
        const isSelected = selectedTeam?.teamId === teamId;
        return (
          <ListItem
            key={teamId}
            component="a"
            href={getTeamUrl({ teamId, withBasePath: true })}
            sx={{
              textDecoration: 'none',
              color: (theme) => theme.palette.secondary.contrastText,
              px: 2,
              py: 1,
              position: 'relative',
            }}
            onClick={() => handleSelect(teamId)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {isSelected ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Check
                    sx={{
                      color: (theme) => theme.palette.secondary.contrastText,
                      fontSize: 24,
                    }}
                  />
                </Box>
              ) : (
                <Avatar
                  sx={{
                    ...sx,
                    bgcolor: 'secondary.dark',
                  }}
                  variant="rounded"
                >
                  {children}
                </Avatar>
              )}
              <Box
                sx={{
                  ml: 1.5,
                  minWidth: 0,
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {name}
                </Typography>
                {getPlanBadge(subscription?.package)}
              </Box>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default TeamSelectMenu;
