import { useLocation, useNavigate } from 'react-router-dom';
import { SortBy, SortOrderBy } from '../types';

type View = 'list' | 'grid';
type QueryParams = Partial<{
  token: string;
  emailVerification: string;
  view: View;
  sortBy: SortBy;
  sortOrder: SortOrderBy;
  searchQuery: string;
  errorCode: string;
  errorTitle: string;
  errorDescription: string;
  redirectUrl: string;
  referrerUrl: string;
  intent: string;
  joinCode: string;
  zoomCode: string; // Added zoomCode
  importUrl: string; // If this is present, we will automatically import this video from the url
  teamId: string; // The team id for settings page overrides
  creatorId: string; // Add this field
  showTrial: string;
}>;

export const useQuery = (): Partial<QueryParams> => {
  // Get the query parameters from the URL. This can be used anywhere in the app
  const queryParams = new URLSearchParams(useLocation().search);

  // Utility function to conditionally include query params
  const includeParam = <T>(key: string, fallbackValue?: T): T | undefined => {
    const value = queryParams.get(key);
    return value !== null ? ((value as unknown) as T) : fallbackValue;
  };

  const result: Partial<QueryParams> = {};

  // Mapping of keys to their respective default or fallback values
  const queryMap: { [key in keyof QueryParams]: () => any } = {
    token: () => includeParam<string>('token'),
    emailVerification: () => includeParam<string>('emailVerification'),
    view: () => includeParam<View>('view'),
    sortBy: () => includeParam<SortBy>('sortBy'),
    sortOrder: () => includeParam<SortOrderBy>('sortOrder'),
    searchQuery: () => includeParam<string>('searchQuery'),
    errorCode: () => includeParam<string>('errorCode'),
    errorTitle: () => includeParam<string>('errorTitle'),
    errorDescription: () => includeParam<string>('errorDescription'),
    redirectUrl: () => includeParam<string>('redirectUrl'),
    referrerUrl: () => includeParam<string>('referrerUrl'),
    intent: () => includeParam<string>('intent'),
    joinCode: () => includeParam<string>('joinCode'),
    zoomCode: () => includeParam<string>('zoomCode'), // Added zoomCode handling
    importUrl: () => includeParam<string>('importUrl'),
    teamId: () => includeParam<string>('teamId'),
    creatorId: () => includeParam<string>('creatorId'), // Add this mapping
    showTrial: () => includeParam<string>('showTrial'),
  };

  // Iterate over the queryMap to populate the result object with non-null values
  Object.keys(queryMap).forEach((key) => {
    const value = queryMap[key as keyof QueryParams]?.();
    if (value !== undefined && value !== '') {
      result[key as keyof QueryParams] = value;
    }
  });

  return result;
};

export const useUpdateQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const queryParams = new URLSearchParams(search);

  const updateQuery = (params: QueryParams) => {
    // To remove a query param, set it to undefined
    Object.keys(params).forEach((key: keyof QueryParams) => {
      const value = params[key];
      if (key && value) {
        queryParams.set(key, value);
      } else {
        queryParams.delete(key);
      }
    });

    const searchParamsString = queryParams.toString();
    navigate({
      pathname,
      search: searchParamsString ? `?${searchParamsString}` : '', // Conditionally add `?`
    });
  };

  return updateQuery;
};

export const useAuthQueryParams = () => {
  // Get all the valid query parameters from the URL and return them as a string
  // which can be appended to the URL when redirecting
  const queryParams = useQuery();
  const query = new URLSearchParams(queryParams).toString();
  const queryString = query ? `?${query}` : '';

  return queryString;
};

export default useQuery;
