import { PackageLimits } from '../../userPackage';
import { PackageType } from '../../../../types';

export type FeatureKey =
  | 'librarySize'
  | 'transcription'
  | 'askAI'
  | 'download'
  | 'priorityTranscription'
  | 'AINotes'
  | 'AISummaries'
  | 'integrations'
  | 'prioritySupport'
  | 'trainingSession'
  | 'customVocabulary'
  | 'fullTranscript'
  | 'AskAITimestamps'
  | 'advancedPlayback'
  | 'upload'
  | 'bulkUpload'
  | 'translateTranscript'
  | 'generalUpgrade'
  | 'skipQueue'
  | 'copyTranscript'
  | 'folderManagement'
  | 'login'
  | 'templates'
  | 'uploadButton'
  | 'importModalUploadButton'
  | 'unlimitedCredits'
  | 'customVocab'
  | 'importUrlButton'
  | 'multipleRecordingAskAI'
  | 'timeLimit'
  | 'screenRecorder'
  | 'audioRecorder'
  | 'downloadTranscript'
  | 'fullChapterAccess'
  | 'whiteLabel'
  | 'googleMeetBot'
  | 'VideoAnalyzer'
  | 'assistant';

interface Feature {
  title: string;
  usageKey?: keyof PackageLimits;
  description: string;
  ranking: number;
  annualtitle?: string;
  annualDescription?: string;
  showContactSales?: boolean;
}

export type FeatureMap = Partial<
  {
    [key in FeatureKey]: Feature;
  }
>;

const freePlanFeatureMap: FeatureMap = {
  timeLimit: {
    title: '45 Minute Time Limit',
    description: 'Record or upload files up to 45 minutes in length.',
    ranking: 2,
  },
  transcription: {
    title: '1 Transcription per month',
    usageKey: 'transcriptionCount',
    description: 'Transcribe one recording.',
    ranking: 3,
  },
  AISummaries: {
    title: 'AI Summaries',
    description: 'Get AI-generated summaries of your recordings.',
    ranking: 4,
  },
  AINotes: {
    title: 'Detailed AI Notes',
    description: 'Get detailed AI-generated notes from your recordings.',
    ranking: 5,
  },
  screenRecorder: {
    title: 'Screen Recorder',
    description: 'Record your screen with audio.',
    ranking: 6,
  },
  audioRecorder: {
    title: 'One Click Audio Recorder',
    description: 'Record audio with a single click.',
    ranking: 7,
  },
  askAI: {
    title: '3 Ask AI Credits',
    usageKey: 'askAICount',
    description:
      'Get insights using 3 AI prompts. Credits can be used for Ask AI questions and template generation.',
    ranking: 8,
  },
};

export const growthFeatureMap: FeatureMap = {
  transcription: {
    title: '30 Transcriptions per Month',
    usageKey: 'transcriptionCount',
    description:
      'Convert your recordings into accurate, speaker-identified transcripts. Perfect for meetings, interviews, and content creation.',
    ranking: 1,
    annualtitle: '360 Transcriptions per Year',
    annualDescription:
      'Get 360 professional transcriptions to use anytime throughout the year. No monthly limits - use them when you need them most.',
  },
  askAI: {
    title: '30 AI Credits a Month',
    usageKey: 'askAICount',
    description:
      'Your personal AI assistant for content creation. Generate summaries, extract insights, and create templates from your recordings instantly.',
    ranking: 2,
    annualtitle: '360 AI Credits a Year',
    annualDescription:
      'Unlock 360 AI credits to use anytime. Create unlimited summaries, notes, and insights from your recordings throughout the year.',
  },
  librarySize: {
    title: '30 Recordings per Month',
    usageKey: 'librarySize',
    description:
      'Store and organize up to 30 recordings monthly in your secure library. Perfect for teams and content creators.',
    ranking: 3,
    annualtitle: '360 Recordings per Year',
    annualDescription:
      'Store up to 360 recordings annually. Flexible storage that grows with your needs throughout the year.',
  },
  fullTranscript: {
    title: 'Full Transcripts',
    description:
      'Get studio-quality transcripts with smart speaker detection and perfect formatting.',
    ranking: 4,
  },
  templates: {
    title: 'AI Templates',
    description:
      'Save hours with AI-powered templates. Create custom workflows for meetings, content, and more.',
    ranking: 5,
  },
  fullChapterAccess: {
    title: 'Full Chapter Access',
    description:
      'Navigate long recordings effortlessly with AI-generated chapters and key moments.',
    ranking: 6,
  },
  multipleRecordingAskAI: {
    title: 'Cross-Recording AI Insights',
    description:
      'Unlock powerful insights across multiple recordings. Perfect for research and content creation.',
    ranking: 7,
  },
  assistant: {
    title: 'AI Knowledge Assistant',
    description:
      'Your second brain - universal search across your entire content library that remembers everything and delivers instant, contextual insights.',
    ranking: 9,
  },
  prioritySupport: {
    title: 'Priority Support',
    description:
      'Get fast, personalized support from our expert success team whenever you need it.',
    ranking: 10,
  },
  trainingSession: {
    title: 'Training Session',
    description:
      'Get up and running fast with a personalized training session from our team.',
    ranking: 11,
  },
  download: {
    title: 'Downloads',
    usageKey: 'mp4DownloadCount',
    description: 'Download recordings to your computer.',
    ranking: 12,
  },
  advancedPlayback: {
    title: 'Advanced Playback',
    description: 'Control playback speed and access other advanced features.',
    ranking: 13,
  },
  translateTranscript: {
    title: 'Translate Transcripts',
    description:
      'Break language barriers - translate your transcripts into 50+ languages instantly.',
    ranking: 14,
  },
  generalUpgrade: {
    title: 'Do More with a Plan',
    usageKey: 'generalUpgrade',
    description:
      'Unlock all premium features. Advanced transcriptions, Templates, Summaries, AI notes, and Ask AI.',
    ranking: 15,
  },
  priorityTranscription: {
    title: 'Priority Transcriptions',
    usageKey: 'priority',
    description: 'Faster transcription speeds with priority processing.',
    ranking: 16,
  },
  copyTranscript: {
    title: 'Copy and Export Full Transcript',
    description: 'Copy and export full timestamped transcripts with speakers.',
    ranking: 17,
  },
  upload: {
    title: 'Upload Recordings',
    usageKey: 'uploadCount',
    description: 'Upload audio or video files to your library.',
    ranking: 18,
  },
  folderManagement: {
    title: 'Folder & Permission Management',
    description:
      'Create and organize folders, plus manage team access permissions for your recordings.',
    ranking: 8,
  },
  login: {
    title: 'Do More with a Plan',
    description:
      'Unlock all premium features. Advanced transcriptions, Templates, Summaries, AI notes, and Ask AI.',
    ranking: 20,
  },
  AskAITimestamps: {
    title: 'Ask AI Timestamps',
    description: 'Access timestamps in Ask AI.',
    ranking: 21,
  },
  uploadButton: {
    title: 'Upload Files',
    description:
      'Upload audio or video files (MP4, MP3, WebM, etc.) to your library.',
    ranking: 22,
  },
  importUrlButton: {
    title: 'Import from URL',
    description:
      'Import and transcribe from YouTube, Vimeo, Twitter and other URLs.',
    ranking: 23,
  },
  downloadTranscript: {
    title: 'Download Transcripts',
    description:
      'Export polished transcripts in any format (TXT, DOCX, PDF, RTF) ready for sharing.',
    ranking: 24,
  },
  importModalUploadButton: {
    title: 'Upload Files in Import Modal',
    description: 'Upload audio or video files through the import modal.',
    ranking: 25,
  },
  googleMeetBot: {
    title: 'Google Meet Bot',
    description:
      'Never miss a meeting - automatically record and transcribe your Google Meet calls.',
    ranking: 26,
  },
};

const businessFeatureMap: FeatureMap = {
  transcription: {
    title: 'Unlimited Transcriptions',
    usageKey: 'transcriptionCount',
    description:
      'Convert unlimited recordings into perfect transcripts. No monthly limits or restrictions.',
    ranking: 1,
  },
  askAI: {
    title: 'Unlimited AI Credits',
    usageKey: 'askAICount',
    description:
      'Your unlimited AI content partner. Generate unlimited summaries, insights, and templates from any recording.',
    ranking: 2,
  },
  librarySize: {
    title: 'Unlimited Recordings',
    usageKey: 'librarySize',
    description:
      'Store unlimited recordings in your secure library. Perfect for growing teams and enterprises.',
    ranking: 3,
  },
  fullTranscript: {
    title: 'Full Transcripts',
    description:
      'Get the highest quality transcripts with advanced speaker detection and perfect formatting.',
    ranking: 4,
  },
  templates: {
    title: 'AI Templates',
    description:
      'Create and share custom workflows across your team. Automate repetitive tasks instantly.',
    ranking: 5,
  },
  fullChapterAccess: {
    title: 'Full Chapter Access',
    description:
      'Navigate any recording with AI-powered chapters and smart bookmarking.',
    ranking: 6,
  },
  multipleRecordingAskAI: {
    title: 'Cross-Recording AI Insights',
    description:
      'Get powerful AI insights across your entire recording library. Perfect for teams and research.',
    ranking: 7,
  },
  assistant: {
    title: 'AI Knowledge Assistant',
    description:
      'Your second brain - universal search across your entire content library that remembers everything and delivers instant, contextual insights.',
    ranking: 9,
  },
  prioritySupport: {
    title: 'Priority Support',
    description: 'Dedicated support from our Customer Success Manager.',
    ranking: 10,
  },
  trainingSession: {
    title: 'Training Session',
    description: 'Personalized training session with our team.',
    ranking: 11,
  },
  download: {
    title: 'Downloads',
    usageKey: 'mp4DownloadCount',
    description: 'Download recordings to your computer.',
    ranking: 12,
  },
  advancedPlayback: {
    title: 'Advanced Playback',
    description: 'Control playback speed and access other advanced features.',
    ranking: 13,
  },
  integrations: {
    title: 'Integrations',
    description:
      'Seamlessly connect with your existing tools via Zapier, API, or Webhooks. Automate your workflow.',
    ranking: 14,
    showContactSales: true,
  },
  customVocabulary: {
    title: 'Custom Vocabulary',
    description: 'Improve transcription accuracy with custom vocabulary.',
    ranking: 15,
  },
  bulkUpload: {
    title: 'Bulk Upload',
    description: 'Upload multiple files at once.',
    ranking: 16,
  },
  translateTranscript: {
    title: 'Translate Transcripts',
    description: 'Translate your transcripts to over 50 languages.',
    ranking: 17,
  },
  generalUpgrade: {
    title: 'Do More with a Plan',
    description:
      'Unlock all premium features. Advanced transcriptions, Templates, Summaries, AI notes, and Ask AI.',
    ranking: 18,
  },
  priorityTranscription: {
    title: 'Priority Transcriptions',
    description: 'Faster transcription speeds with priority processing.',
    ranking: 19,
  },
  copyTranscript: {
    title: 'Copy and Export Full Transcript',
    description: 'Copy and export full timestamped transcripts with speakers.',
    ranking: 20,
  },
  upload: {
    title: 'Upload Recordings',
    usageKey: 'uploadCount',
    description: 'Upload audio or video files to your library.',
    ranking: 21,
  },
  folderManagement: {
    title: 'Folder & Permission Management',
    description:
      'Create and organize folders, plus manage team access permissions for your recordings.',
    ranking: 8,
  },
  login: {
    title: 'Do More with a Plan',
    description:
      'Unlock all premium features. Advanced transcriptions, summaries, AI notes, and ask AI.',
    ranking: 23,
  },
  AskAITimestamps: {
    title: 'Ask AI Timestamps',
    description: 'Access timestamps in Ask AI.',
    ranking: 24,
  },
  customVocab: {
    title: 'Custom Vocabulary',
    description: 'Add custom vocabulary to improve transcription accuracy.',
    ranking: 25,
  },
  uploadButton: {
    title: 'Upload Files',
    description:
      'Upload audio or video files (MP4, MP3, WebM, etc.) to your library.',
    ranking: 26,
  },
  unlimitedCredits: {
    title: 'Unlimited AI Credits',
    description:
      'Get unlimited AI credits for Ask AI questions and template generation.',
    ranking: 27,
  },
  importUrlButton: {
    title: 'Import from URL',
    description:
      'Import and transcribe from YouTube, Vimeo, Twitter and other URLs.',
    ranking: 28,
  },
  downloadTranscript: {
    title: 'Download Transcripts',
    description:
      'Download transcripts in various formats (TXT, DOCX, PDF, RTF)',
    ranking: 29,
  },
  importModalUploadButton: {
    title: 'Upload Files in Import Modal',
    description: 'Upload audio or video files through the import modal.',
    ranking: 30,
  },
  googleMeetBot: {
    title: 'Google Meet Bot',
    description: 'Automatically join and record your Google Meet calls',
    ranking: 31,
    showContactSales: true,
  },
  whiteLabel: {
    title: 'Custom Branding',
    description:
      'Make it yours with custom branding, logos, and team customization options.',
    ranking: 32,
    showContactSales: true,
  },
  VideoAnalyzer: {
    title: 'Video Analyzer',
    description:
      "Advanced AI that watches and understands video content visually - analyze scenes, objects, actions, and visual details beyond what's in the transcript. (Currently in beta and supports videos up to 50 minutes)",
    ranking: 33,
    showContactSales: true,
  },
};

const standardFeaturesMap = growthFeatureMap;

const premiumFeaturesMap = growthFeatureMap;

const planFeatures: Partial<
  {
    [key in PackageType]: FeatureMap;
  }
> = {
  FREE_LOGGEDIN: freePlanFeatureMap,
  STANDARD: standardFeaturesMap,
  PREMIUM: premiumFeaturesMap,
  BUSINESS: businessFeatureMap,
  GROWTH: growthFeatureMap,
};

export default planFeatures;
