import React from 'react';
import { Skeleton, Card, CardContent } from '@mui/material';
import { Box } from '@mui/system';

function FileCardSkeleton() {
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{
          paddingTop: 'calc(9 / 16 * 100%)', // 16:9 aspect ratio
          borderRadius: '8px',
        }}
      />
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          py: '16px',
          px: 0,
          '&:last-child': { pb: '16px' },
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Skeleton variant="circular" width={32} height={32} />
          <Box sx={{ ml: 2, flexGrow: 1, overflow: 'hidden' }}>
            <Skeleton
              variant="text"
              width="90%"
              sx={{
                height: '1.2em',
                mb: '4px',
              }}
            />
            <Skeleton
              variant="text"
              width="80%"
              sx={{
                height: '1.2em',
                mb: '4px',
              }}
            />
            <Skeleton
              variant="text"
              width="60%"
              sx={{
                height: '1.2em',
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FileCardSkeleton;
