import debug from 'debug';
import {
  onOffFeatureFlags,
  stringFeatureFlags,
  FeatureFlagsType,
  featureFlags as defaultFeatureFlags,
  FeatureFlagsKeyType,
  OnOffFeatureFlagsKeyType,
} from '../constants/featureFlags';
import { growthbook } from './growthBookUtils';
import {
  loadFeatureFlags as getFeatureflagsFromLocalStorage,
  setFeatureFlags as setFeatureFlagsInLocalStorage,
} from './localStorageHandler';
import { getHistory } from '../history';

const log = debug('lib:featureFlags');
const history = getHistory();
let features: FeatureFlagsType = defaultFeatureFlags;

export const fetchUrlFeatureFlags = async (
  searchParams: URLSearchParams
): Promise<FeatureFlagsType> => {
  const urlOnOffFeatureFlags = Object.keys(onOffFeatureFlags).reduce(
    (acc, flag) => {
      const featureValue = searchParams.get(flag);
      if (featureValue === 'true' || featureValue === 'false') {
        const flagValue = featureValue === 'true';
        return { ...acc, [flag]: flagValue };
      }
      return acc;
    },
    {}
  );
  const urlStringFeatureFlags = Object.keys(stringFeatureFlags).reduce(
    (acc, flag) => {
      const featureValue = searchParams.get(flag);
      if (featureValue) {
        return { ...acc, [flag]: featureValue };
      }
      return acc;
    },
    {}
  );
  const urlFeatureFlags = { ...urlOnOffFeatureFlags, ...urlStringFeatureFlags };
  log('urlFeatureFlags', urlFeatureFlags);
  return urlFeatureFlags;
};

export const fetchGrowthbookFeatureFlags = async (): Promise<FeatureFlagsType> => {
  // We need to transform the feature flags from growthbook to match the ones in our app
  await growthbook.loadFeatures();
  const growthBookFeatures = await growthbook.getFeatures();
  const growthBookFeatureFlags = Object.keys(growthBookFeatures).reduce(
    (acc, flag) => {
      if (flag in onOffFeatureFlags) {
        return { ...acc, [flag]: !!growthBookFeatures[flag].defaultValue };
      }
      return acc;
    },
    {}
  );
  return { ...growthBookFeatureFlags };
};

// This function needs to be called before the app is mounted
// Currently being called in FeatureFlagsProvider

export const loadFeatureFlags = async (): Promise<FeatureFlagsType> => {
  const { location } = history;
  const searchParams = new URLSearchParams(location.search);
  log('searchParams', searchParams);
  const urlFeatureFlags = await fetchUrlFeatureFlags(searchParams);
  const localStorageFeatureFlags = getFeatureflagsFromLocalStorage();
  const growthBookFeatures = await fetchGrowthbookFeatureFlags();
  // Merge the feature flags from local storage and the url
  // When a user sets a flag once, it stays set until they change it
  const mergedFeatureFlags = {
    ...defaultFeatureFlags,
    ...localStorageFeatureFlags,
    ...growthBookFeatures,
    ...urlFeatureFlags,
  };
  setFeatureFlagsInLocalStorage(mergedFeatureFlags);
  features = mergedFeatureFlags;
  return mergedFeatureFlags;
  // Only update state if the component is still mounted
};

export const getFeatureFlags = (): FeatureFlagsType => {
  return features;
};

export const isFeatureEnabled = (
  feature: OnOffFeatureFlagsKeyType
): boolean => {
  return !!features[feature];
};

export const getFeatureValue = <T extends boolean | string | number>(
  featureName: FeatureFlagsKeyType
): T | null => {
  const featureValue = features[featureName];
  if (featureValue === undefined) {
    return null;
  }
  return featureValue as T;
};
