import { createAsyncThunk } from '@reduxjs/toolkit';
import { pushModal } from '../globalModals/globalModalsSlice';

import { PackageType } from '../../types';
import {
  getSubscriptionDetails,
  getSubscriptionUpgradeStatus,
  GetSubscriptionResponseData,
  getUsage,
  createOrUpdateSubscription as createOrUpdateSubscriptionService,
} from '../../services/subscriptionServices';
import { setAlertMessage } from '../dashboard/dashboardSlice';
import logger from '../../lib/logger';

const TIMEOUT = 60_000;

export const fetchSubscription = createAsyncThunk(
  'plan/subscription/get',
  async ({ teamId }: { teamId: string }) => {
    const response = await getSubscriptionDetails({ teamId });
    return response;
  }
);

export const createOrUpdateSubscription = createAsyncThunk(
  'plan/subscription/createOrUpdate',
  async (args: {
    teamId: string;
    plan: PackageType;
    term: string;
    totalAmount: number;
    promoCode?: string;
    trialLabel?: string;
    metadata?: {
      toltReferral?: string;
    };
  }): Promise<
    // eslint-disable-next-line no-undef
    Awaited<ReturnType<typeof createOrUpdateSubscriptionService>>
  > => {
    const response = await createOrUpdateSubscriptionService(args);
    return response;
  }
);

/**
 * @deprecated Since we now collect the payment details before creating the
 * subscription, there is no `incomplete` subscription status, and hence, no
 * need to repeatedly poll this endpoint.
 *
 * This can be deleted if not used elsewhere.
 */
export const checkUpgradeStatusThunk = createAsyncThunk(
  'plan/upgrade/upgradeStatus/status',
  async (
    {
      teamId,
      plan: expectedPlan,
    }: {
      teamId: string;
      plan: PackageType;
    },
    { dispatch }
  ): Promise<GetSubscriptionResponseData> => {
    const started = Date.now();
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        let subscription;
        let packageProperties;
        let usage;

        let status;
        let gotPlan;

        try {
          const result = await getSubscriptionUpgradeStatus(teamId);
          subscription = result?.subscription;
          packageProperties = result?.packageProperties;
          usage = result?.usage;

          status = subscription?.upgradeStatus;
          gotPlan = subscription?.package;
        } catch (error) {
          logger.error(error);
        }

        if (
          status === 'succeeded' &&
          gotPlan === expectedPlan &&
          subscription &&
          packageProperties &&
          usage
        ) {
          clearInterval(interval);
          dispatch(
            pushModal({
              type: 'upgradeSuccess',
            })
          );
          resolve({
            subscription,
            packageProperties,
            usage,
          });
        } else if (Date.now() - started > TIMEOUT) {
          clearInterval(interval);
          dispatch(
            setAlertMessage({
              type: 'error',
              message: 'Failed to upgrade. Please try again later.',
            })
          );
          reject();
        }
      }, 5000);
    });
  }
);

export const fetchUsageThunk = createAsyncThunk(
  'plan/usage/get',
  async (teamId: string) => {
    const usage = await getUsage(teamId);
    return usage;
  }
);
