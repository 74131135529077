import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Divider,
  useTheme,
  Paper,
} from '@mui/material';
import { X as CloseIcon, ClipboardPlus as FiberNewIcon } from 'lucide-mui';
import { changelogItems } from './changelogData';

interface ChangelogProps {
  open: boolean;
  onClose: () => void;
}

export const Changelog: React.FC<ChangelogProps> = ({ open, onClose }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          background: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <FiberNewIcon
              sx={{
                width: 20,
                height: 20,
                color: theme.palette.primary.main,
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              What&apos;s New
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ pt: 2 }}>
        {changelogItems.map((item) => (
          <Paper
            key={item.version}
            elevation={0}
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              '&:last-child': { mb: 0 },
            }}
          >
            <Box display="flex" alignItems="baseline" gap={1} mb={1}>
              <Typography
                variant="h6"
                color="primary"
                fontWeight="bold"
                sx={{ letterSpacing: 0.5 }}
              >
                v{item.version}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontStyle: 'italic' }}
              >
                {item.date}
              </Typography>
            </Box>
            <List dense sx={{ pt: 0 }}>
              {item.changes.map((change, index) => (
                <ListItem
                  key={index}
                  sx={{
                    pl: 1,
                    py: 0.5,
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                      borderRadius: 1,
                    },
                  }}
                >
                  <ListItemText
                    primary={change}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: '0.95rem',
                        lineHeight: 1.5,
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default Changelog;
