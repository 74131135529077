import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Tooltip,
  useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FileType } from '../../../types';
import defaultThumbnail from '../../../assets/recording/DefaultThumbnail.svg';
import CitationTooltip from './CitationTooltip';
import VideoPlayer from './VideoPlayer';

interface AnswerDisplayProps {
  aiAnswer: string | null;
  isGeneratingAnswer: boolean;
  lastSearchQuery: string | null;
  results: {
    data: FileType[];
    error: string | null;
  };
  featuredThumbnail: {
    fileId: string;
    fileName: string;
    timestamp: string;
    thumbnailUrl: string | null;
    isLoading: boolean;
  } | null;
  activeSource: {
    fileId?: string;
    timestamp?: string;
  } | null;
  handleSourceClick: (fileId: string, timestamp: string) => void;
  citationThumbnails: Array<{
    fileId: string;
    timestamp: string;
    thumbnailUrl: string | null;
    isLoading: boolean;
  }>;
  fetchCitationThumbnail: (fileId: string, timestamp: string) => void;
  formatTimestamp: (seconds: string) => string;
  hideReferences: boolean;
  onClearHistory?: () => void;
  showVideoPlayer?: boolean;
  videoPlayerFile?: FileType | null;
}

/**
 * AnswerDisplay component renders the AI-generated answer with citations, thumbnails, and formatting
 */
const AnswerDisplay: React.FC<AnswerDisplayProps> = ({
  aiAnswer,
  isGeneratingAnswer,
  lastSearchQuery,
  results,
  featuredThumbnail,
  activeSource,
  handleSourceClick,
  citationThumbnails,
  fetchCitationThumbnail,
  formatTimestamp,
  hideReferences,
  onClearHistory,
  showVideoPlayer = false,
  videoPlayerFile = null,
}) => {
  const theme = useTheme();

  // If still generating answer, show a loading indicator
  if (isGeneratingAnswer && !aiAnswer) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          my: 1,
          borderRadius: '16px',
          bgcolor:
            theme.palette.mode === 'dark'
              ? 'rgba(60, 60, 60, 0.4)'
              : 'transparent',
        }}
      >
        <CircularProgress size={28} />
      </Box>
    );
  }

  // If there's no answer to display, don't render anything
  if (!aiAnswer) {
    return null;
  }

  return (
    <Box sx={{ mb: 2, p: 0 }}>
      <Paper
        elevation={0}
        sx={{
          p: 1.5,
          borderRadius: '16px',
          bgcolor: 'transparent',
          border: 'none',
        }}
      >
        {/* Show the last query when viewing stored results */}
        {lastSearchQuery && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontStyle: 'italic',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              {lastSearchQuery}
            </Typography>
            {onClearHistory && (
              <Tooltip title="Clear search history">
                <Typography
                  variant="caption"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClearHistory();
                  }}
                  sx={{
                    fontSize: '0.7rem',
                    color: 'text.secondary',
                    cursor: 'pointer',
                    opacity: 0.6,
                    '&:hover': {
                      opacity: 1,
                      color: 'primary.main',
                    },
                  }}
                >
                  clear
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}

        {/* Create a container for the responsive layout */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 2, md: 2.5 },
            width: '100%',
          }}
        >
          {/* Featured thumbnail OR Video Player */}
          {featuredThumbnail && (
            <Box
              sx={{
                width: { xs: '100%', md: '40%' },
                maxWidth: { md: '240px' },
                maxHeight: { xs: '180px', md: '135px' },
                aspectRatio: '16/9',
                height: 'auto',
                borderRadius: '12px',
                overflow: 'hidden',
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(42, 43, 44, 0.7)'
                    : 'action.selected',
                position: 'relative',
                cursor: 'pointer',
                flexShrink: 0,
                alignSelf: 'flex-start',
              }}
            >
              {showVideoPlayer && videoPlayerFile && activeSource ? (
                <VideoPlayer
                  file={videoPlayerFile}
                  timestamp={activeSource.timestamp || '0'}
                  onClick={() => {
                    if (activeSource) {
                      handleSourceClick(
                        activeSource.fileId || '',
                        activeSource.timestamp || '0'
                      );
                    }
                  }}
                />
              ) : (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                    onClick={() =>
                      handleSourceClick(
                        featuredThumbnail.fileId,
                        featuredThumbnail.timestamp
                      )
                    }
                  >
                    {/* Loading state */}
                    {featuredThumbnail.isLoading && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? 'rgba(42, 43, 44, 0.6)'
                              : 'rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <CircularProgress size={36} />
                      </Box>
                    )}

                    {/* Thumbnail image */}
                    {featuredThumbnail.thumbnailUrl && (
                      <>
                        <img
                          src={featuredThumbnail.thumbnailUrl}
                          alt={`Screenshot from ${featuredThumbnail.fileName}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            filter:
                              theme.palette.mode === 'dark' &&
                              (!featuredThumbnail.thumbnailUrl ||
                                featuredThumbnail.thumbnailUrl ===
                                  defaultThumbnail)
                                ? 'invert(1)'
                                : 'none',
                          }}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            if (target.src !== defaultThumbnail) {
                              target.src = defaultThumbnail;
                            }
                          }}
                        />

                        {/* Caption with file name */}
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: '8px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            px: 1.5,
                            py: 0.5,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            borderRadius: '9999px',
                            maxWidth: '90%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Typography
                            variant="caption"
                            noWrap
                            sx={{
                              fontSize: '0.75rem',
                              lineHeight: '1.2',
                              display: 'block',
                              textAlign: 'center',
                            }}
                          >
                            {featuredThumbnail.fileName}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}

          {/* Markdown content container */}
          <Box sx={{ flexGrow: 1 }}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                p: ({ children }) => (
                  <Typography variant="body2" component="p" sx={{ mb: 1 }}>
                    {children}
                  </Typography>
                ),
                ul: ({ children }) => (
                  <Box component="ul" sx={{ pl: 2, mb: 1 }}>
                    {children}
                  </Box>
                ),
                ol: ({ children }) => (
                  <Box component="ol" sx={{ pl: 2, mb: 1 }}>
                    {children}
                  </Box>
                ),
                li: ({ children }) => (
                  <Typography variant="body2" component="li" sx={{ mb: 0.5 }}>
                    {children}
                  </Typography>
                ),
                a: ({ href, children }: any) => {
                  // Handle citation links in the format [→](fileId@seconds)
                  if (href?.includes('@')) {
                    if (hideReferences) return null;
                    const [fileId, timestamp] = href.split('@');
                    const sourceKey = `${fileId}@${timestamp}`;
                    const isActive =
                      activeSource?.fileId === fileId &&
                      activeSource?.timestamp === timestamp;
                    const file = results.data.find((f) => f._id === fileId);

                    // Set citation number if not already set
                    React.useEffect(() => {
                      // Fetch thumbnail for this citation if needed
                      fetchCitationThumbnail(fileId, timestamp);
                    }, [sourceKey]);

                    // Find thumbnail data for this citation
                    const thumbnailData = citationThumbnails.find(
                      (t) => t.fileId === fileId && t.timestamp === timestamp
                    );

                    return (
                      <CitationTooltip
                        fileId={fileId}
                        timestamp={timestamp}
                        file={file}
                        isActive={isActive}
                        thumbnailUrl={thumbnailData?.thumbnailUrl}
                        isLoading={thumbnailData?.isLoading}
                        onSourceClick={handleSourceClick}
                        formatTimestamp={formatTimestamp}
                      />
                    );
                  }
                  return (
                    <Box
                      component="a"
                      href={href}
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'underline',
                        '&:hover': {
                          textDecoration: 'none',
                        },
                      }}
                    >
                      {children}
                    </Box>
                  );
                },
                code: (props: any) => {
                  const { inline, children } = props;
                  return inline ? (
                    <Typography
                      component="code"
                      variant="body2"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        px: 0.5,
                        borderRadius: 0.5,
                        fontFamily: 'monospace',
                      }}
                    >
                      {children}
                    </Typography>
                  ) : (
                    <Box
                      component="pre"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        p: 1,
                        borderRadius: 1,
                        overflow: 'auto',
                        fontFamily: 'monospace',
                        fontSize: '0.875rem',
                      }}
                    >
                      {children}
                    </Box>
                  );
                },
              }}
            >
              {aiAnswer}
            </ReactMarkdown>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AnswerDisplay;
