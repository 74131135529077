import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { FileType } from '../../types';
import { useQuery, useUpdateQuery } from '../../hooks/urlHooks';
import useTags from '../../hooks/useTags';
import {
  fetchFileSystem,
  getThumbnailByTimestamp,
} from '../../services/fileSystemService';
import defaultThumbnail from '../../assets/recording/DefaultThumbnail.svg';
import { SEARCH_QUERY_PROMPT, ANSWER_GENERATION_PROMPT } from './prompts';
import { config } from '../../../config';

// Constants
const LAST_ANSWER_KEY = 'assistant_last_answer';
const LAST_RESULTS_KEY = 'assistant_last_results';
const GEMINI_MODEL = 'gemini-2.0-flash-lite';

/**
 * Call the Gemini API with the provided prompt.
 * This function is copied from microtools to make the assistant independent.
 */
const callGeminiAPI = async (prompt: string): Promise<string> => {
  try {
    const response = await fetch(config.geminiProxyUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: GEMINI_MODEL,
        contents: [
          {
            parts: [{ text: prompt }],
          },
        ],
      }),
    });

    if (!response.ok) {
      throw new Error(`Gemini API error: ${response.statusText}`);
    }

    const data = await response.json();
    const result = data.candidates[0].content.parts[0].text;

    return result;
  } catch (error) {
    console.error('Error calling Gemini API:', error);
    throw error;
  }
};

// Type for URL parameters
type URLParamsType = {
  teamId?: string;
  folderId?: string;
};

// Interface for transcript data
export interface FileTranscript {
  fileId: string;
  fileName: string;
  text: string;
  createdAt?: string | Date;
}

// Interface for citation thumbnails
export interface CitationThumbnail {
  fileId: string;
  timestamp: string;
  thumbnailUrl: string | null;
  isLoading: boolean;
}

// Interface for file thumbnails in the file list
export interface FileThumbnail {
  fileId: string;
  thumbnailUrl: string | null;
  isLoading: boolean;
}

// Interface for featured thumbnail
export interface FeaturedThumbnail {
  fileId: string;
  fileName: string;
  timestamp: string;
  thumbnailUrl: string | null;
  isLoading: boolean;
}

/**
 * Hook for managing search input state and handlers
 */
export const useSearchInput = () => {
  const { searchQuery: initialQuery } = useQuery();
  const updateQuery = useUpdateQuery();
  const [query, setQuery] = useState(initialQuery || '');
  const inputRef = useRef<HTMLInputElement>(null);

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  // Handle key press events
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    onSearch: () => void,
    onClosePopover: () => void
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (query.trim()) {
        onSearch();
      }
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      onClosePopover();

      // Focus the input after closing popover with Escape
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 50);
    }
  };

  // Clear the search
  const handleClear = (e: React.MouseEvent, onClosePopover: () => void) => {
    // Prevent event propagation
    e.stopPropagation();

    setQuery('');
    onClosePopover();

    // Clear URL query parameter
    updateQuery({ searchQuery: undefined });

    // Focus the input after clearing
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  // Load initial query if present
  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  return {
    query,
    setQuery,
    inputRef,
    handleInputChange,
    handleKeyDown,
    handleClear,
    updateQuery,
  };
};

/**
 * Hook for managing popover UI state
 */
export const useSearchPopover = (
  aiAnswer: string | null,
  results: { data: FileType[]; error: string | null }
) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchBarWidth, setSearchBarWidth] = useState<number | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isFileListExpanded, setIsFileListExpanded] = useState(false);
  const [isSearchIconMode, setIsSearchIconMode] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle input focus
  const handleInputFocus = (query: string) => {
    // Always open popover on small viewports to ensure back button is shown
    if (
      screenWidth < 600 || // Small viewport - always show back button when focused
      aiAnswer ||
      (results.data && results.data.length > 0) ||
      query.trim()
    ) {
      setIsPopoverOpen(true);
    }

    // If in icon mode, expand the search bar
    if (isSearchIconMode) {
      setIsSearchExpanded(true);
    }
  };

  // Toggle file list expansion
  const toggleFileList = () => {
    setIsFileListExpanded(!isFileListExpanded);
  };

  // Add an effect to measure the search bar width whenever it might change
  useEffect(() => {
    const updateSearchBarWidth = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setSearchBarWidth(width);

        // Check if width is less than 130px to set icon mode
        setIsSearchIconMode(width < 130);

        // Auto-collapse search when in icon mode and losing focus
        if (width < 130 && !isPopoverOpen) {
          setIsSearchExpanded(false);
        }
      }
    };

    // Initial measurement
    updateSearchBarWidth();

    // Set up resize observer to detect container size changes
    const resizeObserver = new ResizeObserver(updateSearchBarWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Also update on window resize
    window.addEventListener('resize', updateSearchBarWidth);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', updateSearchBarWidth);
    };
  }, [isPopoverOpen]);

  // Add click outside handler to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        isPopoverOpen
      ) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopoverOpen]);

  // Add keyboard shortcut handler
  useEffect(() => {
    const handleShortcutKeyDown = (e: KeyboardEvent) => {
      // Check for Cmd+K (Mac) or Ctrl+K (Windows/Linux)
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault(); // Prevent default browser behavior
        if (containerRef.current) {
          const inputElement = containerRef.current.querySelector('input');
          if (inputElement) {
            // Toggle the popover state when the shortcut is pressed
            if (isPopoverOpen) {
              setIsPopoverOpen(false);
              // Blur the input when closing
              inputElement.blur();
            } else {
              inputElement.focus();

              // Select all text if there's any text in the input
              if (inputElement.value.length > 0) {
                inputElement.select();
              }

              setIsPopoverOpen(true);
            }
          }
        }
      }
    };

    document.addEventListener('keydown', handleShortcutKeyDown);
    return () => document.removeEventListener('keydown', handleShortcutKeyDown);
  }, [isPopoverOpen]);

  // Get keyboard shortcut text
  const getShortcutText = () => {
    if (screenWidth < 600) return '';
    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    return isMac ? '⌘K' : 'Ctrl+K';
  };

  return {
    isPopoverOpen,
    setIsPopoverOpen,
    containerRef,
    searchBarWidth,
    isFileListExpanded,
    isSearchIconMode,
    isSearchExpanded,
    setIsSearchExpanded,
    handleInputFocus,
    toggleFileList,
    getShortcutText,
    screenWidth,
  };
};

/**
 * Hook for managing search results and status
 */
export const useAssistantSearch = () => {
  const { teamId, folderId } = useParams<URLParamsType>();
  const selectedVideo = useSelector(
    (state: RootState) => state.selectedVideo?.file
  );
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'succeeded' | 'failed'
  >('idle');
  const [results, setResults] = useState<{
    data: FileType[];
    error: string | null;
  }>({
    data: [],
    error: null,
  });
  const [isProcessingQuery, setIsProcessingQuery] = useState(false);
  const [lastSearchQuery, setLastSearchQuery] = useState<string | null>(null);
  const { getTag, setTag } = useTags();

  // Process the query with Gemini API to get optimized search keywords
  const processQueryWithGemini = async (
    originalQuery: string
  ): Promise<string> => {
    setIsProcessingQuery(true);
    try {
      const prompt = SEARCH_QUERY_PROMPT.replace('{query}', originalQuery);

      const result = await callGeminiAPI(prompt);
      return result.trim();
    } catch (error) {
      // Log the error with the Gemini API
      console.error('Gemini API error:', {
        error,
        originalQuery,
        timestamp: new Date().toISOString(),
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });

      // Fall back to original query if there's an error
      return originalQuery;
    } finally {
      setIsProcessingQuery(false);
    }
  };

  // Save query to history
  const saveQueryToHistory = useCallback((queryText: string) => {
    if (!queryText.trim()) return;
    setLastSearchQuery(queryText);
  }, []);

  // Load last search results from tags on component mount
  useEffect(() => {
    // Load last search results if available
    const storedResults = getTag(LAST_RESULTS_KEY);
    if (storedResults) {
      try {
        const parsedResults = JSON.parse(storedResults);
        if (parsedResults.data && Array.isArray(parsedResults.data)) {
          setResults(parsedResults);
          setStatus('succeeded');
        }
      } catch (error) {
        console.error('Error parsing last search results:', error);
      }
    }
  }, [getTag]);

  // Save last search result to tags
  const saveLastSearchResult = useCallback(
    (searchQuery: string, answer: string | null, searchResults: FileType[]) => {
      // Save the search results
      if (searchResults.length > 0) {
        setTag({
          key: LAST_RESULTS_KEY,
          value: JSON.stringify({
            data: searchResults,
            error: null,
          }),
        });
      }
    },
    [setTag]
  );

  // Perform the actual search
  const performSearch = async (query: string): Promise<FileType[]> => {
    try {
      // Process the query with Gemini to get better search terms
      const processedQuery = await processQueryWithGemini(query.trim());

      // Prepare parameters similar to the library component
      const searchParams = {
        teamId,
        folderId,
        searchQuery: processedQuery,
        sortBy: 'createdAt' as const,
        sortOrder: 'desc' as const,
        page: 1,
        limit: 10,
      };

      // Call the file system API with the search query
      const response = await fetchFileSystem(searchParams);

      // Log the search response
      console.log('Assistant search response:', {
        data: response?.data?.data?.fileSystem,
        timestamp: new Date().toISOString(),
        fileCount: response?.data?.data?.fileSystem?.length || 0,
      });

      // Extract the files from the response
      const files = response?.data?.data?.fileSystem || [];

      // Check if we're in a video view context by parsing the URL
      // Example URL structure: /library/[teamId]/[folderId]/[fileId]
      const currentPath = window.location.hash;
      const matches = currentPath.match(
        /#\/library\/([^/]+)\/([^/]+)\/([^/?]+)/
      );

      // If we're in a video view, add the current video to the top of results if not already included
      if (matches && matches.length === 4) {
        const currentFileId = matches[3];

        // Check if the current file is already included in the results
        const hasCurrentFileInResults = files.some(
          (file) => file._id === currentFileId
        );

        if (
          !hasCurrentFileInResults &&
          selectedVideo &&
          selectedVideo._id === currentFileId
        ) {
          console.log(
            'Adding currently selected video to top of results:',
            currentFileId
          );

          // Add the current video to the beginning of the array to prioritize it
          files.unshift(selectedVideo);
        }
      }

      return files;
    } catch (error) {
      console.error('Assistant search error:', {
        error,
        query: query.trim(),
        timestamp: new Date().toISOString(),
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
      });
      throw error;
    }
  };

  return {
    status,
    setStatus,
    results,
    setResults,
    isProcessingQuery,
    lastSearchQuery,
    setLastSearchQuery,
    processQueryWithGemini,
    saveQueryToHistory,
    saveLastSearchResult,
    performSearch,
  };
};

/**
 * Hook for managing transcripts and answer generation
 */
export const useAssistantAnswer = (deps?: {
  extractFeaturedThumbnail?: (answer: string, files: FileType[]) => void;
}) => {
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [aiAnswer, setAiAnswer] = useState<string | null>(null);
  const [lastSearchQuery, setLastSearchQuery] = useState<string | null>(null);
  const { getTag, setTag } = useTags();

  // Get user state from Redux
  const userSettings = useSelector((state: RootState) => state.settings.user);
  const teamState = useSelector((state: RootState) => state.team.selectedTeam);
  const recorderSettings = useSelector(
    (state: RootState) => state.settings.recorder
  );

  // Load last answer from tags on component mount
  useEffect(() => {
    // Load last answer if available
    const storedAnswer = getTag(LAST_ANSWER_KEY);
    const storedResults = getTag(LAST_RESULTS_KEY);

    if (storedAnswer && storedResults && deps?.extractFeaturedThumbnail) {
      try {
        const { answer, query } = JSON.parse(storedAnswer);
        const { data: files } = JSON.parse(storedResults);
        if (answer) {
          setAiAnswer(answer);
          // Set the last search query from storage
          if (query) {
            setLastSearchQuery(query);
          }
          // Extract featured thumbnail from the stored answer
          deps.extractFeaturedThumbnail(answer, files);
        }
      } catch (error) {
        console.error('Error parsing last answer:', error);
      }
    }
  }, [getTag, deps?.extractFeaturedThumbnail]);

  // Save answer to tags
  const saveAnswer = useCallback(
    (searchQuery: string, answer: string | null) => {
      // Save the last answer and query
      if (answer) {
        setTag({
          key: LAST_ANSWER_KEY,
          value: JSON.stringify({
            query: searchQuery,
            answer,
          }),
        });
        // Update the last search query state
        setLastSearchQuery(searchQuery);
      }
    },
    [setTag]
  );

  // Fetch transcripts from search results
  const fetchTranscripts = async (
    files: FileType[]
  ): Promise<FileTranscript[]> => {
    const transcripts: FileTranscript[] = [];

    // Get up to 5 files that have transcript URLs
    const filesWithTranscripts = files
      .filter((file) => file.textData?.transcriptUrl)
      .slice(0, 5);

    if (filesWithTranscripts.length === 0) {
      return transcripts;
    }

    try {
      await Promise.all(
        filesWithTranscripts.map(async (file) => {
          if (!file.textData?.transcriptUrl) return;

          try {
            const response = await fetch(file.textData.transcriptUrl);
            if (!response.ok) {
              console.error(
                `Failed to fetch transcript for file ${file._id}: ${response.statusText}`
              );
              return;
            }

            const text = await response.text();

            // Parse the transcript JSON
            try {
              const parsedTranscript = JSON.parse(text);
              console.log(
                `Raw transcript for file ${file._id}:`,
                parsedTranscript
              );

              // Store the full, raw transcript for better context
              transcripts.push({
                fileId: file._id,
                fileName: file.name,
                text, // Use property shorthand
                createdAt: file.createdAt, // Pass the file's creation date
              });
            } catch (parseError) {
              console.error(
                `Failed to parse transcript for file ${file._id}:`,
                parseError
              );
            }
          } catch (fetchError) {
            console.error(
              `Failed to fetch transcript for file ${file._id}:`,
              fetchError
            );
          }
        })
      );

      return transcripts;
    } catch (error) {
      console.error('Error fetching transcripts:', error);
      return transcripts;
    }
  };

  // Format transcripts in XML structure like MultiAskAI
  const formatTranscriptsAsXML = (transcripts: FileTranscript[]): string => {
    let transcriptText = '';

    transcripts.forEach((transcript, index) => {
      try {
        const parsedTranscript = JSON.parse(transcript.text);
        const segments = parsedTranscript.segments || [];
        const speakers = parsedTranscript.speakers || {};

        // First use transcript.createdAt (passed from file metadata),
        // then fallback to parsed transcript metadata
        const fileCreatedAt =
          transcript.createdAt ||
          parsedTranscript.createdAt ||
          parsedTranscript.created_at;

        // Use the file's creation date, fallback to 'Unknown Date'
        const date = fileCreatedAt
          ? new Date(fileCreatedAt).toISOString().split('T')[0]
          : 'Unknown Date';

        // Add speaker map to the transcript
        const speakerMap = `<speakermap>${JSON.stringify(
          speakers
        )}</speakermap>`;

        // Convert segments to XML format with speaker and timestamp info
        const textWithSegments = segments
          .map((segment: any) => {
            const startTime = Math.floor(segment.start);
            const speaker = segment.speaker || 'UNKNOWN';
            const text = segment.text.trim();

            return `<segment time="${startTime}" speaker="${speaker}">${text}</segment>`;
          })
          .join('\n');

        transcriptText += `<transcript 
          number="${index + 1}" 
          id="${transcript.fileId}"
          filename="${transcript.fileName}" 
          date="${date}"
        >${speakerMap}${textWithSegments}</transcript>\n`;
      } catch (error) {
        console.error(
          `Error formatting transcript ${transcript.fileId}:`,
          error
        );
      }
    });

    return transcriptText;
  };

  // Generate an answer from transcripts using Gemini API
  const generateAnswerFromTranscripts = async (
    originalQuery: string,
    transcripts: FileTranscript[],
    selectedVideo: any // Using any to match original code
  ): Promise<string> => {
    try {
      setIsGeneratingAnswer(true);

      // Check if we're in a video view context by parsing the URL
      // Example URL structure: /library/[teamId]/[folderId]/[fileId]
      const currentPath = window.location.hash;
      const matches = currentPath.match(
        /#\/library\/([^/]+)\/([^/]+)\/([^/?]+)/
      );

      // If we're in a video view, add the current video transcript if not already included
      if (matches && matches.length === 4) {
        const currentFileId = matches[3];

        // Check if the current file's transcript is already included
        const hasCurrentFileTranscript = transcripts.some(
          (t) => t.fileId === currentFileId
        );

        if (
          !hasCurrentFileTranscript &&
          selectedVideo &&
          selectedVideo._id === currentFileId
        ) {
          console.log(
            'Including currently selected video transcript:',
            currentFileId
          );
          try {
            // Use the selected video from Redux store instead of fetching
            if (selectedVideo.textData?.transcriptUrl) {
              const transcriptResponse = await fetch(
                selectedVideo.textData.transcriptUrl
              );
              if (transcriptResponse.ok) {
                const text = await transcriptResponse.text();

                // Add the current video transcript to the beginning of the array to prioritize it
                transcripts.unshift({
                  fileId: currentFileId,
                  fileName: selectedVideo.name,
                  text,
                  createdAt: selectedVideo.createdAt, // Pass the file's creation date
                });

                console.log(
                  'Successfully added current video transcript to context'
                );
              }
            }
          } catch (error) {
            console.error('Error fetching current video transcript:', error);
          }
        }
      }

      if (transcripts.length === 0) {
        return `I couldn't find any relevant information to answer your question about "${originalQuery}".

**Tips to improve your search:**
- Try using more specific keywords related to your topic
- Use names of people or projects if you're looking for specific discussions
- Try different terminology or synonyms
- If searching for a concept, use simpler terms
- Check if the content you're looking for exists in your library

You can also try recording more content about this topic to build your knowledge base.`;
      }

      // Format transcripts in XML format like MultiAskAI
      const transcriptXML = formatTranscriptsAsXML(transcripts);
      console.log(
        'Formatted transcripts as XML:',
        `${transcriptXML.substring(0, 500)}...`
      );

      // Create the user context by replacing variables in the prompt
      let prompt = ANSWER_GENERATION_PROMPT.replace(
        '{query}',
        originalQuery
      ).replace('{transcripts}', transcriptXML);

      // Replace user context variables with actual values
      prompt = prompt
        .replace('{user.name}', userSettings?.name || 'User')
        .replace('{user.role}', userSettings?.role || 'User')
        .replace('{team.name}', teamState?.name || 'Personal')
        .replace(
          '{team.fileOptions.customVocabulary}',
          teamState?.fileOptions?.customVocabulary || 'None'
        )
        .replace(
          '{recorder.otherSettings.defaultTranscriptionLanguage}',
          recorderSettings?.otherSettings?.defaultTranscriptionLanguage ||
            'Default'
        );

      // Log the FULL Gemini API request including transcripts
      console.log('FULL Gemini prompt with transcripts:', prompt);

      // Log the Gemini API request details
      console.log('Gemini answer generation request:', {
        promptLength: prompt.length,
        transcriptCount: transcripts.length,
        fileNames: transcripts.map((t) => t.fileName),
        timestamp: new Date().toISOString(),
      });

      const result = await callGeminiAPI(prompt);

      // Log the Gemini API response
      console.log('Gemini answer generation response:', {
        result: `${result.substring(0, 200)}...`,
        timestamp: new Date().toISOString(),
      });

      return result.trim();
    } catch (error) {
      console.error('Error generating answer from transcripts:', error);
      return "Sorry, I couldn't generate an answer to your question.";
    } finally {
      setIsGeneratingAnswer(false);
    }
  };

  return {
    aiAnswer,
    setAiAnswer,
    isGeneratingAnswer,
    setIsGeneratingAnswer,
    lastSearchQuery,
    setLastSearchQuery,
    fetchTranscripts,
    generateAnswerFromTranscripts,
    saveAnswer,
  };
};

/**
 * Hook for managing citations and thumbnails
 */
export const useCitationsAndThumbnails = () => {
  const [activeSource, setActiveSource] = useState<{
    fileId?: string;
    timestamp?: string;
  } | null>(null);
  const [citationCount, setCitationCount] = useState<{ [key: string]: number }>(
    {}
  );
  const [citationThumbnails, setCitationThumbnails] = useState<
    CitationThumbnail[]
  >([]);
  const [fileThumbnails, setFileThumbnails] = useState<FileThumbnail[]>([]);
  const [
    featuredThumbnail,
    setFeaturedThumbnail,
  ] = useState<FeaturedThumbnail | null>(null);
  const [hideReferences] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoPlayerFile, setVideoPlayerFile] = useState<FileType | null>(null);

  // Format timestamp from seconds to MM:SS or HH:MM:SS format
  const formatTimestamp = (seconds: string): string => {
    const totalSeconds = parseInt(seconds, 10);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours
        .toString()
        .padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return `${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Handle source click
  const handleSourceClick = useCallback(
    (fileId: string, timestamp: string, files: FileType[]) => {
      if (!fileId || !timestamp) return;

      const file = files.find((f) => f._id === fileId);
      if (!file) {
        console.error('File not found:', fileId);
        return;
      }

      // Toggle between showing the thumbnail and playing the video inline
      if (
        activeSource?.fileId === fileId &&
        activeSource.timestamp === timestamp &&
        showVideoPlayer
      ) {
        // Second click on the same source - open in new window
        const baseUrl = '/app/#';
        const { origin } = window.location;

        if (file?.teamId) {
          window.open(
            `${origin}${baseUrl}/library/${file.teamId}/recents/${fileId}?t=${timestamp}`,
            '_blank'
          );
        } else {
          window.open(
            `${origin}${baseUrl}/file/${fileId}?t=${timestamp}`,
            '_blank'
          );
        }
      } else {
        // First click - show the video player
        setActiveSource({ fileId, timestamp });
        setShowVideoPlayer(true);
        setVideoPlayerFile(file);
      }
    },
    [activeSource, showVideoPlayer]
  );

  // Close video player
  const closeVideoPlayer = useCallback(() => {
    setShowVideoPlayer(false);
    setVideoPlayerFile(null);
  }, []);

  // Fetch thumbnail for a citation
  const fetchCitationThumbnail = useCallback(
    async (fileId: string, timestamp: string) => {
      // Check if we already have this thumbnail
      const existingThumbnail = citationThumbnails.find(
        (t) => t.fileId === fileId && t.timestamp === timestamp
      );

      if (existingThumbnail) {
        return;
      }

      // Add a loading placeholder
      setCitationThumbnails((prev) => [
        ...prev,
        {
          fileId,
          timestamp,
          thumbnailUrl: null,
          isLoading: true,
        },
      ]);

      try {
        // Convert timestamp from seconds string to number
        const timeInSeconds = parseInt(timestamp, 10);

        // Fetch the thumbnail
        const url = await getThumbnailByTimestamp({
          fileId,
          timestamp: timeInSeconds,
        });

        // Update the thumbnail in state
        setCitationThumbnails((prev) =>
          prev.map((t) =>
            t.fileId === fileId && t.timestamp === timestamp
              ? { ...t, thumbnailUrl: url, isLoading: false }
              : t
          )
        );
      } catch (error) {
        console.error('Error fetching citation thumbnail:', error);

        // Mark as not loading and use default thumbnail
        setCitationThumbnails((prev) =>
          prev.map((t) =>
            t.fileId === fileId && t.timestamp === timestamp
              ? { ...t, thumbnailUrl: defaultThumbnail, isLoading: false }
              : t
          )
        );
      }
    },
    [citationThumbnails]
  );

  // Fetch thumbnail for a file in the results list
  const fetchFileThumbnail = useCallback(
    async (fileId: string) => {
      // Check if we already have this thumbnail
      const existingThumbnail = fileThumbnails.find((t) => t.fileId === fileId);

      if (existingThumbnail) {
        return;
      }

      // Add a loading placeholder
      setFileThumbnails((prev) => [
        ...prev,
        {
          fileId,
          thumbnailUrl: null,
          isLoading: true,
        },
      ]);

      try {
        // Use 0 timestamp to get the first frame
        const url = await getThumbnailByTimestamp({
          fileId,
          timestamp: 0,
        });

        // Update the thumbnail in state
        setFileThumbnails((prev) =>
          prev.map((t) =>
            t.fileId === fileId
              ? { ...t, thumbnailUrl: url, isLoading: false }
              : t
          )
        );
      } catch (error) {
        console.error('Error fetching file thumbnail:', error);

        // Mark as not loading and provide a default thumbnail instead of just null
        setFileThumbnails((prev) =>
          prev.map((t) =>
            t.fileId === fileId
              ? { ...t, thumbnailUrl: defaultThumbnail, isLoading: false }
              : t
          )
        );
      }
    },
    [fileThumbnails]
  );

  // Reset citation thumbnails when starting a new search
  const resetCitationThumbnails = useCallback(() => {
    setCitationThumbnails([]);
  }, []);

  // Reset file thumbnails
  const resetFileThumbnails = useCallback(() => {
    setFileThumbnails([]);
  }, []);

  // Reset citation count and thumbnails
  const resetCitationCount = useCallback(() => {
    setCitationCount({});
    resetCitationThumbnails();
    resetFileThumbnails();
    setFeaturedThumbnail(null);
  }, [resetCitationThumbnails, resetFileThumbnails]);

  // Extract the first citation from the answer and set it as featured thumbnail
  const extractFeaturedThumbnail = useCallback(
    (answer: string, files: FileType[]) => {
      // Look for the first citation in the format [→](fileId@seconds)
      const citationRegex = /\[→\]\(([^@]+)@(\d+)\)/;
      const match = answer.match(citationRegex);

      if (match && match.length >= 3) {
        const fileId = match[1];
        const timestamp = match[2];

        // Find file details
        const file = files.find((f) => f._id === fileId);
        if (!file) return;

        // Set as loading
        setFeaturedThumbnail({
          fileId,
          fileName: file.name,
          timestamp,
          thumbnailUrl: null,
          isLoading: true,
        });

        // Fetch the actual thumbnail
        getThumbnailByTimestamp({
          fileId,
          timestamp: parseInt(timestamp, 10),
        })
          .then((url) => {
            setFeaturedThumbnail((prev) =>
              prev && prev.fileId === fileId && prev.timestamp === timestamp
                ? { ...prev, thumbnailUrl: url, isLoading: false }
                : prev
            );
          })
          .catch((error) => {
            console.error('Error fetching featured thumbnail:', error);
            setFeaturedThumbnail((prev) =>
              prev && prev.fileId === fileId && prev.timestamp === timestamp
                ? { ...prev, thumbnailUrl: defaultThumbnail, isLoading: false }
                : prev
            );
          });
      }
    },
    []
  );

  return {
    activeSource,
    setActiveSource,
    citationCount,
    setCitationCount,
    citationThumbnails,
    setCitationThumbnails,
    fileThumbnails,
    setFileThumbnails,
    featuredThumbnail,
    setFeaturedThumbnail,
    hideReferences,
    formatTimestamp,
    handleSourceClick,
    fetchCitationThumbnail,
    fetchFileThumbnail,
    resetCitationCount,
    resetCitationThumbnails,
    resetFileThumbnails,
    extractFeaturedThumbnail,
    showVideoPlayer,
    setShowVideoPlayer,
    videoPlayerFile,
    closeVideoPlayer,
  };
};
