import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Trash2 } from 'lucide-mui';
import { RootState } from '../../../../reducers';
import { folderActions } from '../../librarySlice';
import ActionDialog from '../../../globalModals/ActionDialog';
import type { ActionType } from '../../../globalModals/ActionDialog';
import type { FolderType } from '../../../../types';
import { RouteParams } from '../../types';
import { getTeamUrl } from '../../../../lib/routingUtils';

interface DeleteFolderModalProps {
  onClose: () => void;
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { teamId, folderId: routeFolerId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { folder, status } = useSelector((state: RootState) => ({
    folder: state.globalModals.actionData.files?.[0] as FolderType,
    status: state.library.folders.status,
  }));
  const { _id: folderId, name } = folder;
  const isLoading = status === 'pending';

  const handleConfirm = () => {
    dispatch(folderActions({ folderId, action: 'delete' }));
    onClose();
    if (routeFolerId === folderId && teamId && folderId) {
      navigate(getTeamUrl({ teamId }));
    }
  };

  const dialogActions: ActionType[] = [
    {
      key: 'cancel',
      label: 'Cancel',
      onClick: onClose,
      disabled: isLoading,
      variant: 'text',
      color: 'inherit',
      'data-testid': 'cancel-delete-folder-button',
    },
    {
      key: 'delete',
      label: isLoading ? 'Deleting...' : 'Delete Folder',
      onClick: handleConfirm,
      disabled: isLoading,
      loading: isLoading,
      startIcon: <Trash2 sx={{ color: 'inherit !important' }} />,
      variant: 'contained',
      color: 'error',
      'data-testid': 'confirm-delete-folder-button',
    },
  ];

  return (
    <ActionDialog
      open
      title={`Delete "${name}"`}
      onClose={onClose}
      actions={dialogActions}
      loading={isLoading}
      maxWidth="xs"
    >
      <Box sx={{ pt: 1 }}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          This action cannot be undone. All contents within this folder will be
          permanently deleted.
        </Alert>
        <Typography variant="body2" color="text.secondary">
          If you proceed, you will lose access to all files and subfolders
          within this folder. Make sure you have backed up any important content
          before continuing.
        </Typography>
      </Box>
    </ActionDialog>
  );
};

export default DeleteFolderModal;
