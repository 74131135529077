import React from 'react';
import { useSelector } from 'react-redux';
import FileListItemSkeleton from './FileListItemSkeleton';
import { RootState } from '../../../../../reducers';

const FilesSkeleton: React.FC = () => {
  const { library } = useSelector((state: RootState) => state);
  const { status } = library.files;
  if (status !== 'pending') {
    return null;
  }

  return (
    <>
      {[...Array(20)].map((_, index) => (
        <FileListItemSkeleton key={index} data-testid="file-list-skeleton" />
      ))}
    </>
  );
};

export default FilesSkeleton;
