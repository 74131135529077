import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
  Button,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Rocket } from 'lucide-mui';

import { RootState } from '../../../reducers';
import { usePlan } from '../../plan/hooks/plan';
import { pushModal } from '../../globalModals/globalModalsSlice';

const iconStyle = { width: 16, height: 16 };

const UpsellButton = ({ selected }: { selected?: 'annual' | 'unlimited' }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { term } = useSelector((state: RootState) => state.plan.subscription);
  const { isPlan } = usePlan();

  const showGoAnnual =
    selected !== 'unlimited' &&
    (selected === 'annual' ||
      (isPlan(['GROWTH', 'BUSINESS', 'ENTERPRISE']) && term === 'monthly'));

  const showGoUnlimited =
    selected === 'unlimited' || (!showGoAnnual && isPlan(['GROWTH']));

  const handleClick = () => {
    dispatch(pushModal({ type: 'upsell-modal' }));
  };

  if (!showGoAnnual && !showGoUnlimited) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {showGoAnnual && (
        <Tooltip
          title="Save money with our annual plan"
          arrow
          placement="bottom"
        >
          {isSmallScreen ? (
            <IconButton
              color={selected === 'annual' ? 'primary' : 'default'}
              onClick={handleClick}
              sx={{
                width: '40px',
                height: '40px',
              }}
            >
              {selected === 'annual' ? (
                <Rocket sx={iconStyle} />
              ) : (
                <Rocket sx={iconStyle} />
              )}
            </IconButton>
          ) : (
            <Button
              variant={selected === 'annual' ? 'contained' : 'outlined'}
              color={selected === 'annual' ? 'primary' : 'secondary'}
              onClick={handleClick}
              startIcon={
                selected === 'annual' ? (
                  <Rocket sx={iconStyle} />
                ) : (
                  <Rocket sx={iconStyle} />
                )
              }
              sx={{
                height: '40px',
              }}
            >
              Save&nbsp;Money
            </Button>
          )}
        </Tooltip>
      )}
      {showGoUnlimited && (
        <Tooltip title="Unlock unlimited recording" arrow placement="bottom">
          {isSmallScreen ? (
            <IconButton
              color={selected === 'unlimited' ? 'primary' : 'default'}
              onClick={handleClick}
              sx={{
                width: '40px',
                height: '40px',
              }}
            >
              {selected === 'unlimited' ? (
                <Rocket sx={iconStyle} />
              ) : (
                <Rocket sx={iconStyle} />
              )}
            </IconButton>
          ) : (
            <Button
              variant={selected === 'unlimited' ? 'contained' : 'outlined'}
              color={selected === 'unlimited' ? 'primary' : 'secondary'}
              onClick={handleClick}
              startIcon={
                selected === 'unlimited' ? (
                  <Rocket sx={iconStyle} />
                ) : (
                  <Rocket sx={iconStyle} />
                )
              }
              sx={{
                height: '40px',
              }}
            >
              Go&nbsp;Unlimited
            </Button>
          )}
        </Tooltip>
      )}
    </Box>
  );
};

export default UpsellButton;
