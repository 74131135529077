import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ArrowUp, ArrowDown } from 'lucide-mui';
import { SortBy } from '../../../../types';
import { useTags } from '../../../../hooks/useTags';
import {
  LIBRARY_SORT_BY_KEY,
  LIBRARY_SORT_ORDER_KEY,
} from '../../../../constants/tags';

const FileListHeader = () => {
  const theme = useTheme();
  const { folderId } = useParams<{ folderId: string }>();
  const isInRecentsView = folderId === 'recents';
  const { getTag, setTag } = useTags();
  const sortBy = (getTag(LIBRARY_SORT_BY_KEY) as SortBy) || 'createdAt';
  const sortOrder =
    (getTag(LIBRARY_SORT_ORDER_KEY) as 'asc' | 'desc') || 'desc';

  const handleSort = (column: SortBy) => {
    if (sortBy === column) {
      setTag({
        key: LIBRARY_SORT_ORDER_KEY,
        value: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setTag({ key: LIBRARY_SORT_BY_KEY, value: column });
      setTag({
        key: LIBRARY_SORT_ORDER_KEY,
        value: column === 'name' ? 'asc' : 'desc',
      });
    }
  };

  // Component for sortable headers
  const SortableHeader = ({
    column,
    label,
    sx = {},
  }: {
    column: SortBy;
    label: string;
    sx?: any;
  }) => (
    <Box
      onClick={() => handleSort(column)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
          opacity: 0.8,
        },
        ...sx,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: 'text.secondary',
          opacity: 0.7,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        {label}
        {sortBy === column &&
          (sortOrder === 'asc' ? (
            <ArrowUp sx={{ fontSize: 'inherit' }} />
          ) : (
            <ArrowDown sx={{ fontSize: 'inherit' }} />
          ))}
      </Typography>
    </Box>
  );

  // Component for non-sortable headers
  const HeaderText = ({ label, sx = {} }: { label: string; sx?: any }) => (
    <Typography
      variant="subtitle2"
      sx={{
        color: 'text.secondary',
        opacity: 0.7,
        ...sx,
      }}
    >
      {label}
    </Typography>
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'minmax(200px, 1fr) 65px 70px',
          sm: isInRecentsView
            ? 'minmax(200px, 1.5fr) 80px 100px 80px 24px'
            : 'minmax(200px, 1.5fr) 80px 80px 24px',
          md: isInRecentsView
            ? 'minmax(200px, 1.5fr) 80px 100px 120px 80px 24px'
            : 'minmax(200px, 1.5fr) 80px 120px 80px 24px',
          lg: isInRecentsView
            ? 'minmax(250px, 1.5fr) 100px 120px 120px 80px 24px'
            : 'minmax(250px, 1.5fr) 100px 150px 80px 24px',
        },
        alignItems: 'center',
        gap: { xs: 1, sm: 2 },
        px: 2,
        py: 1.5,
        borderBottom: 1,
        borderColor: theme.palette.divider,
        bgcolor: theme.palette.background.paper,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
        <Box sx={{ width: { xs: 24, sm: 32 }, mr: 2, flexShrink: 0 }} />
        <SortableHeader column="name" label="Name" />
      </Box>

      <SortableHeader column="duration" label="Duration" />

      {isInRecentsView && (
        <HeaderText
          label="Folder"
          sx={{
            display: { xs: 'none', sm: 'block' },
            pl: 0,
          }}
        />
      )}

      <HeaderText
        label="Creator"
        sx={{
          display: { xs: 'none', md: 'block' },
          pl: 0,
        }}
      />

      <SortableHeader
        column="createdAt"
        label="Date"
        sx={{
          display: 'block',
          pl: 0,
        }}
      />

      <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
    </Box>
  );
};

export default FileListHeader;
