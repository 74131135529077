import debug from 'debug';

const log = debug('app:videoUtils');

const downloadVideo = (url: string, name: string) => {
  try {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = name;
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    log(error);
  }
};

export { downloadVideo };
export default downloadVideo;
