import { UpdateMessageMap } from './types';
import store from '../../store';
import {
  setSearchAnswer,
  setSearchStatus,
} from '../../features/search/searchSlice';

export const handleRagUpdate = (
  payload: UpdateMessageMap['rag']['payload']
) => {
  const { dispatch } = store;

  switch (payload.ragUpdateType) {
    case 'answer':
      console.log('setting answer payload', payload.text);
      dispatch(setSearchAnswer(decodeURIComponent(payload.text)));
      break;
    case 'status':
      dispatch(setSearchStatus(payload.status));
      break;
    default:
      console.warn('Unknown RAG update type:', payload);
  }
};

export default handleRagUpdate;
