import React, { useState, useCallback } from 'react';
import {
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FileEdit } from 'lucide-mui';
import { RootState } from '../../../../reducers';
import { folderActions } from '../../librarySlice';
import type { FolderType } from '../../../../types';

interface RenameFolderModalProps {
  onClose: () => void;
}

const MAX_FOLDER_NAME_LENGTH = 50;

const RenameFolderModal: React.FC<RenameFolderModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { folder, status } = useSelector((state: RootState) => ({
    folder: state.globalModals.actionData.files?.[0] as FolderType,
    status: state.library.folders.status,
  }));
  const { _id: folderId, name: currentName } = folder || {};
  const [newName, setNewName] = useState(currentName);
  const [error, setError] = useState('');
  const isLoading = status === 'pending';

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setNewName(value);

      if (!value.trim()) {
        setError('Folder name cannot be empty');
      } else if (value.trim().length > MAX_FOLDER_NAME_LENGTH) {
        setError(`Maximum length is ${MAX_FOLDER_NAME_LENGTH} characters`);
      } else if (!/^[a-zA-Z0-9\s-_()]+$/.test(value.trim())) {
        setError(
          'Only letters, numbers, spaces, and basic punctuation (-_()) are allowed'
        );
      } else {
        setError('');
      }
    },
    []
  );

  const handleConfirm = () => {
    if (error || !newName.trim() || isLoading) return;
    dispatch(
      folderActions({
        folderId,
        action: 'rename',
        data: { newName: newName.trim() },
      })
    );
    onClose();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !error && newName.trim()) {
      handleConfirm();
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '28px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          px: 4,
          pt: 3,
          pb: 2,
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        {`Rename "${currentName}"`}
      </DialogTitle>
      <DialogContent
        sx={{
          px: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="body1" color="text.secondary">
          Enter a new name for your folder. Use a clear and descriptive name to
          help organize your content.
        </Typography>
        <TextField
          id="new-folder-name"
          label="New Folder Name"
          value={newName}
          onChange={handleNameChange}
          onKeyPress={handleKeyPress}
          error={Boolean(error)}
          helperText={
            error || `${newName.length}/${MAX_FOLDER_NAME_LENGTH} characters`
          }
          disabled={isLoading}
          autoFocus
          fullWidth
          inputProps={{
            maxLength: MAX_FOLDER_NAME_LENGTH,
            'aria-label': 'New folder name',
            'data-testid': 'rename-folder-input',
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          px: 4,
          pb: 3,
          gap: 1,
        }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          disabled={isLoading}
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={Boolean(error) || !newName.trim() || isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : <FileEdit />}
          sx={{
            borderRadius: '24px',
            textTransform: 'none',
            px: 3,
            py: 1,
            minWidth: '100px',
          }}
        >
          {isLoading ? 'Renaming...' : 'Rename'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameFolderModal;
