import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useUpdateQuery } from '../../../hooks/urlHooks';
import { RootState } from '../../../reducers';
import { pushModal, popModal } from '../../globalModals/globalModalsSlice';

const LOCAL_STORAGE_KEY = 'trialModalShown';

const TrialDialogManager: React.FC = () => {
  const dispatch = useDispatch();
  const { showTrial } = useQuery();
  const updateQuery = useUpdateQuery();
  const { subscription } = useSelector((state: RootState) => state.plan);
  const modalStack = useSelector(
    (state: RootState) => state.globalModals.modalStack
  );
  const [hasShownTrialModal, setHasShownTrialModal] = useState(false);

  // Check if the free trial modal is already open
  const isFreeTrialModalOpen = modalStack.includes('free-trial-modal');
  // Check if any other modal is currently open
  const isAnyModalOpen = modalStack.length > 0;
  // Check if the upgrade modal is open (to prevent it from showing)
  const isUpgradeModalOpen = modalStack.includes(
    'stripe-pricing-table-upgrade-modal'
  );

  // Initialize the state from localStorage on first render
  useEffect(() => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedValue === 'true') {
      setHasShownTrialModal(true);
    }
  }, []);

  // Handle closing the trial modal and updating URL
  const handleTrialModalClosed = () => {
    if (!isFreeTrialModalOpen && hasShownTrialModal === false) {
      // Update localStorage to remember we've shown the modal
      setHasShownTrialModal(true);
      localStorage.setItem(LOCAL_STORAGE_KEY, 'true');

      // Remove the showtrial parameter from the URL
      updateQuery({ showTrial: undefined });
    }
  };

  // When the free trial modal closes, update our state and localStorage
  useEffect(() => {
    // This runs when the free trial modal was previously open but is now closed
    if (
      !isFreeTrialModalOpen &&
      modalStack.length === 0 &&
      !hasShownTrialModal
    ) {
      handleTrialModalClosed();
    }
  }, [isFreeTrialModalOpen, modalStack.length, hasShownTrialModal]);

  // Prevent upgrade modal from appearing when showtrial is present
  useEffect(() => {
    if (showTrial === 'true' && isUpgradeModalOpen) {
      // If upgrade modal appears while showtrial is active, close it
      dispatch(popModal());
    }
  }, [showTrial, isUpgradeModalOpen, dispatch]);

  // Main effect to show the trial modal
  useEffect(() => {
    // Only show the trial dialog if:
    // 1. The URL has the showTrial parameter set to true
    // 2. The user is on a free plan
    // 3. No other modals are currently open
    // 4. The free trial modal is not already in the stack
    // 5. We haven't already shown the trial modal in this session or previously
    if (
      showTrial === 'true' &&
      subscription.package === 'FREE_LOGGEDIN' &&
      !isAnyModalOpen &&
      !isFreeTrialModalOpen &&
      !hasShownTrialModal
    ) {
      dispatch(pushModal({ type: 'free-trial-modal' }));
    }
  }, [
    showTrial,
    subscription.package,
    isAnyModalOpen,
    isFreeTrialModalOpen,
    hasShownTrialModal,
    dispatch,
  ]);

  // No need to render anything as the modal is managed by GlobalModalManager
  return null;
};

export default TrialDialogManager;
