/* eslint no-param-reassign: "error" */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TeamSpace } from '../../types/state';
import { requestStatusType } from '../../types';
import {
  getJoinCode,
  removeTeamMember,
  scheduleTeamDeletion,
  unscheduleTeamDeletion,
  updateTeamSettings,
  addWebhookUrl,
  removeWebhookUrl,
  addTeamTag,
} from '../../services/teamService';
import { setAlertMessage } from '../dashboard/dashboardSlice';

type TeamProperties = Omit<TeamSpace, 'subscription'>; // Omit subscription. Maintain it in subscription slice instead
interface TeamState {
  status: requestStatusType;
  selectedTeam: TeamProperties | undefined;
  teams: TeamProperties[];
}

export interface AcceptTeamspaceInvitation {
  senderId: string;
  receiverId: string;
  teamId: string;
}

const initialState: TeamState = {
  status: 'idle',
  selectedTeam: undefined,
  teams: [],
};

export const getJoinUrlThunk = createAsyncThunk(
  'team/member/join/code',
  async (teamId: string) => {
    const joinCode = await getJoinCode(teamId);
    return joinCode;
  }
);

export const removeTeamMemberThunk = createAsyncThunk(
  'team/member/delete',
  async (options: Parameters<typeof removeTeamMember>[0]) => {
    const response = await removeTeamMember(options);
    return response.data;
  }
);

export const updateTeamSettingsThunk = createAsyncThunk(
  'team/settings/update',
  async (options: Parameters<typeof updateTeamSettings>[0]) => {
    const response = await updateTeamSettings(options);
    return response.data;
  }
);

export const scheduleTeamDeletionThunk = createAsyncThunk(
  'team/delete',
  async (teamId: string, { rejectWithValue }) => {
    try {
      const response = await scheduleTeamDeletion(teamId);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addTeamTagThunk = createAsyncThunk(
  'team/tag/add',
  async (
    { teamId, tag }: { teamId: string; tag: { key: string; value: string } },
    { rejectWithValue }
  ) => {
    try {
      await addTeamTag({ teamId, tag });
      return { teamId, tag };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const unscheduleTeamDeletionThunk = createAsyncThunk(
  'team/undelete',
  async (teamId: string, { rejectWithValue }) => {
    try {
      const response = await unscheduleTeamDeletion(teamId);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addWebhookUrlThunk = createAsyncThunk(
  'team/addWebhookUrl',
  async (
    {
      teamId,
      name,
      url,
      templateId,
    }: { teamId: string; name: string; url: string; templateId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await addWebhookUrl({ teamId, name, url, templateId });
      return { teamId, name, url, templateId };
    } catch (error) {
      dispatch(
        setAlertMessage({
          message: 'Failed to add webhook',
          type: 'error',
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const removeWebhookUrlThunk = createAsyncThunk(
  'team/removeWebhookUrl',
  async (
    { teamId, url }: { teamId: string; url: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await removeWebhookUrl({ teamId, url });
      return { teamId, url };
    } catch (error) {
      dispatch(
        setAlertMessage({
          message: 'Failed to remove webhook',
          type: 'error',
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeamState: (state, action: PayloadAction<TeamState>) => {
      const { status, selectedTeam, teams } = action.payload;
      state.status = status;
      state.selectedTeam =
        selectedTeam || teams.find((team) => !team.deleteRequestedAt);
      state.teams = teams;
    },
    selectTeam: (state, action: PayloadAction<TeamProperties>) => {
      state.selectedTeam = action.payload;
    },
    setTeams: (state, action: PayloadAction<TeamProperties[]>) => {
      state.teams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJoinUrlThunk.fulfilled, (state, action) => {
      const teamId = action.meta.arg;
      const { selectedTeam } = state;
      if (selectedTeam && selectedTeam.teamId === teamId) {
        selectedTeam.joinCode = action.payload;
      }
    });
    builder.addCase(removeTeamMemberThunk.pending, (state, action) => {
      const { selectedTeam } = state;
      if (selectedTeam) {
        selectedTeam.teamSpaceMembers = selectedTeam.teamSpaceMembers.filter(
          (member) => member._id !== action.meta.arg.userId
        );
      }
    });
    builder.addCase(scheduleTeamDeletionThunk.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(scheduleTeamDeletionThunk.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.meta.arg) {
          return action.payload;
        }
        return team;
      });
      state.selectedTeam = state.teams.find((team) => !team.deleteRequestedAt);
    });
    builder.addCase(scheduleTeamDeletionThunk.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(unscheduleTeamDeletionThunk.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(unscheduleTeamDeletionThunk.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.teams = state.teams.map((team) => {
        if (team.teamId === action.meta.arg) {
          return action.payload;
        }
        return team;
      });
      state.selectedTeam = action.payload;
    });
    builder.addCase(unscheduleTeamDeletionThunk.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(addWebhookUrlThunk.fulfilled, (state, action) => {
      // Assume the this is applied to selected team
      const { name, url } = action.meta.arg;
      const team = state.selectedTeam;
      if (team) {
        team.integrations.webhooks.push({ name, url });
      }
    });
    builder.addCase(removeWebhookUrlThunk.fulfilled, (state, action) => {
      const { url } = action.meta.arg;
      const team = state.selectedTeam;
      if (team) {
        team.integrations.webhooks = team.integrations.webhooks.filter(
          (webhook) => webhook.url !== url
        );
      }
    });
  },
});

export const { setTeamState, selectTeam, setTeams } = teamSlice.actions;
export default teamSlice.reducer;
