/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getPlugin,
  initPlugin,
  setUpPlugin,
} from '../../services/pluginService';

export const PLUGIN_RECORDING_STATUS = {
  INIT: 'INIT',
  NOT_READY: 'NOT_READY',
} as const;

const STORE_INITIATE_VALUE = {
  PLUGIN: {
    website: '',
    _id: '',
    pluginMode: false,
    errorText: '',
    folderId: '',
    status: 'idle',
  },
  PLUGIN_RECORDING: {
    status: 'INIT',
    name: '',
    email: '',
    title: '',
  },
};

const initialState = {
  plugin: { ...STORE_INITIATE_VALUE.PLUGIN },
  pluginRecording: { ...STORE_INITIATE_VALUE.PLUGIN_RECORDING },
};

export interface IAuthenticatePluginData {
  key?: string;
}

export interface IAuthenticatePluginSuccessPayload {
  folderId: string;
  teamId: string;
}

export const getPluginData = createAsyncThunk(
  'plugin/get',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getPlugin();
      return { data };
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const setUpPluginData = createAsyncThunk(
  'plugin/set',
  async (website: string, { rejectWithValue }) => {
    try {
      const { data } = await setUpPlugin({ website });
      return { data };
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const initializePlugin = createAsyncThunk(
  'plugin/init',
  async (key: string, { rejectWithValue }) => {
    try {
      const { data } = await initPlugin(key);
      return { data };
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

const pluginSlice = createSlice({
  name: 'plugin',
  initialState: initialState.plugin,
  reducers: {
    authenticatePluginSuccess(
      state,
      action: PayloadAction<IAuthenticatePluginSuccessPayload>
    ) {
      state.pluginMode = true;
      state.folderId = action.payload?.folderId || '';
    },
    authenticatePluginFail(state, action) {
      state.pluginMode = false;
      state.errorText = action.payload;
    },
    getPluginDetailsFail(state) {
      state._id = '';
      state.website = '';
      state.pluginMode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPluginData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPluginData.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state._id = action.payload.data.data._id;
      state.website = action.payload.data.data.website;
    });
    builder.addCase(getPluginData.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(setUpPluginData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setUpPluginData.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state._id = action.payload.data.data._id; // Update _id
      state.website = action.payload.data.data.website; // Update website
    });
    builder.addCase(setUpPluginData.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

const pluginRecordingSlice = createSlice({
  name: 'pluginRecording',
  initialState: initialState.pluginRecording,
  reducers: {
    initPluginRecording(
      state,
      action: {
        payload: {
          title: string;
          status: typeof PLUGIN_RECORDING_STATUS[keyof typeof PLUGIN_RECORDING_STATUS];
        };
      }
    ) {
      Object.assign(state, action.payload);
    },
  },
});

// Export actions for use elsewhere
export const {
  authenticatePluginSuccess,
  authenticatePluginFail,
  getPluginDetailsFail,
  // Add other exported actions here...
} = pluginSlice.actions;

export const { initPluginRecording } = pluginRecordingSlice.actions;

export const pluginReducer = pluginSlice.reducer;
export const pluginRecordingReducer = pluginRecordingSlice.reducer;
