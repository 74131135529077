import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  InputAdornment,
  Box,
  Typography,
  Snackbar,
  Alert,
  Button,
  useTheme,
  Tabs,
  Tab,
  Stack,
  Chip,
  CircularProgress,
} from '@mui/material';
import {
  Copy,
  Check,
  Mail,
  Link as LinkIcon,
  X,
  Send,
  ShieldCheck,
  Rocket,
} from 'lucide-mui';

import ActionDialog from '../../globalModals/ActionDialog';
import type { GlobalDialogProps } from '../../../types';
import { RootState } from '../../../reducers';
import { getJoinUrlThunk } from '../teamSlice';
import { getJoinUrl } from '../../../lib/routingUtils';
import { usePlan } from '../../plan/hooks/plan';
import { sendEmail } from '../../../services/emailService';
import emailTemplates from '../../email/templates';
import ContactSalesForm from '../../sales/ContactSalesForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`invite-tabpanel-${index}`}
      aria-labelledby={`invite-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

export const InviteLinkDialog = ({ onClose }: GlobalDialogProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state);
  const { teamId, joinCode, name: teamName } = team?.selectedTeam || {};
  const [inviteLink, setInviteLink] = useState('');
  const { isPlan } = usePlan();
  const isPaidUser = isPlan(['GROWTH', 'BUSINESS', 'ENTERPRISE']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hasAcknowledged, setHasAcknowledged] = useState(false);
  const [copied, setCopied] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [emailInput, setEmailInput] = useState('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const [emailError, setEmailError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [salesDialogOpen, setSalesDialogOpen] = useState(false);

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '28px',
      bgcolor: 'background.paper',
      height: '48px',
      border: '1px solid',
      borderColor: 'divider',
      '& fieldset': {
        border: 'none',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '-6px',
        height: '100%',
      },
      '& .MuiButton-root': {
        borderRadius: '24px',
        height: '40px',
        marginRight: '-2px',
        px: 2.5,
      },
    },
    '& .MuiOutlinedInput-input': {
      height: '48px',
      boxSizing: 'border-box',
      padding: '0 16px',
    },
  };

  useEffect(() => {
    if (!joinCode && teamId) {
      dispatch(getJoinUrlThunk(teamId));
    }
  }, [dispatch, joinCode, teamId]);

  useEffect(() => {
    if (joinCode) {
      setInviteLink(getJoinUrl(joinCode));
    }
  }, [joinCode]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setSnackbarMessage('Invitation link copied to clipboard!');
    setSnackbarOpen(true);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      setSnackbarOpen(false);
    }, 2000);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const validateEmail = (input: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(input.toLowerCase());
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');

    // Split by common delimiters (comma, semicolon, newline, space) and clean up
    const emails = pastedText
      .split(/[,;\n\s]+/)
      .map((email) => email.trim())
      .filter((email) => email.length > 0);

    // Filter valid emails and remove duplicates
    const validEmails = emails
      .filter((email) => validateEmail(email))
      .filter((email) => !emailList.includes(email));

    if (validEmails.length > 0) {
      setEmailList([...emailList, ...validEmails]);
      setEmailInput('');
    }

    // Show error if some emails were invalid
    if (validEmails.length < emails.length) {
      setEmailError('Some email addresses were invalid and were not added');
    }
  };

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!emailInput) {
      setEmailError('Please enter an email address');
      return;
    }
    if (!validateEmail(emailInput)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    if (emailList.includes(emailInput)) {
      setEmailError('This email has already been added');
      return;
    }
    setEmailList([...emailList, emailInput]);
    setEmailInput('');
    setEmailError('');
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setEmailList(
      emailList.filter((currentEmail) => currentEmail !== emailToRemove)
    );
  };

  const handleSendInvites = async () => {
    try {
      if (!joinCode) {
        setSnackbarMessage(
          'Unable to generate invitation link. Please try again.'
        );
        setSnackbarOpen(true);
        return;
      }

      setIsSending(true);
      const inviteUrl = getJoinUrl(joinCode);

      // Send emails in parallel
      await Promise.all(
        emailList.map((email) =>
          sendEmail({
            template: 'genericEmail',
            toEmail: email,
            data: emailTemplates.teamInvite({
              teamName: teamName || 'a team',
              inviteUrl,
            }),
          })
        )
      );

      setSnackbarMessage(
        `Invitations sent to ${emailList.length} email${
          emailList.length === 1 ? '' : 's'
        }!`
      );
      setSnackbarOpen(true);
      setEmailList([]);
    } catch (error) {
      console.error('Failed to send invitations:', error);
      setSnackbarMessage('Failed to send invitations. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setIsSending(false);
    }
  };

  const renderBillingNotice = () => (
    <Box sx={{ mb: 1.5 }}>
      <Box
        sx={{
          m: 0,
          p: 2,
          width: '100%',
          bgcolor: `${theme.palette.primary.main}08`,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="body2" sx={{ flex: 1 }}>
          Adding team members will adjust your billing based on team size
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setHasAcknowledged(true)}
          disabled={hasAcknowledged}
          sx={{
            minWidth: 'auto',
            height: '40px',
            px: 2,
            whiteSpace: 'nowrap',
            textTransform: 'none',
            borderRadius: '24px',
          }}
          startIcon={
            hasAcknowledged ? (
              <Check style={{ width: 16, height: 16 }} />
            ) : (
              <ShieldCheck style={{ width: 16, height: 16 }} />
            )
          }
        >
          {hasAcknowledged ? 'Got it!' : 'I understand'}
        </Button>
      </Box>
    </Box>
  );

  return (
    <ActionDialog
      title="Invite Team Members"
      open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      actions={[]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 1,
          px: { xs: 2, sm: 3 },
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 0.5 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="invite methods"
            variant="fullWidth"
            sx={{
              minHeight: 42,
              '& .MuiTabs-indicator': {
                height: 2,
              },
              '& .MuiTab-root': {
                minHeight: 42,
                fontSize: '0.875rem',
                textTransform: 'none',
                fontWeight: 500,
                py: 0,
                '& .MuiSvgIcon-root': {
                  marginBottom: '0 !important',
                },
              },
            }}
          >
            <Tab
              icon={<Mail style={{ width: 15, height: 15 }} />}
              iconPosition="start"
              label="Invite by Email"
              sx={{ gap: 1 }}
            />
            <Tab
              icon={<LinkIcon style={{ width: 15, height: 15 }} />}
              iconPosition="start"
              label="Invite by Link"
              sx={{ gap: 1 }}
            />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box sx={{ mx: 0.5 }}>
            {isPaidUser && renderBillingNotice()}
            <form onSubmit={handleEmailSubmit}>
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 2,
                    fontWeight: 600,
                    letterSpacing: '0.01em',
                    color: 'text.primary',
                  }}
                >
                  Email Addresses
                </Typography>
                <TextField
                  value={emailInput}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                    setEmailError('');
                  }}
                  onPaste={handlePaste}
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Enter email address"
                  error={!!emailError}
                  helperText={emailError}
                  disabled={isPaidUser && !hasAcknowledged}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="medium"
                          disabled={
                            !emailInput || (isPaidUser && !hasAcknowledged)
                          }
                          sx={{
                            minWidth: 100,
                            textTransform: 'none',
                          }}
                        >
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    ...inputStyles,
                    '& .MuiFormHelperText-root': {
                      mx: 2,
                    },
                  }}
                />
              </Box>
            </form>

            {emailList.length > 0 && (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: 'wrap', gap: 1, mt: 3 }}
                >
                  {emailList.map((currentEmail) => (
                    <Chip
                      key={currentEmail}
                      label={currentEmail}
                      onDelete={() => handleRemoveEmail(currentEmail)}
                      deleteIcon={<X style={{ width: 14, height: 14 }} />}
                      disabled={isPaidUser && !hasAcknowledged}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Stack>
                <Box sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSendInvites}
                    disabled={(isPaidUser && !hasAcknowledged) || isSending}
                    sx={{ textTransform: 'none' }}
                  >
                    {isSending ? (
                      <>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        Sending...
                      </>
                    ) : (
                      <>
                        <Send
                          style={{ width: 16, height: 16, marginRight: 8 }}
                        />
                        Send {emailList.length} Invitation
                        {emailList.length === 1 ? '' : 's'}
                      </>
                    )}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ mx: 0.5 }}>
            {isPaidUser && renderBillingNotice()}
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 1.5,
                  fontWeight: 600,
                  letterSpacing: '0.01em',
                  color: 'text.primary',
                }}
              >
                Invitation Link
              </Typography>
              <TextField
                value={inviteLink}
                variant="outlined"
                fullWidth
                size="small"
                disabled={isPaidUser && !hasAcknowledged}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={handleCopyLink}
                        color="primary"
                        variant="contained"
                        size="medium"
                        disabled={isPaidUser && !hasAcknowledged}
                        startIcon={
                          copied ? (
                            <Check style={{ width: 18, height: 18 }} />
                          ) : (
                            <Copy style={{ width: 18, height: 18 }} />
                          )
                        }
                        sx={{
                          minWidth: 120,
                          textTransform: 'none',
                        }}
                      >
                        {copied ? 'Copied!' : 'Copy Link'}
                      </Button>
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                sx={inputStyles}
              />
            </Box>
          </Box>
        </TabPanel>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
            mb: 1,
          }}
        >
          <Button
            onClick={() => setSalesDialogOpen(true)}
            variant="outlined"
            color="secondary"
            startIcon={<Rocket style={{ width: 16, height: 16 }} />}
            sx={{
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '0.875rem',
              py: 1,
              px: 2.5,
            }}
          >
            Enquire about a team plan
          </Button>
        </Box>
      </Box>

      <ActionDialog
        title="Get in touch"
        open={salesDialogOpen}
        onClose={() => setSalesDialogOpen(false)}
        maxWidth="md"
        fullWidth
        actions={[]}
      >
        <Box>
          <ContactSalesForm formSource="Team Invite - Enquire Team Plan" />
        </Box>
      </ActionDialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes('Failed') ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ActionDialog>
  );
};

export default InviteLinkDialog;
