import React, { useState, useRef } from 'react';
import moment from 'moment';
import {
  ListItem,
  Avatar,
  Link,
  useTheme,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Lock, Users } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FileType, FolderType } from '../../../../types';
import { pushModal } from '../../../globalModals/globalModalsSlice';
import FileActionMenu from '../FileActionMenu';
import { RootState } from '../../../../reducers';
import defaultThumbnail from '../../../../assets/recording/DefaultThumbnail.svg';

interface FileListItemProps {
  id?: string;
  file: FileType;
  isSelected: boolean;
  fileUrl: string;
  freeUserTimeLimit: Date | null;
}

const formatDuration = (seconds: number | undefined): string => {
  if (typeof seconds !== 'number') return '0:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return hours > 0
    ? `${hours}:${minutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
    : `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const FileListItem = ({
  file,
  fileUrl,
  freeUserTimeLimit,
}: FileListItemProps) => {
  const { name, thumbUrl, createdAt, owner, duration, parentId } = file;
  const { name: ownerName } = owner;
  const [thumbUrlValid, setThumbUrlValid] = useState<string>(
    thumbUrl || defaultThumbnail
  );
  const [showMenu, setShowMenu] = useState(false);
  const longPressTimer = useRef<ReturnType<typeof setTimeout>>();
  const touchStartTime = useRef<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { folderId } = useParams<{ folderId: string }>();
  const isInRecentsView = folderId === 'recents';

  const handleTouchStart = () => {
    touchStartTime.current = Date.now();
    longPressTimer.current = setTimeout(() => {
      setShowMenu(true);
    }, 500);
  };

  const handleTouchEnd = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  const handleTouchMove = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  const parentFolder = useSelector((state: RootState) => {
    return state.library.folders.data.find(
      (folder: FolderType) => folder._id === parentId
    );
  });

  let hiddenForFreeUser = false;

  if (freeUserTimeLimit) {
    hiddenForFreeUser =
      new Date(createdAt).getTime() < new Date(freeUserTimeLimit).getTime();
  }
  const handleMoveClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(pushModal({ type: 'move-file-modal', files: [file] }));
    },
    [dispatch, file]
  );

  return (
    <ListItem
      data-testid="video-list-tile"
      disablePadding
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onMouseEnter={() => !hiddenForFreeUser && !isMobile && setShowMenu(true)}
      onMouseLeave={() => !hiddenForFreeUser && !isMobile && setShowMenu(false)}
      onClick={() => {
        if (hiddenForFreeUser) {
          dispatch(pushModal({ type: 'upsell-modal' }));
        }
      }}
      sx={{
        borderBottom: theme.palette.mode === 'dark' ? 0 : 1,
        borderColor: theme.palette.secondary.light,
        position: 'relative',
        py: 1.5,
        pr: 2,
        backgroundColor: {
          xs: 'inherit',
          sm:
            showMenu && !hiddenForFreeUser
              ? theme.palette.background.default
              : 'inherit',
        },
        transition: 'background-color 0.3s',
      }}
    >
      <Link
        href={fileUrl}
        color="secondary.contrastText"
        underline="none"
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'minmax(200px, 1fr) 65px 70px',
            sm: isInRecentsView
              ? 'minmax(200px, 1.5fr) 80px 100px 80px 24px'
              : 'minmax(200px, 1.5fr) 80px 80px 24px',
            md: isInRecentsView
              ? 'minmax(200px, 1.5fr) 80px 100px 120px 80px 24px'
              : 'minmax(200px, 1.5fr) 80px 120px 80px 24px',
            lg: isInRecentsView
              ? 'minmax(250px, 1.5fr) 100px 120px 120px 80px 24px'
              : 'minmax(250px, 1.5fr) 100px 150px 80px 24px',
          },
          alignItems: 'center',
          flexGrow: 1,
          gap: { xs: 1, sm: 2 },
          pl: 2,
          pointerEvents: hiddenForFreeUser ? 'none' : 'auto',
          opacity: hiddenForFreeUser ? 0.5 : 1,
        }}
      >
        {/* Name Column */}
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 0 }}
        >
          <Avatar
            alt={name}
            src={thumbUrlValid}
            sx={{
              width: { xs: 24, sm: 32 },
              height: { xs: 24, sm: 32 },
              backgroundColor: theme.palette.secondary.main,
              borderRadius: 1,
              flexShrink: 0,
            }}
            onError={() => setThumbUrlValid(defaultThumbnail)}
          />
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              minWidth: 0,
              flex: 1,
            }}
          >
            {name}
          </Typography>
        </Box>

        {/* Duration Column */}
        <Typography
          sx={{
            color: 'text.secondary',
          }}
        >
          {formatDuration(duration)}
        </Typography>

        {/* Folder Column */}
        {isInRecentsView && (
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              gap: 1,
              color: 'text.secondary',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleMoveClick}
          >
            {parentId ? (
              <>
                <Users
                  sx={{
                    fontSize: '1rem',
                    opacity: 0.7,
                    color: theme.palette.text.secondary,
                  }}
                />
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    opacity: 0.7,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {parentFolder?.name || 'Unknown Folder'}
                </Typography>
              </>
            ) : (
              <>
                <Lock
                  sx={{
                    fontSize: '1rem',
                    opacity: 0.7,
                    color: theme.palette.text.secondary,
                  }}
                />
                <Typography
                  sx={{ opacity: 0.7, color: theme.palette.text.secondary }}
                >
                  Private
                </Typography>
              </>
            )}
          </Box>
        )}

        {/* Creator Column */}
        <Typography
          sx={{
            display: { xs: 'none', md: 'block' },
            color: 'text.secondary',
            opacity: 0.7,
          }}
        >
          {ownerName}
        </Typography>

        {/* Date Column */}
        <Typography
          sx={{
            display: 'block',
            color: 'text.secondary',
            opacity: 0.7,
          }}
        >
          {moment(createdAt).format('DD/MM/YY')}
        </Typography>
      </Link>
      {showMenu && !hiddenForFreeUser && (
        <Box
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <FileActionMenu file={file} />
        </Box>
      )}
    </ListItem>
  );
};

export default FileListItem;
