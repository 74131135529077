import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Users2, Lock } from 'lucide-mui';
import type { RootState } from '../../../../reducers';
import type { FolderType } from '../../../../types';
import FolderPermissionsModal from './FolderPermissionsModal';
import ActionDialog from '../../../globalModals/ActionDialog';
import type { ActionType } from '../../../globalModals/ActionDialog';

interface FolderAccessDetailsProps {
  open: boolean;
  onClose: () => void;
  folder: FolderType;
}

const FolderAccessDetails: React.FC<FolderAccessDetailsProps> = ({
  open,
  onClose,
  folder,
}) => {
  const [showPermissionsModal, setShowPermissionsModal] = React.useState(false);
  const { teamMembers, selectedTeam, currentUser } = useSelector(
    (state: RootState) => ({
      teamMembers: state.team.selectedTeam?.teamSpaceMembers || [],
      selectedTeam: state.team.selectedTeam,
      currentUser: state.settings.user,
    })
  );

  // Check if user can manage permissions (owner or team owner)
  const canManagePermissions = React.useMemo(() => {
    const currentUserId = currentUser?._id;
    return (
      currentUserId === folder.ownerId || // Folder owner
      currentUserId === selectedTeam?.ownerId // Team owner
    );
  }, [folder.ownerId, selectedTeam?.ownerId, currentUser?._id]);

  // Get owner info from selectedTeam
  const owner = teamMembers.find(
    (member) => member._id === selectedTeam?.ownerId
  ) || {
    _id: selectedTeam?.ownerId || '',
    email: selectedTeam?.email || '',
    name: 'Team Owner',
    picture: null,
    status: true,
    role: 'Owner',
    createdAt: '',
    inviteAccepted: true,
    lastActiveAt: '',
    updatedAt: '',
  };

  // Combine owner with team members if not already included
  const allMembers = teamMembers.some((member) => member._id === owner._id)
    ? teamMembers
    : [owner, ...teamMembers];

  // Get all users with access
  const usersWithAccess =
    folder.permissions === null
      ? allMembers // If public, all team members have access
      : allMembers.filter(
          (member) =>
            member._id === folder.ownerId || // Owner always has access
            folder.permissions?.some((p) => p.userId === member._id) // Users with explicit permissions
        );

  const actions: ActionType[] = canManagePermissions
    ? [
        {
          key: 'manage',
          label: 'Manage Access',
          onClick: () => {
            setShowPermissionsModal(true);
            onClose();
          },
          startIcon: folder.permissions === null ? <Users2 /> : <Lock />,
          variant: 'contained',
          color: 'primary',
        },
      ]
    : [];

  return (
    <>
      <ActionDialog
        open={open}
        onClose={onClose}
        title={`${
          folder.permissions === null ? 'Team Access' : 'Restricted Access'
        } - "${folder.name}"`}
        actions={actions}
        maxWidth="md"
      >
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {folder.permissions === null
            ? 'All team members can access this folder'
            : `${usersWithAccess.length} ${
                usersWithAccess.length === 1 ? 'person has' : 'people have'
              } access to this folder`}
        </Typography>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Access Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersWithAccess.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name || '—'}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {(() => {
                        if (user._id === folder.ownerId) {
                          return (
                            <Chip
                              label="Owner"
                              size="small"
                              sx={{
                                bgcolor: 'primary.light',
                                color: 'primary.dark',
                              }}
                            />
                          );
                        }
                        if (user._id === selectedTeam?.ownerId) {
                          return (
                            <Chip
                              label="Team Owner"
                              size="small"
                              sx={{
                                bgcolor: 'warning.light',
                                color: 'warning.dark',
                              }}
                            />
                          );
                        }
                        return (
                          <Chip
                            label={
                              folder.permissions === null
                                ? 'Team Access'
                                : 'Has Access'
                            }
                            size="small"
                            sx={{
                              bgcolor: 'success.light',
                              color: 'success.dark',
                            }}
                          />
                        );
                      })()}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!canManagePermissions && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ fontStyle: 'italic', mt: 2, display: 'block' }}
          >
            Only folder owners and team owners can manage access
          </Typography>
        )}
      </ActionDialog>

      {showPermissionsModal && (
        <FolderPermissionsModal
          open={showPermissionsModal}
          onClose={() => setShowPermissionsModal(false)}
          folder={folder}
        />
      )}
    </>
  );
};

export default FolderAccessDetails;
