import { apiV2 } from '../lib/baseAxios';
import config from '../../config';
import { getToken } from '../lib/localStorageHandler';

export interface Template {
  _id: string;
  id: string;
  teamId: string;
  ownerId: string;
  title: string;
  description?: string;
  prompt: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CreateTemplateParams {
  id?: string; // Make ID optional for creation
  title: string;
  description?: string;
  prompt: string;
}

const templatesUrl = `/templates`;

export const createTemplate = async (params: CreateTemplateParams) => {
  const response = await apiV2.post(templatesUrl, params);
  return response.data;
};

export const getTemplates = async (): Promise<Template[] | null> => {
  try {
    const response = await apiV2.get(templatesUrl);
    return response.data.data;
  } catch (error) {
    console.error('Failed to fetch templates:', error);
    return null;
  }
};

export const updateTemplate = async (
  id: string,
  template: Partial<
    Omit<
      Template,
      '_id' | 'createdAt' | 'updatedAt' | 'id' | 'ownerId' | 'teamId'
    >
  >
) => {
  const response = await apiV2.put(`${templatesUrl}/${id}`, template);
  return response.data;
};

export const deleteTemplate = async (id: string) => {
  const response = await apiV2.delete(`${templatesUrl}/${id}`);
  return response.data;
};

export const generateTitle = async (
  customTemplate: string
): Promise<string> => {
  const response = await fetch(`${config.authBaseUrlV2}/proxy/ai/completion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      prompt: `Generate a concise title (max 12 characters plaintext and nothing else) for this template prompt: "${customTemplate.trim()}"`,
      model: 'fast',
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to generate title');
  }

  const data = await response.text();
  return data.trim();
};

export const getTemplate = async (id: string): Promise<Template | null> => {
  try {
    const response = await apiV2.get(`${templatesUrl}/${id}`);
    return response.data.data;
  } catch (error) {
    if ((error as any)?.response?.status === 404) {
      return null;
    }
    console.error('Failed to fetch template:', error);
    throw error;
  }
};
