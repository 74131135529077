import {
  Sparkles,
  FileAudio,
  MonitorPlay,
  Mic,
  FileText,
  ScrollText,
  Mic2,
  GraduationCap,
  FileStack,
  Video,
  Youtube,
  Webhook,
  MessageSquare,
  CalendarClock,
  Podcast,
  Combine,
  MonitorSmartphone,
  PhoneCall,
  Quote,
  Upload,
  Subtitles,
  Scissors,
  File,
  ToggleLeft,
  MessagesSquare,
  Instagram,
  MessageCircle,
  Twitter,
  Monitor,
  Link,
  MonitorUp,
  Briefcase,
  Download,
  RefreshCw,
  Camera,
  Chrome,
  UserPlus,
  Globe,
} from 'lucide-mui';

export interface Intent {
  key: string;
  label: string;
  description: string;
  action?: string;
  icon?: any;
}

export const intents: Intent[] = [
  {
    key: 'lecture-ai-notetaker',
    label: 'Take Lecture Notes',
    description: 'Take automated notes from your lectures using AI',
    action: 'record-audio',
    icon: GraduationCap,
  },
  {
    key: 'ai-summarizer',
    label: 'Summarize Media',
    description: 'Get instant AI-powered summaries of any content',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'youtube-ai-notetaker',
    label: 'Take YouTube Notes',
    description: 'Generate notes automatically from YouTube videos',
    action: 'import-from-url',
    icon: Youtube,
  },
  {
    key: 'ai-note-taker',
    label: 'Take AI Notes',
    description: 'Let AI take detailed notes from any recording',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'meeting-minutes-generator',
    label: 'Generate Minutes',
    description: 'Create automated meeting minutes from recordings',
    action: 'record-audio',
    icon: Sparkles,
  },
  {
    key: 'video-analyzer',
    label: 'Analyze Video',
    description: 'Get in-depth analysis of video content',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'voice-test-online',
    label: 'Test Voice',
    description: 'Test your voice recording capabilities online',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'audio-notetaker',
    label: 'Take Audio Notes',
    description: 'Convert audio recordings into organized notes',
    action: 'record-audio',
    icon: FileAudio,
  },
  {
    key: 'audio-translator',
    label: 'Translate Audio',
    description: 'Translate audio content between languages',
    action: 'record-audio',
    icon: Globe,
  },
  {
    key: 'video-downloader',
    label: 'Download Video',
    description: 'Download videos from various online sources',
    action: 'import-from-url',
    icon: Download,
  },
  {
    key: 'video-ask-ai',
    label: 'Ask Video AI',
    description: 'Ask questions about video content and get AI answers',
    action: 'import-from-url',
    icon: MessagesSquare,
  },
  {
    key: 'lecture-summarizer',
    label: 'Summarize Lecture',
    description: 'Get concise summaries of lecture recordings',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'youtube-text-extractor',
    label: 'Extract YouTube Text',
    description: 'Extract text content from YouTube videos',
    action: 'import-from-url',
    icon: Youtube,
  },
  {
    key: 'ai-recorder',
    label: 'Record with AI',
    description: 'Smart recording with built-in AI processing',
    action: 'record-audio',
    icon: ToggleLeft,
  },
  {
    key: 'ai-video-watcher',
    label: 'Watch with AI',
    description: 'Let AI analyze and understand video content',
    action: 'record-screen',
    icon: Video,
  },
  {
    key: 'youtube-video-link-generator',
    label: 'Generate YouTube Link',
    description: 'Generate shareable links for YouTube videos',
    action: 'import-from-url',
    icon: Link,
  },
  {
    key: 'audio-summarizer',
    label: 'Summarize Audio',
    description: 'Create summaries from audio recordings',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'online-live-caption-tool',
    label: 'Caption Live',
    description: 'Generate real-time captions for live content',
    action: 'record-audio',
    icon: MonitorPlay,
  },
  {
    key: 'audio-ask-ai',
    label: 'Ask Audio AI',
    description: 'Ask questions about audio content and get AI responses',
    action: 'record-audio',
    icon: Sparkles,
  },
  {
    key: 'online-screen-recorder',
    label: 'Record Screen',
    description: 'Record your screen directly in the browser',
    action: 'record-screen',
    icon: MonitorSmartphone,
  },
  {
    key: 'whatsapp-call-recorder',
    label: 'Record WhatsApp',
    description: 'Record WhatsApp calls and conversations',
    action: 'record-audio',
    icon: PhoneCall,
  },
  {
    key: 'in-person-notetaker',
    label: 'Take Meeting Notes',
    description: 'Take notes during in-person meetings and events',
    action: 'record-screen',
    icon: Sparkles,
  },
  {
    key: 'meeting-recorder',
    label: 'Record Meeting',
    description: 'Record online and in-person meetings',
    action: 'record-screen',
    icon: MonitorSmartphone,
  },
  {
    key: 'video-converter',
    label: 'Convert Video',
    description: 'Convert videos between different formats',
    action: 'import-from-url',
    icon: RefreshCw,
  },
  {
    key: 'chrome-tab-audio-capture',
    label: 'Capture Chrome Audio',
    description: 'Capture audio from specific Chrome tabs',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'chrome-audio-capture',
    label: 'Record Chrome Audio',
    description: 'Record audio from Chrome browser',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'screencast-online',
    label: 'New Screencast',
    description: 'Create and share screen recordings online',
    action: 'record-screen',
    icon: MonitorSmartphone,
  },
  {
    key: 'youtube-stream-recorder',
    label: 'Record Stream',
    description: 'Record live streams from YouTube',
    action: 'record-screen',
    icon: MonitorPlay,
  },
  {
    key: 'tiktok-summarizer',
    label: 'Summarize TikTok',
    description: 'Get summaries of TikTok video content',
    action: 'import-from-url',
    icon: Upload,
  },
  {
    key: 'ai-video-dubbing',
    label: 'Dub Video',
    description: 'Add AI-powered voice dubbing to videos',
    action: 'import-from-url',
    icon: Mic2,
  },
  {
    key: 'mp4-recorder',
    label: 'Record MP4',
    description: 'Record videos directly in MP4 format',
    action: 'record-screen',
    icon: Camera,
  },
  {
    key: 'video-translator',
    label: 'Translate Video',
    description: 'Translate video content between languages',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'lecture-recorder',
    label: 'Record Lecture',
    description: 'Record lectures and educational content',
    action: 'record-screen',
    icon: GraduationCap,
  },
  {
    key: 'audio-to-word-converter',
    label: 'Convert to Word',
    description: 'Convert audio recordings to Word documents',
    action: 'import-from-url',
    icon: File,
  },
  {
    key: 'ai-podcast-summarizer',
    label: 'Summarize Podcast',
    description: 'Get AI-powered summaries of podcast episodes',
    action: 'import-from-url',
    icon: Podcast,
  },
  {
    key: 'merge-video-and-audio-tool',
    label: 'Merge Media',
    description: 'Combine separate video and audio files',
    action: 'import-from-url',
    icon: Combine,
  },
  {
    key: 'live-transcribe-app',
    label: 'Transcribe Live',
    description: 'Real-time transcription of spoken content',
    action: 'record-audio',
    icon: FileText,
  },
  {
    key: 'sop-generator',
    label: 'Generate SOP',
    description: 'Generate standard operating procedures from recordings',
    action: 'import-from-url',
    icon: Quote,
  },
  {
    key: 'video-chatbot',
    label: 'Chat with Video',
    description: 'Interactive AI chatbot for video content',
    action: 'import-from-url',
    icon: MessageSquare,
  },
  {
    key: 'safari-screen-recorder',
    label: 'Record Safari',
    description: 'Record screen content in Safari browser',
    action: 'record-screen',
    icon: Monitor,
  },
  {
    key: 'phone-call-summarizer',
    label: 'Summarize Call',
    description: 'Get summaries of recorded phone calls',
    action: 'import-from-url',
    icon: PhoneCall,
  },
  {
    key: 'ai-study-guide-maker',
    label: 'Create Study Guide',
    description: 'Create study guides from educational content',
    action: 'import-from-url',
    icon: GraduationCap,
  },
  {
    key: 'tool-to-paraphrase',
    label: 'Paraphrase Text',
    description: 'Rewrite content in different words',
    action: 'import-from-url',
    icon: Quote,
  },
  {
    key: 'meeting-summarizer',
    label: 'Summarize Meeting',
    description: 'Generate summaries from meeting recordings',
    action: 'import-from-url',
    icon: FileStack,
  },
  {
    key: 'audio-upload',
    label: 'Upload Audio',
    description: 'Upload and process audio files',
    action: 'import-from-url',
    icon: Upload,
  },
  {
    key: 'firefox-screen-recorder',
    label: 'Record Firefox',
    description: 'Record screen content in Firefox browser',
    action: 'record-screen',
    icon: Monitor,
  },
  {
    key: 'voice-memo-summarizer',
    label: 'Summarize Memo',
    description: 'Get summaries of voice memo recordings',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'hindi-audio-translator',
    label: 'Translate Hindi',
    description: 'Translate audio content to and from Hindi',
    action: 'record-audio',
    icon: Globe,
  },
  {
    key: 'youtube-transcript',
    label: 'Transcribe YouTube',
    description: 'Generate transcripts from YouTube videos',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'japanese-video-translator',
    label: 'Translate Japanese',
    description: 'Translate video content to and from Japanese',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'urdu-video-translator',
    label: 'Translate Urdu',
    description: 'Translate video content to and from Urdu',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'tagalog-video-translator',
    label: 'Translate Tagalog',
    description: 'Translate video content to and from Tagalog',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'vimeo-summarizer',
    label: 'Summarize Vimeo',
    description: 'Get summaries of Vimeo video content',
    action: 'import-from-url',
    icon: FileStack,
  },
  {
    key: 'ai-audio-listener',
    label: 'Listen with AI',
    description: 'AI-powered analysis of audio content',
    action: 'import-from-url',
    icon: Mic,
  },
  {
    key: 'extract-audio-from-videos',
    label: 'Extract Audio',
    description: 'Separate audio tracks from video files',
    action: 'import-from-url',
    icon: FileAudio,
  },
  {
    key: 'online-audio-cutter',
    label: 'Cut Audio',
    description: 'Trim and edit audio files online',
    action: 'import-from-url',
    icon: Scissors,
  },
  {
    key: 'auto-subtitle-generator-online',
    label: 'Generate Subtitle',
    description: 'Generate subtitles automatically for videos',
    action: 'import-from-url',
    icon: Subtitles,
  },
  {
    key: 'hindi-video-translator',
    label: 'Translate Hindi Video',
    description: 'Translate video content to and from Hindi',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'audio-to-text-converter',
    label: 'Convert to Text',
    description: 'Convert audio recordings to text',
    action: 'record-audio',
    icon: FileText,
  },
  {
    key: 'german-audio-translator',
    label: 'Translate German',
    description: 'Translate audio content to and from German',
    action: 'record-audio',
    icon: Globe,
  },
  {
    key: 'youtube-summarizer',
    label: 'Summarize YouTube',
    description: 'Get summaries of YouTube videos',
    action: 'import-from-url',
    icon: FileStack,
  },
  {
    key: 'arabic-video-translator',
    label: 'Translate Arabic',
    description: 'Translate video content to and from Arabic',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'video-description-generator',
    label: 'Generate Description',
    description: 'Generate descriptions for video content',
    action: 'import-from-url',
    icon: File,
  },
  {
    key: 'google-meet-bot',
    label: 'Assist Meet',
    description: 'Automated assistant for Google Meet sessions',
    action: 'import-from-url',
    icon: ToggleLeft,
  },
  {
    key: 'quiz-generator-from-video-and-audio',
    label: 'Generate Quiz',
    description: 'Create quizzes from video and audio content',
    action: 'import-from-url',
    icon: ScrollText,
  },
  {
    key: 'french-video-translator',
    label: 'Translate French',
    description: 'Translate video content to and from French',
    action: 'import-from-url',
    icon: Globe,
  },
  {
    key: 'instagram-recorder',
    label: 'Record Instagram',
    description: 'Record Instagram content and lives',
    action: 'record-screen',
    icon: Instagram,
  },
  {
    key: 'voice-memo-ai-notetaker',
    label: 'Take Memo Notes',
    description: 'Convert voice memos into organized notes',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'ai-clinical-notes-generator',
    label: 'Generate Clinical Notes',
    description: 'Generate medical notes using AI',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'chrome-extension',
    label: 'Use Extension',
    description: 'Browser extension for Chrome',
    action: 'chrome-install',
    icon: Chrome,
  },
  {
    key: 'video-simplifier',
    label: 'Simplify Video',
    description: 'Make complex videos easier to understand',
    action: 'import-from-url',
    icon: Quote,
  },
  {
    key: 'presentation-recorder',
    label: 'Record Presentation',
    description: 'Record presentations and slideshows',
    action: 'record-screen',
    icon: MonitorUp,
  },
  {
    key: 'transcript-summarizer',
    label: 'Summarize Transcript',
    description: 'Summarize written transcripts',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'tagalog-audio-translator',
    label: 'Translate Tagalog Audio',
    description: 'Translate audio content to and from Tagalog',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'telegram-voice-ai-notetaker',
    label: 'Take Telegram Notes',
    description: 'Take notes from Telegram voice messages',
    action: 'import-from-url',
    icon: MessageCircle,
  },
  {
    key: 'online-video-teleprompter',
    label: 'Use Teleprompter',
    description: 'Teleprompter tool for video recording',
    action: 'record-screen',
    icon: MonitorUp,
  },
  {
    key: 'twitch-recorder',
    label: 'Record Twitch',
    description: 'Record Twitch streams and content',
    action: 'record-screen',
    icon: MonitorPlay,
  },
  {
    key: 'word-doc-summarizer',
    label: 'Summarize Document',
    description: 'Summarize Word documents',
    action: 'import-from-url',
    icon: File,
  },
  {
    key: 'online-voice-recorder',
    label: 'Record Voice',
    description: 'Record voice directly in browser',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'transcribe-audio-recordings-chrome-extension',
    label: 'Transcribe Chrome',
    description: 'Chrome extension for audio transcription',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'jitsi-ai-notetaker',
    label: 'Take Jitsi Notes',
    description: 'Take notes from Jitsi meetings',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'google-meet-recording-extension',
    label: 'Record Meet',
    description: 'Record Google Meet sessions',
    action: 'record-screen',
    icon: MonitorSmartphone,
  },
  {
    key: 'video-note-generator',
    label: 'Generate Video Notes',
    description: 'Generate notes from video content',
    action: 'import-from-url',
    icon: FileText,
  },
  {
    key: 'twitch-summarizer',
    label: 'Summarize Twitch',
    description: 'Get summaries of Twitch streams',
    action: 'import-from-url',
    icon: FileStack,
  },
  {
    key: 'slack-call-recorder',
    label: 'Record Slack',
    description: 'Record Slack calls and conversations',
    action: 'record-screen',
    icon: Briefcase,
  },
  {
    key: 'video-summarizer',
    label: 'Summarize Video',
    description: 'Get summaries of video content',
    action: 'import-from-url',
    icon: FileStack,
  },
  {
    key: 'speak-youtube-subtitles',
    label: 'Speak Subtitles',
    description: 'Convert YouTube subtitles to speech',
    action: 'import-from-url',
    icon: Subtitles,
  },
  {
    key: 'meeting-agenda-generator',
    label: 'Generate Agenda',
    description: 'Create agendas from meeting recordings',
    action: 'import-from-url',
    icon: CalendarClock,
  },
  {
    key: 'audio-gpt',
    label: 'Use Audio GPT',
    description: 'GPT-powered audio analysis and processing',
    action: 'record-audio',
    icon: Mic,
  },
  {
    key: 'video-trimmer',
    label: 'Trim Video',
    description: 'Trim and edit video content',
    action: 'import-from-url',
    icon: Quote,
  },
  {
    key: 'filler-remover',
    label: 'Remove Fillers',
    description: 'Remove filler words from recordings',
    action: 'import-from-url',
    icon: Quote,
  },
  {
    key: 'twitter-transcript-summarizer',
    label: 'Summarize Twitter',
    description: 'Summarize Twitter Spaces transcripts',
    action: 'import-from-url',
    icon: Twitter,
  },
  {
    key: 'line-call-recorder',
    label: 'Record LINE',
    description: 'Record LINE voice and video calls',
    action: 'record-audio',
    icon: PhoneCall,
  },
  {
    key: 'opera-screen-recorder',
    label: 'Record Screen',
    description: 'Record screen content in Opera browser',
    action: 'record-screen',
    icon: Monitor,
  },
  {
    key: 'api',
    label: 'Use API',
    description: 'Access features through API endpoints',
    action: 'import-from-url',
    icon: Webhook,
  },
  {
    key: 'zoom-bot',
    label: 'Assist Zoom',
    description: 'Automated assistant for Zoom meetings',
    action: 'import-from-url',
    icon: Sparkles,
  },
  {
    key: 'add-team-member',
    label: 'Add Team Member',
    description: 'Invite new members to your team',
    action: 'add-team-member',
    icon: UserPlus,
  },
];

export default intents;
