import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { ArrowUpRight } from 'lucide-mui';
import { FileType } from '../../../types';
import defaultThumbnail from '../../../assets/recording/DefaultThumbnail.svg';

interface CitationTooltipProps {
  fileId: string;
  timestamp: string;
  file?: FileType;
  isActive: boolean;
  thumbnailUrl?: string | null;
  isLoading?: boolean;
  onSourceClick: (fileId: string, timestamp: string) => void;
  formatTimestamp: (seconds: string) => string;
}

const CitationTooltip: React.FC<CitationTooltipProps> = ({
  fileId,
  timestamp,
  file,
  isActive,
  thumbnailUrl,
  isLoading,
  onSourceClick,
  formatTimestamp,
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={
        <Box sx={{ maxWidth: 280 }}>
          {/* Show thumbnail in tooltip if available */}
          {thumbnailUrl && (
            <Box
              sx={{
                width: '100%',
                height: '158px', // 16:9 aspect ratio for 280px width
                mb: 1,
                borderRadius: '12px',
                overflow: 'hidden',
                bgcolor: 'background.paper',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={thumbnailUrl}
                alt={`Thumbnail at ${formatTimestamp(timestamp)}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  filter:
                    theme.palette.mode === 'dark' &&
                    (!thumbnailUrl || thumbnailUrl === defaultThumbnail)
                      ? 'invert(1)'
                      : 'none',
                }}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  // If the thumbnail URL fails, use default thumbnail
                  const target = e.currentTarget;
                  if (target.src !== defaultThumbnail) {
                    target.src = defaultThumbnail;
                  }
                }}
              />
            </Box>
          )}

          {/* Show loading indicator if thumbnail is loading */}
          {isLoading && (
            <Box
              sx={{
                width: '100%',
                height: '158px',
                mb: 1,
                borderRadius: '12px',
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(60, 60, 60, 0.7)'
                    : 'action.hover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={24} />
            </Box>
          )}

          {/* Text description */}
          <Typography variant="body2">
            {`Go to source: ${file?.name || 'File'} at ${formatTimestamp(
              timestamp
            )}`}
          </Typography>
        </Box>
      }
      arrow
      placement="top"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            bgcolor: 'background.paper',
            color: 'text.primary',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
            p: 1,
            maxWidth: 'none',
            borderRadius: '16px',
          },
          '& .MuiTooltip-arrow': {
            color: 'background.paper',
          },
        },
      }}
    >
      <Box
        component="span"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          onSourceClick(fileId, timestamp);
        }}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '1.2rem',
          height: '1.2rem',
          borderRadius: '50%',
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(70, 70, 70, 0.7)' : 'white',
          color: isActive ? 'primary.main' : 'text.secondary',
          opacity: isActive ? 1 : 0.6,
          cursor: 'pointer',
          verticalAlign: 'middle',
          position: 'relative',
          lineHeight: 1,
          minWidth: '1.2rem',
          margin: '0 0.125rem',
          boxShadow: 'none',
          '&:hover': {
            opacity: 1,
            backgroundColor:
              theme.palette.mode === 'dark' ? 'rgba(70, 70, 70, 1)' : 'white',
          },
        }}
      >
        <ArrowUpRight width={11} height={11} />
      </Box>
    </Tooltip>
  );
};

export default CitationTooltip;
