/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import RecorderV3 from '../../recorder';
import { getToken } from '../../lib/localStorageHandler';
import { addFile } from '../library/librarySlice';
import { addFileToUploads, removeFileFromUploads } from '../upload/uploadSlice';
import { RootState } from '../../reducers';
import { TEAM_SPACE_USER_ROLES } from '../../constants/companySharedConstants';
import { setAlertMessage } from '../dashboard/dashboardSlice';

let recorderV3: RecorderV3;

export const initializeRecorder = createAsyncThunk(
  'recorder/initializeRecorder',
  async (
    {
      teamId,
      folderId,
      settings,
    }: {
      teamId: string;
      folderId: string;
      settings?: ConstructorParameters<typeof RecorderV3>[0]['settings'];
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    const token = getToken();
    const state = getState() as RootState;
    const teamMembers = state.team?.selectedTeam?.teamSpaceMembers;
    const currentUserId = state.settings.user._id;

    const isGuest = teamMembers?.some(
      (member) =>
        member._id === currentUserId &&
        member.role === TEAM_SPACE_USER_ROLES.GUEST
    );
    if (isGuest) {
      dispatch(
        setAlertMessage({
          type: 'warning',
          message:
            "You're currently a guest. Please request access from the Team Owner to become a member and unlock these features.",
        })
      );
      return undefined;
    }

    if (!token) return rejectWithValue('Token not found');
    recorderV3 = new RecorderV3({
      apiKey: token,
      teamId,
      folderId,
      settings: {
        ...settings,
      },
    });
    recorderV3.on('ready', (event) => {
      console.log('Recorder ready', event.detail);
    });
    recorderV3.on('stopped', (event) => {
      const { folderId: uploadingFileId, fileId } = event.detail;

      dispatch(
        addFileToUploads({
          folderId: uploadingFileId,
          fileId,
          fileName: `Recording-${new Date().toISOString()}`,
          progress: 0,
        })
      );
    });
    recorderV3.on('error', (event) => {
      console.error('Recorder error', event.detail);
    });
    recorderV3.on('uploaded', (event) => {
      const { folderId: uploadingFileId } = event.detail;
      const { _id: fileId } = event.detail.file;
      dispatch(removeFileFromUploads({ folderId: uploadingFileId, fileId }));
      dispatch(addFile(event.detail.file));
    });
    await recorderV3.initialize();
    return undefined;
  }
);
