/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getFile,
  updateSpeaker as updateSpeakerRequest,
  askAIV2,
  trim,
  getDownloadUrl,
  getFolders,
  moveFile2,
  deleteFile2,
  shareFile,
  updateFile2,
  reprocessV2,
  deleteAskAISessionV2,
  deleteTemplateOutputV2,
  saveEnhancedNotes,
  SaveEnhancedNotesParams,
  saveNotes,
  SaveNotesParams,
  updateTranscriptSegment,
  restoreTranscriptSegment,
  askAIMultimodal,
} from '../../services/fileSystemService';
import type {
  ChatMessage,
  PromptResponses,
  TrimParams,
  UpdateSpeakerProps,
  MediaAnalysisOptions,
} from '../../services/fileSystemService';
import {
  FileType,
  FolderType,
  ShareDetails,
  Speaker,
  requestStatusType,
  CompactSegment,
  SegmentEditRecord,
  Word,
} from '../../types';
import { RootState } from '../../reducers';
import downloadVideo from '../../lib/videoUtils';
import { setAlertMessage } from '../dashboard/dashboardSlice';
import { getShareUrl } from '../../lib/routingUtils';

interface SuggestionType {
  title: string;
  prompt: string;
}
interface SelectedVideoState {
  status: requestStatusType;
  file: FileType;
  jobs: {
    transcribe?: {
      position: number;
      timeToComplete: number;
      updatedAt: string;
    };
  };
  transcript: {
    compactSegments: CompactSegment[];
    editRecords: Record<string, SegmentEditRecord>;
    history: string[];
    selectedSpeakerIds: string[];
    selectedSpeakerId: string | null;
    showNotesOnly: boolean;
    updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed';
    words?: Word[];
  };
  chat: {
    status: requestStatusType;
    suggestions: SuggestionType[];
    conversation: ChatMessage[];
    view: 'expanded' | 'compact';
    sessionId: string;
    isVideoMode: boolean;
  };
  postProcess: {
    systemPromptResponses: PromptResponses;
    userPromptResponses: PromptResponses;
  };
  actions: {
    trim: {
      status: requestStatusType;
      visibility: boolean;
    };
    share: {
      status: requestStatusType;
    };
    embed: {
      status: requestStatusType;
      url: string;
    };
    download: {
      status: requestStatusType;
    };
    move: {
      status: requestStatusType;
      folders: FolderType[];
    };
    delete: {
      status: requestStatusType;
    };
    clip: {
      status: requestStatusType;
    };
    dub: {
      status: requestStatusType;
    };
    tagUser: {
      status: requestStatusType;
    };
    shareEmail: {
      status: requestStatusType;
    };
  };
  summary: {
    status: requestStatusType;
    text: string;
    isEditing: boolean;
  };
  title: {
    status: requestStatusType;
    text: string;
    isEditing: boolean;
  };
  trimmerVisible: boolean;
  clipperVisible: boolean;
}

const initialState: SelectedVideoState = {
  status: 'idle',
  file: {} as FileType,
  jobs: {},
  transcript: {
    words: [],
    compactSegments: [],
    editRecords: {},
    history: [],
    selectedSpeakerIds: [],
    selectedSpeakerId: null,
    showNotesOnly: false,
    updateStatus: 'idle',
  },
  chat: {
    status: 'idle',
    suggestions: [],
    conversation: [],
    view: 'compact',
    sessionId: '',
    isVideoMode: false,
  },
  postProcess: {
    systemPromptResponses: {},
    userPromptResponses: {},
  },
  actions: {
    trim: {
      status: 'idle',
      visibility: false,
    },
    share: {
      status: 'idle',
    },
    embed: {
      status: 'idle',
      url: '',
    },
    download: {
      status: 'idle',
    },
    move: {
      status: 'idle',
      folders: [],
    },
    delete: {
      status: 'idle',
    },
    clip: {
      status: 'idle',
    },
    dub: {
      status: 'idle',
    },
    tagUser: {
      status: 'idle',
    },
    shareEmail: {
      status: 'idle',
    },
  },
  summary: {
    status: 'idle',
    text: '',
    isEditing: false,
  },
  title: {
    status: 'idle',
    text: '',
    isEditing: false,
  },
  trimmerVisible: false,
  clipperVisible: false,
};

export const updateSpeaker = createAsyncThunk(
  'selectedVideo/updateSpeaker',
  async (
    data: UpdateSpeakerProps
  ): Promise<{
    [key: string]: Speaker;
  }> => {
    const speakers = await updateSpeakerRequest(data);
    return speakers;
  }
);

export const reprocessSelectedVideo = createAsyncThunk(
  'selectedVideo/reprocessSelectedVideo',
  async (id: string): Promise<{ name: string; description: string }> => {
    const response = await reprocessV2(id);
    const { name, description = '' } = response.data.file;
    return { name, description };
  }
);

export const getTeamFolders = createAsyncThunk(
  'folders/getTeamFolders',
  async (teamId: string, { rejectWithValue }) => {
    try {
      const response = await getFolders({ teamId });
      return response.data.data.folders;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export interface MoveFileData {
  fileId: string;
  toTeamId: string;
  toFolderId: string;
}

export const moveFile = createAsyncThunk(
  'files/moveFile',
  async (data: MoveFileData, { rejectWithValue }) => {
    try {
      const response = await moveFile2(data);
      return response.data.data.file;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteFile = createAsyncThunk(
  'files/deleteFile',
  async (fileId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteFile2(fileId);
      if (response.data.success) {
        return response.data.success;
      }
      return null;
    } catch (error) {
      dispatch(
        setAlertMessage({
          type: 'error',
          message: 'Failed to delete file',
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFile = createAsyncThunk(
  'selectedVideo/fetchFile',
  async (fileId: string) => {
    const data = await getFile(fileId);
    return data;
  }
);

interface UpdateFileOptions {
  fileId: string;
  summary: string;
}

export const updateSummary = createAsyncThunk(
  'selectedVideo/summary/update',
  async (options: UpdateFileOptions): Promise<string> => {
    const { fileId, summary } = options;
    const response = await updateFile2(fileId, { description: summary });
    const description = response?.data?.data?.file?.description || '';
    return description;
  }
);

interface UpdateNameOptions {
  fileId: string;
  title: string;
}

export const updateTitle = createAsyncThunk(
  'selectedVideo/title/update',
  async (options: UpdateNameOptions, { dispatch }): Promise<string> => {
    try {
      const { fileId, title } = options;
      const response = await updateFile2(fileId, { name: title });
      const updatedName = response?.data?.data?.file?.name || '';
      return updatedName;
    } catch (error) {
      dispatch(
        setAlertMessage({
          type: 'error',
          message: 'Failed to update title',
        })
      );
      throw error;
    }
  }
);

export const trimFile = createAsyncThunk<
  FileType,
  Pick<TrimParams, 'startMS' | 'endMS'>
>('selectedVideo/trim', async ({ startMS, endMS }, { getState }) => {
  const { selectedVideo } = getState() as RootState;
  const { _id: fileId } = selectedVideo.file;
  const response = await trim({
    fileId,
    startMS,
    endMS,
  });
  return response.data.data.file;
});

interface AskQuestionOptions {
  fileId: string;
  question: string;
}

export const askQuestion = createAsyncThunk(
  'selectedVideo/askQuestion',
  async ({ fileId, question }: AskQuestionOptions) => {
    const response = await askAIV2({
      fileId,
      question,
    });
    return response.data.data;
  }
);

interface AskMultimodalQuestionOptions {
  fileId: string;
  promptText: string;
  mediaAnalysisOptions: MediaAnalysisOptions;
}

export const askMultimodalQuestion = createAsyncThunk(
  'selectedVideo/askQuestion/multimodal',
  async ({
    fileId,
    promptText,
    mediaAnalysisOptions,
  }: AskMultimodalQuestionOptions) => {
    const response = await askAIMultimodal({
      fileId,
      promptText,
      mediaAnalysisOptions,
    });
    return response.data.data;
  }
);

export const deleteAskAISession = createAsyncThunk(
  'selectedVideo/deleteAskAISession',
  async ({ fileId, sessionId }: { fileId: string; sessionId: string }) => {
    const response = await deleteAskAISessionV2(fileId, sessionId);
    return response.data.success;
  }
);

export const deleteTemplateOutput = createAsyncThunk(
  'selectedVideo/deleteTemplateOutput',
  async ({ fileId, templateId }: { fileId: string; templateId: string }) => {
    const response = await deleteTemplateOutputV2(fileId, templateId);
    return !!response.data.success;
  }
);

export const shareThunk = createAsyncThunk(
  'selectedVideo/share',
  async (
    options: Parameters<typeof shareFile>[0] & {
      copyUrlToClipboard?: boolean;
    },
    { getState }
  ): Promise<ShareDetails> => {
    const { fileId, permissions, copyUrlToClipboard = true } = options;
    const { selectedVideo } = getState() as RootState;
    const { shareId: existingShareId } = selectedVideo.file.share || {};
    let shareId = existingShareId || '';
    if (!existingShareId) {
      const { shareId: newShareId } = await shareFile({
        fileId,
        permissions,
      });
      shareId = newShareId || '';
    }
    // Set a delay of 1 second before opening the share link
    if (copyUrlToClipboard && shareId) {
      const url = getShareUrl(shareId);
      // Write to clipboard
      await navigator.clipboard.writeText(url);
      setTimeout(() => {
        window.open(url, '_blank', 'noopener');
      }, 1000);
    }
    return { shareId };
  }
);

export const downloadFile = createAsyncThunk(
  'selectedVideo/download',
  async (data: Parameters<typeof getDownloadUrl>[0], { rejectWithValue }) => {
    try {
      const { url } = await getDownloadUrl(data);
      if (url) {
        downloadVideo(url, data.fileId);
      }
      return { url };
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export const saveEnhancedNotesThunk = createAsyncThunk(
  'selectedVideo/saveEnhancedNotes',
  async (params: SaveEnhancedNotesParams) => {
    const response = await saveEnhancedNotes(params);
    return {
      success: response.data.success,
      responseText: params.responseText,
    };
  }
);

export const saveNotesThunk = createAsyncThunk(
  'selectedVideo/saveNotes',
  async ({ fileId, notes }: SaveNotesParams) => {
    const response = await saveNotes({ fileId, notes });
    return {
      success: response.data.success,
      notes,
    };
  }
);

export const setClipperVisibility = createAction<boolean>(
  'selectedVideo/setClipperVisibility'
);

export const updateSegmentText = createAsyncThunk(
  'selectedVideo/updateSegmentText',
  async (
    {
      segmentId,
      newText,
    }: {
      segmentId: string | number;
      newText: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const { file } = state.selectedVideo;

    const { editRecord, compactSegment } = await updateTranscriptSegment({
      fileId: file._id,
      segmentId,
      newText,
      teamId: file.teamId,
    });

    return {
      segmentId,
      editRecord,
      compactSegment,
    };
  }
);

export const restoreSegmentVersion = createAsyncThunk(
  'selectedVideo/restoreSegmentVersion',
  async (
    {
      segmentId,
      versionId,
    }: {
      segmentId: string | number;
      versionId: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const { file } = state.selectedVideo;

    const { editRecord, compactSegment } = await restoreTranscriptSegment({
      fileId: file._id,
      segmentId,
      versionId,
      teamId: file.teamId,
    });

    return {
      segmentId,
      editRecord,
      compactSegment,
    };
  }
);

const selectedVideoSlice = createSlice({
  name: 'selectedVideo',
  initialState,
  reducers: {
    selectVideoFromLibrary: (state, action: PayloadAction<FileType>) => {
      state.file = action.payload;
    },
    toggleSpeakerSelection: (state, action: PayloadAction<string>) => {
      const speakerId = action.payload;
      if (state.transcript.selectedSpeakerIds.includes(speakerId)) {
        state.transcript.selectedSpeakerIds = state.transcript.selectedSpeakerIds.filter(
          (id) => id !== speakerId
        );
      } else {
        state.transcript.selectedSpeakerIds.push(speakerId);
      }
    },
    // Trimmer
    setTrimmerVisibility: (state, action: PayloadAction<boolean>) => {
      state.actions.trim.visibility = action.payload;
    },
    // TODO: remove
    resetDownloadUrl: (state) => {
      state.actions.download.status = 'idle';
    },
    // Summary
    toggleSummaryEdit: (state) => {
      state.summary.isEditing = !state.summary.isEditing;
    },
    toggleChatView: (state) => {
      state.chat.view = state.chat.view === 'expanded' ? 'compact' : 'expanded';
    },
    cleanup: () => initialState,
    updateJobStatus: (
      state,
      action: PayloadAction<{
        position: number;
        timeToComplete: number;
        updatedAt: string;
        job: 'transcribe';
      }>
    ) => {
      const job = action.payload;
      state.jobs[job.job] = job;
    },
    setTemplateOutput: (
      state,
      action: PayloadAction<{
        templateId: string;
        promptTitle: string;
        responseText: string;
      }>
    ) => {
      const { templateId, responseText, promptTitle } = action.payload;
      state.postProcess.userPromptResponses[templateId] = {
        promptTitle,
        responseText,
      };
    },
    setSelectedSpeaker: (state, action: PayloadAction<string | null>) => {
      state.transcript.selectedSpeakerId = action.payload;
    },
    setShowNotesOnly: (state, action: PayloadAction<boolean>) => {
      state.transcript.showNotesOnly = action.payload;
    },
    setVideoMode: (state, action: PayloadAction<boolean>) => {
      state.chat.isVideoMode = action.payload;
    },
    resetSelectedVideo: () => initialState,
  },
  extraReducers: (builder) => {
    // Fetch File
    builder.addCase(fetchFile.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchFile.fulfilled, (state, action) => {
      const { file, jobs, transcript, chat, postProcess } = action.payload;
      const { systemPromptResponses, userPromptResponses } = postProcess || {};
      const {
        compact_segments: compactSegments,
        edit_records: editRecords,
        words,
      } = transcript || {};
      state.status = 'succeeded';
      state.file = file;
      state.jobs = jobs;
      state.chat.conversation = chat.conversation;
      state.chat.sessionId = chat.sessionId;
      state.transcript.words = words || [];
      state.transcript.compactSegments = (compactSegments || []).map(
        (segment) => ({
          ...segment,
          isEdited: false,
        })
      );
      state.transcript.editRecords = editRecords || {};
      state.summary.text = file.description || '';
      state.title.text = file.name || '';
      state.postProcess.systemPromptResponses = systemPromptResponses;
      state.postProcess.userPromptResponses = Object.fromEntries(
        Object.entries(userPromptResponses).map(([id, response]) => [
          id,
          {
            responseText: response.responseText,
            promptTitle: response.promptTitle || 'AI notes',
          },
        ])
      );
      // Suggestions are in `SUMMARY_AND_PROMPTS` in systemPromptResponses
      try {
        const { responseText } = systemPromptResponses?.SUMMARY_AND_PROMPTS || {
          responseText: '',
        };
        const summaryAndPrompt = JSON.parse(responseText) as {
          prompts?: SuggestionType[];
        };
        state.chat.suggestions = summaryAndPrompt?.prompts || [];
      } catch (error) {
        state.chat.suggestions = [];
        console.error('Error parsing suggestions', error);
      }
    });
    builder.addCase(fetchFile.rejected, (state) => {
      state.status = 'failed';
    });
    // Delete Template Output
    builder.addCase(deleteTemplateOutput.fulfilled, (state, action) => {
      const { templateId, fileId } = action.meta.arg;
      if (fileId === state.file._id) {
        delete state.postProcess.userPromptResponses[templateId];
      }
    });
    // Update Speaker
    builder.addCase(updateSpeaker.pending, (state, action) => {
      const payload = action.meta.arg;
      if (!state.file?.textData?.speakers) {
        state.file.textData = {
          ...state.file.textData,
          speakers: {},
        };
      }
      if (!state.file?.textData?.speakers) return;
      const { speakerId, speaker } = payload;
      const currentSpeaker = state.file.textData.speakers[speakerId];
      state.file.textData = {
        ...state.file.textData,
        speakers: {
          ...state.file?.textData?.speakers,
          [speakerId]: {
            ...currentSpeaker,
            ...speaker,
          },
        },
      };
    });
    builder.addCase(reprocessSelectedVideo.fulfilled, (state, action) => {
      const { name, description } = action.payload;
      state.file.name = name;
      state.file.description = description;
    });
    // Chat
    builder.addCase(askQuestion.pending, (state, action) => {
      const { question } = action.meta.arg;
      state.chat.conversation.push({
        role: 'user',
        content: question,
      } as ChatMessage);
      state.chat.status = 'pending';
    });
    builder.addCase(askQuestion.fulfilled, (state, action) => {
      state.chat.status = 'succeeded';
      state.chat.sessionId = action.payload.sessionId;
      state.chat.conversation.push(action.payload.answer);
    });
    builder.addCase(askQuestion.rejected, (state) => {
      state.chat.status = 'failed';
    });
    // Multimodal Chat
    builder.addCase(askMultimodalQuestion.pending, (state, action) => {
      const { promptText } = action.meta.arg;
      state.chat.conversation.push({
        role: 'user',
        content: promptText,
      } as ChatMessage);
      state.chat.status = 'pending';
    });
    builder.addCase(askMultimodalQuestion.fulfilled, (state, action) => {
      state.chat.status = 'succeeded';
      state.chat.sessionId = action.payload.sessionId;
      state.chat.conversation.push(action.payload.answer);
    });
    builder.addCase(askMultimodalQuestion.rejected, (state) => {
      state.chat.status = 'failed';
    });
    // Delete Ask AI Session
    builder.addCase(deleteAskAISession.fulfilled, (state) => {
      state.chat.sessionId = '';
      state.chat.conversation = [];
    });
    // Share
    builder.addCase(shareThunk.pending, (state) => {
      state.actions.share.status = 'pending';
    });
    builder.addCase(shareThunk.fulfilled, (state, action) => {
      state.actions.share.status = 'succeeded';
      const { shareId } = action.payload;
      state.file.share = {
        ...state.file.share,
        shareId,
      };
    });
    builder.addCase(shareThunk.rejected, (state) => {
      state.actions.share.status = 'failed';
    });
    // Download
    builder.addCase(downloadFile.pending, (state) => {
      state.actions.download.status = 'pending';
    });
    builder.addCase(downloadFile.fulfilled, (state) => {
      state.actions.download.status = 'succeeded';
    });
    builder.addCase(downloadFile.rejected, (state) => {
      state.actions.download.status = 'failed';
    });
    // Get team folders
    builder.addCase(getTeamFolders.pending, (state) => {
      state.actions.move.status = 'pending';
    });
    builder.addCase(
      getTeamFolders.fulfilled,
      (state, action: PayloadAction<FolderType[]>) => {
        state.actions.move.status = 'succeeded';
        state.actions.move.folders = action.payload;
      }
    );
    builder.addCase(getTeamFolders.rejected, (state) => {
      state.actions.move.status = 'failed';
    });
    // Move
    builder.addCase(moveFile.pending, (state) => {
      state.actions.move.status = 'pending';
    });
    builder.addCase(
      moveFile.fulfilled,
      (state, action: PayloadAction<FileType>) => {
        state.actions.move.status = 'succeeded';
        state.file = action.payload;
      }
    );
    builder.addCase(moveFile.rejected, (state) => {
      state.actions.move.status = 'failed';
    });
    // Delete
    builder.addCase(deleteFile.pending, (state) => {
      state.actions.delete.status = 'pending';
    });
    builder.addCase(deleteFile.fulfilled, (state) => {
      state.actions.delete.status = 'succeeded';
    });
    builder.addCase(deleteFile.rejected, (state) => {
      state.actions.delete.status = 'failed';
    });
    // Update Summary
    builder.addCase(updateSummary.pending, (state) => {
      state.summary.status = 'pending';
    });
    builder.addCase(updateSummary.fulfilled, (state, action) => {
      const text = action.payload || '';
      state.summary.text = text;
      state.file.description = text;
      state.summary.status = 'succeeded';
      state.summary.isEditing = false;
    });
    builder.addCase(updateSummary.rejected, (state) => {
      state.summary.status = 'failed';
      state.summary.text = state.file.description || '';
    });
    // Update Title
    builder.addCase(updateTitle.pending, (state) => {
      state.title.status = 'pending';
    });
    builder.addCase(updateTitle.fulfilled, (state, action) => {
      const text = action.payload || '';
      state.title.text = text;
      state.file.name = text;
      state.title.status = 'succeeded';
    });
    builder.addCase(updateTitle.rejected, (state) => {
      state.title.status = 'failed';
      state.title.text = state.file.name || '';
    });
    builder.addCase(trimFile.pending, (state) => {
      state.actions.trim.status = 'pending';
      state.actions.trim.visibility = false;
    });
    builder.addCase(trimFile.fulfilled, (state, action) => {
      state.actions.trim.status = 'succeeded';
      state.file = action.payload;
    });
    builder.addCase(trimFile.rejected, (state) => {
      state.actions.trim.status = 'failed';
    });
    // Save Enhanced Notes
    builder.addCase(saveEnhancedNotesThunk.fulfilled, (state, action) => {
      const { responseText } = action.payload;
      state.postProcess.systemPromptResponses.ENHANCED_NOTES = {
        promptTitle: 'Notes',
        responseText,
      };
    });
    // Save Notes
    builder.addCase(saveNotesThunk.fulfilled, (state, action) => {
      if (!state.file.textData) {
        state.file.textData = {};
      }
      state.file.textData.notes = action.payload.notes;
    });
    builder.addCase(setClipperVisibility, (state, action) => {
      state.clipperVisible = action.payload;
    });
    builder.addCase(updateSegmentText.fulfilled, (state, action) => {
      const { segmentId, editRecord, compactSegment } = action.payload;
      console.log('updateSegmentText', action.payload);
      const segmentIndex = state.transcript?.compactSegments.findIndex(
        (s: CompactSegment) => +s.id === +segmentId
      );

      if (segmentIndex === -1) {
        return;
      }

      state.transcript.compactSegments[segmentIndex] = compactSegment;

      if (!state.transcript.history) {
        state.transcript.history = [];
      }
      state.transcript.history.push(editRecord.id);

      if (!state.transcript.editRecords) {
        state.transcript.editRecords = {};
      }
      state.transcript.editRecords[editRecord.id] = editRecord;
    });
    builder.addCase(restoreSegmentVersion.fulfilled, (state, action) => {
      const { segmentId, editRecord, compactSegment } = action.payload;
      const segmentIndex = state.transcript?.compactSegments.findIndex(
        (s: CompactSegment) => +s.id === +segmentId
      );

      if (segmentIndex === -1) {
        return;
      }

      state.transcript.compactSegments[segmentIndex] = compactSegment;

      if (!state.transcript.history) {
        state.transcript.history = [];
      }
      state.transcript.history.push(editRecord.id);

      if (!state.transcript.editRecords) {
        state.transcript.editRecords = {};
      }
      state.transcript.editRecords[editRecord.id] = editRecord;
    });
  },
});

export const {
  selectVideoFromLibrary,
  cleanup,
  toggleSpeakerSelection,
  setTrimmerVisibility,
  resetDownloadUrl,
  toggleSummaryEdit,
  toggleChatView,
  updateJobStatus,
  setTemplateOutput,
  setSelectedSpeaker,
  setShowNotesOnly,
  setVideoMode,
  resetSelectedVideo,
} = selectedVideoSlice.actions;
export default selectedVideoSlice.reducer;
