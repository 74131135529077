/* eslint-disable import/prefer-default-export */

// TODO Ideally, we should fetch the prices from the server to avoid doing code
// changes every time a price updates. Prices should be environment variables
// in the backend.
export const planPricesPerMonth: Record<
  'GROWTH' | 'BUSINESS' | 'ENTERPRISE',
  Record<'annual' | 'monthly', number>
> = {
  GROWTH: {
    annual: 14, // = $168/year/user
    monthly: 30, // = $360/year/user
  },
  BUSINESS: {
    annual: 34, // = $408/year/user
    monthly: 69, // = $828/year/user
  },
  ENTERPRISE: {
    annual: 34, // = $408/year/user
    monthly: 69, // = $828/year/user
  },
};
