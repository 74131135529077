import React, { useState } from 'react';
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { X, Check, Rocket, Gift, Bell } from 'lucide-mui';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../reducers';
import logger from '../../../lib/logger';
import { requestStatusType } from '../../../types';
import { setupPayments } from '../../../services/subscriptionServices';
import { planPricesPerMonth } from '../../../constants/prices';
import { config } from '../../../../config';
import { createOrUpdateSubscription } from '../thunk';
import { setAlertMessage } from '../../dashboard/dashboardSlice';
import { pushModal } from '../../globalModals/globalModalsSlice';

const { stripePublishableKey } = config;
const stripePromise = loadStripe(stripePublishableKey, {
  betas: ['custom_checkout_beta_2'],
});

const getTrialChecks = (yearlyPrice: number): string[] => [
  'Free 3-day trial, cancel any time',
  "We'll remind you before your trial ends",
  `Flat rate of $${yearlyPrice} USD for your subscription`,
  'No charge today',
];

// Helper function to get relative dates
const getRelativeDate = (daysFromNow: number): string => {
  const date = new Date();
  date.setDate(date.getDate() + daysFromNow);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

// Add a due amounts configuration array
const getDueAmounts = (yearlyPrice: number) => [
  {
    label: `Due ${getRelativeDate(3)}`,
    amount: `$${yearlyPrice}`,
    isSuccess: false,
  },
  {
    label: 'Due today',
    note: '(3 days free)',
    amount: '$0',
    isSuccess: true,
    bold: true,
  },
];

const PaymentSection = ({ onClose }: { onClose: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [upgradeStatus, setUpgradeStatus] = useState<requestStatusType>('idle');

  const { team, settings } = useSelector((state: RootState) => state);
  const teamId = team.selectedTeam?.teamId || team.teams[0].teamId;
  const { email } = settings.user;

  const yearlyPrice = planPricesPerMonth.GROWTH.annual * 12; // Define yearlyPrice here

  const handleError = (error: any) => {
    logger.error(error);
    setUpgradeStatus('failed');
  };

  const handleStartTrial = async () => {
    if (!stripe || !elements) {
      logger.error('Something is wrong with stripejs or elements');
      return;
    }

    setUpgradeStatus('pending');

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    try {
      // Collect and verify the payment method
      const { clientSecret } = await setupPayments({
        // userId is implicitly passed with the jwt token
        teamId,
        metadata: {
          toltReferral: window.tolt_referral,
        },
      });

      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${config.appBaseUrl}`,
        },
        redirect: 'if_required',
      });

      if (error) {
        throw error;
      }

      const result = await dispatch(
        createOrUpdateSubscription({
          teamId,
          plan: 'GROWTH',
          term: 'annual',
          totalAmount: yearlyPrice,
          // Backend checks this and assigns the number of trial days
          trialLabel: '3 days free growth annual trial',
          metadata: {
            toltReferral: window.tolt_referral,
          },
        })
      );

      if (createOrUpdateSubscription.rejected.match(result as never)) {
        onClose();
        const { message = '' } = (result as any).error || {};
        throw new Error(message);
      }

      onClose();
      setUpgradeStatus('succeeded');
      dispatch(
        pushModal({
          type: 'upgradeSuccess',
        })
      );
    } catch (error) {
      setUpgradeStatus('failed');
      dispatch(
        setAlertMessage({
          type: 'error',
          message: `Failed to upgrade. Please try again later. Error: ${error.message}`,
        })
      );
      handleError(error);
    }
  };

  return (
    <Box sx={{ my: 1 }}>
      <PaymentElement
        id="stripe-payment-element"
        options={{
          defaultValues: {
            billingDetails: {
              email,
            },
          },
        }}
      />
      <Box sx={{ mb: 3, mt: 3 }}>
        {getDueAmounts(yearlyPrice).map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: index === 0 ? 2 : 0,
              color: item.isSuccess ? 'success.main' : 'inherit',
              '& > *:not(:last-child)': {
                mr: 2,
              },
            }}
          >
            <Typography>{item.label}</Typography>
            {item.note && <Typography sx={{ flex: 1 }}>{item.note}</Typography>}
            <Typography
              sx={{
                fontWeight: item.bold ? 'bold' : 'inherit',
              }}
            >
              {item.amount}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        variant="contained"
        fullWidth
        size="large"
        sx={{
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        onClick={handleStartTrial}
        disabled={upgradeStatus === 'pending'}
      >
        {upgradeStatus === 'pending' ? (
          'Processing...'
        ) : (
          <>
            <Gift
              sx={{
                width: 20,
                height: 20,
              }}
            />
            Get Free Trial
          </>
        )}
      </Button>
    </Box>
  );
};

interface FreeTrialProps {
  onClose: () => void;
}

// Create custom step icons
const CustomStepIcon = (IconComponent: React.FC, theme: any) => {
  return function CustomIcon() {
    const isFirstStep = IconComponent === Gift;
    return (
      <Box
        sx={{
          backgroundColor: isFirstStep
            ? theme.palette.primary.main
            : theme.palette.grey[300],
          borderRadius: '50%',
          width: 24,
          height: 24,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& > svg': {
            color: isFirstStep ? '#fff' : theme.palette.grey[700],
          },
        }}
      >
        <IconComponent />
      </Box>
    );
  };
};

// Add a steps configuration array
const TRIAL_STEPS = [
  {
    Icon: Gift,
    title: 'Today - Free trial for 3 days. Cancel anytime.',
    description: 'Start your free trial and get our most-loved features',
    isActive: true,
  },
  {
    Icon: Bell,
    title: getRelativeDate(2),
    description: "We'll send you a reminder before your trial ends",
  },
  {
    Icon: Rocket,
    title: getRelativeDate(3),
    description:
      "Your subscription starts, unless you've cancelled during the trial",
  },
];

const FreeTrial: React.FC<FreeTrialProps> = ({ onClose }) => {
  const theme = useTheme();

  // Simplify price calculations
  const yearlyPrice = planPricesPerMonth.GROWTH.annual * 12;

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullWidth
      data-testid="free-trial-modal"
      PaperProps={{
        sx: {
          borderRadius: 4,
          background: theme.palette.background.default,
          maxWidth: '900px !important',
          width: '100%',
          margin: { xs: 2, sm: 3 },
          border: `1px solid ${theme.palette.secondary.light}`,
        },
      }}
    >
      <Box
        sx={{
          fontFamily: 'Inter',
          position: 'relative',
        }}
      >
        {/* Header Section */}
        <Box sx={{ p: { xs: 2, sm: 3 }, pb: 1 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="bold">
              Start your free trial
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              sx={{
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <X />
            </IconButton>
          </Box>
        </Box>

        <Divider />

        {/* Content Section */}
        <Box
          sx={{
            p: { xs: 2, sm: 3 },
            display: 'flex',
            gap: 3,
            flexDirection: { xs: 'column-reverse', sm: 'row' },
          }}
        >
          {/* Left Column - Vertical Stepper */}
          <Box sx={{ flex: 1 }}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 4,
              }}
            >
              <Stepper
                activeStep={0}
                orientation="vertical"
                sx={{
                  '& .MuiStepLabel-root': {
                    py: 1.5,
                    px: 1.5,
                    mx: -1.5,
                    transition: 'all 0.2s ease-in-out',
                    borderRadius: 1.5,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                  },
                  '& .MuiStepLabel-iconContainer': {
                    pr: 2,
                    '& > *': {
                      transition: 'transform 0.2s ease-in-out',
                    },
                  },
                  '& .MuiStepLabel-root:hover .MuiStepLabel-iconContainer > *': {
                    transform: 'scale(1.1)',
                  },
                  '& .MuiStepConnector-line': {
                    minHeight: 24,
                    borderLeftWidth: 2,
                    borderColor: theme.palette.secondary.main,
                    ml: 2,
                    transition: 'border-color 0.2s ease-in-out',
                  },
                  '& .MuiStep-root:first-of-type .MuiStepConnector-line': {
                    background: `linear-gradient(#2A9134 50%, ${theme.palette.secondary.main} 50%)`,
                    border: 'none',
                    width: 2,
                  },
                  '& .MuiStepConnector-root': {
                    marginLeft: 0,
                  },
                  '& .MuiStepContent-root': {
                    borderLeft: '1px solid',
                    borderColor: theme.palette.secondary.main,
                    ml: 2,
                  },
                }}
              >
                {TRIAL_STEPS.map((step, index) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon(step.Icon, theme)}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'medium' }}
                      >
                        {step.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Box>

          {/* Right Column - Pricing & Trial Info */}
          <Box sx={{ flex: 1 }}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 4,
                mb: 3,
              }}
            >
              <Box sx={{ mb: 0 }}>
                {getTrialChecks(yearlyPrice).map((text, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      mb:
                        index === getTrialChecks(yearlyPrice).length - 1
                          ? 0
                          : 1,
                    }}
                  >
                    <Check
                      fontSize="small"
                      sx={{
                        color: 'success.main',
                        verticalAlign: 'middle',
                        mr: 1,
                      }}
                    />
                    {text}
                  </Typography>
                ))}
              </Box>
            </Paper>

            {/* Payment section Paper */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 4,
              }}
            >
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'setup',
                  currency: 'usd',
                  setupFutureUsage: 'off_session',
                }}
              >
                <PaymentSection onClose={onClose} />
              </Elements>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FreeTrial;
