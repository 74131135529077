import { apiV2 } from '../lib/baseAxios';
import { IVFSResponse } from './fileSystemService';

export interface InitGoogleCalendarIntegrationData {
  oauthUrl: string;
}

export const initGoogleCalendarIntegration = async (): Promise<InitGoogleCalendarIntegrationData> => {
  const response = await apiV2.get<
    IVFSResponse<InitGoogleCalendarIntegrationData>
  >(`/integrate/google/calendar/init`);
  return response.data.data;
};

export const disconnectGoogleCalendarIntegration = async (): Promise<
  string | undefined
> => {
  const response = await apiV2.post<IVFSResponse<unknown>>(
    `/integrate/google/calendar/disconnect`
  );
  return response.data.message;
};

export const disconnectZoomIntegration = async (): Promise<
  string | undefined
> => {
  const response = await apiV2.post<IVFSResponse<unknown>>(
    `/integrations/zoom/disconnect`
  );
  return response.data.message;
};

export const addUserWebhookUrl = async ({
  name,
  url,
  templateId,
}: {
  name: string;
  url: string;
  templateId: string;
}) => {
  return apiV2.post(`/integrations/webhook`, { name, url, templateId });
};

export const removeUserWebhookUrl = async ({ url }: { url: string }) => {
  return apiV2.delete(`/integrations/webhook`, { params: { url } });
};
