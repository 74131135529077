import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  LinearProgress,
  Alert,
  Snackbar,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import { X } from 'lucide-mui';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import { config } from '../../../config';
import { getCustomLogger } from '../../lib/logger';

const logger = getCustomLogger('Microtools: common');

// Gemini model configuration
export const GEMINI_MODEL = 'gemini-2.0-flash-lite';

// Common interfaces
export interface UploadState {
  uploading: boolean;
  progress: number;
  uploadComplete: boolean;
  error: string | null;
}

export interface CopyState {
  copySuccess: boolean;
}

export interface MediaRecorderState extends UploadState {
  isRecording: boolean;
}

export interface MediaStreamRefs {
  mediaRecorder: React.MutableRefObject<MediaRecorder | null>;
  chunks: React.MutableRefObject<Blob[]>;
}

// Custom hooks
export const useIframeResizer = () => {
  useEffect(() => {
    // The iframe-resizer content window script auto-initializes
  }, []);
};

// Common utilities
export const finishProcessing = (url: string, openInNewTab?: boolean) => {
  // Get the part after the hash
  const hashPart = window.location.hash.split('?')[1] || '';
  const urlParams = new URLSearchParams(hashPart);
  const intent = urlParams.get('intent') || '';

  const redirectUrl = `https://screenapp.io/app/#/dashboard?importUrl=${encodeURIComponent(
    url
  )}${intent ? `&intent=${encodeURIComponent(intent)}` : ''}`;

  if (openInNewTab) {
    window.open(redirectUrl, '_blank');
  } else {
    window.parent.location.href = redirectUrl;
  }
};

// Common API functions
export const sanitizeFileName = (fileName: string): string => {
  return fileName
    .replace(/-/g, '_') // Replace hyphens with underscores
    .replace(/#/g, '') // Remove hash symbols
    .replace(/[^a-zA-Z0-9._\s]/g, '') // Remove any other special characters
    .trim(); // Remove leading/trailing whitespace
};

export const uploadFileToS3 = async (
  blob: Blob,
  uploadUrl: string,
  downloadUrl: string,
  onProgress: (progress: number) => void,
  onComplete: () => void,
  openInNewTab?: boolean
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', uploadUrl, true);
  xhr.setRequestHeader('Content-Type', blob.type);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const progress = (event.loaded / event.total) * 100;
      onProgress(progress);
    }
  };

  xhr.onload = () => {
    if (xhr.status === 200) {
      onComplete();
      finishProcessing(downloadUrl, openInNewTab);
    }
  };

  xhr.send(blob);
};

export const uploadToScreenApp = async (
  blob: Blob,
  fileName: string,
  onStart: () => void,
  onProgress: (progress: number) => void,
  onComplete: () => void,
  onError: (error: string) => void,
  openInNewTab?: boolean
) => {
  onStart();

  try {
    const sanitizedFileName = sanitizeFileName(fileName);
    const response = await fetch(
      'https://api.screenapp.io/v2/temp-files/urls',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: sanitizedFileName }),
      }
    );

    const data = await response.json();
    if (data.success) {
      await uploadFileToS3(
        blob,
        data.data.uploadUrl,
        data.data.downloadUrl,
        onProgress,
        onComplete,
        openInNewTab
      );
    } else {
      throw new Error(data.message || 'Failed to get download URL');
    }
  } catch (error) {
    logger.error('Error uploading to screen app', error);
    onError('Upload failed. Please try again.');
  }
};

// Media handling utilities
export const initializeMediaRecorder = (
  mediaRecorder: React.MutableRefObject<MediaRecorder | null>,
  chunks: React.MutableRefObject<Blob[]>,
  onDataAvailable: (event: BlobEvent) => void
) => {
  const recorder = mediaRecorder.current;
  if (recorder) {
    recorder.ondataavailable = onDataAvailable;
    return Object.assign(chunks, { current: [] });
  }
  return chunks;
};

export const stopMediaRecording = (
  mediaRecorder: React.MutableRefObject<MediaRecorder | null>,
  streams: (MediaStream | null)[],
  animationFrame?: React.MutableRefObject<number | null>
) => {
  if (mediaRecorder.current) {
    mediaRecorder.current.stop();

    // Stop all tracks
    streams.forEach((stream) => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    });

    // Cancel animation frame if it exists
    if (animationFrame?.current) {
      cancelAnimationFrame(animationFrame.current);
    }
  }
};

// Text to Speech API
export const convertTextToSpeech = async (text: string): Promise<Blob> => {
  const response = await fetch('https://api.neets.ai/v1/tts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': 'c0d9aeeef6f14e98ad46c8d23e24535b',
    },
    body: JSON.stringify({
      text,
      voice_id: 'vits-eng-1',
      params: {
        model: 'vits',
      },
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to convert text to speech');
  }

  return response.blob();
};

// Common UI Components
interface ProgressModalProps {
  open: boolean;
  progress: number;
  isComplete: boolean;
  processingMessage?: string;
  completeMessage?: string;
  onReset?: () => void;
  onSaveAgain?: () => void;
  onClose?: () => void;
}

export const ProgressModal: React.FC<ProgressModalProps> = ({
  open,
  progress,
  isComplete,
  processingMessage = 'Please wait while we process your file',
  completeMessage = 'Your recording is now ready to be processed.',
  onReset,
  onSaveAgain,
  onClose,
}) => {
  const theme = useTheme();
  const { t: translate } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      hideBackdrop
      sx={{
        '& .MuiDialog-container': {
          p: 3,
        },
        '& .MuiDialog-paper': {
          m: { xs: 2, sm: 3 },
          width: 'calc(100% - 32px)',
          maxWidth: (t) => ({
            sm: `calc(${t.breakpoints.values.sm}px - 48px)`,
          }),
        },
      }}
      PaperProps={{
        sx: {
          p: 4,
          textAlign: 'center',
          borderRadius: '16px',
          border: `1px solid ${theme.palette.divider}`,
          bgcolor: theme.palette.background.paper,
          boxShadow: `0 8px 32px 0 ${
            theme.palette.mode === 'dark'
              ? 'rgba(0,0,0,0.35)'
              : 'rgba(0,0,0,0.15)'
          }`,
        },
      }}
    >
      <DialogTitle sx={{ pb: 3, pt: 0 }}>
        <Typography variant="h5" component="div" fontWeight="500">
          {isComplete
            ? translate('common.modal.processingComplete')
            : translate('common.modal.processing')}
        </Typography>
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: theme.palette.grey[500],
              transition: 'color 0.2s',
              '&:hover': {
                color: theme.palette.grey[700],
              },
            }}
          >
            <X />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {isComplete ? completeMessage : processingMessage}
        </Typography>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: 24,
              borderRadius: 12,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,0.12)'
                  : 'rgba(0,0,0,0.08)',
              '& .MuiLinearProgress-bar': {
                borderRadius: 12,
                backgroundColor: theme.palette.success.main,
                backgroundImage: `linear-gradient(90deg,
                  ${theme.palette.success.main},
                  ${theme.palette.success.light},
                  ${theme.palette.success.main})`,
                backgroundSize: '200% 100%',
                animation: 'shimmer 2s ease infinite',
              },
              '@keyframes shimmer': {
                '0%': {
                  backgroundPosition: '100% 0',
                },
                '100%': {
                  backgroundPosition: '-100% 0',
                },
              },
            }}
          />
          {progress >= 10 && (
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                top: '50%',
                left: `${progress / 2}%`,
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                fontWeight: 600,
                fontSize: '0.875rem',
                textShadow: '0 1px 2px rgba(0,0,0,0.2)',
                transition: 'left 0.3s ease',
                whiteSpace: 'nowrap',
                pointerEvents: 'none',
              }}
            >
              {Math.round(progress)}%
            </Typography>
          )}
        </Box>
      </DialogContent>
      {!completeMessage?.includes('redirect') && (
        <DialogActions
          sx={{ px: 3, pb: 3, pt: 1, justifyContent: 'center', gap: 2 }}
        >
          {isComplete && onSaveAgain && (
            <Button
              onClick={onSaveAgain}
              variant="contained"
              color="primary"
              sx={{
                px: 3,
                py: 1,
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: (t) => `0 4px 12px ${t.palette.primary.main}40`,
                },
              }}
            >
              {translate('common.modal.saveAnother')}
            </Button>
          )}
          {isComplete && onReset && (
            <Button
              onClick={onReset}
              variant="outlined"
              sx={{
                px: 3,
                py: 1,
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
            >
              {translate('common.modal.reset')}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

interface FeedbackSnackbarProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
  autoHideDuration?: number;
  onClose: () => void;
}

export const FeedbackSnackbar: React.FC<FeedbackSnackbarProps> = ({
  open,
  message,
  severity,
  autoHideDuration = 6000,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        '& .MuiAlert-root': {
          bgcolor: theme.palette[severity].main,
          color: theme.palette[severity].contrastText,
        },
      }}
    >
      <Alert severity={severity} variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export const callGeminiAPI = async (prompt: string): Promise<string> => {
  try {
    const response = await fetch(config.geminiProxyUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: GEMINI_MODEL,
        contents: [
          {
            parts: [{ text: prompt }],
          },
        ],
      }),
    });

    if (!response.ok) {
      throw new Error(`Gemini API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Gemini API response:', data);

    const result = data.candidates[0].content.parts[0].text;
    console.log('Generated result:', {
      resultLength: result.length,
      previewStart: `${result.substring(0, 100)}...`,
    });

    return result;
  } catch (error) {
    console.error('Error calling Gemini API:', error);
    throw error;
  }
};
