import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Dialog,
  Button,
  MobileStepper,
  useTheme,
  useMediaQuery,
  Fade,
  Slide,
  Grow,
  IconButton,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import {
  Users,
  User,
  GraduationCap,
  Upload,
  Radio,
  Mic,
  Smartphone,
  Calendar,
  Puzzle,
  Folder,
  ArrowLeftRight,
  ArrowLeft,
  ArrowRight,
  Youtube,
  Tv,
} from 'lucide-mui';
import type { GlobalDialogProps, PackageType } from '../../../../types';
import UpgradeView from '../../../plan/upgrade/upgradeView';
import useTags from '../../../../hooks/useTags';
import {
  ONBOARDING_STEP_TAG_KEY,
  ONBOARDING_STEP_TAG_VALUE,
} from '../../../../constants/tags';

const ANIMATION_DURATION = 400;

const importOptions = [
  {
    value: 'import-from-url',
    icon: Upload,
    label: 'Upload from Link',
  },
  {
    value: 'import-from-youtube',
    icon: Youtube,
    label: 'Import YouTube Video',
  },
  {
    value: 'record-screen',
    icon: Radio,
    label: 'Record Screen',
  },
  { value: 'record-audio', icon: Mic, label: 'Record Audio' },
  {
    value: 'join-meeting',
    icon: Calendar,
    label: 'Record with Meeting Bot',
  },
  { value: 'record-phone', icon: Smartphone, label: 'Record from Phone' },
  {
    value: 'chrome-extension',
    icon: Puzzle,
    label: 'Use Chrome Extension',
  },
  {
    value: 'record-livestream',
    icon: Tv,
    label: 'Record Live Stream',
  },
  { value: 'local-files', icon: Folder, label: 'Upload from Computer' },
  {
    value: 'video-library',
    icon: ArrowLeftRight,
    label: 'Import from Platform',
  },
];

const goalOptions = [
  {
    icon: <User sx={{ fontSize: 48, mb: 2 }} />,
    title: 'For Personal Use',
    description: 'Get AI summaries of your meetings and recordings',
    tagValue: 'personal',
  },
  {
    icon: <Users sx={{ fontSize: 48, mb: 2 }} />,
    title: 'For Business',
    description: 'Share and review recordings with your team',
    tagValue: 'business',
  },
  {
    icon: <GraduationCap sx={{ fontSize: 48, mb: 2 }} />,
    title: 'For Education',
    description: 'Organize lectures and discussions with AI notes',
    tagValue: 'education',
  },
];

const commonButtonStyles = {
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
    borderColor: 'primary.main',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
};

const nextButtonStyles = {
  minWidth: 200,
  textTransform: 'none',
  fontSize: '1.1rem',
  mb: 2,
  position: 'sticky',
  bottom: 16,
  zIndex: 1,
};

interface FileType {
  _id: string;
  parentId: string | null;
  streams?: string[];
}

interface AppState {
  library: {
    files: {
      data: FileType[];
    };
  };
  team: {
    selectedTeam?: {
      teamId: string;
    };
  };
  import: {
    status: string;
  };
  plan: {
    subscription: {
      package: PackageType;
    };
  };
}

export const OnboardingModal = ({ onClose }: GlobalDialogProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setTag } = useTags();
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedImports, setSelectedImports] = useState<string[]>([]);

  const files = useSelector((state: AppState) => state.library.files.data);
  const currentTeamId = useSelector(
    (state: AppState) => state.team.selectedTeam?.teamId
  );
  const importStatus = useSelector((state: AppState) => state.import.status);
  const currentPackage = useSelector(
    (state: AppState) => state.plan.subscription.package
  );

  const showPaymentStep = currentPackage === 'FREE_LOGGEDIN';

  const steps = showPaymentStep
    ? ['Welcome', 'Your Goals', 'Import Methods', 'Choose a Plan']
    : ['Welcome', 'Your Goals', 'Import Methods'];

  const handleClose = () => {
    if (selectedImports.length > 0) {
      dispatch({
        type: 'onboarding/trackImportOptions',
        payload: { importOptions: selectedImports },
      });
    }

    setTag({
      key: ONBOARDING_STEP_TAG_KEY,
      value: ONBOARDING_STEP_TAG_VALUE,
    });

    if (onClose) {
      onClose();
    }

    const firstVideo = files.find(
      (file: FileType) =>
        file.streams?.includes('video') || file.streams?.includes('audio')
    );

    if (firstVideo && currentTeamId) {
      const parentId = firstVideo.parentId || 'default';
      navigate(`/library/${currentTeamId}/${parentId}/${firstVideo._id}`);
    }
  };

  const handleImportToggle = (value: string) => {
    setSelectedImports((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      }
      return [...prev, value];
    });
  };

  const handleGoalSelect = (goalValue: string) => {
    dispatch({
      type: 'onboarding/trackGoal',
      payload: { goal: goalValue },
    });
    setTag({
      key: 'userGoal',
      value: goalValue,
    });
    setActiveStep((prev) => prev + 1);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Fade in timeout={ANIMATION_DURATION}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                mb: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                minHeight: '400px',
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 300, mb: 2 }}>
                {importStatus === 'succeeded' || importStatus === 'pending'
                  ? 'Your Recording is ready'
                  : 'Welcome to ScreenApp'}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ mb: 3, textAlign: 'center' }}
              >
                {importStatus === 'succeeded' || importStatus === 'pending'
                  ? "But first, let's take a quick tour"
                  : "Let's take a quick tour"}
              </Typography>
              <Box
                sx={{
                  width: '70%',
                  maxWidth: '800px',
                  position: 'relative',
                  margin: '0 auto',
                  '&::before': {
                    content: '""',
                    display: 'block',
                    paddingTop: '56.25%',
                  },
                }}
              >
                <Box
                  component="iframe"
                  src="https://www.youtube.com/embed/a3L4K7Y8E54?autoplay=0&rel=0&modestbranding=1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                  title="ScreenApp Demo"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                    borderRadius: 1,
                  }}
                />
              </Box>
            </Box>
          </Fade>
        );
      case 1:
        return (
          <Fade in timeout={ANIMATION_DURATION}>
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                mb: 4,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 300, mb: 2 }}>
                How do you want to use ScreenApp?
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ mb: 4, textAlign: 'center' }}
              >
                This helps customize your experience
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? 1.5 : 3,
                  maxWidth: '1000px',
                  margin: '0 auto',
                  flex: 1,
                  alignItems: 'center',
                }}
              >
                {goalOptions.map((option, index) => (
                  <Grow
                    key={option.title}
                    in
                    timeout={ANIMATION_DURATION + index * 200}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleGoalSelect(option.tagValue)}
                      color="secondary"
                      sx={{
                        ...commonButtonStyles,
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: isMobile ? 1.5 : 3,
                        textAlign: 'center',
                        borderRadius: 2,
                        minHeight: {
                          xs: '140px',
                          sm: '160px',
                          md: '250px',
                        },
                        width: '100%',
                      }}
                    >
                      {React.cloneElement(option.icon, {
                        sx: {
                          fontSize: {
                            xs: 32,
                            sm: 36,
                            md: 48,
                          },
                          mb: {
                            xs: 0.5,
                            sm: 1,
                            md: 2,
                          },
                        },
                      })}
                      <Typography
                        variant={isMobile ? 'body1' : 'h6'}
                        sx={{
                          mb: { xs: 0.25, sm: 0.5 },
                          fontSize: {
                            xs: '0.9rem',
                            sm: 'inherit',
                          },
                        }}
                      >
                        {option.title}
                      </Typography>
                      <Typography
                        variant={isMobile ? 'body2' : 'body1'}
                        color="text.secondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: 'inherit',
                          },
                        }}
                      >
                        {option.description}
                      </Typography>
                    </Button>
                  </Grow>
                ))}
              </Box>
            </Box>
          </Fade>
        );
      case 2:
        return (
          <Fade in timeout={ANIMATION_DURATION}>
            <Box sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
              <Typography variant="h4" sx={{ fontWeight: 300, mb: 2 }}>
                How do you collect your content?
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ mb: 4, textAlign: 'center' }}
              >
                Select all that apply
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: '1fr',
                    md: '1fr 1fr',
                  },
                  gap: 2,
                  maxWidth: '1000px',
                  margin: '0 auto',
                  maxHeight: isMobile ? 'calc(100vh - 300px)' : 'none',
                  overflowY: isMobile ? 'auto' : 'visible',
                  px: 2,
                }}
              >
                {importOptions.map((option, index) => {
                  const Icon = option.icon;
                  const isSelected = selectedImports.includes(option.value);
                  return (
                    <Grow
                      key={option.value}
                      in
                      timeout={ANIMATION_DURATION + index * 200}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleImportToggle(option.value)}
                        color={isSelected ? 'primary' : 'secondary'}
                        sx={{
                          ...commonButtonStyles,
                          height: isMobile ? '48px' : '60px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          padding: isMobile ? 2 : 3,
                          textAlign: 'left',
                          borderRadius: 2,
                          gap: 2,
                        }}
                      >
                        <Icon sx={{ fontSize: isMobile ? 24 : 32 }} />
                        <Typography variant={isMobile ? 'body1' : 'h6'}>
                          {option.label}
                        </Typography>
                      </Button>
                    </Grow>
                  );
                })}
              </Box>
            </Box>
          </Fade>
        );
      case 3:
        return showPaymentStep ? (
          <Fade in timeout={ANIMATION_DURATION}>
            <Box>
              <Typography
                variant="h4"
                sx={{ textAlign: 'center', fontWeight: 300 }}
              >
                Find the Right Plan for You
              </Typography>
              <Slide direction="up" in timeout={ANIMATION_DURATION}>
                <Box>
                  <UpgradeView />
                </Box>
              </Slide>
            </Box>
          </Fade>
        ) : null;
      default:
        return null;
    }
  };

  const SlideTransition = React.useMemo(
    () =>
      React.forwardRef(function TransitionComponent(
        props: TransitionProps & {
          children: React.ReactElement;
        },
        ref: React.Ref<unknown>
      ) {
        return isMobile ? (
          <Slide direction="up" ref={ref} {...props} />
        ) : (
          <Fade ref={ref} {...props} timeout={ANIMATION_DURATION} />
        );
      }),
    [isMobile]
  );

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={SlideTransition}
      PaperProps={{
        sx: {
          m: isMobile ? 0 : 2,
          borderRadius: isMobile ? 0 : 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
          transition: 'all 0.3s ease-in-out',
          maxHeight: isMobile ? '100vh' : '90vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          p: { xs: 2, sm: 4 },
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: { xs: 2, sm: 4 },
            position: 'relative',
          }}
        >
          <Box sx={{ width: 40, display: 'flex', justifyContent: 'center' }}>
            {activeStep > 0 ? (
              <IconButton
                onClick={() => setActiveStep((prev) => prev - 1)}
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'text.primary',
                  },
                }}
              >
                <ArrowLeft />
              </IconButton>
            ) : (
              <Box sx={{ width: 40 }} />
            )}
          </Box>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{
              height: '40px',
              background: 'transparent',
              flex: 1,
              justifyContent: 'center',
              padding: 0,
              '& .MuiMobileStepper-dots': {
                gap: '8px',
              },
              '& .MuiMobileStepper-dot': {
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
                margin: 0,
                '&.MuiMobileStepper-dotActive': {
                  backgroundColor: 'primary.main',
                  transform: 'scale(1.2)',
                },
              },
            }}
            nextButton={null}
            backButton={null}
            onClick={(e) => {
              const dots = e.currentTarget.querySelectorAll(
                '.MuiMobileStepper-dot'
              );
              const clickedDotIndex = Array.from(dots).indexOf(
                e.target as Element
              );
              if (clickedDotIndex !== -1) {
                setActiveStep(clickedDotIndex);
              }
            }}
          />
          {showPaymentStep && activeStep === 3 && (
            <Button
              onClick={handleClose}
              color="inherit"
              sx={{
                position: 'absolute',
                right: 0,
                textTransform: 'none',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  transform: 'scale(1.05)',
                },
              }}
            >
              Later
            </Button>
          )}
          <Box sx={{ width: 40 }} />
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            pb: {
              xs:
                (showPaymentStep
                  ? activeStep < 3
                  : activeStep < steps.length) &&
                activeStep !== 1 &&
                (activeStep !== 2 || selectedImports.length > 0)
                  ? 2
                  : 0,
              sm: 4,
            },
          }}
        >
          {renderStepContent(activeStep)}
        </Box>

        {(showPaymentStep ? activeStep < 3 : activeStep < steps.length) &&
          activeStep !== 1 &&
          (activeStep !== 2 || selectedImports.length > 0) && (
            <Fade in timeout={ANIMATION_DURATION}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'background.paper',
                  position: 'sticky',
                  bottom: 0,
                  borderTop: 1,
                  borderColor: 'divider',
                  py: { xs: 1.5, sm: 2 },
                }}
              >
                <Button
                  onClick={() => {
                    if (!showPaymentStep && activeStep === 2) {
                      handleClose();
                    } else {
                      setActiveStep((prev) => prev + 1);
                    }
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={activeStep === 2 && selectedImports.length === 0}
                  sx={{
                    ...nextButtonStyles,
                    mb: 0,
                  }}
                  endIcon={<ArrowRight />}
                >
                  {!showPaymentStep && activeStep === 2 ? 'Done' : 'Next'}
                </Button>
              </Box>
            </Fade>
          )}
      </Box>
    </Dialog>
  );
};

export default OnboardingModal;
