// To do: Replace with auth reducer

/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import { requestStatusType } from '../../types';
import {
  forgotPassword,
  resetPassword,
  userSignInRequest,
  userSignUpRequest,
} from './authThunks';

interface AuthState {
  signIn: {
    status: requestStatusType;
    errorMessage?: string;
  };
  signUp: {
    status: requestStatusType;
  };
  forgotPassword: {
    status: requestStatusType;
  };
  resetPassword: {
    status: requestStatusType;
  };
  deleteAccount: {
    isDialogOpen: boolean;
  };
}

const initialState = {
  signIn: {
    status: 'idle',
  },
  signUp: {
    status: 'idle',
  },
  forgotPassword: {
    status: 'idle',
  },
  resetPassword: {
    status: 'idle',
  },
  deleteAccount: {
    isDialogOpen: false,
  },
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setDeleteAccountDialogOpen: (state, action: { payload: boolean }) => {
      state.deleteAccount.isDialogOpen = action.payload;
    },
    signOut: () => {
      // Reset state to initial
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPassword.status = 'pending';
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPassword.status = 'succeeded';
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPassword.status = 'failed';
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.status = 'pending';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPassword.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPassword.status = 'failed';
      })
      .addCase(userSignInRequest.pending, (state) => {
        state.signIn.status = 'pending';
      })
      .addCase(userSignInRequest.fulfilled, (state) => {
        state.signIn.status = 'succeeded';
      })
      .addCase(userSignInRequest.rejected, (state, action) => {
        state.signIn.status = 'failed';
        state.signIn.errorMessage = action.error.message as string;
      })
      .addCase(userSignUpRequest.pending, (state) => {
        state.signUp.status = 'pending';
      })
      .addCase(userSignUpRequest.fulfilled, (state) => {
        state.signUp.status = 'succeeded';
      })
      .addCase(userSignUpRequest.rejected, (state) => {
        state.signUp.status = 'failed';
      });
  },
});

export const { setDeleteAccountDialogOpen, signOut } = authSlice.actions;
export default authSlice.reducer;
