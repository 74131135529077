import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import type { LoadingButtonProps } from '@mui/lab';

export interface ActionButtonProps extends LoadingButtonProps {
  tooltipMessage?: string;
  flashTooltip?: boolean;
  'data-testid'?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  tooltipMessage,
  flashTooltip = false,
  ...otherProps
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (flashTooltip) {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
    }
  }, [flashTooltip]);

  if (!tooltipMessage) {
    return <LoadingButton color="success" {...otherProps} />;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      disableFocusListener={!flashTooltip}
      disableHoverListener={!flashTooltip}
      disableTouchListener={!flashTooltip}
    >
      <LoadingButton
        color="success"
        {...otherProps}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      />
    </Tooltip>
  );
};

export default ActionButton;
