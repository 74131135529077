import { scan } from 'react-scan'; // must be imported before React and React DOM
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client' instead of 'react-dom'
import { Provider } from 'react-redux';
import { Box } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import debug from 'debug';
import theme from './theme/default';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import { store } from './store';
import { config } from '../config';
// import GlobalErrorBoundary from './GlobalErrorBoundary';
import { reportError } from './lib/errorReports';
import { FeatureFlagProvider } from './providers/FeatureFlagsProvider';
import { cleanupOldBackups } from './lib/recovery';

scan({
  enabled: config.enableReactScan,
});

debug.enable(config.debug);
const log = debug('app:index');

window.displayVersionInfo = () => {
  const buildTime = new Date(config.buildTime);

  log(
    `%cScreenApp ${config.appEnv}`,
    'font-size: 20px; background-color: black; font-weight: bold; color: white;'
  );

  log(
    `%cBuild Time: ${buildTime}`,
    'font-size: 14px; background-color: black; font-weight: bold; color: white;'
  );

  log(
    `%c${config.version}%c-${config.appEnv.toLocaleLowerCase()}%c+${
      config.commit
    }`,
    'font-size: 20px; background-color: black; color: #db2777;',
    'font-size: 20px; background-color: black; color: #f472b6;',
    'font-size: 20px; background-color: black; color: #fbcfe8;'
  );
};

window.displayVersionInfo();

window.onerror = function handleGlobalError(
  message,
  source,
  lineno,
  colno,
  error
) {
  // SA-991 No effect from this error
  if (message !== 'ResizeObserver loop limit exceeded') {
    reportError('window.onerror', {
      message,
      source,
      lineno,
      colno,
      error,
    });
  }

  // return true to indicate the error was handled
  return false;
};

window.onunhandledrejection = function handleGlobalError(event) {
  const error = event.reason;

  reportError('window.onunhandledrejection', {
    // Error message or whatever in here is lost when passing to reportError
    error,
  });

  return false;
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}
const root = ReactDOM.createRoot(rootElement); // Create a root using ReactDOM.createRoot

root.render(
  // <GlobalErrorBoundary>
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FeatureFlagProvider>
          <App />
          <Box id="screenapp-recorder" />
        </FeatureFlagProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
  // </GlobalErrorBoundary>
);

cleanupOldBackups();

// Register service worker
serviceWorkerRegistration.register();
