import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { Link, Tooltip, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PanelLeft } from 'lucide-mui';
import DrawerActions from './DrawerActions';
import { setDrawerState } from '../dashboardSlice';
import FolderTree from '../../library/folders';
import { RootState } from '../../../reducers';
import { DRAWER_WIDTH } from '../../../constants/ui';
import QuickActions from './QuickActions';
import Logo from '../../../assets/icons/new_logo.svg';
import { getFolderUrl } from '../../../lib/routingUtils';
import { useTeamTags } from '../../../hooks/useTags';
import { TEAM_LOGO_URL_KEY } from '../../../constants/tags';

const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  '& .toggle-icon-wrapper': {
    position: 'relative',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .toggle-icon': {
    position: 'absolute',
    transition: theme.transitions.create('opacity', {
      duration: 100,
      easing: theme.transitions.easing.easeOut,
    }),
    opacity: 0,
    visibility: 'hidden',
  },
  '& .toggle-icon-open': {
    left: 0,
  },
  '& .toggle-icon-closed': {
    right: 0,
  },
  '&.open .toggle-icon-open': {
    opacity: 1,
    visibility: 'visible',
  },
  '&:not(.open) .toggle-icon-closed': {
    opacity: 1,
    visibility: 'visible',
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.down('md')]: {
    width: 0,
    '& .MuiDrawer-paper': {
      position: 'fixed',
      height: '100%',
      zIndex: theme.zIndex.drawer + 200,
      width: DRAWER_WIDTH,
      top: 0,
      left: 0,
      transform: 'translateX(-100%)',
      visibility: 'hidden',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create(['transform', 'visibility'], {
        easing: theme.transitions.easing.easeOut,
        duration: 200,
      }),
      ...(open && {
        transform: 'none',
        visibility: 'visible',
      }),
    },
  },
  [theme.breakpoints.up('md')]: {
    width: open ? DRAWER_WIDTH : `calc(${theme.spacing(7)})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: 200,
    }),
    '& .MuiDrawer-paper': {
      width: open ? DRAWER_WIDTH : `calc(${theme.spacing(7)})`,
      position: 'fixed',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: 200,
      }),
      overflowX: 'hidden',
    },
  },
}));

const DrawerContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  position: 'sticky',
  top: 0,
}));

const ScrollableContent = styled(Box)(() => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const LogoWrapper = styled(Box)(() => ({
  width: '2.3rem',
  height: '2.3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '8px',
}));

const BottomActions = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
}));

const CustomDrawer = () => {
  const dispatch = useDispatch();
  const { dashboard, team } = useSelector((state: RootState) => ({
    dashboard: state.dashboard,
    team: state.team,
  }));
  const { getTeamTag } = useTeamTags();
  const teamLogoUrl = getTeamTag(TEAM_LOGO_URL_KEY);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const open = dashboard.drawerState === 'open';
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const handleDrawerClose = () => {
    dispatch(setDrawerState('closed'));
  };

  // Effect to handle initial drawer state - only close on mobile
  React.useEffect(() => {
    if (isMobile) {
      dispatch(setDrawerState('closed'));
    }
  }, []);

  // Effect to handle window resize - close on mobile, open on desktop
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < theme.breakpoints.values.md) {
        dispatch(setDrawerState('closed'));
      } else {
        dispatch(setDrawerState('open'));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [theme.breakpoints.values.md]);

  const getRecentsUrl = () => {
    const teamId = team.selectedTeam?.teamId || team.teams[0]?.teamId;
    return getFolderUrl({
      teamId,
      folderId: 'recents',
      withBasePath: true,
    });
  };

  return (
    <>
      {isMobile && (
        <Backdrop
          open={open}
          onClick={handleDrawerClose}
          sx={{
            zIndex: theme.zIndex.drawer + 150,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
      )}
      <Drawer variant="permanent" open={open}>
        <DrawerContent>
          <DrawerHeader open={open} className={open ? 'open' : ''}>
            <LogoWrapper>
              <Link href={getRecentsUrl()}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
                >
                  {teamLogoUrl ? (
                    <Avatar
                      src={teamLogoUrl}
                      alt="Team Logo"
                      variant="square"
                      sx={{
                        height: 30,
                        maxWidth: 120,
                        width: 'auto',
                        bgcolor: 'transparent',
                        '& img': {
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',
                        },
                      }}
                    />
                  ) : (
                    <img src={Logo} alt="Logo" width="30px" />
                  )}
                </Box>
              </Link>
            </LogoWrapper>
            {!isMobile && (
              <Tooltip title={open ? 'Close Sidebar' : 'Open Sidebar'} arrow>
                <div className="toggle-icon-wrapper">
                  <IconButton
                    onClick={handleDrawerClose}
                    className="toggle-icon toggle-icon-open"
                  >
                    <PanelLeft />
                  </IconButton>
                  <IconButton
                    onClick={handleDrawerClose}
                    className="toggle-icon toggle-icon-closed"
                  >
                    <PanelLeft style={{ transform: 'rotate(180deg)' }} />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </DrawerHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 64px)',
            }}
          >
            <ScrollableContent ref={scrollableRef}>
              <QuickActions />
              <FolderTree />
            </ScrollableContent>
            <BottomActions>
              <DrawerActions />
            </BottomActions>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
