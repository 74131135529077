import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import FileCardSkeleton from './FileCardSkeleton';
import { RootState } from '../../../../../reducers';

const FilesSkeleton: React.FC = () => {
  const { library } = useSelector((state: RootState) => state);
  const { status } = library.files;
  if(status !== 'pending') {
    return null
  }
  return (
    <>
      {[...Array(20)].map((_, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2} data-testid="file-card-skeleton">
          <FileCardSkeleton />
        </Grid>
      ))}
    </>
  );
};

export default FilesSkeleton;