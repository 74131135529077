import React, { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { popModal } from './globalModalsSlice';
import InviteLinkDialog from '../team/invite/inviteByLinkModal';
import CreateOrUpdateFolder from '../library/folders/CreateOrUpdateFolderDialog';
import DeleteFilesModal from '../selectedVideo/actions/modals/DeleteFileModal';
import type { IModalTypes } from './globalModalsSlice';
import RenameFolderModal from '../library/folders/actions/RenameFolderModal';
import DeleteFolderModal from '../library/folders/actions/DeleteFolderModal';
import MoveFileModal from '../selectedVideo/actions/modals/MoveFileModal';
import UploadModal from '../upload/UploadModal';
import ImportModal from '../import/ImportModal';
import EmbedModal from '../selectedVideo/actions/modals/Embed';
import OnboardingModal from '../library/files/onboarding';
import { RootState } from '../../reducers';
import UpgradeSuccessDialog from '../plan/upgrade/UpgradeSuccessDialog';
import UpSellModal from '../dashboard/toolbar/UpSellModal';
import ConnectGoogleCalendarModal from '../settings/meetbot/ConnectGoogleCalendarModal';
import FreeTrial from '../plan/upgrade/FreeTrial';
import LoaderComponent from '../app/loader/LoaderReact';

const LazyUpgradeModal = lazy(() => import('../plan/upgrade/UpgradeModal'));

const GlobalModalManager = () => {
  const dispatch = useDispatch();
  const modals = useSelector(
    (state: RootState) => state.globalModals.modalStack
  );
  const openModal = modals[modals.length - 1];
  const onClose = () => dispatch(popModal());
  const getOpenModal = (modalType: IModalTypes) => {
    switch (modalType) {
      case 'stripe-pricing-table-upgrade-modal':
        return (
          <Suspense fallback={<LoaderComponent />}>
            <LazyUpgradeModal onClose={onClose} />
          </Suspense>
        );
      case 'import-videos-modal':
        return <UploadModal onClose={onClose} />;
      case 'import-urls-modal':
        return <ImportModal onClose={onClose} />;
      case 'move-file-modal':
        return <MoveFileModal onClose={onClose} />;
      case 'delete-files-modal':
        return <DeleteFilesModal onClose={onClose} />;
      case 'invite-by-link-modal':
        return <InviteLinkDialog onClose={onClose} />;
      case 'create-folder-modal':
        return <CreateOrUpdateFolder onClose={onClose} />;
      case 'rename-folder-modal':
        return <RenameFolderModal onClose={onClose} />;
      case 'delete-folder-modal':
        return <DeleteFolderModal onClose={onClose} />;
      case 'embed-file-modal':
        return <EmbedModal onClose={onClose} />;
      case 'onboarding-modal':
        return <OnboardingModal onClose={onClose} />;
      case 'upgradeSuccess':
        return <UpgradeSuccessDialog onClose={onClose} />;
      case 'upsell-modal':
        return <UpSellModal onClose={onClose} />;
      case 'connect-google-calendar-modal':
        return <ConnectGoogleCalendarModal onClose={onClose} />;
      case 'free-trial-modal':
        return <FreeTrial onClose={onClose} />;
      default:
        return null;
    }
  };

  return getOpenModal(openModal);
};

export default GlobalModalManager;
