import React from 'react';
import {
  MenuItem,
  Popover,
  MenuList,
  Box,
  Typography,
  Divider,
  useTheme,
} from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import { SortAsc, Clock, ArrowUp, ArrowDown, Timer } from 'lucide-mui';
import { SortBy, SortOrderBy } from '../../../types';
import { useTags } from '../../../hooks/useTags';
import {
  LIBRARY_SORT_BY_KEY,
  LIBRARY_SORT_ORDER_KEY,
} from '../../../constants/tags';

interface SortComponentProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

interface SortCategory {
  type: SortBy;
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  descLabel: string;
  ascLabel: string;
}

const sortCategories: SortCategory[] = [
  {
    type: 'createdAt',
    label: 'Date',
    icon: Clock,
    descLabel: 'Newest first',
    ascLabel: 'Oldest first',
  },
  {
    type: 'name',
    label: 'Name',
    icon: SortAsc,
    descLabel: 'Z to A',
    ascLabel: 'A to Z',
  },
  {
    type: 'duration',
    label: 'Duration',
    icon: Timer,
    descLabel: 'Longest first',
    ascLabel: 'Shortest first',
  },
];

const SortComponent: React.FC<SortComponentProps> = ({ anchorEl, onClose }) => {
  const theme = useTheme();
  const { getTag, setTag } = useTags();
  const sortBy = (getTag(LIBRARY_SORT_BY_KEY) as SortBy) || 'createdAt';
  const sortOrder = (getTag(LIBRARY_SORT_ORDER_KEY) as SortOrderBy) || 'desc';

  const open = Boolean(anchorEl);
  const id = open ? 'sort-popover' : undefined;

  const handleSort = (type: SortBy, order: SortOrderBy) => {
    setTag({ key: LIBRARY_SORT_BY_KEY, value: type });
    setTag({ key: LIBRARY_SORT_ORDER_KEY, value: order });
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        elevation: 3,
        sx: {
          mt: 1,
          borderRadius: 2,
          overflow: 'hidden',
        },
      }}
    >
      <MenuList
        sx={{
          width: 220,
          p: 1,
        }}
      >
        {sortCategories.map(
          ({ type, label, icon: Icon, descLabel, ascLabel }, index) => (
            <React.Fragment key={type}>
              {index > 0 && <Divider sx={{ my: 1 }} />}
              <Box sx={{ py: 0.5 }}>
                <Box
                  sx={{
                    px: 1.5,
                    py: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 18,
                      color: theme.palette.text.secondary,
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 600,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
                <MenuItem
                  id="sort-desc"
                  onClick={() => handleSort(type, 'desc')}
                  selected={sortBy === type && sortOrder === 'desc'}
                  sx={{
                    minHeight: 36,
                    borderRadius: 2,
                    px: 1.5,
                    py: 0.5,
                    my: 0.5,
                    position: 'relative',
                    overflow: 'hidden',
                    bgcolor: 'transparent',
                    '&.Mui-selected': {
                      bgcolor: 'transparent',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundColor: 'secondary.main',
                      opacity: sortBy === type && sortOrder === 'desc' ? 1 : 0,
                      borderRadius: 'inherit',
                    },
                    '&:hover': {
                      '&::before': {
                        opacity: 1,
                        backgroundColor: 'secondary.light',
                      },
                    },
                    '& > *': {
                      position: 'relative',
                      zIndex: 1,
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <ArrowDown
                      sx={{
                        fontSize: 16,
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.primary,
                        fontWeight:
                          sortBy === type && sortOrder === 'desc' ? 600 : 400,
                      }}
                    >
                      {descLabel}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  id="sort-asc"
                  onClick={() => handleSort(type, 'asc')}
                  selected={sortBy === type && sortOrder === 'asc'}
                  sx={{
                    minHeight: 36,
                    borderRadius: 2,
                    px: 1.5,
                    py: 0.5,
                    my: 0.5,
                    position: 'relative',
                    overflow: 'hidden',
                    bgcolor: 'transparent',
                    '&.Mui-selected': {
                      bgcolor: 'transparent',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      backgroundColor: 'secondary.main',
                      opacity: sortBy === type && sortOrder === 'asc' ? 1 : 0,
                      borderRadius: 'inherit',
                    },
                    '&:hover': {
                      '&::before': {
                        opacity: 1,
                        backgroundColor: 'secondary.light',
                      },
                    },
                    '& > *': {
                      position: 'relative',
                      zIndex: 1,
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <ArrowUp
                      sx={{
                        fontSize: 16,
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.primary,
                        fontWeight:
                          sortBy === type && sortOrder === 'asc' ? 600 : 400,
                      }}
                    >
                      {ascLabel}
                    </Typography>
                  </Box>
                </MenuItem>
              </Box>
            </React.Fragment>
          )
        )}
      </MenuList>
    </Popover>
  );
};

export default SortComponent;
