import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const FilesEmpty: React.FC = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 300px)', // Adjust height to account for header and actions
          position: 'relative',
        }}
      >
        <ArrowUpwardIcon
          sx={{
            position: 'absolute',
            top: 0,
            fontSize: 40,
            color: 'text.secondary',
            animation: 'bounce 2s infinite',
          }}
        />
        <Typography
          variant="h4"
          align="center"
          sx={{ mt: 8, mb: 2, fontWeight: 500 }}
          color="text.secondary"
        >
          Add your first recording
        </Typography>

        <Typography
          variant="body1"
          align="center"
          color="text.secondary"
          sx={{ maxWidth: 600 }}
        >
          Upload files, import URLs, or record directly. We&apos;ll create
          transcripts and summaries to help you quickly find what you need.
        </Typography>
      </Box>
      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
          }
        `}
      </style>
    </Box>
  );
};

export default memo(FilesEmpty);
