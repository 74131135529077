import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { Calendar } from 'lucide-mui';
import { initGoogleCalendarIntegration } from '../../../services/integrationService';
import useLimits from '../../plan/hooks/limits';

interface ConnectGoogleCalendarModalProps {
  onClose: () => void;
}

const ConnectGoogleCalendarModal = ({
  onClose,
}: ConnectGoogleCalendarModalProps) => {
  const guardLimits = useLimits();

  const handleConnectGoogleCalendar = async () => {
    guardLimits('googleMeetBot', async () => {
      const response = await initGoogleCalendarIntegration();
      window.location.href = response.oauthUrl;
    });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 400,
        },
      }}
    >
      <DialogContent>
        <Stack spacing={2} alignItems="center" sx={{ py: 4 }}>
          <Calendar sx={{ width: 48, height: 48, color: 'primary.main' }} />
          <Typography variant="h6">Connect Google Calendar</Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Allow access to your calendar so we can automatically join and
            record your meetings
          </Typography>
          <Button
            onClick={handleConnectGoogleCalendar}
            variant="contained"
            startIcon={<Calendar />}
            data-testid="connect-google-calendar-button"
          >
            Connect with Google
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectGoogleCalendarModal;
