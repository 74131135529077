/**
 * This is the first experment to migrate from redux to redux-toolkit
 */
/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FileType, FolderType, requestStatusType } from '../../types';

export const FileActionTypesWithData = [
  'move-files-modal',
  'move-file-modal',
  'link-share-modal',
  'rename-folder-modal',
  'delete-folder-modal',
  'delete-files-modal',
] as const;

export const ActionModalTypes = [
  'alert',
  'stripe-pricing-table-upgrade-modal',
  'import-videos-modal',
  'import-urls-modal',
  'invite-by-link-modal',
  'create-folder-modal',
  'embed-file-modal',
  'onboarding-modal',
  'upgradeSuccess',
  'upsell-modal',
  'connect-google-calendar-modal',
  'free-trial-modal',
] as const;

export type IModalTypes =
  | typeof FileActionTypesWithData[number]
  | typeof ActionModalTypes[number];

export interface MultiFilesActionPayLoadType {
  type: typeof FileActionTypesWithData[number];
  files: FileType[] | FolderType[];
  source?: string;
}

interface OtherActionPayLoadType {
  type: typeof ActionModalTypes[number];
  source?: string;
}

export type ModalActionPayLoadType =
  | MultiFilesActionPayLoadType
  | OtherActionPayLoadType;

interface GlobalModalActionData {
  files?: FileType[] | FolderType[];
  status?: requestStatusType;
  source?: string;
}

// interface FileActionDataType {
//   files: FileType[] | FolderType[];
//   status: requestStatusType;
// }

// interface OtherActionDataType {
//   source: string;
// }

export interface IGlobalModalsState {
  modalStack: IModalTypes[];
  // This is a global alert to show success or error messages. Needs further fine tuning
  // XXX IMO model stack should be objects containing their own data
  actionData: GlobalModalActionData;
}

const initialState: IGlobalModalsState = {
  modalStack: [],
  actionData: {
    files: [],
    folderId: '',
    status: 'idle',
  },
} as IGlobalModalsState;

export const globalModalsSlice = createSlice({
  name: 'globalModals',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<ModalActionPayLoadType>) => {
      // check if the modal is already in the stack
      if (state.modalStack.includes(action.payload.type)) {
        return;
      }
      state.modalStack.push(action.payload.type);
      state.actionData.status = 'idle';
      switch (action.payload.type) {
        case 'upsell-modal':
          state.actionData.source = action.payload.source;
          break;
        case 'move-files-modal':
        case 'move-file-modal':
        case 'link-share-modal':
        case 'rename-folder-modal':
        case 'delete-folder-modal':
        case 'delete-files-modal':
          state.actionData.files = action.payload.files;
          break;
        default:
          state.actionData.files = [];
      }
    },
    popModal: (state) => {
      if (state.modalStack.length) {
        state.modalStack.pop();
        state.actionData.files = [];
      }
    },
    setActionStatus: (state, action: PayloadAction<requestStatusType>) => {
      state.actionData.status = action.payload;
    },
  },
});

export const {
  pushModal,
  popModal,
  setActionStatus,
} = globalModalsSlice.actions;
export default globalModalsSlice.reducer;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function deleteFile(arg0: string): any {
  throw new Error('Function not implemented.');
}
