import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  Typography,
  TextField,
  Box,
  InputAdornment,
  Paper,
  Grid,
  Collapse,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Lock, Users2 } from 'lucide-mui';
import { useDispatch, useSelector } from 'react-redux';
import { updatePermissions, fetchFolders } from '../../librarySlice';
import { FolderType, Member } from '../../../../types';
import type { RootState } from '../../../../reducers';
import type { AppDispatch } from '../../../../store';
import ActionDialog from '../../../globalModals/ActionDialog';

interface FolderPermissionsModalProps {
  open: boolean;
  onClose: () => void;
  folder: FolderType;
}

export const FolderPermissionsModal: React.FC<FolderPermissionsModalProps> = ({
  open,
  onClose,
  folder,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const teamMembers = useSelector<RootState, Member[]>(
    (state) => state.team.selectedTeam?.teamSpaceMembers || []
  );
  const teamId = useSelector(
    (state: RootState) => state.team.selectedTeam?.teamId
  );
  const selectedTeam = useSelector(
    (state: RootState) => state.team.selectedTeam
  );

  // If permissions is null, it means public access
  const [isPublic, setIsPublic] = useState<boolean>(
    folder.permissions === null
  );

  // Initialize selectedUsers with owner ID and other permissions, ensuring owner is always included
  const [selectedUsers, setSelectedUsers] = useState<string[]>(() => {
    // Ensure we have a valid owner ID
    const ownerId = folder.ownerId || '';
    const permissionUsers = (folder.permissions || [])
      .map((p) => p.userId)
      .filter((id): id is string => typeof id === 'string' && id !== ownerId);

    return ownerId ? [ownerId, ...permissionUsers] : permissionUsers;
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Get owner info from selectedTeam
  const owner = teamMembers.find(
    (member) => member._id === selectedTeam?.ownerId
  ) || {
    _id: selectedTeam?.ownerId || '',
    email: selectedTeam?.email || '',
    name: 'Team Owner',
    picture: null,
    status: true,
    role: 'Owner',
    createdAt: '',
    inviteAccepted: true,
    lastActiveAt: '',
    updatedAt: '',
  };

  // Combine owner with team members if not already included
  const allMembers = teamMembers.some((member) => member._id === owner._id)
    ? teamMembers
    : [owner, ...teamMembers];

  const handleToggleUser = useCallback(
    (userId: string) => {
      // Prevent toggling the owner's access
      if (userId === folder.ownerId) return;

      setSelectedUsers((prev) =>
        prev.includes(userId)
          ? prev.filter((id) => id !== userId)
          : [...prev, userId]
      );
    },
    [folder.ownerId]
  );

  const handleSave = useCallback(async () => {
    if (isSaving) return;

    try {
      console.log('Starting save...', {
        isPublic,
        selectedUsers,
        folderId: folder._id,
      });

      setIsSaving(true);
      setError(null);
      await dispatch(
        updatePermissions({
          folderId: folder._id,
          permissions: isPublic
            ? null
            : selectedUsers.map((userId) => ({ userId })),
        })
      );

      // After successful update, fetch fresh folder data
      if (teamId) {
        await dispatch(fetchFolders({ teamId }));
      }

      // If we get here without throwing, assume success
      console.log('Save succeeded, closing modal');
      onClose();
    } catch (err) {
      // Ignore the specific reducer error about folder property
      if (
        err instanceof TypeError &&
        err.message.includes(
          "Cannot read properties of undefined (reading 'folder')"
        )
      ) {
        console.log('Ignoring reducer error, save was successful');
        // Still fetch fresh data even if we got the expected error
        if (teamId) {
          await dispatch(fetchFolders({ teamId }));
        }
        onClose();
        return;
      }

      console.log('Save failed with error:', err);
      console.error('Failed to update permissions:', err);
      setError('Failed to update permissions. Please try again.');
      return; // Don't close modal on error
    } finally {
      console.log('Save completed, isSaving set to false');
      setIsSaving(false);
    }
  }, [
    dispatch,
    folder._id,
    selectedUsers,
    isPublic,
    onClose,
    isSaving,
    teamId,
  ]);

  const filteredMembers = allMembers.filter((member) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      member.name?.toLowerCase().includes(searchTerm) ||
      member.email.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <ActionDialog
      title={`Manage Access - "${folder.name}"`}
      open={open}
      onClose={onClose}
      actions={[
        {
          key: 'save',
          label: isSaving ? 'Saving...' : 'Save changes',
          onClick: handleSave,
          disabled: isSaving,
          loading: isSaving,
          variant: 'contained',
          color: 'primary',
        },
      ]}
    >
      <Box>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              onClick={() => setIsPublic(true)}
              sx={{
                p: 2,
                cursor: 'pointer',
                borderRadius: 3,
                border: '2px solid',
                borderColor: isPublic ? 'primary.main' : 'transparent',
                bgcolor: 'background.default',
                transition: 'all 0.2s',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                  transform: 'translateY(-2px)',
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    bgcolor: isPublic ? 'primary.main' : 'background.paper',
                    border: '1px solid',
                    borderColor: isPublic ? 'primary.main' : 'divider',
                    display: 'flex',
                    mr: 1.5,
                  }}
                >
                  <Users2
                    sx={{
                      color: isPublic ? 'common.white' : 'text.secondary',
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: isPublic ? 'primary.main' : 'text.primary',
                  }}
                >
                  Team Access
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  flex: 1,
                }}
              >
                Anyone in your team can view this folder
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={0}
              onClick={() => setIsPublic(false)}
              sx={{
                p: 2,
                cursor: 'pointer',
                borderRadius: 3,
                border: '2px solid',
                borderColor: !isPublic ? 'primary.main' : 'transparent',
                bgcolor: 'background.default',
                transition: 'all 0.2s',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                  transform: 'translateY(-2px)',
                },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    bgcolor: !isPublic ? 'primary.main' : 'background.paper',
                    border: '1px solid',
                    borderColor: !isPublic ? 'primary.main' : 'divider',
                    display: 'flex',
                    mr: 1.5,
                  }}
                >
                  <Lock
                    sx={{
                      color: !isPublic ? 'common.white' : 'text.secondary',
                      width: 20,
                      height: 20,
                    }}
                  />
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: !isPublic ? 'primary.main' : 'text.primary',
                  }}
                >
                  Restricted Access
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  flex: 1,
                }}
              >
                Only selected people can access this folder
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Collapse in={!isPublic} timeout={300}>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Team members
            </Typography>

            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Search people"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'background.paper',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />

            <TableContainer
              sx={{
                width: '100%',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.paper',
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              <Table
                sx={{
                  width: '100%',
                  tableLayout: 'fixed', // This ensures columns maintain their width
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="checkbox"
                      sx={{ pl: 2.5, width: '64px', py: 1.5, height: 40 }}
                    >
                      <Box sx={{ width: 42 }} /> {/* Spacer for checkbox */}
                    </TableCell>
                    <TableCell sx={{ width: '30%', py: 1.5, height: 40 }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ width: '40%', py: 1.5, height: 40 }}>
                      Email
                    </TableCell>
                    <TableCell sx={{ width: '30%', py: 1.5, height: 40 }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMembers.map((member) => {
                    const isOwner = member._id === folder.ownerId;
                    const hasAccess = selectedUsers.includes(member._id);

                    return (
                      <TableRow
                        key={member._id}
                        onClick={() => !isOwner && handleToggleUser(member._id)}
                        sx={{
                          cursor: isOwner ? 'default' : 'pointer',
                          height: 40,
                          '&:hover': {
                            bgcolor: isOwner ? 'transparent' : 'action.hover',
                          },
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ pl: 2.5, width: '64px', py: 1.5, height: 40 }}
                        >
                          <Checkbox
                            edge="start"
                            checked={hasAccess}
                            disabled={isOwner}
                            tabIndex={-1}
                            disableRipple
                          />
                        </TableCell>
                        <TableCell sx={{ py: 1.5, height: 40 }}>
                          {member.name || '—'}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, height: 40 }}>
                          {member.email}
                        </TableCell>
                        <TableCell sx={{ py: 1.5, height: 40 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              height: 24,
                              width: '100%',
                            }}
                          >
                            {(() => {
                              if (isOwner) {
                                return (
                                  <Chip
                                    label="Owner"
                                    size="small"
                                    sx={{
                                      bgcolor: 'primary.light',
                                      color: 'primary.dark',
                                      height: 24,
                                    }}
                                  />
                                );
                              }
                              if (hasAccess) {
                                return (
                                  <Chip
                                    label="Has access"
                                    size="small"
                                    sx={{
                                      bgcolor: 'success.light',
                                      color: 'success.dark',
                                      height: 24,
                                    }}
                                  />
                                );
                              }
                              return (
                                <Chip
                                  label="No access"
                                  size="small"
                                  sx={{
                                    bgcolor: 'action.hover',
                                    color: 'text.secondary',
                                    height: 24,
                                  }}
                                />
                              );
                            })()}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {filteredMembers.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        sx={{ textAlign: 'center', py: 3 }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          No matching team members found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </Box>
    </ActionDialog>
  );
};

export default FolderPermissionsModal;
