import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Rocket, CheckCircle } from 'lucide-mui';

import { RootState } from '../../../reducers';
import ActionButton from '../../plan/upgrade/ActionButton';
import usePlan from '../../plan/hooks/plan';
import useLimits from '../../plan/hooks/limits';
import { requestStatusType } from '../../../types';

const BUTTON_TEXTS = {
  actionType: 'delete',
  tooltipMessages: {
    idle: 'Upgrade your Account',
    pending: 'Upgrading your account...',
    succeeded: 'Successfully upgraded',
    failed: 'Failed to upgrade',
  },
  buttonTexts: {
    idle: 'Upgrade',
    pending: 'Upgrading...',
    succeeded: 'Upgraded',
    failed: 'Failed',
  },
};

const UpgradeButton = () => {
  const guardLimits = useLimits();
  const { plan } = useSelector((state: RootState) => state);
  const { upgradeStatus } = plan.subscription;
  const handleClick = () => {
    guardLimits('generalUpgrade', () => {});
  };

  const { isPlan } = usePlan();
  const upgraded = isPlan(['GROWTH', 'BUSINESS', 'ENTERPRISE']);
  let status = upgradeStatus || 'idle';
  if (
    !upgraded &&
    (['failed', 'succeeded'] as requestStatusType[]).includes(upgradeStatus)
  ) {
    status = 'idle';
  }

  if (upgraded) {
    return null;
  }

  // Determine the icon to display based on the status
  const Icon = status === 'succeeded' ? CheckCircle : Rocket;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ActionButton
        startIcon={<Icon />}
        variant="contained"
        color="primary"
        size="medium"
        onClick={handleClick}
        sx={{ minWidth: 'unset' }}
        flashTooltip={status !== 'idle'}
        tooltipMessage={BUTTON_TEXTS.tooltipMessages[status]}
        loading={status === 'pending'}
      >
        {BUTTON_TEXTS.buttonTexts[status].replace(/ /g, '\u00A0')}
      </ActionButton>
    </Box>
  );
};

export default UpgradeButton;
