import React, { useState } from 'react';
import { Menu, IconButton, Tooltip, useTheme } from '@mui/material';
import { MoreVertical } from 'lucide-mui';
import { useDispatch } from 'react-redux';
import {
  LIBRARY_VIEW_ACTIONS,
  ActionType,
} from '../../selectedVideo/actions/actions';
import {
  downloadFile,
  shareThunk,
} from '../../selectedVideo/selectedVideoSlice';
import { pushModal } from '../../globalModals/globalModalsSlice';
import type { FileType, requestStatusType } from '../../../types';
import ActionMenuItem from '../../plan/upgrade/ActionMenuItem';

interface FileActionMenuProps {
  file: FileType;
}

const FileActionMenu = ({ file }: FileActionMenuProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null);
  const [actionStatus, setActionStatus] = useState<requestStatusType>('idle');
  const dispatch = useDispatch();

  const handleMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAction = React.useCallback(
    async (key: ActionType) => {
      const { _id: fileId, defaultProfile } = file;
      setSelectedAction(key);
      setActionStatus('pending');
      switch (key) {
        case 'share':
          await dispatch(shareThunk({ fileId }));
          break;
        case 'download':
          if (defaultProfile) {
            await dispatch(downloadFile({ fileId, profile: defaultProfile }));
          }
          break;
        case 'delete':
          await dispatch(
            pushModal({ type: 'delete-files-modal', files: [file] })
          );
          break;
        case 'move':
          await dispatch(pushModal({ type: 'move-file-modal', files: [file] }));
          break;
        default:
      }
      setActionStatus('idle');
      handleMenuClose();
    },
    [dispatch, file, handleMenuClose]
  );

  const menuItems = React.useMemo(
    () =>
      LIBRARY_VIEW_ACTIONS.map(
        ({ actionType, tooltipMessages, buttonTexts, IconComponent }) => {
          const isSelectedAction = selectedAction === actionType;
          const isLoading = isSelectedAction && actionStatus === 'pending';
          const status = isSelectedAction ? actionStatus : 'idle';
          return (
            <ActionMenuItem
              key={actionType}
              onClick={() => handleAction(actionType)}
              tooltipMessage={tooltipMessages[status]}
              loading={isLoading}
              startIcon={<IconComponent />}
            >
              {buttonTexts[status]}
            </ActionMenuItem>
          );
        }
      ),
    [selectedAction, actionStatus, handleAction]
  );

  return (
    <>
      <Tooltip title="More Actions" arrow>
        <IconButton
          data-testid="more-actions-button"
          onClick={handleMenuOpen}
          size="small"
          sx={{
            width: 32,
            height: 32,
            padding: 0,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(42, 43, 44, 0.9)'
                : 'rgba(255, 255, 255, 0.9)',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(42, 43, 44, 0.95)'
                  : 'rgba(255, 255, 255, 0.95)',
              transform: 'scale(1.1)',
            },
            transition: 'all 0.2s ease-in-out',
            backdropFilter: 'blur(4px)',
            border:
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.secondary.dark}`
                : 'none',
            '& .MuiSvgIcon-root': {
              color: theme.palette.text.primary,
              fontSize: '1.2rem',
            },
          }}
        >
          <MoreVertical />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="more-action-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.common.white,
            border: `1px solid ${
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.dark
                : theme.palette.secondary.light
            }`,
          },
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default React.memo(FileActionMenu);
