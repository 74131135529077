import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Paper,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import {
  X as CloseIcon,
  Search as SearchIcon,
  ArrowLeft as ArrowLeftIcon,
} from 'lucide-mui';
import { RootState } from '../../reducers';
import AnswerDisplay from './components/AnswerDisplay';
import SearchResults from './components/SearchResults';
import {
  useSearchInput,
  useSearchPopover,
  useAssistantSearch,
  useAssistantAnswer,
  useCitationsAndThumbnails,
} from './hooks';
import useLimits from '../plan/hooks/limits';
import useTags from '../../hooks/useTags';
import assistantActions from './actions';

interface AssistantToolbarProps {
  onFocusChange?: (isFocused: boolean) => void;
}

export const AssistantToolbar: React.FC<AssistantToolbarProps> = ({
  onFocusChange,
}) => {
  // Use the custom hooks
  const selectedVideo = useSelector(
    (state: RootState) => state.selectedVideo?.file
  );
  const guardLimits = useLimits();
  const { setTag } = useTags();
  const dispatch = useDispatch();

  // Search results and state
  const {
    status,
    setStatus,
    results,
    setResults,
    isProcessingQuery,
    saveQueryToHistory,
    saveLastSearchResult,
    performSearch,
  } = useAssistantSearch();

  // Citations and thumbnails
  const {
    activeSource,
    setActiveSource,
    citationThumbnails,
    fileThumbnails,
    setFileThumbnails,
    featuredThumbnail,
    hideReferences,
    formatTimestamp,
    handleSourceClick: rawHandleSourceClick,
    fetchCitationThumbnail,
    fetchFileThumbnail,
    resetCitationCount,
    extractFeaturedThumbnail,
    showVideoPlayer,
    videoPlayerFile,
  } = useCitationsAndThumbnails();

  // Answer generation
  const {
    aiAnswer,
    setAiAnswer,
    isGeneratingAnswer,
    setIsGeneratingAnswer,
    lastSearchQuery,
    setLastSearchQuery,
    fetchTranscripts,
    generateAnswerFromTranscripts,
    saveAnswer,
  } = useAssistantAnswer({ extractFeaturedThumbnail });

  // Search input handling
  const {
    query,
    inputRef,
    handleInputChange: handleRawInputChange,
    handleKeyDown: handleRawKeyDown,
    handleClear: handleRawClear,
    updateQuery,
  } = useSearchInput();

  // Popover and UI state
  const {
    isPopoverOpen,
    setIsPopoverOpen,
    containerRef,
    searchBarWidth,
    isFileListExpanded,
    isSearchIconMode,
    isSearchExpanded,
    setIsSearchExpanded,
    handleInputFocus: handleRawInputFocus,
    toggleFileList,
    getShortcutText,
    screenWidth,
  } = useSearchPopover(aiAnswer, results);

  // Handle search submission - define this before it's used
  const handleSearch = function searchFunc() {
    if (query.trim()) {
      // SINGLE TRACKING POINT - Track the assistant query in PostHog
      dispatch(assistantActions.trackAssistantQuery({ query: query.trim() }));

      // Use guardLimits with a callback to show upgrade modal if needed
      guardLimits('assistant', () => {
        // First set up the loading UI immediately
        setStatus('loading');
        setIsPopoverOpen(true);
        setAiAnswer(null);
        setResults({
          data: [],
          error: null,
        });
        resetCitationCount();
        setActiveSource(null);
        // Set the last search query
        setLastSearchQuery(query.trim());

        // Use setTimeout to ensure the loading UI is rendered before async operations begin
        setTimeout(() => {
          // Focus the input after a short delay
          if (inputRef.current) {
            inputRef.current.focus();
          }

          const doSearch = async () => {
            try {
              // Save query to history
              saveQueryToHistory(query.trim());

              // Update URL query parameter with original query
              updateQuery({ searchQuery: query.trim() });

              // Perform the search
              const files = await performSearch(query.trim());

              // Update the state with the search results
              setResults({
                data: files,
                error: null,
              });
              setStatus('succeeded');

              // Show popover with results
              setIsPopoverOpen(true);

              // Fetch thumbnails for files in the results
              files.forEach((file) => {
                // Only fetch thumbnails for video files
                const isVideoFile =
                  file.streams?.some((stream) => stream !== 'audio') ||
                  !file.streams ||
                  file.streams.length === 0;

                if (isVideoFile) {
                  fetchFileThumbnail(file._id);
                }
              });

              // Set loading state before fetching transcripts
              setIsGeneratingAnswer(true);

              // Fetch transcripts from the top results
              const transcripts = await fetchTranscripts(files);

              if (transcripts.length > 0) {
                // Generate answer from transcripts
                const answer = await generateAnswerFromTranscripts(
                  query.trim(),
                  transcripts,
                  selectedVideo
                );
                setAiAnswer(answer);

                // Save the answer
                saveAnswer(query.trim(), answer);

                // Extract featured thumbnail using the hook's function
                extractFeaturedThumbnail(answer, files);

                // Save the search results
                saveLastSearchResult(query.trim(), answer, files);
              } else {
                setIsGeneratingAnswer(false);

                // Even if we don't have an answer, still save the files
                saveLastSearchResult(query.trim(), null, files);
              }
            } catch (error) {
              console.error('Assistant search error:', {
                error,
                query: query.trim(),
                timestamp: new Date().toISOString(),
                errorMessage:
                  error instanceof Error ? error.message : 'Unknown error',
              });

              setResults({
                data: [],
                error: error instanceof Error ? error.message : 'Search failed',
              });
              setStatus('failed');
              setIsGeneratingAnswer(false);
            }
          };

          // Execute the search
          doSearch();
        }, 0);
      });
    }
  };

  // Adapter functions to work with the hooks API
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleRawInputChange(e);

    // If user is typing, set loading state for any future queries
    const newQuery = e.target.value;
    if (newQuery.length > 0 && status === 'succeeded') {
      // Don't clear results or answers yet, but prepare UI for a potential new search
      setStatus('idle');
    }

    // If query is empty and we have results, show them
    if (
      newQuery.length === 0 &&
      (aiAnswer || (results.data && results.data.length > 0))
    ) {
      setIsPopoverOpen(true);
    }
    // If query is empty and no results, close popover
    else if (
      newQuery.length === 0 &&
      !aiAnswer &&
      (!results.data || results.data.length === 0)
    ) {
      setIsPopoverOpen(false);
    }

    // If query has content, always show popover
    if (newQuery.length > 0) {
      setIsPopoverOpen(true);
    }
  };

  // Handle key press events with our specific search
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleRawKeyDown(e, handleSearch, () => setIsPopoverOpen(false));
  };

  // Handle input focus with our specific state
  const handleInputFocus = () => {
    // Focus the input
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Don't check limits here - users should be able to focus and type
    // Only check limits when they actually try to submit the search
    handleRawInputFocus(query);

    // If we have a lastSearchQuery but no current query, make sure it's displayed
    if (lastSearchQuery && !query.trim() && aiAnswer) {
      setLastSearchQuery(lastSearchQuery);
    }

    // Always open popover on small viewports
    if (screenWidth < 600) {
      setIsPopoverOpen(true);
    }

    // Notify parent component that assistant is focused
    onFocusChange?.(true);
  };

  // Handle mouse down in popover to prevent blur and focus issues
  const handlePopoverMouseDown = (e: React.MouseEvent) => {
    // Stop propagation to prevent focus issues
    e.stopPropagation();
  };

  // Close the popover
  const handleClose = () => {
    // Don't clear the AI answer or results, just close the popover UI
    setIsPopoverOpen(false);

    // Notify parent component that assistant is no longer focused
    onFocusChange?.(false);
  };

  // Get placeholder text
  const getPlaceholderText = () => {
    return 'Search your second brain';
  };

  // Determine if there's content to display (results, answer, or processing state)
  const isLoading = status === 'loading';
  const isProcessing = isProcessingQuery || isGeneratingAnswer;
  const hasContent =
    results.data.length > 0 || aiAnswer !== null || status === 'failed';
  const shouldShowContent = hasContent || isLoading || isProcessing;

  // Handle clear with our specific state
  const handleClear = (e: React.MouseEvent) => {
    handleRawClear(e, () => {
      // Only clear the query, but keep the popover open if there are results to show
      handleRawInputChange({
        target: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>);

      // If we have a lastSearchQuery and aiAnswer, make sure it's displayed
      if (lastSearchQuery && aiAnswer) {
        setLastSearchQuery(lastSearchQuery);
      }

      // On small viewports, always keep the assistant visible and focused
      if (screenWidth < 600) {
        // Keep popover open on small screens
        setIsPopoverOpen(true);
        // Keep the input focused
        if (inputRef.current) {
          inputRef.current.focus();
        }
        return;
      }

      // For larger viewports, follow the original behavior
      if (!hasContent) {
        setIsPopoverOpen(false);
        // Only notify parent that focus is lost if there's no content to display
        onFocusChange?.(false);
      }
    });
  };

  // Should we round the search bar's bottom corners
  const shouldRoundBottomCorners = !(shouldShowContent && isPopoverOpen);

  // Determine if the component is focused for styling purposes
  const isFocused =
    isPopoverOpen ||
    query.length > 0 ||
    (document.activeElement === inputRef.current && screenWidth < 600);

  // Add focus detection for small viewports
  useEffect(() => {
    const handleFocusChange = () => {
      if (screenWidth < 600 && document.activeElement === inputRef.current) {
        // Ensure popover is open when input is focused on small viewports
        setIsPopoverOpen(true);
      }
    };

    // Listen for focus events
    if (inputRef.current) {
      inputRef.current.addEventListener('focus', handleFocusChange);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focus', handleFocusChange);
      }
    };
  }, [screenWidth, setIsPopoverOpen]);

  // Handle back button press (for all viewports)
  const handleBackButtonPress = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    // Prevent default behavior and stop propagation to prevent jumping
    e.preventDefault();
    e.stopPropagation();

    // Close the popover
    setIsPopoverOpen(false);

    // Update the query state directly to empty string
    handleRawInputChange({
      target: { value: '' },
    } as React.ChangeEvent<HTMLInputElement>);

    // If we have a lastSearchQuery and aiAnswer, make sure it's displayed
    if (lastSearchQuery && aiAnswer) {
      setLastSearchQuery(lastSearchQuery);
    }

    // Notify parent component that assistant is no longer focused
    onFocusChange?.(false);
  };

  // Handle clearing the search history
  const handleClearHistory = useCallback(() => {
    // Clear the stored answer and results
    setTag({ key: 'assistant_last_answer', value: '' });
    setTag({ key: 'assistant_last_results', value: '' });

    // Clear the UI state
    setAiAnswer(null);
    setLastSearchQuery(null);
    setResults({ data: [], error: null });
    setStatus('idle');

    // Close the popover if it's open
    setIsPopoverOpen(false);

    // Clear the input field
    if (inputRef.current) {
      inputRef.current.value = '';
      handleRawInputChange({
        target: { value: '' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [
    setTag,
    setAiAnswer,
    setLastSearchQuery,
    setResults,
    setStatus,
    setIsPopoverOpen,
    handleRawInputChange,
  ]);

  // Function to handle expanding the search bar from icon mode
  const handleSearchIconClick = useCallback(() => {
    if (isSearchIconMode && !isSearchExpanded) {
      setIsSearchExpanded(true);
      // Focus the input after expanding
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 50);
    }
  }, [isSearchIconMode, isSearchExpanded, setIsSearchExpanded]);

  // Use for collapsing when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        isSearchIconMode &&
        isSearchExpanded &&
        !query.trim()
      ) {
        setIsSearchExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchIconMode, isSearchExpanded, query, setIsSearchExpanded]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minWidth: { xs: '100px', sm: '300px' },
        maxWidth: '100%',
        mx: 0,
        ml: { xs: '8px', sm: '8px', md: '16px' },
        mr: { xs: isFocused ? 0 : '2px', sm: 0 },
        flexGrow: 1,
        display: 'flex',
        zIndex: isFocused ? 1200 : 1150, // Increase z-index when focused
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        overflowAnchor: 'none', // Prevent scroll anchoring
      }}
      ref={containerRef}
      onClick={(e) => e.stopPropagation()} // Prevent click propagation
    >
      {/* Search Icon Button (when in icon mode and not expanded) */}
      {isSearchIconMode && !isSearchExpanded ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            backgroundColor: 'background.paper',
            border: 'none',
            cursor: 'pointer',
            transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
          onClick={handleSearchIconClick}
        >
          <SearchIcon width={20} height={20} color="action" />
        </Box>
      ) : (
        <TextField
          placeholder={getPlaceholderText()}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleInputFocus}
          inputRef={inputRef}
          variant="outlined"
          fullWidth
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleInputFocus();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  // Enhanced mobile visibility
                  ml: { xs: 0.75, sm: 0.5 },
                  minWidth: { xs: 30, sm: 30 },
                  p: { xs: 0.25, sm: 0 },
                }}
              >
                <Box
                  sx={{
                    color: 'rgba(0, 0, 0, 0.38)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: 18,
                    cursor: isFocused || isPopoverOpen ? 'pointer' : 'default',
                    position: 'relative',
                    overflow: 'hidden',
                    minWidth: { xs: 24, sm: 18 },
                    mr: { xs: 0.5, sm: 0 },
                  }}
                  onClick={
                    isFocused || isPopoverOpen
                      ? handleBackButtonPress
                      : undefined
                  }
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      opacity: isFocused || isPopoverOpen ? 1 : 0,
                      transform:
                        isFocused || isPopoverOpen ? 'scale(1)' : 'scale(0.8)',
                      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                      visibility:
                        isFocused || isPopoverOpen ? 'visible' : 'hidden',
                    }}
                  >
                    <ArrowLeftIcon width={18} height={18} />
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100%',
                      opacity: isFocused || isPopoverOpen ? 0 : 1,
                      transform:
                        isFocused || isPopoverOpen ? 'scale(0.8)' : 'scale(1)',
                      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                      visibility:
                        isFocused || isPopoverOpen ? 'hidden' : 'visible',
                    }}
                  >
                    <SearchIcon width={18} height={18} />
                  </Box>
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  mr: { xs: 0.5, sm: 0.75 },
                  my: 0,
                  minWidth: 'auto',
                  display: 'flex',
                  gap: 0.75,
                }}
              >
                {/* Keyboard shortcut text */}
                {!query && !isPopoverOpen && (
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.75rem',
                      color: 'rgba(0, 0, 0, 0.38)',
                      mr: 1,
                      userSelect: 'none',
                    }}
                  >
                    {getShortcutText()}
                  </Typography>
                )}
                {/* Show clear button ONLY when there's query text */}
                {query && (
                  <Box
                    component="button"
                    type="button"
                    onClick={handleClear}
                    sx={{
                      cursor: isLoading || isProcessing ? 'default' : 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'transparent',
                      border: 'none',
                      padding: 0,
                      margin: 0,
                      width: '22px',
                      height: '22px',
                      opacity: 1,
                      borderRadius: '50%',
                      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                      transform: 'scale(1)',
                      '&:hover': {
                        opacity: isLoading || isProcessing ? 1 : 1,
                        backgroundColor:
                          isLoading || isProcessing ? 'transparent' : 'white',
                        transform:
                          isLoading || isProcessing ? 'scale(1)' : 'scale(1.1)',
                      },
                      '&:active': {
                        transform:
                          isLoading || isProcessing
                            ? 'scale(1)'
                            : 'scale(0.95)',
                      },
                    }}
                  >
                    {isLoading || isProcessing ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      <CloseIcon width={18} height={18} />
                    )}
                  </Box>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '100%',
            position: 'relative',
            zIndex: 1125, // Above any other elements
            ...(isFocused && {
              width: '100%',
            }),
            '& .MuiOutlinedInput-root': {
              height: '40px',
              backgroundColor: 'background.paper',
              borderRadius: shouldRoundBottomCorners ? '20px' : '20px 20px 0 0',
              ...(isFocused && {
                borderRadius: {
                  xs: shouldRoundBottomCorners ? '16px' : '16px 16px 0 0',
                  sm: shouldRoundBottomCorners ? '20px' : '20px 20px 0 0',
                },
              }),
              '& fieldset': {
                border: 'none',
                boxShadow: 'none',
              },
              display: 'flex',
              alignItems: 'center',
              paddingLeft: { xs: 0.75, sm: 0.75 }, // Restore this for non-focused state
              paddingRight: { xs: 0.5, sm: 0.5 },
              transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                opacity: 0.9,
              },
              '&.Mui-focused': {
                opacity: 0.95,
              },
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px 0px 0px 8px',
              height: '40px',
              lineHeight: '40px',
              fontSize: { xs: '0.875rem', sm: '1rem' },
              textIndent: '0',
              display: 'flex',
              alignItems: 'center',
              '&::placeholder': {
                opacity: 0.7,
                transition: 'opacity 0.2s ease',
                '& > span': {
                  fontSize: '0.75rem',
                  color: 'rgba(0, 0, 0, 0.38)',
                  marginLeft: '4px',
                },
              },
              '&:focus::placeholder': {
                opacity: 0.4,
              },
            },
            '& .MuiInputAdornment-root': {
              height: '40px',
              maxHeight: '40px',
              marginTop: 0,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
            },
          }}
        />
      )}

      {/* Add semi-transparent dark backdrop when search is focused AND there is content */}
      {isPopoverOpen && shouldShowContent && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: {
              xs: 'rgba(0, 0, 0, 0.3)',
              sm: 'rgba(0, 0, 0, 0.3)',
            },
            zIndex: 1100,
          }}
          onClick={() => setIsPopoverOpen(false)}
        />
      )}

      {/* Replace Popover with absolute positioned Paper - only show when there is content */}
      {isPopoverOpen && shouldShowContent && (
        <Paper
          elevation={0}
          sx={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            width: searchBarWidth
              ? `${searchBarWidth}px`
              : { xs: '90vw', sm: '400px' },
            zIndex: 1200,
            maxHeight: '70vh',
            overflow: 'auto',
            borderRadius: '0 0 20px 20px',
            bgcolor: 'background.paper',
            border: 'none',
            marginTop: '-1px',
            opacity: 0,
            transform: 'translateY(-8px)',
            animation: 'slideIn 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards',
            '@keyframes slideIn': {
              '0%': {
                opacity: 0,
                transform: 'translateY(-8px)',
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0)',
              },
            },
          }}
          onMouseDown={handlePopoverMouseDown}
        >
          <Box sx={{ p: 0.75 }} onMouseDown={handlePopoverMouseDown}>
            {/* When loading, show skeleton loader */}
            {isLoading || isProcessing ? (
              <Box sx={{ p: 1 }}>
                {/* Main answer skeleton */}
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2.5,
                  }}
                >
                  {/* Thumbnail skeleton */}
                  <Skeleton
                    variant="rectangular"
                    width={240}
                    height={135}
                    sx={{
                      borderRadius: '12px',
                      display: { xs: 'none', md: 'block' },
                      bgcolor: 'rgba(0, 0, 0, 0.04)',
                    }}
                  />

                  {/* Text content skeleton */}
                  <Box sx={{ flexGrow: 1 }}>
                    <Skeleton
                      variant="text"
                      width="90%"
                      height={20}
                      sx={{
                        mb: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width="95%"
                      height={20}
                      sx={{
                        mb: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={20}
                      sx={{
                        mb: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      width="70%"
                      height={20}
                      sx={{
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                      }}
                    />
                  </Box>
                </Box>

                {/* Show search results below skeleton if they're available */}
                {results.data && results.data.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <SearchResults
                      results={results}
                      isFileListExpanded={isFileListExpanded}
                      toggleFileList={toggleFileList}
                      fileThumbnails={fileThumbnails}
                      setFileThumbnails={setFileThumbnails}
                      handleClose={handleClose}
                      isLoading
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <>
                {/* Only show the other content when not loading */}
                {status === 'failed' && (
                  <Paper
                    elevation={0}
                    sx={{
                      p: 1.5,
                      borderRadius: '16px',
                      bgcolor: 'transparent',
                      color: 'error.dark',
                    }}
                  >
                    <Typography variant="body2">
                      {results.error || 'Search failed. Please try again.'}
                    </Typography>
                  </Paper>
                )}

                {/* AI Answer Section */}
                {(status === 'succeeded' || aiAnswer) && (
                  <AnswerDisplay
                    aiAnswer={aiAnswer}
                    isGeneratingAnswer={isGeneratingAnswer}
                    lastSearchQuery={lastSearchQuery}
                    results={results}
                    featuredThumbnail={featuredThumbnail}
                    activeSource={activeSource}
                    handleSourceClick={(fileId, timestamp) =>
                      rawHandleSourceClick(fileId, timestamp, results.data)
                    }
                    citationThumbnails={citationThumbnails}
                    fetchCitationThumbnail={fetchCitationThumbnail}
                    formatTimestamp={formatTimestamp}
                    hideReferences={hideReferences}
                    onClearHistory={handleClearHistory}
                    showVideoPlayer={showVideoPlayer}
                    videoPlayerFile={videoPlayerFile}
                  />
                )}

                {/* Search Results Section */}
                {status === 'succeeded' && (
                  <SearchResults
                    results={results}
                    isFileListExpanded={isFileListExpanded}
                    toggleFileList={toggleFileList}
                    fileThumbnails={fileThumbnails}
                    setFileThumbnails={setFileThumbnails}
                    handleClose={handleClose}
                  />
                )}
              </>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default AssistantToolbar;
