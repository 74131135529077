import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { PackageType } from '../../../types';

export const usePlan = () => {
  const { plan } = useSelector((state: RootState) => state);
  const { package: teamPlan } = plan.subscription || {};
  const isPlan = (plans: PackageType[]) => {
    if (!teamPlan) return false;
    return plans.includes(teamPlan);
  };
  return { isPlan, teamPlan };
};
export default usePlan;
