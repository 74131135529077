import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import debug from 'debug';
import { Settings, History, Bell, Sparkles, Smartphone } from 'lucide-mui';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Badge,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import type { ListProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ActionType } from '../../../types';
import { getSettingsUrl } from '../../../lib/routingUtils';
import type { RootState } from '../../../reducers';
import useTags from '../../../hooks/useTags';
import {
  CHROME_EXTENSION_TAG_KEY,
  CHANGELOG_SEEN_TAG_KEY,
} from '../../../constants/tags';
import Changelog from '../../common/Changelog';
import { LATEST_VERSION } from '../../common/Changelog/changelogData';
import Notifications from '../toolbar/Notifications';
import { useLimits } from '../../plan/hooks/limits';
import MobileAppDialog from './MobileAppDialog';

const logger = debug('screenapp:components:DrawerActions');

type DrawerActionKeyType =
  | 'refer-earn'
  | 'help'
  | 'install-addon'
  | 'install-mobile-app'
  | 'old-screenapp'
  | 'settings'
  | 'changelog'
  | 'notifications'
  | 'ai-usage';
type DrawerActionType = ActionType<DrawerActionKeyType> & {
  customContent?: React.ReactNode;
};

export type SubSettingKeyType = 'profile' | 'team' | 'recording' | 'plugin';
export const DrawerActions = (listProps: ListProps) => {
  const { dashboard } = useSelector((state: RootState) => ({
    dashboard: state.dashboard,
    plan: state.plan,
  }));
  const open = dashboard.drawerState === 'open';
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [mobileAppOpen, setMobileAppOpen] = useState(false);
  // const noTeamMembers = team.selectedTeam?.teamSpaceMembers.length === 0;
  const { getTag, setTag } = useTags();
  const hasClickedExtension = Boolean(getTag(CHROME_EXTENSION_TAG_KEY));
  const hasSeenLatestChangelog =
    getTag(CHANGELOG_SEEN_TAG_KEY) === LATEST_VERSION;
  const showChangelogNotification = !hasSeenLatestChangelog;
  const { notifications } = dashboard;
  const unreadCount = notifications.filter((n) => !n.read).length;
  const hasUnreadNotifications = unreadCount > 0;
  const { packageProperties, usage } = useSelector(
    (state: RootState) => state?.plan || {}
  );
  const guardLimits = useLimits();
  const theme = useTheme();

  const handleClick = (actionKey: DrawerActionKeyType) => {
    switch (actionKey) {
      case 'help':
        try {
          window?.Userback.open();
        } catch (error) {
          logger.log('Error opening Userback', error);
        }
        break;
      case 'old-screenapp':
        window.open('/legacy', '_blank');
        break;
      case 'settings':
        setSettingsOpen(!settingsOpen);
        break;
      case 'changelog':
        setChangelogOpen(true);
        setTag({
          key: CHANGELOG_SEEN_TAG_KEY,
          value: LATEST_VERSION,
        });
        break;
      case 'notifications':
        setNotificationsOpen(true);
        break;
      case 'install-mobile-app':
        setMobileAppOpen(true);
        setTag({
          key: CHROME_EXTENSION_TAG_KEY,
          value: 'mobile-app-clicked',
        });
        break;
      default:
        break;
    }
  };

  const handleAIUsageClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    guardLimits('unlimitedCredits', () => {
      window.location.href = '/settings/usage';
    });
  };

  const baseDrawerActions: DrawerActionType[] = [
    {
      key: 'changelog',
      label: "What's New",
      startIcon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={!showChangelogNotification}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#ff4270',
              width: '6px',
              height: '6px',
              minWidth: '6px',
            },
          }}
        >
          <History />
        </Badge>
      ),
      onClick: () => handleClick('changelog'),
    },
    {
      key: 'notifications',
      label: 'Notifications',
      startIcon: (
        <Badge
          color="secondary"
          variant="dot"
          invisible={!hasUnreadNotifications}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#ff4270',
              width: '6px',
              height: '6px',
              minWidth: '6px',
            },
          }}
        >
          <Bell />
        </Badge>
      ),
      onClick: () => setNotificationsOpen(true),
    },
    {
      key: 'settings',
      label: 'Settings',
      startIcon: <Settings />,
      href: getSettingsUrl({ withBasePath: true }),
    },
  ];

  const aiUsageAction: DrawerActionType | null =
    packageProperties?.askAICount && packageProperties.askAICount <= 998
      ? {
          key: 'ai-usage' as DrawerActionKeyType,
          label: `AI Usage: ${usage?.askAICount || 0} / ${
            packageProperties.askAICount
          }`,
          startIcon: open ? null : <Sparkles />,
          customContent: open ? (
            <Box
              sx={{
                flex: 1,
                cursor: 'pointer',
                px: open ? 1.5 : 1.25,
                mx: 0.5,
                borderRadius: 20,
                minHeight: '36px',
                position: 'relative',
                overflow: 'hidden',
                bgcolor: 'transparent',
                '&:hover': {
                  '&::before': {
                    opacity: 1,
                  },
                  '& .MuiLinearProgress-root': {
                    backgroundColor: theme.palette.action.hover,
                  },
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: 'secondary.light',
                  opacity: 0,
                  borderRadius: 'inherit',
                },
              }}
              onClick={handleAIUsageClick}
            >
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  py: 1,
                  pl: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 0.5,
                    pr: 0,
                  }}
                >
                  <Typography
                    variant="caption"
                    color="text.secondaryContrastText"
                  >
                    AI Usage
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondaryContrastText"
                  >
                    {usage?.askAICount || 0} /{' '}
                    {packageProperties.askAICount > 998
                      ? 'Unlimited'
                      : packageProperties.askAICount}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={
                    packageProperties.askAICount > 998
                      ? 0
                      : ((usage?.askAICount || 0) /
                          packageProperties.askAICount) *
                        100
                  }
                  sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: 'secondary.light',
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 3,
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                      '& .MuiLinearProgress-bar1Buffer': {
                        backgroundColor: 'primary.dark',
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          ) : null,
          onClick: handleAIUsageClick,
        }
      : null;

  const mobileAppAction: DrawerActionType = {
    key: 'install-mobile-app',
    label: 'Install Mobile App',
    startIcon: <Smartphone />,
    onClick: () => handleClick('install-mobile-app'),
  };

  const drawerActions = [
    baseDrawerActions[0],
    baseDrawerActions[1],
    mobileAppAction,
    baseDrawerActions[2],
    ...(aiUsageAction ? [aiUsageAction] : []),
  ];

  return (
    <>
      <ListItemText
        primary="Other"
        sx={{
          opacity: open ? 1 : 0,
          '& .MuiTypography-root': {
            fontSize: '0.75rem',
            fontWeight: 700,
            color: 'secondary.contrastText',
            textTransform: 'uppercase',
            px: 2,
            pt: 2,
          },
        }}
      />
      <List
        {...listProps}
        sx={{
          py: 0.5,
          '& .MuiListItem-root:not(:last-child)': {
            mb: 0.5,
          },
        }}
      >
        {drawerActions.map(
          ({ key, label, startIcon, onClick, href, target, customContent }) => (
            <ListItem key={key} disablePadding sx={{ display: 'block' }}>
              {customContent || (
                <Tooltip
                  title={label}
                  placement="right"
                  arrow
                  disableHoverListener={open}
                >
                  <ListItemButton
                    {...{ href, onClick, target }}
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      px: open ? 2.5 : 1.25,
                      borderRadius: 20,
                      mx: 0.5,
                      minHeight: '36px',
                      position: 'relative',
                      overflow: 'hidden',
                      bgcolor: 'transparent',
                      '&.Mui-selected': {
                        bgcolor: 'transparent',
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'secondary.light',
                        opacity: 0,
                        borderRadius: 'inherit',
                      },
                      '&:hover': {
                        '&::before': {
                          opacity: 1,
                          backgroundColor: theme.palette.action.hover,
                        },
                      },
                      '& > *': {
                        position: 'relative',
                        zIndex: 1,
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        pl: open ? 0 : 0.5,
                      }}
                    >
                      {key === 'install-mobile-app' ? (
                        <Badge
                          color="secondary"
                          variant="dot"
                          invisible={hasClickedExtension}
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: '#ff4270',
                              width: '6px',
                              height: '6px',
                              minWidth: '6px',
                            },
                          }}
                        >
                          {startIcon}
                        </Badge>
                      ) : (
                        startIcon
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      sx={{
                        opacity: open ? 1 : 0,
                        '& .MuiTypography-root': {
                          fontWeight:
                            (key === 'changelog' &&
                              showChangelogNotification) ||
                            (key === 'notifications' && hasUnreadNotifications)
                              ? 700
                              : 400,
                        },
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              )}
            </ListItem>
          )
        )}
      </List>
      {changelogOpen && (
        <Changelog
          open={changelogOpen}
          onClose={() => setChangelogOpen(false)}
        />
      )}
      <Notifications
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
      />
      <MobileAppDialog
        open={mobileAppOpen}
        onClose={() => setMobileAppOpen(false)}
      />
    </>
  );
};

export default DrawerActions;
