import { createAction } from '@reduxjs/toolkit';

// Action to track assistant queries in PostHog
const trackAssistantQuery = createAction<{ query: string }>(
  'assistant/trackQuery'
);

export default {
  trackAssistantQuery,
};
