interface ChangelogItem {
  version: string;
  date: string;
  changes: string[];
}

export const changelogItems: ChangelogItem[] = [
  {
    version: '6.21.6',
    date: '31 Mar 2025',
    changes: ['Added Video Analyzer support for templates'],
  },
  {
    version: '6.21.4',
    date: '26 Mar 2025',
    changes: [
      'Optimized Library view performance for faster loading times',
      'Added word-level timestamp support for more precise transcriptions',
      'Various UI improvements and bug fixes',
      'Added video captions',
    ],
  },
  {
    version: '6.21.3',
    date: '19 Mar 2025',
    changes: [
      'Added educational discount program',
      'Enhanced template sharing and printing capabilities',
      'Improved document download experience with advanced formatting',
      'Fixed payment processing for faster subscription upgrades',
      'Other bug fixes, UI improvements, and performance improvements',
    ],
  },
  {
    version: '6.21.0',
    date: '13 Mar 2025',
    changes: [
      'Enhanced transcript usage limit visibility with clearer upgrade prompts',
      'Added screenshot support in templates for better visual documentation',
      'Updated plugin setup guidelines for improved user experience',
      'Added Apple Sign-In support for authentication',
      'Improved team management interface and profile image updates',
      'Enhanced video URL validation and resource handling',
      'Various performance improvements and bug fixes',
    ],
  },
  {
    version: '6.20.16',
    date: '20 Feb 2025',
    changes: [
      'Added download option for AI summaries and templates',
      'Improved folder navigation in Move Recording modal with better scrolling',
      'Enhanced plugin integration with recorder functionality',
      'Added ability to customize built-in AI template prompts',
      'Improved device selection and permissions handling',
      'Enhanced recording reliability and device management',
    ],
  },
  {
    version: '6.20.13',
    date: '19 Feb 2025',
    changes: [
      'Enhanced Contact Sales workflow for better user experience',
      'Improved media handling and playback controls',
      'Updated UI elements for better visual consistency',
      'Enhanced email template system',
      'Fixed device selection and recording issues',
      'General performance improvements and bug fixes',
    ],
  },
  {
    version: '6.20.12',
    date: '17 Feb 2025',
    changes: ['Folder permissions', 'Bug fixes and performance enhancements'],
  },
  {
    version: '6.20.9',
    date: '11 Feb 2025',
    changes: [
      // List includes all changes from 6.20.3 to 6.20.9
      'Transcripts are now editable',
      'Added Hungarian language to translation feature',
      'Improved how webhooks work',
      'Better chapter summary with thumbnails',
      'Allowed team logo customization',
      'New theme and UI improvements',
      'Bug fixes and performance enhancements',
    ],
  },
  {
    version: '6.20.3',
    date: '23 Jan 2025',
    changes: [
      'Custom template persistence',
      'Bug fixes and performance enhancements',
    ],
  },
  {
    version: '6.20.0',
    date: '22 Jan 2025',
    changes: [
      'Faster and more accurate transcription engine',
      'Allow disabling email notifications for transcription',
      'Install ScreenApp as a Web App',
      'UI improvements',
      'Bug fixes and performance enhancements',
      // Most of these actually released between 6.19.15 and 6.20.0
    ],
  },
  {
    version: '6.19.15',
    date: '6 Jan 2025',
    changes: [
      'Introduced template output editor',
      'Added/fixed multi-video actions',
      'Added Hebrew and Danish to translate feature',
      'Resolved various bugs and enhanced overall performance',
      'UI improvements',
      // Trial is removed
      // Editable transcript [reverted, needs backend]
      // Zoom integration [still WIP]
      // Custom templates [backend only yet]
    ],
  },
  {
    version: '6.19.13',
    date: '27 Dec 2024',
    changes: [
      'Usage resets on the billing date for all plans, limits are applied for the billing period',
      'UI improvements',
    ],
  },
  {
    version: '6.19.10',
    date: '26 Dec 2024',
    changes: [
      'Introduced note-taking feature during recording sessions',
      'Redesigned the user interface for Multi Ask AI',
      'Resolved various bugs and enhanced overall performance',
    ],
  },
  {
    version: '6.19.3',
    date: '10 Dec 2024',
    changes: [
      'Added Contact Sales button for Business users',
      'Added Meeting bot (beta) with Google Calendar support',
      'Added translate feature for Summary and Templates',
      'Bug fixes and UI improvements',
    ],
  },
  {
    version: '6.19.0',
    date: '9 Dec 2024',
    changes: [
      'Enhanced template management with improved popup menu and tag support',
      'Added detailed summary and enhanced chapter generation capabilities',
      'Improved trimming interface and better folder organization',
      'Added download options for transcripts and templates',
      'Enhanced payment system and subscription management',
      'Improved failed URL import notifications',
      'UI improvements: sidebar redesign, folder navigation, and styling updates',
      'Various performance optimizations and bug fixes',
    ],
  },
  {
    version: '6.18.1',
    date: '3 Dec 2024',
    changes: [
      'Introducing Multi Ask AI - Convert multiple files into templates and perform powerful searches across your content',
      'Plugin and API documentation now available in settings for developers',
      'Streamlined onboarding experience',
      'Enhanced mobile video player with improved controls and performance',
      'Improved commenting and speaker selection capabilities',
      'Dark mode support for comfortable viewing',
      'Enhanced notifications system',
      'Improved template management and persistence',
      'UI/UX improvements for better navigation and discoverability',
      'Various bug fixes and performance enhancements',
    ],
  },
];

export const LATEST_VERSION = changelogItems[0].version;
