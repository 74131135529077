// eslint-disable-next-line import/prefer-default-export
export const USER_ROLES = {
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
  ADMIN: 'Admin',
} as const;

export const TEAM_SPACE_USER_ROLES = {
  ADMIN: 'Admin',
  MEMBER: 'Member',
  GUEST: 'Guest',
} as const;

export type TeamSpaceUserRoleType = typeof TEAM_SPACE_USER_ROLES[keyof typeof TEAM_SPACE_USER_ROLES];
