import { FolderType } from '../../../types';

interface TreeFolderType extends FolderType {
  children: TreeFolderType[];
}
export const compareCreatedAt = (
  { createdAt: a = new Date(0) }: { createdAt: Date | string },
  { createdAt: b = new Date(0) }: { createdAt: Date | string }
) => {
  return new Date(a).getTime() - new Date(b).getTime();
};

export const generateFolderTree = (
  folderList: FolderType[] = []
): TreeFolderType[] => {
  const tree: { [key: string]: TreeFolderType } = {};
  folderList.forEach((folder) => {
    tree[folder._id] = { ...folder, children: [] };
  });

  // Handles orphan folders, children created before parents, etc.
  for (let i = 0; i < folderList.length; i += 1) {
    const folder = tree[folderList[i]._id];
    if (
      folder.parentId &&
      folder.parentId !== 'null' &&
      folder.parentId in tree
    ) {
      tree[folder.parentId].children.push(folder);
    } else {
      folder.parentId = null;
    }
  }

  return Object.values(tree)
    .filter((folder: TreeFolderType) => folder.parentId === null)
    .sort(compareCreatedAt);
};
