import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  useTheme,
} from '@mui/material';
import { createPortal } from 'react-dom';
import { X, Smartphone, Monitor, SquarePlus } from 'lucide-mui';
import IosShareIcon from '@mui/icons-material/IosShare';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface PWAInstallProps {
  open: boolean;
  onClose: () => void;
}

const PWAInstall: React.FC<PWAInstallProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const [platform, setPlatform] = useState<
    'ios' | 'android' | 'chrome' | 'other'
  >('other');
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    // Detect platform
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      setPlatform('ios');
    } else if (/android/.test(userAgent)) {
      setPlatform('android');
    } else if (/chrome/.test(userAgent)) {
      setPlatform('chrome');
    }

    // Handle PWA install prompt
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setDeferredPrompt(null);
        onClose();
      }
    }
  };

  const renderArrows = () => {
    if (!open) return null;

    const arrowStyles = {
      fontSize: 48,
      color: theme.palette.background.paper,
      animation: 'bounce 2s infinite',
      filter: 'drop-shadow(0 0 8px rgba(0,0,0,0.4))',
    };

    switch (platform) {
      case 'ios':
        return createPortal(
          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
              pointerEvents: 'none',
            }}
          >
            <ArrowUpwardIcon
              sx={{
                ...arrowStyles,
                transform: 'rotate(180deg)',
              }}
            />
          </Box>,
          document.body
        );
      case 'android':
        return createPortal(
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              right: 20,
              transform: 'translateY(-50%)',
              zIndex: 9999,
              pointerEvents: 'none',
            }}
          >
            <ArrowUpwardIcon
              sx={{
                ...arrowStyles,
                transform: 'rotate(-90deg)',
              }}
            />
          </Box>,
          document.body
        );
      case 'chrome':
        return createPortal(
          <Box
            sx={{
              position: 'fixed',
              top: 20,
              right: '25%',
              zIndex: 9999,
              pointerEvents: 'none',
            }}
          >
            <ArrowUpwardIcon
              sx={{
                ...arrowStyles,
              }}
            />
          </Box>,
          document.body
        );
      default:
        return null;
    }
  };

  const renderInstructions = () => {
    switch (platform) {
      case 'ios':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Smartphone style={{ width: 48, height: 48 }} />
            <Typography variant="h6" gutterBottom>
              Install ScreenApp
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Get quick access to ScreenApp right from your home screen
            </Typography>
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                1. Tap{' '}
                <IosShareIcon
                  sx={{
                    width: 16,
                    height: 16,
                    verticalAlign: 'text-bottom',
                    display: 'inline-block',
                  }}
                />{' '}
                Share at the bottom of Safari
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                2. Scroll down and tap{' '}
                <SquarePlus
                  style={{
                    width: 16,
                    height: 16,
                    verticalAlign: 'text-bottom',
                    display: 'inline-block',
                  }}
                />{' '}
                Add to Home Screen
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                3. Tap &quot;Add&quot; in the top right corner
              </Typography>
            </Box>
          </Box>
        );
      case 'android':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Smartphone style={{ width: 48, height: 48 }} />
            <Typography variant="h6" gutterBottom>
              Install ScreenApp
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Get quick access to ScreenApp right from your home screen
            </Typography>
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Tap{' '}
                <MoreVertIcon
                  sx={{
                    width: 16,
                    height: 16,
                    verticalAlign: 'text-bottom',
                    display: 'inline-block',
                  }}
                />{' '}
                Menu, then &quot;Install app&quot;
              </Typography>
            </Box>
          </Box>
        );
      case 'chrome':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Monitor style={{ width: 48, height: 48 }} />
            <Typography variant="h6" gutterBottom>
              Install ScreenApp
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Get quick access to ScreenApp right from your desktop
            </Typography>
            {deferredPrompt ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleInstall}
                startIcon={<InstallDesktopIcon />}
                sx={{ minWidth: 200 }}
              >
                Install App
              </Button>
            ) : (
              <Box sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Click{' '}
                  <InstallDesktopIcon
                    sx={{
                      width: 16,
                      height: 16,
                      verticalAlign: 'text-bottom',
                      display: 'inline-block',
                    }}
                  />{' '}
                  in the address bar
                </Typography>
              </Box>
            )}
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Install ScreenApp
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Please open ScreenApp in Chrome, Safari, or your mobile browser to
              install the app.
            </Typography>
          </Box>
        );
    }
  };

  const getRotationAngle = () => {
    switch (platform) {
      case 'ios':
        return '180deg';
      case 'android':
        return '-90deg';
      default:
        return '0deg';
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: { borderRadius: 2 },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          Install ScreenApp
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'grey.500' }}
          >
            <X style={{ width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{renderInstructions()}</DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {platform === 'chrome' && deferredPrompt && (
            <Button variant="contained" onClick={handleInstall} color="primary">
              Install
            </Button>
          )}
          <Button onClick={onClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {renderArrows()}
      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0) rotate(${getRotationAngle()}); }
            50% { transform: translateY(-10px) rotate(${getRotationAngle()}); }
          }
        `}
      </style>
    </>
  );
};

export default PWAInstall;
