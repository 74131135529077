import { apiV2 } from '../lib/baseAxios';

interface EmailData {
  template: string;
  toEmail: string;
  data: {
    content: string;
    [key: string]: any;
  };
}

/**
 * Send an email using Listmonk
 * @param emailData The email data containing template, recipient, and content
 * @returns Promise with the API response
 */
// eslint-disable-next-line import/prefer-default-export
export const sendEmail = async (emailData: EmailData) => {
  return apiV2.post('/mail/send', emailData);
};

// Additional email-related API endpoints can be added here
