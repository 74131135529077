import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Folder,
  CalendarDays,
  GraduationCap,
  Briefcase,
  Building2,
  Users,
  Lightbulb,
  Microscope,
  Activity,
  Plane,
  Film,
  Home,
  Utensils,
  Laptop,
  Trophy,
  ShoppingCart,
  Target,
  UsersRound,
  Scale,
  ClipboardList,
  BarChart3,
  Video,
  Youtube,
  Code,
  DollarSign,
  BarChart,
  Settings,
  PhoneCall,
  Layout,
  FileText,
  Mic2,
  Music2,
  Radio,
  Headphones,
  Speaker,
  Podcast,
} from 'lucide-mui';
import useTags from '../../../../hooks/useTags';

const AVAILABLE_ICONS = [
  { name: 'Folder', icon: Folder },
  { name: 'Video', icon: Video },
  { name: 'Youtube', icon: Youtube },
  { name: 'Mic', icon: Mic2 },
  { name: 'Music', icon: Music2 },
  { name: 'Radio', icon: Radio },
  { name: 'Headphones', icon: Headphones },
  { name: 'Speaker', icon: Speaker },
  { name: 'Podcast', icon: Podcast },
  { name: 'Code', icon: Code },
  { name: 'DollarSign', icon: DollarSign },
  { name: 'BarChart', icon: BarChart },
  { name: 'Settings', icon: Settings },
  { name: 'PhoneCall', icon: PhoneCall },
  { name: 'Layout', icon: Layout },
  { name: 'FileText', icon: FileText },
  { name: 'CalendarDays', icon: CalendarDays },
  { name: 'GraduationCap', icon: GraduationCap },
  { name: 'Briefcase', icon: Briefcase },
  { name: 'Building2', icon: Building2 },
  { name: 'Users', icon: Users },
  { name: 'Lightbulb', icon: Lightbulb },
  { name: 'Microscope', icon: Microscope },
  { name: 'Activity', icon: Activity },
  { name: 'Plane', icon: Plane },
  { name: 'Film', icon: Film },
  { name: 'Home', icon: Home },
  { name: 'Utensils', icon: Utensils },
  { name: 'Laptop', icon: Laptop },
  { name: 'Trophy', icon: Trophy },
  { name: 'ShoppingCart', icon: ShoppingCart },
  { name: 'Target', icon: Target },
  { name: 'UsersRound', icon: UsersRound },
  { name: 'Scale', icon: Scale },
  { name: 'ClipboardList', icon: ClipboardList },
  { name: 'BarChart3', icon: BarChart3 },
];

interface FolderIconProps {
  open: boolean;
  onClose: () => void;
  folderId: string;
  currentIcon?: string;
}

const FolderIcon: React.FC<FolderIconProps> = ({
  open,
  onClose,
  folderId,
  currentIcon = 'Folder',
}) => {
  const { setTag, getTag } = useTags();
  const folderIconsTag = getTag('folder_icons');
  const folderIcons = folderIconsTag ? JSON.parse(folderIconsTag) : {};
  const [selectedIcon, setSelectedIcon] = React.useState(currentIcon);

  const handleIconSelect = (iconName: string) => {
    setSelectedIcon(iconName);
  };

  const handleSave = () => {
    const updatedIcons = { ...folderIcons, [folderId]: selectedIcon };
    setTag({ key: 'folder_icons', value: JSON.stringify(updatedIcons) });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle sx={{ pb: 1, fontSize: '0.9rem' }}>Change Icon</DialogTitle>
      <DialogContent sx={{ pt: 0, pb: 1 }}>
        <Grid container spacing={0.5}>
          {AVAILABLE_ICONS.map(({ name, icon: Icon }) => (
            <Grid item xs={2} key={name}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  p: 1,
                  borderRadius: 1,
                  transition: 'all 0.15s',
                  bgcolor:
                    selectedIcon === name ? 'action.selected' : 'transparent',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  aspectRatio: '1',
                }}
                onClick={() => handleIconSelect(name)}
              >
                <Icon style={{ width: 18, height: 18, opacity: 0.8 }} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 2, py: 1.5 }}>
        <Button
          onClick={onClose}
          size="small"
          sx={{ color: 'text.secondary', minWidth: 60 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          size="small"
          variant="contained"
          color="primary"
          sx={{ minWidth: 60 }}
          disabled={!selectedIcon}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FolderIcon;
export { AVAILABLE_ICONS };
