import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import Loader from './loader/LoaderFullScreen';
import { useAuthQueryParams } from '../../hooks/urlHooks';

const DefaultRoute = () => {
  const queryString = useAuthQueryParams();
  const { team } = useSelector((state: RootState) => state) || {};
  const { teamId } = team.selectedTeam || {};
  useEffect(() => {}, [teamId]);
  if (!teamId) return <Loader />;
  return <Navigate to={`/library/${teamId}/recents${queryString}`} />;
};

export default DefaultRoute;
