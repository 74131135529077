import React, { useMemo, useState, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Typography,
  Tooltip,
  ListItemButton,
  ListItemText,
  IconButton,
  Collapse,
  List,
  alpha,
} from '@mui/material';
import {
  Folder,
  Plus,
  FolderPlus,
  ChevronDown,
  ChevronRight,
  Lock,
  Users2,
  CalendarDays,
  GraduationCap,
  Briefcase,
  Building2,
  Users,
  Lightbulb,
  Microscope,
  Activity,
  Plane,
  Film,
  Home,
  Utensils,
  Laptop,
  Trophy,
  ShoppingCart,
  Target,
  UsersRound,
  Scale,
  ClipboardList,
  BarChart3,
  Video,
  Youtube,
  Code,
  DollarSign,
  BarChart,
  Settings,
  PhoneCall,
  Layout,
  FileText,
  Mic2,
  Music2,
  Radio,
  Headphones,
  Speaker,
  Podcast,
} from 'lucide-mui';
import type { RootState } from '../../../reducers';
import { generateFolderTree } from './folderHelper';
import { FolderType } from '../../../types';
import { getFolderUrl } from '../../../lib/routingUtils';
import { RouteParams } from '../types';
import FolderMenu from './actions';
import { pushModal } from '../../globalModals/globalModalsSlice';
import useLimits from '../../plan/hooks/limits';
import { updateSettings } from '../../settings/settingsSlice';
import { setDrawerState } from '../../dashboard/dashboardSlice';
import useTags from '../../../hooks/useTags';
import FolderAccessDetails from './actions/FolderAccessDetails';

const ICON_MAP = {
  Folder,
  Video,
  Youtube,
  Mic: Mic2,
  Music: Music2,
  Radio,
  Headphones,
  Speaker,
  Podcast,
  Code,
  DollarSign,
  BarChart,
  Settings,
  PhoneCall,
  Layout,
  FileText,
  CalendarDays,
  GraduationCap,
  Briefcase,
  Building2,
  Users,
  Lightbulb,
  Microscope,
  Activity,
  Plane,
  Film,
  Home,
  Utensils,
  Laptop,
  Trophy,
  ShoppingCart,
  Target,
  UsersRound,
  Scale,
  ClipboardList,
  BarChart3,
};

const FolderListItem = ({
  folder,
  open,
  level = 0,
}: {
  folder: FolderType;
  open: boolean;
  level?: number;
}) => {
  const [expanded, setExpanded] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showAccessDetails, setShowAccessDetails] = useState(false);
  const { folderId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const muiTheme = useTheme();

  // Get fresh folder data from Redux with a more specific selector
  const { currentFolder, selectedTeamId, isMobileView } = useSelector(
    (state: RootState) => ({
      currentFolder:
        state.library.folders.data.find((f) => f._id === folder._id) || folder,
      selectedTeamId: state.team.selectedTeam?.teamId,
      isMobileView: useMediaQuery(muiTheme.breakpoints.down('sm')),
    })
  );

  // Memoize the folder URL to prevent recalculation on every render
  const folderUrl = useMemo(() => {
    if (!selectedTeamId || !folder._id) {
      return null;
    }
    return getFolderUrl({
      folderId: folder._id,
      teamId: selectedTeamId,
      withBasePath: false,
    });
  }, [folder._id, selectedTeamId]);

  const { selectedTeam, teamMembers } = useSelector((state: RootState) => ({
    selectedTeam: state.team.selectedTeam,
    teamMembers: state.team.selectedTeam?.teamSpaceMembers || [],
  }));

  // Get owner info from selectedTeam
  const owner = teamMembers.find(
    (member) => member._id === selectedTeam?.ownerId
  ) || {
    _id: selectedTeam?.ownerId || '',
    email: selectedTeam?.email || '',
    name: 'Team Owner',
    picture: null,
    status: true,
    role: 'Owner',
    createdAt: '',
    inviteAccepted: true,
    lastActiveAt: '',
    updatedAt: '',
  };

  // Combine owner with team members if not already included
  const allMembers = teamMembers.some((member) => member._id === owner._id)
    ? teamMembers
    : [owner, ...teamMembers];

  // Calculate number of users with access
  const usersWithAccess =
    currentFolder.permissions === null
      ? allMembers.length // All team members have access
      : (currentFolder.permissions?.filter((p) => p.userId !== owner._id)
          .length || 0) + 1; // +1 for folder owner

  const getAccessTooltip = () => {
    if (currentFolder.permissions === null) {
      return 'Team access';
    }

    if (usersWithAccess === 1) {
      return 'Private';
    }

    return `Private (${usersWithAccess})`;
  };

  const { getTag } = useTags();
  const folderIconsTag = getTag('folder_icons');
  const folderIcons = folderIconsTag ? JSON.parse(folderIconsTag) : {};
  const iconName = folderIcons[currentFolder._id] || 'Folder';
  const IconComponent = ICON_MAP[iconName as keyof typeof ICON_MAP] || Folder;

  const hasChildren =
    currentFolder.children && currentFolder.children.length > 0;
  const isSelected = folderId === currentFolder._id;

  // Split the click handler into two separate functions
  const handleExpand = useCallback(() => {
    if (hasChildren) {
      setExpanded((prev) => !prev);
    }
  }, [hasChildren]);

  const handleNavigation = useCallback(() => {
    if (!folderUrl) {
      console.warn('Cannot navigate: missing team ID or folder ID');
      return;
    }

    try {
      navigate(folderUrl);

      if (isMobileView) {
        dispatch(setDrawerState('closed'));
      }
    } catch (error) {
      console.error('Navigation failed:', error);
      // If using window.location as fallback, we need to include the hash
      window.location.href = `${window.location.pathname}#${folderUrl}`;
    }
  }, [folderUrl, isMobileView, dispatch, navigate]);

  // Combined click handler that manages both expansion and navigation
  const handleFolderClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      // First handle expansion
      if (hasChildren) {
        handleExpand();
      }

      // Then handle navigation
      handleNavigation();
    },
    [hasChildren, handleExpand, handleNavigation]
  );

  const renderLabel = () => {
    const { name } = currentFolder;

    return (
      <Tooltip title={name} placement="right" arrow disableHoverListener={open}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: open ? 0 : 0,
            justifyContent: open ? 'space-between' : 'center',
            position: 'relative',
            height: '45px',
            py: 1,
            width: open ? '100%' : 'auto',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              '& .folder-menu': {
                opacity: 1,
              },
              '& .permission-indicator': {
                opacity: 1,
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              textDecoration: 'none',
              color: 'inherit',
              flexGrow: open ? 1 : 0,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Box
              component="span"
              sx={{
                mr: open ? 1.5 : 0,
                ml: open ? 0 : 0.5,
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              <IconComponent />
            </Box>
            {open && (
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  minWidth: 0,
                  flex: 1,
                  fontWeight: isSelected ? 700 : 400,
                }}
              >
                {name}
              </Typography>
            )}
            {open && (
              <Tooltip title={getAccessTooltip()} arrow>
                <Box
                  className="permission-indicator"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAccessDetails(true);
                  }}
                  sx={{
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                    display: isMobileView && !showMenu ? 'none' : 'flex',
                    alignItems: 'center',
                    ml: 1,
                    color: 'text.secondary',
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'primary.main',
                    },
                    '& svg': {
                      width: '1rem',
                      height: '1rem',
                    },
                  }}
                >
                  {currentFolder.permissions === null ? <Users2 /> : <Lock />}
                </Box>
              </Tooltip>
            )}
          </Box>
          {open && (
            <Box
              className="folder-menu"
              sx={{
                opacity: showMenu ? 1 : 0,
                transition: 'opacity 0.4s ease-in-out',
                height: '100%',
                display: isMobileView && !showMenu ? 'none' : 'block',
                flexShrink: 0,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(true);
              }}
            >
              <FolderMenu folder={currentFolder} />
            </Box>
          )}
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      <ListItemButton
        onMouseEnter={() => !isMobileView && setShowMenu(true)}
        onMouseLeave={() => !isMobileView && setShowMenu(false)}
        sx={{
          pl: 2 + level * 3,
          margin: '4px 4px',
          borderRadius: 20,
          height: '45px',
          position: 'relative',
          overflow: 'hidden',
          bgcolor: 'transparent',
          '&.Mui-selected': {
            bgcolor: 'transparent',
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: isSelected
              ? muiTheme.palette.action.hover
              : muiTheme.palette.action.hover,
            opacity: isSelected ? 1 : 0,
            borderRadius: 'inherit',
          },
          '&:hover': {
            '&::before': {
              opacity: 1,
            },
          },
          '& > *': {
            position: 'relative',
            zIndex: 1,
          },
        }}
        onClick={handleFolderClick}
      >
        {hasChildren && (
          <Box
            component="span"
            sx={{
              display: 'flex',
              mr: 1,
              ml: -2,
            }}
          >
            {expanded ? <ChevronDown /> : <ChevronRight />}
          </Box>
        )}
        {renderLabel()}
      </ListItemButton>
      {hasChildren && (
        <Collapse in={expanded}>
          <List disablePadding>
            {currentFolder.children.map((child) => (
              <FolderListItem
                key={`${child._id}-${child.permissions === null}`}
                folder={child}
                open={open}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
      <FolderAccessDetails
        open={showAccessDetails}
        onClose={() => setShowAccessDetails(false)}
        folder={currentFolder}
      />
    </>
  );
};

// Memoize the FolderListItem to prevent unnecessary re-renders
const MemoizedFolderListItem = memo(FolderListItem, (prevProps, nextProps) => {
  return (
    prevProps.folder._id === nextProps.folder._id &&
    prevProps.open === nextProps.open &&
    prevProps.level === nextProps.level
  );
});

export const FolderTreeView = () => {
  const dispatch = useDispatch();
  const guardLimits = useLimits();
  const [isHovered, setIsHovered] = useState(false);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Split selectors to minimize re-renders
  const folderListExpanded = useSelector(
    (state: RootState) => state.settings.ui.folderListExpanded
  );
  const drawerState = useSelector(
    (state: RootState) => state.dashboard.drawerState
  );
  const open = drawerState === 'open';

  // Memoize folder data selector
  const folderData = useSelector((state: RootState) => ({
    folders: state.library.folders.data,
    permissionsStatus: state.library.actions.permissions.status,
  }));

  // Memoize folder tree generation with stable dependencies
  const folderTree = useMemo(() => {
    return generateFolderTree(folderData.folders);
  }, [folderData.folders]); // Remove permissionsStatus dependency unless absolutely necessary

  // Memoize handlers to prevent recreation
  const handleCreateFolder = useCallback(() => {
    guardLimits('folderManagement', () => {
      dispatch(pushModal({ type: 'create-folder-modal' }));
      if (isMobile) {
        dispatch(setDrawerState('closed'));
      }
    });
  }, [dispatch, guardLimits, isMobile]);

  const handleToggleFolderList = useCallback(() => {
    dispatch(
      updateSettings({
        key: 'ui',
        newSettings: { folderListExpanded: !folderListExpanded },
      })
    );
  }, [dispatch, folderListExpanded]);

  return (
    <Box>
      <Tooltip
        title="Create Folder"
        placement="right"
        arrow
        disableHoverListener={open}
      >
        {open ? (
          <ListItemButton
            onClick={handleToggleFolderList}
            sx={(theme) => ({
              justifyContent: 'initial',
              px: 2.5,
              height: '45px',
              position: 'relative',
              overflow: 'hidden',
              margin: '3px 4px',
              borderRadius: 20,
              '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: alpha(theme.palette.action.hover, 1),
                transition: theme.transitions.create(
                  ['opacity', 'background-color'],
                  {
                    duration: theme.transitions.duration.shorter,
                    easing: theme.transitions.easing.easeOut,
                  }
                ),
                opacity: 0,
                borderRadius: 'inherit',
              },
              '&:hover': {
                '&::before': {
                  opacity: 1,
                },
                '& .MuiListItemIcon-root .MuiSvgIcon-root': {
                  transform: 'scale(1.1)',
                },
              },
              '& > *': {
                position: 'relative',
                zIndex: 1,
              },
            })}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ChevronDown
              sx={{
                position: 'absolute',
                left: isHovered ? '12px' : '-28px',
                transition: 'all 0.15s ease-in-out',
                transform: folderListExpanded
                  ? 'rotate(0deg)'
                  : 'rotate(-90deg)',
              }}
            />
            <ListItemText
              primary="Folders"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  color: 'secondary.contrastText',
                  textTransform: 'uppercase',
                  transition: 'padding-left 0.15s ease-in-out',
                  paddingLeft: isHovered ? '28px' : '0px',
                },
              }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleCreateFolder();
              }}
              data-testid="create-folder-button"
              sx={{
                marginLeft: 'auto',
              }}
            >
              <Plus />
            </IconButton>
          </ListItemButton>
        ) : (
          <IconButton
            onClick={handleCreateFolder}
            data-testid="create-folder-button"
            sx={{
              padding: '14px 16px',
              borderRadius: 20,
              margin: '4px 4px',
              width: 'calc(100% - 8px)',
              height: '45px',
              justifyContent: 'flex-start',
              '& .MuiSvgIcon-root': {
                transform: 'translateZ(0)',
                willChange: 'transform',
                backfaceVisibility: 'hidden',
              },
              '&:hover .MuiSvgIcon-root': {
                transform: 'translateZ(0) scale(1.1)',
              },
            }}
          >
            <FolderPlus />
          </IconButton>
        )}
      </Tooltip>

      <Collapse in={folderListExpanded}>
        <List disablePadding>
          {folderTree.map((folder) => (
            <MemoizedFolderListItem
              key={`${folder._id}-${folder.permissions === null}`}
              folder={folder}
              open={open}
            />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default FolderTreeView;
