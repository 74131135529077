import React from 'react';
import { Box, useTheme, GlobalStyles, CircularProgress } from '@mui/material';

const LoaderFullScreen = () => {
  const theme = useTheme();

  // Add global styles to remove margin and padding from body when loader is mounted
  const globalStyles = (
    <GlobalStyles
      styles={{
        body: {
          margin: 0,
          padding: 0,
          overflow: 'hidden',
        },
      }}
    />
  );

  return (
    <>
      {globalStyles}
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: theme.palette.background.default,
          margin: 0,
          // Add GPU acceleration
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
          perspective: 1000,
          willChange: 'transform',
        }}
      >
        <Box
          sx={{
            margin: 0,
            // Add GPU acceleration for child element
            transform: 'translateZ(0)',
            backfaceVisibility: 'hidden',
            willChange: 'transform',
          }}
        >
          <CircularProgress size={40} color="secondary" />
        </Box>
      </Box>
    </>
  );
};

export default LoaderFullScreen;
