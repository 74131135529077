import * as React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from './toolbar';
import CustomDrawer from './drawer';
import AlertMessage from './AlertMessage';
import { setDrawerState } from './dashboardSlice';
import GlobalModalManager from '../globalModals/GlobalModalManager';
import MultiAskAIManager from './MultiAskAIManager';
import MaintenanceBanner from '../common/MaintenanceBanner';
import TrialDialogManager from '../plan/upgrade/TrialDialogManager';

// Create a context for MultiAskAI state management
export const MultiAskAIContext = React.createContext<{
  showMultiAskAI: boolean;
  setShowMultiAskAI: (show: boolean) => void;
}>({
  showMultiAskAI: false,
  setShowMultiAskAI: () => {},
});

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const Dashboard = ({ children }: BoxProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [showMultiAskAI, setShowMultiAskAI] = React.useState(false);

  // Set initial drawer state before first render
  React.useLayoutEffect(() => {
    dispatch(setDrawerState(isMobile ? 'closed' : 'open'));
    setIsInitialized(true);
  }, [isMobile]);

  return (
    <MultiAskAIContext.Provider value={{ showMultiAskAI, setShowMultiAskAI }}>
      <Box
        sx={{
          display: 'flex',
          margin: 0,
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
        }}
      >
        <CssBaseline />
        <Toolbar />
        {/* Only render drawer after initial state is set and not on server */}
        {isInitialized && typeof window !== 'undefined' && <CustomDrawer />}
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
            position: 'relative',
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <DrawerHeader />
          <MaintenanceBanner />
          <AlertMessage />
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Box>
      </Box>
      <GlobalModalManager />
      <TrialDialogManager />
      <MultiAskAIManager
        open={showMultiAskAI}
        onClose={() => setShowMultiAskAI(false)}
      />
    </MultiAskAIContext.Provider>
  );
};

export default Dashboard;
