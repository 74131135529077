/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { requestStatusType } from '../../types';
import { addNotification } from '../dashboard/dashboardSlice';
import { importFile } from '../../services/fileSystemService';
import { addFile } from '../library/librarySlice';

interface ImportState {
  status: requestStatusType;
}

interface ImportUrlThunkArg {
  teamId: string;
  folderId: string;
  url: string;
}

export const importUrl = createAsyncThunk(
  'import/importUrl',
  async (
    { teamId, folderId, url }: ImportUrlThunkArg,
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(
        addNotification({
          id: Date.now().toString(),
          type: 'processing',
          title: 'Importing file',
          message: `We're importing your file. This might take a few moments.`,
          read: false,
          timestamp: Date.now(),
        })
      );
      const {
        data: {
          data: { file },
        },
      } = await importFile(teamId, folderId, url, {});
      dispatch(addFile(file));
      dispatch(
        addNotification({
          id: Date.now().toString(),
          type: 'transcription',
          title: 'Import complete',
          message: `"${file.name}" has been imported successfully`,
          read: false,
          timestamp: Date.now(),
        })
      );
      return file;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState: ImportState = {
  status: 'idle',
} as ImportState;

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    resetImportState: (state) => {
      state.status = 'idle';
    },
    reset: (state) => {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importUrl.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(importUrl.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(importUrl.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { resetImportState, reset } = importSlice.actions;

export default importSlice.reducer;
