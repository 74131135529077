import ALLOWED_TYPES from './AllowedTypes';
import logger from '../../lib/logger';

const MAX_DURATION_HOURS = 3;
const MAX_DURATION_SECONDS = MAX_DURATION_HOURS * 3600;

const getFileDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    let mediaElement: HTMLAudioElement | HTMLVideoElement;

    if (file.type.startsWith('audio')) {
      mediaElement = new Audio();
    } else if (file.type.startsWith('video') || file.name.endsWith('.mkv')) {
      mediaElement = document.createElement('video');
    } else {
      reject(new Error('Unsupported media type'));
      return;
    }

    const objectURL = URL.createObjectURL(file);

    mediaElement.src = objectURL;

    mediaElement.onloadedmetadata = () => {
      URL.revokeObjectURL(objectURL);
      resolve(mediaElement.duration);
    };

    mediaElement.onerror = () => {
      URL.revokeObjectURL(objectURL);
      reject(new Error('Error loading media file'));
    };
  });
};

interface ValidationResult {
  isValid: boolean;
  errorMessage?: string;
  errorType?: 'FILE_TYPE' | 'DURATION' | 'LOADING_ERROR';
}

const validateFileSizes = async (files: File[]): Promise<ValidationResult> => {
  const validationResults = await Promise.all(
    files.map(
      async (file): Promise<ValidationResult> => {
        if (!ALLOWED_TYPES.includes(file.type)) {
          const errorMessage = `"${file.name}" cannot be uploaded. You can upload video files (MP4, MOV, etc.) or audio files (MP3, WAV, etc.).`;
          logger.error(new Error(errorMessage));
          return {
            isValid: false,
            errorMessage,
            errorType: 'FILE_TYPE',
          };
        }

        try {
          const duration = await getFileDuration(file);
          if (duration > MAX_DURATION_SECONDS) {
            const errorMessage = `"${file.name}" is too long. Videos must be ${MAX_DURATION_HOURS} hours or shorter.`;
            logger.error(new Error(errorMessage));
            return {
              isValid: false,
              errorMessage,
              errorType: 'DURATION',
            };
          }
        } catch (error) {
          logger.error('File validation error:', error);
          const errorMessage = `Unable to process "${file.name}". The file might be corrupted or in an unsupported format.`;
          return {
            isValid: false,
            errorMessage,
            errorType: 'LOADING_ERROR',
          };
        }

        return { isValid: true };
      }
    )
  );

  const errors = validationResults.filter((result) => !result.isValid);
  const errorMessage = errors.map((result) => result.errorMessage).join('\n');
  const errorType = errors.length > 0 ? errors[0].errorType : undefined;

  return {
    isValid: errorMessage.length === 0,
    errorMessage,
    errorType,
  };
};

export default validateFileSizes;
