/**
 * We use multiple feature flags types. The order of precedence is:
 * 1. Url feature flags: These are set in the url query params and are used to test features in production.
 * 2. Growthbook feature flags
 * 3. Local storage feature flags: These are set in local storage (above flags are saved here after the first time they are set).
 * 4. Default feature flags: These are the default values for all feature flags.
 * // In any child component, use the custom hooks to access the feature flags and their values:
import { useIsFeatureEnabled, useFeatureValue } from './FeatureFlag';

const MyComponent: React.FC = () => {
  const isNewFeatureEnabled = useIsFeatureEnabled('newFeatureEnabled');
  const someFeatureValue = useFeatureValue<string>('someFeatureValue');
  const anotherFeatureValue = useFeatureValue<number>('anotherFeatureValue');

  return (
    <div>
      {isNewFeatureEnabled ? 'New Feature is enabled!' : 'New Feature is disabled.'}
      <p>Some Feature Value: {someFeatureValue}</p>
      <p>Another Feature Value: {anotherFeatureValue}</p>
    </div>
  );
};
 *
 */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import {
  FeatureFlagsType,
  featureFlags as defaultFeatureFlags,
  FeatureFlagsKeyType,
  OnOffFeatureFlagsKeyType,
} from '../constants/featureFlags';
import { loadFeatureFlags as getAllFeatureFlags } from '../lib/featureFlags';

interface FeatureFlagProviderPropsType {
  featureFlags: FeatureFlagsType;
  flagsLoaded: boolean;
}
const FeatureFlagContext = createContext<FeatureFlagProviderPropsType>({
  featureFlags: defaultFeatureFlags,
  flagsLoaded: true,
});

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsType>(
    defaultFeatureFlags
  );
  const [flagsLoaded, setflagsLoaded] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;
    async function loadFeatureFlags() {
      const mergedFeatureFlags = await getAllFeatureFlags();
      // Only update state if the component is still mounted
      if (isMounted) {
        setFeatureFlags(mergedFeatureFlags);
        setflagsLoaded(true);
      }
    }
    loadFeatureFlags();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, flagsLoaded }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagProviderPropsType => {
  const { featureFlags, flagsLoaded } = useContext(FeatureFlagContext);
  return { featureFlags, flagsLoaded };
};

export const useIsFlagsLoaded = (): boolean => {
  const { flagsLoaded } = useFeatureFlags();
  return flagsLoaded;
};

export const useIsFeatureEnabled = (
  featureName: OnOffFeatureFlagsKeyType
): boolean => {
  const { featureFlags } = useFeatureFlags();
  return (featureFlags[featureName] as boolean) || false;
};

export const useFeatureValue = <T extends boolean | string | number>(
  featureName: FeatureFlagsKeyType
): T | null => {
  const { featureFlags } = useFeatureFlags();
  return (featureFlags[featureName] as T) || null;
};
