import { LibraryTabType, PackageType } from '../../types';

export interface IUsage {
  recordingRequestsCount: number;
  askAICount: number;
  transcriptionCount: number;
  copyTranscriptCount: number;
  uploadCount: number; // both uploads and imports are counted here
  mp4DownloadCount: number;
  webmDownloadCount: number;
  mkvDownloadCount: number;
  mp3DownloadCount: number;
}

export interface PlanLimits extends IUsage {
  recordingLength: number;
  librarySize: number;
  priority: number;
}

export type PlanLimitsKeys = keyof PlanLimits;

export interface PackageLimits extends PlanLimits {
  isTreeViewEnabled: Boolean;
  trimmer: Boolean;
  canUpload: Boolean;
  canShare: Boolean;
  canCloudSync: Boolean;
  plugin: Boolean;
  canRequest: Boolean;
  integrations: Boolean;
  generalUpgrade: Boolean;
}

export interface PackageProperties extends PackageLimits {
  id: string;
  title: string;
}

export const LIBRARY_TABS: LibraryTabType[] = ['team'];

export const USER_PACKAGE: { [key in PackageType]: PackageType } = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
  STANDARD: 'STANDARD',
  FREE_LOGGEDIN: 'FREE_LOGGEDIN',

  ENTERPRISE: 'ENTERPRISE',
  BUSINESS: 'BUSINESS',
  GROWTH: 'GROWTH',

  FREE: 'FREE',
};

// This is about enabling main routing/views for each package.
export const PERMISSION = {
  DASHBOARD: 'DASHBOARD',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  LIBRARY: 'LIBRARY',
  SHARE: 'SHARE',
  EDITOR: 'EDITOR',
  RECORDER: 'RECORDER',
};

export const PACKAGE_PERMISSION = {
  PREMIUM: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'EDITOR', 'RECORDER'],
  STANDARD: [
    'DASHBOARD',
    'SIGN_IN',
    'SIGN_UP',
    'LIBRARY',
    'EDITOR',
    'RECORDER',
  ],
  FREE_LOGGEDIN: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'RECORDER'],
  GROWTH: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'EDITOR', 'RECORDER'],
  BUSINESS: [
    'DASHBOARD',
    'SIGN_IN',
    'SIGN_UP',
    'LIBRARY',
    'EDITOR',
    'RECORDER',
  ],
  FREE: ['SIGN_IN', 'SIGN_UP'],
};

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;

export const DEFAULT_PACKAGE_CONFIG: PackageProperties = {
  id: '2',
  title: 'Free Registered',
  recordingLength: 1 * HOUR,
  librarySize: 10,
  recordingRequestsCount: 5,
  askAICount: 5,
  transcriptionCount: 1,
  copyTranscriptCount: 1,
  uploadCount: 3,
  webmDownloadCount: 3,
  mkvDownloadCount: 3,
  mp4DownloadCount: 0,
  mp3DownloadCount: 0,
  isTreeViewEnabled: true,
  trimmer: true,
  canUpload: true,
  canShare: true,
  canCloudSync: true,
  plugin: false,
  canRequest: true,
  priority: 1,
  integrations: false,
  generalUpgrade: true,
};

export const CANCEL_PLAN_FORM_URL = 'https://view.forms.app/screenapp/cancel';
