import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List, Box, useTheme } from '@mui/material';
import FileListItem from './FileListItem';
import { RootState } from '../../../../reducers';
import { fetchFiles } from '../../librarySlice';
import FilesSkeleton from './FilesSkeleton';
import { RouteParams } from '../../types';
import { getFileUrl } from '../../../../lib/routingUtils';
import PendingUploads from '../../../upload/PendingUploads';
import PendingImport from '../../../import/PendingImport';
import FilesEmpty from '../FilesEmpty';
import { useQuery } from '../../../../hooks/urlHooks';
import FileListHeader from './FileListHeader';

export const FileGrid = () => {
  const theme = useTheme();
  const { library, upload, import: importState } = useSelector(
    (state: RootState) => state
  );
  const { activeUploads } = upload;
  const dispatch = useDispatch();
  const { teamId, folderId } = useParams<RouteParams>();
  const { searchQuery, sortBy = 'createdAt', sortOrder = 'desc' } = useQuery();
  const { files } = library;
  const { selectedFiles } = library;
  const { status } = files;
  const [isNearBottom, setIsNearBottom] = useState(false);

  const handleScroll = useCallback(() => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    const threshold = window.innerHeight; // Start loading when within one viewport height

    // Update near-bottom state for early skeleton display
    setIsNearBottom(distanceFromBottom < threshold);

    if (
      distanceFromBottom < threshold &&
      files.status !== 'pending' &&
      files.pagination.total > files.data.length
    ) {
      const nextPage = files.pagination.page + 1;
      dispatch(
        fetchFiles({
          teamId,
          folderId,
          page: nextPage,
          limit: 20,
          searchQuery,
          sortBy,
          sortOrder,
        })
      );
    }
  }, [files, dispatch, teamId, folderId, searchQuery, sortBy, sortOrder]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!teamId || !folderId) {
    return null;
  }

  if (
    files.data?.length === 0 &&
    activeUploads.length === 0 &&
    importState.status !== 'pending' &&
    status !== 'pending'
  ) {
    return <FilesEmpty />;
  }

  const showSkeleton =
    status === 'pending' ||
    (isNearBottom && files.pagination.total > files.data.length);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <FileListHeader />
      <List
        sx={{
          width: '100%',
          padding: 0,
          '& > *:last-child': { borderBottom: 0 },
        }}
      >
        <PendingUploads view="list" />
        <PendingImport view="list" />
        {files.data.map((file) => {
          const isSelected = !!selectedFiles[file._id];
          return (
            <FileListItem
              file={file}
              isSelected={isSelected}
              freeUserTimeLimit={files.freeUserTimeLimit}
              fileUrl={getFileUrl({
                teamId,
                parentId: folderId,
                fileId: file._id,
                withBasePath: true,
              })}
              key={file._id}
            />
          );
        })}
        {showSkeleton && <FilesSkeleton />}
      </List>
    </Box>
  );
};

export default FileGrid;
