import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography, IconButton, Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Mic,
  Webcam,
  Download,
  MonitorDot,
  Calendar,
  ChevronLeft,
  ChevronRight,
  Sparkles,
  Check,
  Headset,
  UserPlus,
  Blocks,
  CloudDownload,
} from 'lucide-mui';
import { ActionType } from '../../../../types';
import Upload from '../../../upload';
import { initializeRecorder } from '../../../recording/recordingSlice';
import { RouteParams } from '../../types';
import { pushModal } from '../../../globalModals/globalModalsSlice';
import { DRAWER_WIDTH } from '../../../../constants/ui';
import { RootState } from '../../../../reducers';
import { intents } from './intents';
import { trackMediaAction } from './mediaActionsSlice';
import PWAInstall from './pwainstall';
import { getSettingsUrl } from '../../../../lib/routingUtils';
// Import Apple logo SVG
import AppleIcon from '../../../../assets/icons/applelogo.svg';
// import { uploadFiles } from '../../../upload/uploadSlice';

type LibraryActionKeyType =
  | 'upload'
  | 'record-audio'
  | 'record-screen'
  | 'import-from-url'
  | 'record-video'
  | 'instant-meeting'
  | 'join-meeting'
  | 'install-app'
  | 'chrome-install'
  | 'add-team-member'
  | 'integrations';

interface ExtendedActionType<T> extends ActionType<T> {
  tooltip: string;
  comingSoon?: boolean;
}

type MediaActionType = ExtendedActionType<LibraryActionKeyType>;

type Gradient = {
  startColor: string;
  endColor: string;
  name: string;
};

const gradients: Record<string, Gradient> = {
  purple: {
    startColor: '#8A2BE2',
    endColor: '#BA55D3',
    name: 'Purple gradient',
  },
  pink: {
    startColor: '#E16574',
    endColor: '#D263BA',
    name: 'Pink gradient',
  },
  blue: {
    startColor: '#4E54C8',
    endColor: '#8F94FB',
    name: 'Blue gradient',
  },
  teal: {
    startColor: '#06aab0',
    endColor: '#05cdac',
    name: 'Teal gradient',
  },
  orange: {
    startColor: '#FF6347',
    endColor: '#FFA07A',
    name: 'Orange gradient',
  },
};

const gradientsList = Object.values(gradients);

const desktopActions: MediaActionType[] = [
  {
    key: 'import-from-url',
    label: 'Import URL',
    tooltip: 'Add media from an online source',
    startIcon: <CloudDownload />,
  },
  {
    key: 'record-screen',
    label: 'Record Screen',
    tooltip: 'Capture screen content with optional audio/webcam',
    startIcon: <MonitorDot />,
  },
  {
    key: 'record-audio',
    label: 'Record Audio',
    tooltip: 'Capture voice or sound using your microphone',
    startIcon: <Mic />,
  },
  {
    key: 'add-team-member',
    label: 'Add Team Member',
    tooltip: 'Invite new members to your team',
    startIcon: <UserPlus />,
  },
  {
    key: 'instant-meeting',
    label: 'Instant Meeting',
    tooltip: 'Video call with auto-recording to your library',
    startIcon: <Headset />,
  },
  {
    key: 'record-video',
    label: 'Record Webcam',
    tooltip: "Capture video using your device's camera",
    startIcon: <Webcam />,
  },
  {
    key: 'join-meeting',
    label: 'Auto Join Meetings',
    tooltip: 'Connect your calendar to auto join meetings',
    startIcon: <Calendar />,
    comingSoon: false,
  },
  {
    key: 'integrations',
    label: 'Integrations',
    tooltip: 'Connect with your favorite tools and services',
    startIcon: <Blocks />,
    comingSoon: false,
  },
];

const mobileActions: MediaActionType[] = [
  {
    key: 'record-audio',
    label: 'Record Audio',
    tooltip: 'Capture voice or sound using your microphone',
    startIcon: <Mic />,
  },
  {
    key: 'import-from-url',
    label: 'Import URL',
    tooltip: 'Add media from an online source',
    startIcon: <CloudDownload />,
  },
  {
    key: 'record-video',
    label: 'Record Webcam',
    tooltip: "Capture video using your device's camera",
    startIcon: <Webcam />,
  },
];

// Move these outside the component since they don't need to be recreated on each render
const buttonHeight = 100;
const buttonMargin = 4;
const minWidth = buttonHeight * (16 / 9); // Maintain 16:9 ratio minimum
const gradientAngle = Math.atan2(buttonHeight, 180) * (180 / Math.PI) + 90;

// Move isMobile check into a custom hook or memoized value
const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

// Helper function to detect iOS
const isIOS = () => {
  const { userAgent, platform, maxTouchPoints } = navigator;
  const iOS =
    /iPad|iPhone|iPod/.test(userAgent) ||
    (platform === 'MacIntel' && maxTouchPoints > 1) ||
    /iOS|iPhone OS|iPhone/.test(userAgent);

  // Log detection info for debugging
  console.log('User Agent:', userAgent);
  console.log('Platform:', platform);
  console.log('Is iOS detected:', iOS);

  return iOS;
};

// Custom hook to detect if app is running as PWA
const useIsPWA = () => {
  const [isPWA, setIsPWA] = React.useState(false);

  React.useEffect(() => {
    // Check if running in standalone mode (PWA)
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://');

    setIsPWA(isStandalone);

    // Listen for changes in display mode
    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    const handleChange = (e: MediaQueryListEvent) => setIsPWA(e.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return isPWA;
};

const MediaActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { teamId, folderId } = useParams<RouteParams>();
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [showLeftChevron, setShowLeftChevron] = React.useState(false);
  const [showRightChevron, setShowRightChevron] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isPWADialogOpen, setIsPWADialogOpen] = React.useState(false);
  const {
    status,
    drawerOpen,
    googleCalendarConnected,
    teamWebhooks,
    userWebhooks,
  } = useSelector((state: RootState) => ({
    status: state.import.status as 'uploading' | 'idle' | 'error' | 'success',
    drawerOpen: state.dashboard.drawerState === 'open',
    googleCalendarConnected:
      state.settings.user?.integrations?.google?.calendar?.isActive,
    teamWebhooks: state.team.selectedTeam?.integrations?.webhooks || [],
    userWebhooks: state.settings.user.integrations.webhooks || [],
  }));

  const webhookCount = teamWebhooks.length + userWebhooks.length;

  const intent = useSelector((state: RootState) => state.plan.marketing.intent);
  const isMobile = useIsMobile();
  const isPWA = useIsPWA();

  // Calculate button width based on drawer state
  const getButtonWidth = React.useMemo(
    () => ({
      xs: `max(35vw, ${minWidth}px)`,
      sm: `max(20vw, ${minWidth}px)`,
      md: `max(calc((100vw - ${drawerOpen ? DRAWER_WIDTH : 56}px) / 4 - ${
        buttonMargin * 2
      }px), ${minWidth}px)`,
      lg: `max(12vw, ${minWidth}px)`,
      xl: `max(10vw, ${minWidth}px)`,
    }),
    [drawerOpen]
  );

  const commonButtonStyles = React.useMemo(
    () => ({
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      height: `${buttonHeight}px`,
      minWidth: getButtonWidth,
      flex: 1,
      padding: '8px',
      margin: `0 ${buttonMargin}px`,
      flexShrink: 0,
      overflow: 'hidden',
      position: 'relative',
      willChange: 'transform',
      borderRadius: '8px',
      '&:hover': {
        '& .icon': {
          transform: 'translateY(-200%) translateZ(0)',
          opacity: 0,
        },
        '& .label': {
          transform: 'translateY(-250%) translateZ(0)',
        },
        '& .tooltip': {
          transform: 'translateY(0) translateZ(0)',
          opacity: 1,
        },
      },
    }),
    [getButtonWidth, buttonHeight, buttonMargin]
  );

  const allActions = React.useMemo(() => {
    const baseActions = isMobile ? mobileActions : desktopActions;

    // Create the install app action with dynamic icon based on platform
    const installAppAction = {
      key: 'install-app' as LibraryActionKeyType,
      label: 'Install App',
      tooltip: 'Get our mobile app for easy recording and transcription',
      startIcon: isIOS() ? (
        <Box
          component="img"
          src={AppleIcon}
          sx={{
            width: 24,
            height: 24,
            filter: 'brightness(0) invert(1)', // Make the logo white
          }}
        />
      ) : (
        <Download />
      ),
      comingSoon: false,
    } as MediaActionType;

    // Show install app button if not running as PWA OR if on iOS device
    if (!isPWA || isIOS()) {
      // For iOS, place the install button at the beginning of the array
      if (isIOS()) {
        return [installAppAction, ...baseActions];
      }
      // For other devices, place at the end of the array
      return [...baseActions, installAppAction];
    }

    return baseActions;
  }, [isMobile, isPWA]);

  const handleAction = React.useCallback(
    (actionKey: LibraryActionKeyType) => {
      if (status === 'uploading') return;

      // Only track if we have required IDs
      if (teamId && folderId) {
        dispatch(
          trackMediaAction({
            actionKey: actionKey.toString(),
            teamId,
            folderId,
          })
        );
      }

      if (actionKey === 'upload') {
        // ... existing upload code ...
      } else if (actionKey === 'record-audio') {
        if (teamId && folderId) {
          const settings = {
            audioSettings: { microphoneAudio: true, browserAudio: true },
            videoSettings: { screen: false, webCam: false },
          };
          dispatch(initializeRecorder({ teamId, folderId, settings }));
        }
      } else if (actionKey === 'record-screen') {
        if (teamId && folderId) {
          const settings = {
            audioSettings: { microphoneAudio: true, browserAudio: true },
            videoSettings: { screen: true, webCam: true },
          };
          dispatch(initializeRecorder({ teamId, folderId, settings }));
        }
      } else if (actionKey === 'import-from-url') {
        dispatch(
          pushModal({
            type: 'import-urls-modal',
          })
        );
      } else if (actionKey === 'record-video') {
        if (teamId && folderId) {
          const settings = {
            audioSettings: { microphoneAudio: true, browserAudio: true },
            videoSettings: { screen: false, webCam: true },
          };
          dispatch(initializeRecorder({ teamId, folderId, settings }));
        }
      } else if (actionKey === 'instant-meeting') {
        navigate('/tool/p2pmeeting', { state: { fromMediaActions: true } });
      } else if (actionKey === 'join-meeting') {
        navigate('/settings/meetingbot');
      } else if (actionKey === 'add-team-member') {
        dispatch(
          pushModal({
            type: 'invite-by-link-modal',
          })
        );
      } else if (actionKey === 'integrations') {
        navigate(getSettingsUrl({ settingType: 'integrations' }));
      } else if (actionKey === 'install-app') {
        // For iOS devices, redirect to the mobile app landing page
        if (isIOS()) {
          console.log('iOS device detected, opening URL');
          window.open(
            'https://screenapp.io/mobile?utm_source=mediaactions',
            '_blank'
          );
        } else {
          // For non-iOS, show the PWA install dialog if available
          console.log('Non-iOS device, showing PWA dialog');
          setIsPWADialogOpen(true);
        }
      }
    },
    [dispatch, status, teamId, folderId, navigate]
  );

  const handleScroll = React.useCallback(() => {
    if (scrollContainerRef.current) {
      setShowLeftChevron(false);
      setShowRightChevron(false);
    }
  }, []);

  React.useEffect(() => {
    const debouncedHandleScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    handleScroll();
    window.addEventListener('resize', debouncedHandleScroll, { passive: true });
    return () => window.removeEventListener('resize', debouncedHandleScroll);
  }, [handleScroll]);

  const scrollTwoCards = React.useCallback(
    (direction: 'left' | 'right') => {
      if (scrollContainerRef.current) {
        const scrollAmount =
          direction === 'left'
            ? -(180 + buttonMargin * 2) * 2
            : (180 + buttonMargin * 2) * 2;
        scrollContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [buttonMargin]
  );

  // Memoize the intent config lookup
  const currentIntent = React.useMemo(
    () => (intent ? intents.find((i) => i.key === intent) : null),
    [intent]
  );

  const handleIntentClick = React.useCallback(() => {
    if (!currentIntent?.action) return;
    handleAction(currentIntent.action as LibraryActionKeyType);
  }, [currentIntent, handleAction]);

  const commonTransitionStyles = {
    transition:
      'transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    willChange: 'transform, opacity',
  };

  const renderIcon = (
    key: LibraryActionKeyType,
    startIcon: React.ReactElement
  ) => {
    if (key === 'join-meeting' && googleCalendarConnected) {
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            background: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '16px',
            padding: '4px 10px 4px 8px',
            transition: 'background 0.2s ease',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          {React.cloneElement(startIcon, {
            sx: {
              fontSize: 24,
              mr: 0.5,
            },
          })}
          <Check
            sx={{
              fontSize: 14,
              opacity: 0.9,
            }}
          />
        </Stack>
      );
    }

    if (key === 'integrations' && webhookCount > 0) {
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            background: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '16px',
            padding: '4px 10px 4px 8px',
            transition: 'background 0.2s ease',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          {React.cloneElement(startIcon, {
            sx: {
              fontSize: 24,
              mr: 0.5,
            },
          })}
          <Typography
            variant="caption"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
              opacity: 0.9,
            }}
          >
            {webhookCount}
          </Typography>
        </Stack>
      );
    }

    // Special case for Apple icon which is an <img> wrapped in a Box
    if (key === 'install-app' && isIOS() && startIcon.type === Box) {
      return startIcon; // Return the Box with img as is
    }

    return React.cloneElement(startIcon, {
      sx: {
        fontSize: key === 'import-from-url' ? 26 : 28,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: {
            xs: '100vw',
            md: drawerOpen
              ? `calc(100vw - ${DRAWER_WIDTH}px)`
              : 'calc(100vw - 56px)',
          },
          overflow: 'hidden',
          willChange: 'transform',
          '& .MuiButton-root': {
            borderRadius: '8px',
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box
          sx={{
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: -1,
              top: '4px',
              width: '41px',
              height: `${buttonHeight}px`,
              background: (theme) => theme.palette.background.default,
              zIndex: 2,
              opacity: showLeftChevron && isHovering ? 1 : 0,
              transform:
                showLeftChevron && isHovering
                  ? 'translate3d(0, 0, 0)'
                  : 'translate3d(-100%, 0, 0)',
              transition:
                'transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
              willChange: 'transform, opacity',
              clipPath:
                'path("M40.0568 100H0.0532067V0H40.0568C35.6385 0 32.0568 3.58172 32.0568 8V92C32.0568 96.418 35.6385 100 40.0568 100Z")',
              display: {
                xs: 'none',
                sm: 'block',
              },
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              right: 0,
              top: '4px',
              width: '40px',
              height: `${buttonHeight}px`,
              background: (theme) => theme.palette.background.default,
              zIndex: 2,
              opacity: showRightChevron && isHovering ? 1 : 0,
              transform:
                showRightChevron && isHovering
                  ? 'translate3d(0, 0, 0)'
                  : 'translate3d(100%, 0, 0)',
              transition:
                'transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
              willChange: 'transform, opacity',
              clipPath:
                'path("M0 100H57V0H0C4.41833 0 8 3.58203 8 8V92C8 96.418 4.41833 100 0 100Z")',
              display: {
                xs: 'none',
                sm: 'block',
              },
            },
          }}
        >
          <Box
            ref={scrollContainerRef}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              padding: '4px 12px 12px 12px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              willChange: 'scroll-position',
              WebkitOverflowScrolling: 'touch',
              '& > *': {
                flex: '1 1 0',
                maxWidth: getButtonWidth,
              },
            }}
            onScroll={() => requestAnimationFrame(handleScroll)}
          >
            {currentIntent && (
              <Button
                onClick={handleIntentClick}
                variant="text"
                color="secondary"
                size="medium"
                sx={{
                  ...commonButtonStyles,
                  background: `linear-gradient(${gradientAngle}deg, ${gradients.blue.startColor}, ${gradients.blue.endColor})`,
                }}
              >
                <Box
                  className="icon"
                  sx={{
                    ...commonTransitionStyles,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '28px',
                    mb: 2,
                    position: 'absolute',
                    top: '8px',
                    left: '8px',
                    color: 'white',
                    '& svg': {
                      color: 'white !important',
                    },
                  }}
                >
                  {React.createElement(currentIntent.icon || Sparkles, {
                    sx: { fontSize: 28 },
                  })}
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    className="label"
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      ...commonTransitionStyles,
                      textAlign: 'left',
                      mb: 1,
                      position: 'absolute',
                      bottom: '8px',
                      left: '8px',
                      right: '8px',
                      color: 'white',
                    }}
                  >
                    {currentIntent.label || intent}
                  </Typography>
                  <Typography
                    className="tooltip"
                    variant="caption"
                    sx={{
                      textAlign: 'left',
                      ...commonTransitionStyles,
                      padding: '0',
                      position: 'absolute',
                      bottom: '8px',
                      left: '8px',
                      right: '8px',
                      transform: 'translateY(100%) translateZ(0)',
                      opacity: 0,
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      height: '2.4em',
                      lineHeight: '1.2em',
                      color: 'white',
                    }}
                  >
                    {currentIntent.description || `Start ${intent}`}
                  </Typography>
                </Box>
              </Button>
            )}
            <Upload />
            {allActions.map(({ key, label, tooltip, startIcon }, index) => {
              const gradient = gradientsList[index % gradientsList.length];

              const displayLabel =
                key === 'join-meeting' && googleCalendarConnected
                  ? 'Meeting Bot Active'
                  : label;

              const displayTooltip =
                key === 'join-meeting' && googleCalendarConnected
                  ? 'Calendar connected - Click to manage settings'
                  : tooltip;

              return (
                <Button
                  key={key}
                  variant="text"
                  color="secondary"
                  size="medium"
                  onClick={() => handleAction(key)}
                  sx={{
                    ...commonButtonStyles,
                    background: `linear-gradient(${gradientAngle}deg, ${gradient.startColor}, ${gradient.endColor})`,
                  }}
                >
                  <Box
                    className="icon"
                    sx={{
                      ...commonTransitionStyles,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      height: '28px',
                      mb: 2,
                      position: 'absolute',
                      top: '8px',
                      left: '8px',
                      color: 'white',
                      '& svg': {
                        color: 'white !important',
                      },
                    }}
                  >
                    {renderIcon(key, startIcon as React.ReactElement)}
                  </Box>
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      className="label"
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        ...commonTransitionStyles,
                        textAlign: 'left',
                        mb: 1,
                        position: 'absolute',
                        bottom: '8px',
                        left: '8px',
                        right: '8px',
                        color: 'white',
                      }}
                    >
                      {displayLabel}
                    </Typography>
                    <Typography
                      className="tooltip"
                      variant="caption"
                      sx={{
                        textAlign: 'left',
                        ...commonTransitionStyles,
                        padding: '0',
                        position: 'absolute',
                        bottom: '8px',
                        left: '8px',
                        right: '8px',
                        transform: 'translateY(100%) translateZ(0)',
                        opacity: 0,
                        whiteSpace: 'normal',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        height: '2.4em',
                        lineHeight: '1.2em',
                        color: 'white',
                      }}
                    >
                      {displayTooltip}
                    </Typography>
                  </Box>
                </Button>
              );
            })}
          </Box>
          <IconButton
            onClick={() => scrollTwoCards('left')}
            sx={{
              position: 'absolute',
              left: '5px',
              top: '50%',
              transform:
                showLeftChevron && isHovering
                  ? 'translate3d(0, -50%, 0)'
                  : 'translate3d(-100%, -50%, 0)',
              zIndex: 3,
              color: 'rgba(0, 0, 0, 0.54)',
              opacity: showLeftChevron && isHovering ? 1 : 0,
              transition:
                'transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
              willChange: 'transform, opacity',
              padding: '4px',
              '& svg': {
                fontSize: '20px',
              },
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => scrollTwoCards('right')}
            sx={{
              position: 'absolute',
              right: '5px',
              top: '50%',
              transform:
                showRightChevron && isHovering
                  ? 'translate3d(0, -50%, 0)'
                  : 'translate3d(100%, -50%, 0)',
              zIndex: 3,
              color: 'rgba(0, 0, 0, 0.54)',
              opacity: showRightChevron && isHovering ? 1 : 0,
              transition:
                'transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
              willChange: 'transform, opacity',
              padding: '4px',
              '& svg': {
                fontSize: '20px',
              },
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <PWAInstall
        open={isPWADialogOpen}
        onClose={() => setIsPWADialogOpen(false)}
      />
    </>
  );
};

// Add memo with a custom comparison function
export default React.memo(MediaActions);
