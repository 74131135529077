import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../reducers';
import theme from '../../theme/default';
import { unscheduleDeleteAccount } from './authThunks';
import { removeToken } from '../../lib/localStorageHandler';
import { getInitialState } from '../app/appSlice';
import { setAlertMessage } from '../dashboard/dashboardSlice';

const AccountDeletingCover = () => {
  const dispatch = useDispatch();
  const deleteRequestedAt = useSelector(
    (state: RootState) => state.settings.user.deleteRequestedAt
  );

  const deleteRequestedMoment = moment(deleteRequestedAt);
  const deletionDate = moment(deleteRequestedMoment).add(14, 'days');
  const currentDate = moment();
  const daysRemaining = deletionDate.diff(currentDate, 'days');

  const handleProceed = async () => {
    await dispatch(unscheduleDeleteAccount());
    await dispatch(getInitialState());
    dispatch(
      setAlertMessage({
        type: 'success',
        message: `Your account and teams have been restored successfully. You can access your account immediately.`,
      })
    );
  };

  const handleCancel = () => {
    removeToken();
    // Force reload, and go to home page
    window.location.href = '/';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '98vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(4),
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.secondary.main}`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: 400,
          textAlign: 'left',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Deletion in Progress
        </Typography>
        <Box sx={{ width: 320, textAlign: 'left', mb: 3 }}>
          <Typography variant="body1" sx={{ marginBottom: 3 }}>
            Your account is scheduled to be deleted permanently in{' '}
            <strong>{daysRemaining} days</strong>.
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 3 }}>
            After {daysRemaining} days, all associated data will be removed.
            However, if you wish to retain your account, you can proceed to sign
            in.
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: 3 }}>
            Once you sign in, the deletion process will be cancelled
            immediately, and your account will remain active without any data
            loss.
          </Typography>
        </Box>
        <Box sx={{ mb: 2, width: 320 }}>
          <Button
            id="proceed-to-signin-button"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ padding: theme.spacing(2) }}
            onClick={handleProceed}
          >
            Proceed
          </Button>
        </Box>
        <Box sx={{ mb: 2, width: 320 }}>
          <Button
            id="cancel-button"
            variant="text"
            fullWidth
            sx={{ padding: theme.spacing(2) }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountDeletingCover;
