import { UpdateMessageMap } from './types';
import logger from '../../lib/logger';
import store from '../../store';
import { setUsage } from '../../features/plan/planSlice';
import { RootState } from '../../reducers';
import {
  fetchFile,
  updateJobStatus,
} from '../../features/selectedVideo/selectedVideoSlice';
import { addNotification } from '../../features/dashboard/dashboardSlice';
import { getFileUrl } from '../../lib/routingUtils';
import { updateFile } from '../../features/library/librarySlice';
import { handleRagUpdate } from './ragUpdate';

const handleFileUpdate = (
  payload: UpdateMessageMap['fileUpdate']['payload']
) => {
  const { dispatch, getState } = store;
  const state = getState() as RootState;
  const { teamId, parentId, fileId, name } = payload;
  const { _id } = state?.selectedVideo?.file;

  // If this is the currently selected file, just fetch the updated file
  if (fileId === _id) {
    dispatch(fetchFile(fileId));
  } else {
    // Update the file in the library
    dispatch(
      updateFile({
        _id: fileId,
        name,
      })
    );

    // Check if a notification for this file already exists
    const existingNotification = state.dashboard.notifications.find(
      (notification) => notification.id === fileId
    );

    // Only add a notification if one doesn't already exist for this file
    if (!existingNotification) {
      dispatch(
        addNotification({
          id: fileId,
          type: 'transcription',
          title: 'File Processed',
          message: `File "${name}" has been processed`,
          link: getFileUrl({ teamId, parentId, fileId, withBasePath: true }),
          read: false,
          timestamp: Date.now(),
        })
      );
    }
  }
};

const handleJobUpdate = (payload: UpdateMessageMap['jobUpdate']['payload']) => {
  const { dispatch, getState } = store;
  const state = getState() as RootState;
  const { _id } = state?.selectedVideo?.file;

  if (_id === payload.recordingId && payload.job === 'transcribe') {
    dispatch(updateJobStatus({ ...payload, job: 'transcribe' }));
  }
};

const updateHandler = (update: UpdateMessageMap[keyof UpdateMessageMap]) => {
  console.log('socket update,', update);
  try {
    const { type, payload } = update;
    const { dispatch } = store;
    logger.info(`socket: Received ${type} update`, payload);
    switch (type) {
      case 'usage':
        dispatch(setUsage(payload));
        break;
      case 'file':
        handleFileUpdate(payload);
        break;
      case 'job':
        handleJobUpdate(payload);
        break;
      case 'rag':
        handleRagUpdate(payload);
        logger.info('socket: Received RAG update');
        break;
      default:
        logger.warn(`socket: Unknown update type ${type}`);
        break;
    }
  } catch (error) {
    logger.error('socket: Error handling update', error);
  }
};

export default updateHandler;
