/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Template as ServiceTemplate,
  createTemplate,
  getTemplates,
  updateTemplate,
  deleteTemplate,
  getTemplate,
} from '../../services/templateService';
import { RootState } from '../../reducers';
import templates from './tabs/templates/templates';

// Extend the service template type to include optional properties from local templates
export interface Template
  extends Omit<ServiceTemplate, '_id' | 'teamId' | 'ownerId'> {
  featured?: boolean;
  intents?: string[];
  _id: string;
  teamId: string;
  ownerId: string;
}

// Type for creating a new template
export type NewTemplate = {
  id?: string;
  title: string;
  description?: string;
  prompt: string;
  featured?: boolean;
  intents?: string[];
};

interface TemplatesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  templates: Template[];
}

const initialState: TemplatesState = {
  status: 'idle',
  error: null,
  templates: [],
};

export const fetchTemplates = createAsyncThunk<Template[]>(
  'templates/fetchTemplates',
  async () => {
    const response = await getTemplates();
    return response || [];
  }
);

export const addTemplate = createAsyncThunk<Template, NewTemplate>(
  'templates/addTemplate',
  async (template) => {
    const response = await createTemplate(template);
    return response.data;
  }
);

export const customizeBuiltInTemplate = createAsyncThunk<
  Template,
  { id: string; prompt: string; description?: string }
>('templates/customizeBuiltInTemplate', async ({ id, prompt, description }) => {
  const builtInTemplate = templates.find((t: { id: string }) => t.id === id);
  if (!builtInTemplate) {
    throw new Error('Template not found');
  }

  // First check if the template exists
  try {
    const getResponse = await getTemplate(id);
    if (getResponse) {
      // Template exists, update it
      const updateResponse = await updateTemplate(id, {
        prompt,
        description,
      });
      return updateResponse.data;
    }
  } catch (error) {
    // Template doesn't exist or other error, proceed to create
    console.error('Error customizing built-in template:', error);
  }

  // Create new template with the built-in template's ID
  const createResponse = await createTemplate({
    id: builtInTemplate.id, // Explicitly pass the built-in template's ID
    title: builtInTemplate.title,
    prompt,
    description,
  });

  return createResponse.data;
});

export const editTemplate = createAsyncThunk<
  Template,
  { id: string; template: Partial<NewTemplate> }
>('templates/editTemplate', async ({ id, template }) => {
  const response = await updateTemplate(id, template);
  return response.data;
});

export const removeTemplate = createAsyncThunk<string, string>(
  'templates/removeTemplate',
  async (id) => {
    await deleteTemplate(id);
    return id;
  }
);

// Helper function to create a Template from a built-in template
export const createTemplateFromBuiltIn = (builtInTemplate: {
  id: string;
  title: string;
  description?: string;
  prompt: string;
  featured?: boolean;
  intents?: string[];
}): Template => {
  return {
    _id: '',
    teamId: '',
    ownerId: '',
    id: builtInTemplate.id, // Keep the original ID
    title: builtInTemplate.title,
    description: builtInTemplate.description || '',
    prompt: builtInTemplate.prompt,
    featured: builtInTemplate.featured,
    intents: builtInTemplate.intents,
  };
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch templates
      .addCase(fetchTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.templates = payload;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      // Add template
      .addCase(addTemplate.fulfilled, (state, { payload }) => {
        state.templates.push(payload);
      })
      // Customize built-in template
      .addCase(customizeBuiltInTemplate.fulfilled, (state, { payload }) => {
        const index = state.templates.findIndex(
          (template) => template.id === payload.id
        );
        if (index !== -1) {
          state.templates[index] = payload;
        } else {
          state.templates.push(payload);
        }
      })
      // Edit template
      .addCase(editTemplate.fulfilled, (state, { payload }) => {
        const index = state.templates.findIndex(
          (template) => template.id === payload.id
        );
        if (index !== -1) {
          state.templates[index] = payload;
        }
      })
      // Remove template
      .addCase(removeTemplate.fulfilled, (state, { payload }) => {
        state.templates = state.templates.filter(
          (template) => template.id !== payload
        );
      });
  },
});

export const selectAllTemplates = (state: RootState) =>
  state.templates?.templates || [];
export const selectTemplatesStatus = (state: RootState) =>
  state.templates?.status || 'idle';
export const selectTemplatesError = (state: RootState) =>
  state.templates?.error || null;

export default templatesSlice.reducer;
