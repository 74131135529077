import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Tooltip, Collapse } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  Download,
  MoveUp,
  Trash2,
  X,
  LayoutGrid,
  ArrowDownUp,
  List,
} from 'lucide-mui';
import { useTheme } from '@mui/material/styles';
import type { ActionType, FileType } from '../../../types';
import type { RootState } from '../../../reducers';
import { deselectAllFiles, downloadMultipleFiles } from '../librarySlice';
import { RouteParams } from '../types';
import { useQuery } from '../../../hooks/urlHooks';
import SortComponent from './Sort';
import { LibraryViewType } from '../../../types';
import MediaActions from './mediaActions';
import MultiAskAI from '../files/MultiAskAI';
import { useTags } from '../../../hooks/useTags';
import { LIBRARY_VIEW_TYPE_KEY } from '../../../constants/tags';
import { pushModal } from '../../globalModals/globalModalsSlice';

type SelectedFileActionKeyType = 'download' | 'move' | 'delete' | 'deselect';
type ActionKeyType = LibraryViewType | SelectedFileActionKeyType | 'sort';

type LibraryActionType = ActionType<ActionKeyType> & {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  href?: string;
  id?: string;
};

export const LibraryActions = () => {
  const { library, upload, import: importState } = useSelector(
    (state: RootState) => state
  );
  const { teamId, folderId } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedFiles, files } = library;
  const showSelectedFileActions = Object.keys(selectedFiles).length > 0;
  const fileArray: FileType[] = Object.values(selectedFiles);
  const { getTag, setTag } = useTags();

  const viewFromQuery = useQuery().view as LibraryViewType | undefined;
  const savedView = getTag(LIBRARY_VIEW_TYPE_KEY) as LibraryViewType | null;
  const [view, setView] = useState<LibraryViewType>(
    viewFromQuery || savedView || 'grid'
  );

  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  if (
    !teamId ||
    !folderId ||
    (files.data.length === 0 &&
      !upload.activeUploads.length &&
      importState.status !== 'pending')
  ) {
    return (
      <Box
        sx={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}
      >
        <Box>
          <MediaActions />
        </Box>
        <Box sx={{ height: 56 }} />
      </Box>
    );
  }

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleClick = (
    actionKey: ActionKeyType,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    switch (actionKey) {
      case 'list':
      case 'grid':
        setView(actionKey);
        setTag({ key: LIBRARY_VIEW_TYPE_KEY, value: actionKey });
        break;
      case 'download':
        dispatch(downloadMultipleFiles(fileArray));
        break;
      case 'move':
        dispatch(pushModal({ type: 'move-file-modal', files: fileArray }));
        break;
      case 'delete':
        dispatch(pushModal({ type: 'delete-files-modal', files: fileArray }));
        break;
      case 'deselect':
        dispatch(deselectAllFiles());
        break;
      case 'sort':
        handleSortClick(event!);
        break;
      default:
        break;
    }
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const selectedFileActions: LibraryActionType[] = [
    {
      key: 'download',
      label: 'Download',
      startIcon: <Download />,
    },
    {
      key: 'move',
      label: 'Move',
      startIcon: <MoveUp />,
    },
    {
      key: 'delete',
      label: 'Delete',
      startIcon: <Trash2 />,
    },
    {
      key: 'deselect',
      label: 'Deselect',
      startIcon: <X />,
    },
  ];

  const fixedActions: LibraryActionType[] = [
    {
      key: 'sort',
      label: 'Sort',
      id: 'sort-button',
      startIcon: <ArrowDownUp />,
      onClick: handleSortClick,
    },
    {
      key: view === 'grid' ? 'list' : 'grid',
      label: view === 'grid' ? 'List View' : 'Grid View',
      id: 'list-view-button',
      onClick: () => handleClick(view === 'grid' ? 'list' : 'grid'),
      startIcon: view === 'list' ? <LayoutGrid /> : <List />,
    },
  ];

  const renderActionButton = (action: LibraryActionType) => (
    <Box key={action.key}>
      <Tooltip title={action.label} arrow>
        <IconButton
          aria-label={action.label}
          id={action.id}
          size="medium"
          href={action.href}
          component={action.href ? 'a' : 'button'}
          sx={{
            borderRadius: '50%',
            minWidth: 40,
            minHeight: 40,
            backgroundColor:
              view === action.key
                ? theme.palette.secondary.main
                : 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            action.onClick
              ? action.onClick(event)
              : handleClick(action.key, event)
          }
        >
          {React.cloneElement(action.startIcon as React.ReactElement, {
            size: 'large',
          })}
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box
      sx={{
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}
    >
      <Box>
        <MediaActions />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box sx={{ ml: 2, flex: 1 }}>{/* <Searchbar /> */}</Box>
        {showSelectedFileActions ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              marginBottom: 2,
            }}
          >
            {selectedFileActions.map(({ key, startIcon, label }) => (
              <Collapse key={key} in={showSelectedFileActions}>
                <Tooltip key={key} title={label}>
                  <IconButton
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      handleClick(key, event)
                    }
                    aria-label={label}
                    size="medium"
                    sx={{
                      borderRadius: '50%',
                      minWidth: 40,
                      minHeight: 40,
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {React.cloneElement(startIcon as React.ReactElement, {
                      size: 'large',
                    })}
                  </IconButton>
                </Tooltip>
              </Collapse>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 0,
              mr: 2,
              alignItems: 'center',
            }}
          >
            {fixedActions.map(renderActionButton)}
            <SortComponent anchorEl={sortAnchorEl} onClose={handleSortClose} />
          </Box>
        )}
      </Box>
      {templateModalOpen && (
        <MultiAskAI
          open={templateModalOpen}
          onClose={() => setTemplateModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default LibraryActions;
