import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

import theme from '../../theme/default';
import { unscheduleTeamDeletionThunk } from './teamSlice';
import { RootState } from '../../reducers';
import { setAlertMessage } from '../dashboard/dashboardSlice';

const TeamDeletionCover = () => {
  const dispatch = useDispatch();
  const { deleteRequestedAt, teamId, name } = useSelector(
    (state: RootState) =>
      state.team.selectedTeam || { deleteRequestedAt: '', teamId: '', name: '' }
  );

  if (!deleteRequestedAt || !teamId) {
    return null;
  }

  const deleteRequestedMoment = moment(deleteRequestedAt);
  const deletionDate = moment(deleteRequestedMoment).add(14, 'days');
  const currentDate = moment();
  const daysRemaining = deletionDate.diff(currentDate, 'days');

  const handleRestore = async () => {
    await dispatch(unscheduleTeamDeletionThunk(teamId));
    dispatch(
      setAlertMessage({
        type: 'success',
        message: `Your team '${name}' has been restored successfully. You can access its contents immediately.`,
      })
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          p: theme.spacing(4),
          borderRadius: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '100%',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, color: theme.palette.primary.main }}
        >
          Access Unavailable, Team Deletion in Progress
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          This team is in the process of being deleted. You have{' '}
          <strong>{daysRemaining} days</strong> to recover it before it&apos;s
          permanently removed.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRestore}>
          Restore Team
        </Button>
      </Box>
    </Box>
  );
};

export default TeamDeletionCover;
