import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface MediaActionPayload {
  actionKey: string;
  actionLabel?: string;
  intentKey?: string;
  intentLabel?: string;
  teamId?: string;
  folderId?: string;
}

const initialState = {};

const mediaActionsSlice = createSlice({
  name: 'mediaActions',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trackMediaAction: (_state, _action: PayloadAction<MediaActionPayload>) => {
      // This is just for tracking, no state changes needed
      return initialState;
    },
  },
});

export const { trackMediaAction } = mediaActionsSlice.actions;
export default mediaActionsSlice.reducer;
