import React from 'react';
import {
  Scissors,
  FolderInput,
  Download,
  Trash2,
  Forward,
  Mic2,
  Mail,
  AtSign,
} from 'lucide-mui';

import { requestStatusType } from '../../../types';
import { FeatureKey } from '../../plan/upgrade/upgradeView/featureLimits';

export type ActionType =
  | 'share'
  | 'download'
  | 'trim'
  | 'move'
  | 'delete'
  | 'clip'
  | 'embed'
  | 'dub'
  | 'tagUser'
  | 'shareEmail';

export const VIDEO_ACTIONS: {
  actionType: ActionType;
  featureKey?: FeatureKey;
  tooltipMessages: Record<requestStatusType, string>;
  buttonTexts: Record<requestStatusType, string>;
  IconComponent: React.ElementType;
}[] = [
  {
    actionType: 'share',
    tooltipMessages: {
      idle: 'Copy share link',
      pending: 'Generating share link...',
      succeeded: 'Share link copied!',
      failed: 'Failed to copy share link',
    },
    buttonTexts: {
      idle: 'Share',
      pending: 'Sharing...',
      succeeded: 'Shared!',
      failed: 'Failed',
    },
    IconComponent: Forward,
  },
  {
    actionType: 'clip',
    tooltipMessages: {
      idle: 'Share a clip',
      pending: 'Preparing clip...',
      succeeded: 'Clip ready!',
      failed: 'Failed to prepare clip',
    },
    buttonTexts: {
      idle: 'Clip',
      pending: 'Preparing...',
      succeeded: 'Ready!',
      failed: 'Failed',
    },
    IconComponent: Scissors,
  },
  {
    actionType: 'download',
    featureKey: 'download',
    tooltipMessages: {
      idle: 'Download recording',
      pending: 'Downloading...',
      succeeded: 'Download complete!',
      failed: 'Download failed',
    },
    buttonTexts: {
      idle: 'Download',
      pending: 'Downloading...',
      succeeded: 'Download',
      failed: 'Download',
    },
    IconComponent: Download,
  },
  {
    actionType: 'trim',
    tooltipMessages: {
      idle: 'Trim recording',
      pending: 'Trimming...',
      succeeded: 'Trim complete!',
      failed: 'Trim failed',
    },
    buttonTexts: {
      idle: 'Trim',
      pending: 'Trimming...',
      succeeded: 'Trim',
      failed: 'Trim',
    },
    IconComponent: Scissors,
  },
  {
    actionType: 'move',
    tooltipMessages: {
      idle: 'Move recording',
      pending: 'Moving...',
      succeeded: 'Move complete!',
      failed: 'Move failed',
    },
    buttonTexts: {
      idle: 'Move',
      pending: 'Moving...',
      succeeded: 'Move',
      failed: 'Move',
    },
    IconComponent: FolderInput,
  },
  {
    actionType: 'dub',
    tooltipMessages: {
      idle: 'Add AI dubbing',
      pending: 'Processing...',
      succeeded: 'Dubbing complete!',
      failed: 'Dubbing failed',
    },
    buttonTexts: {
      idle: 'Dub',
      pending: 'Processing...',
      succeeded: 'Dub',
      failed: 'Dub',
    },
    IconComponent: Mic2,
  },
  {
    actionType: 'delete',
    tooltipMessages: {
      idle: 'Delete recording',
      pending: 'Deleting...',
      succeeded: 'Delete complete!',
      failed: 'Delete failed',
    },
    buttonTexts: {
      idle: 'Delete',
      pending: 'Deleting...',
      succeeded: 'Delete',
      failed: 'Delete',
    },
    IconComponent: Trash2,
  },
  {
    actionType: 'tagUser',
    tooltipMessages: {
      idle: 'Tag team members',
      pending: 'Tagging members...',
      succeeded: 'Members tagged!',
      failed: 'Failed to tag members',
    },
    buttonTexts: {
      idle: 'Tag Someone',
      pending: 'Tagging...',
      succeeded: 'Tagged!',
      failed: 'Failed',
    },
    IconComponent: AtSign,
  },
  {
    actionType: 'shareEmail',
    tooltipMessages: {
      idle: 'Share via email',
      pending: 'Preparing email share...',
      succeeded: 'Email share ready!',
      failed: 'Failed to share via email',
    },
    buttonTexts: {
      idle: 'Share via Email',
      pending: 'Preparing...',
      succeeded: 'Ready!',
      failed: 'Failed',
    },
    IconComponent: Mail,
  },
];

export const LIBRARY_VIEW_ACTIONS = VIDEO_ACTIONS.filter(({ actionType }) => {
  return (['share', 'download', 'move', 'delete'] as ActionType[]).includes(
    actionType
  );
});
