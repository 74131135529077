import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';
import ActionDialog from '../../globalModals/ActionDialog';

interface MobileAppDialogProps {
  open: boolean;
  onClose: () => void;
}

const MOBILE_URL =
  'https://screenapp.io/mobile?utm_source=screenapp-product&utm_medium=mobile-dialog&utm_campaign=link';
const MOBILE_URL_APP_STORE =
  'https://screenapp.io/mobile?utm_source=screenapp-product&utm_medium=mobile-dialog-appstore&utm_campaign=link';

const MobileAppDialog: React.FC<MobileAppDialogProps> = ({ open, onClose }) => {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      title="Install Mobile App"
      actions={[]}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            justifyContent: 'center',
            maxWidth: 'sm',
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pt: { xs: 3, sm: 2 },
            }}
          >
            <QRCodeSVG
              value={MOBILE_URL}
              size={140}
              level="M"
              includeMargin={false}
              fgColor="currentColor"
              bgColor="transparent"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: 200,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <AppStoreButton
                url={MOBILE_URL_APP_STORE}
                theme="light"
                height={50}
              />

              <Box
                sx={{
                  position: 'relative',
                  opacity: 0.7,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      borderRadius: 1,
                      cursor: 'not-allowed',
                    },
                    pointerEvents: 'none',
                  }}
                >
                  <GooglePlayButton url="#" theme="light" height={50} />
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: 'block',
                    textAlign: 'center',
                    mt: 0.5,
                    fontWeight: 'bold',
                  }}
                >
                  Coming Soon
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{
          mt: 1,
          mb: 2,
          width: '100%',
          maxWidth: 300,
          mx: 'auto',
        }}
      >
        Scan with your phone&apos;s camera to visit the mobile app page
      </Typography>
    </ActionDialog>
  );
};

export default MobileAppDialog;
