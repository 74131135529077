import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import { X } from 'lucide-mui';
import type { DialogProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { requestStatusType } from '../../types';

export interface ActionType extends Omit<LoadingButtonProps, 'key'> {
  key: string;
  label: string;
  'data-testid'?: string;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: React.ReactNode;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
}

export interface ActionDialogProps extends DialogProps {
  onClose?: () => void;
  title: string;
  actions: ActionType[];
  'data-testid'?: string;
  loading?: boolean;
  showCloseIcon?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const ActionDialog = ({
  onClose,
  title,
  actions,
  children,
  key,
  loading = false,
  showCloseIcon = true,
  maxWidth = 'sm',
  ...OtherDialogProps
}: ActionDialogProps) => {
  const handleClose = () => {
    if (onClose && !loading) {
      onClose();
    }
  };
  const theme = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...OtherDialogProps}
      key={key}
      PaperProps={{
        elevation: 8,
        sx: {
          borderRadius: theme.shape.borderRadius * 1.5,
        },
      }}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          px: 4,
          pt: 3,
          pb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontSize: '1.5rem',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {showCloseIcon && onClose && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            disabled={loading}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              },
            }}
          >
            <X />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        id="dialog-description"
        sx={{
          p: theme.spacing(3),
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          p: theme.spacing(3),
          gap: theme.spacing(1),
        }}
      >
        {actions.map(
          ({
            label,
            key: actionKey,
            loading: buttonLoading,
            disabled,
            startIcon,
            onClick,
            variant = 'outlined',
            color = 'secondary',
            size = 'medium',
            'data-testid': testId,
            ...otherProps
          }) => (
            <LoadingButton
              key={actionKey || label}
              variant={variant}
              color={color}
              size={size}
              data-testid={testId}
              loading={buttonLoading || loading}
              disabled={disabled || loading}
              startIcon={startIcon}
              onClick={onClick}
              {...otherProps}
            >
              {label}
            </LoadingButton>
          )
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;

// Helper types for specific use cases
export type LabelsType = {
  [key in requestStatusType]: string;
};

export type ActionsType = Pick<
  ActionType,
  'key' | 'onClick' | 'loading' | 'startIcon'
> & {
  labels: LabelsType;
  'data-testid'?: string;
};
