import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../reducers';
import { fetchFiles } from '../librarySlice';
import { RouteParams } from '../types';
import FilesEmpty from './FilesEmpty';
import GridView from './gridView';
import ListView from './listView';
import { useQuery } from '../../../hooks/urlHooks';
import { useTags } from '../../../hooks/useTags';
import {
  LIBRARY_VIEW_TYPE_KEY,
  LIBRARY_SORT_BY_KEY,
  LIBRARY_SORT_ORDER_KEY,
} from '../../../constants/tags';
import { SortBy, SortOrderBy } from '../../../types';

export type viewType = 'grid' | 'list';

export const Files = () => {
  const { library, upload } = useSelector((state: RootState) => state);
  const { activeUploads } = upload;
  const dispatch = useDispatch();
  const { teamId, folderId } = useParams<RouteParams>();
  const query = useQuery();
  const { getTag } = useTags();
  const { searchQuery } = query;
  const sortBy = (getTag(LIBRARY_SORT_BY_KEY) as SortBy) || 'createdAt';
  const sortOrder = (getTag(LIBRARY_SORT_ORDER_KEY) as SortOrderBy) || 'desc';
  const { files } = library;
  const { status } = files;

  // Get view from URL or fallback to saved preference
  const viewFromUrl = query.view as viewType | undefined;
  const savedView = getTag(LIBRARY_VIEW_TYPE_KEY) as viewType | null;
  const view = viewFromUrl || savedView || 'grid';

  useEffect(() => {}, [query]);

  useEffect(() => {
    dispatch(
      fetchFiles({
        teamId,
        folderId,
        page: 1,
        limit: 20,
        searchQuery,
        sortBy,
        sortOrder,
      })
    );
  }, [teamId, folderId, view, searchQuery, sortBy, sortOrder, dispatch]);

  if (!teamId || !folderId) {
    return null;
  }

  if (
    status === 'succeeded' &&
    files.data?.length === 0 &&
    activeUploads.length === 0
  ) {
    return <FilesEmpty />;
  }

  return <>{view === 'list' ? <ListView /> : <GridView />}</>;
};

export default Files;
