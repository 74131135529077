import type { Middleware } from 'redux';
import { io, Socket } from 'socket.io-client';
import { RootState } from '../../reducers';
import { getToken } from '../../lib/localStorageHandler';
import { getInitialState } from '../../features/app/appSlice';
import handleUpdate from './handleUpdate';
import config from '../../../config';
import logger from '../../lib/logger';

const { authBaseUrlV2 } = config;
// TODO: Remove this when the socket server and APIV2 is stable.
const socketUrl = authBaseUrlV2.replace('/v2', '');

let socket: Socket;
const socketMiddleware: Middleware<{}, RootState> = () => {
  socket = io(socketUrl, {
    auth: (cb) => {
      cb({ token: getToken() });
    },
    transports: ['websocket'],
  });
  // Handle incoming socket events
  socket.on('update', handleUpdate);
  socket.on('error', (error) => {
    logger.error(error);
  });
  return (next) => (action) => {
    switch (action.type) {
      case getInitialState.pending.type:
        socket?.connect();
        break;
      case 'socket/disconnect':
        socket?.disconnect();
        break;
      case 'socket/emit':
        socket?.emit(action.event, action.payload);
        break;
      default:
        break;
    }
    return next(action);
  };
};

export default socketMiddleware;
