import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Alert,
} from '@mui/material';
import { ChevronDown, CheckCircle, GraduationCap } from 'lucide-mui';
import { useTheme, alpha } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentForm from './paymentForm';
import { config } from '../../../../../config';
import { RootState } from '../../../../reducers';
import { selectPlan } from '../../planSlice';
import { planPricesPerMonth } from '../../../../constants/prices';
import { getDiscount } from './discounts';

const { stripePublishableKey } = config;
const stripePromise = loadStripe(stripePublishableKey, {
  betas: ['custom_checkout_beta_2'],
});

interface BillingOptionsProps {
  onOptionChange: (option: 'monthly' | 'annual') => void;
}

const BillingOptions: React.FC<BillingOptionsProps> = ({ onOptionChange }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { plan } = useSelector((state: RootState) => state);
  const { selectedPlan } = plan.upgrade;
  const [selectedTerm, setSelectedTerm] = useState<'annual' | 'monthly'>(
    'annual'
  );
  const [promoCode, setPromoCode] = useState('');
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showMonthly, setShowMonthly] = useState(false);
  const isUnlimited = selectedPlan === 'BUSINESS';
  const { annual, monthly } = planPricesPerMonth[
    selectedPlan as keyof typeof planPricesPerMonth
  ];
  const annualSaving = Math.round(((monthly - annual) / monthly) * 100);
  const [isValidPromo, setIsValidPromo] = useState(false);

  useEffect(() => {
    onOptionChange(selectedTerm);
  }, [selectedTerm, onOptionChange]);

  // Check for promo code in URL
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const promoFromUrl = searchParams.get('promo');
    if (promoFromUrl) {
      setPromoCode(promoFromUrl);
      setShowPromoInput(true);
      // If it's a valid code, apply it
      const discount = getDiscount(promoFromUrl);
      if (discount) {
        setIsValidPromo(true);
      }
    }
  }, []);

  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = event.target.value as 'annual' | 'monthly';
    setSelectedTerm(newTerm);
  };

  const handleUnlimitedChange = () => {
    if (!isUnlimited) {
      dispatch(selectPlan({ plan: 'BUSINESS' }));
    } else {
      dispatch(selectPlan({ plan: 'GROWTH' }));
    }
  };

  const handlePromoCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value;
    setPromoCode(code);
    setIsValidPromo(!!getDiscount(code));
  };

  const totalAmount =
    (planPricesPerMonth[selectedPlan as keyof typeof planPricesPerMonth][
      selectedTerm
    ] as number) * (selectedTerm === 'annual' ? 12 : 1);

  // Function to render the promo code section
  const renderPromoSection = () => {
    // If EDU50 code is applied, show success or unavailable message based on plan term
    if (isValidPromo && promoCode.toUpperCase() === 'EDU50') {
      // Show different message for monthly plan
      if (selectedTerm === 'monthly') {
        return (
          <Alert
            icon={<GraduationCap />}
            severity="warning"
            sx={{
              width: '100%',
              '& .MuiAlert-message': {
                fontWeight: 500,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              },
            }}
          >
            Educational Discount not applicable for Monthly Plans
          </Alert>
        );
      }

      // Show success message for annual plan
      return (
        <Alert
          icon={<GraduationCap />}
          severity="success"
          sx={{
            width: '100%',
            '& .MuiAlert-message': {
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            },
          }}
          action={
            <CheckCircle
              sx={{
                color: '#2e7d32',
                width: 20,
                height: 20,
              }}
            />
          }
        >
          50% Educational Discount Applied!
        </Alert>
      );
    }

    // If promo input is shown, display the text field
    if (showPromoInput) {
      return (
        <TextField
          variant="outlined"
          size="small"
          value={promoCode}
          onChange={handlePromoCodeChange}
          placeholder="Enter Promo code"
          fullWidth
          InputProps={{
            endAdornment: isValidPromo && (
              <CheckCircle
                sx={{
                  color: theme.palette.success.main,
                  marginRight: 1,
                }}
              />
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: theme.palette.background.paper,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        />
      );
    }

    // Otherwise show the button to display the promo input
    return (
      <Button
        variant="text"
        onClick={() => setShowPromoInput(true)}
        sx={{
          color: theme.palette.secondary.contrastText,
          textTransform: 'none',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
      >
        Have a discount code?
      </Button>
    );
  };

  return (
    <Box
      sx={{
        padding: '20px',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <RadioGroup value={selectedTerm} onChange={handleTermChange}>
        <Card
          onClick={() => setSelectedTerm('annual')}
          sx={{
            border: '1px solid',
            borderColor: selectedTerm === 'annual' ? '#2e7d32' : '#e6e6e6',
            boxShadow: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            position: 'relative',
            mb: 1,
            '&:hover': {
              borderColor: '#2e7d32',
              background:
                selectedTerm === 'annual' ? alpha('#2e7d32', 0.04) : 'none',
            },
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: theme.spacing(2),
              paddingRight: theme.spacing(2),
              paddingBottom: `${theme.spacing(2)} !important`,
              paddingLeft: 0,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                value="annual"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.5rem',
                        color: theme.palette.secondary.dark,
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: '#2e7d32',
                      },
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Bill Annually
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      ${planPricesPerMonth.GROWTH.annual}
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="regular"
                        sx={{ opacity: 0.5 }}
                      >
                        /month/user
                      </Typography>
                    </Typography>
                  </Box>
                }
                sx={{ marginLeft: 0 }}
              />
            </Box>
            <Typography
              variant="body2"
              fontWeight="medium"
              sx={{
                color: '#1e4620',
                textAlign: 'center',
                backgroundColor: alpha('#2e7d32', 0.1),
                borderRadius: '3px',
                padding: '2px 6px',
                fontSize: '0.75rem',
              }}
            >
              {`Save ${annualSaving}%`}
            </Typography>
          </CardContent>
        </Card>
        <Collapse in={showMonthly}>
          <Card
            onClick={() => setSelectedTerm('monthly')}
            sx={{
              border: '1px solid',
              borderColor: selectedTerm === 'monthly' ? '#2e7d32' : '#e6e6e6',
              boxShadow: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              position: 'relative',
              mb: 1,
              '&:hover': {
                borderColor: '#2e7d32',
                background:
                  selectedTerm === 'monthly' ? alpha('#2e7d32', 0.04) : 'none',
              },
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingBottom: `${theme.spacing(2)} !important`,
                paddingLeft: 0,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                  value="monthly"
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.5rem',
                          color: theme.palette.secondary.dark,
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          color: '#2e7d32',
                        },
                      }}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Bill Monthly
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        ${planPricesPerMonth.GROWTH.monthly}
                        <Typography
                          component="span"
                          variant="body2"
                          fontWeight="regular"
                          sx={{ opacity: 0.5 }}
                        >
                          /month/user
                        </Typography>
                      </Typography>
                    </Box>
                  }
                  sx={{ marginLeft: 0 }}
                />
              </Box>
            </CardContent>
          </Card>
        </Collapse>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 0,
            mb: 1,
          }}
        >
          <IconButton
            onClick={() => setShowMonthly(!showMonthly)}
            size="small"
            sx={{
              color: theme.palette.secondary.dark,
              border: `1px solid ${theme.palette.secondary.main}`,
              width: 20,
              height: 20,
              padding: 0,
              '&:hover': {
                color: theme.palette.secondary.main,
                border: `1px solid ${theme.palette.secondary.main}`,
              },
            }}
          >
            <ChevronDown
              sx={{
                transform: showMonthly ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
                width: 14,
                height: 14,
              }}
            />
          </IconButton>
        </Box>
      </RadioGroup>
      <Card
        onClick={handleUnlimitedChange}
        sx={{
          border: '1px solid',
          borderColor: isUnlimited ? '#2e7d32' : '#e6e6e6',
          boxShadow: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'visible',
          transition: 'all 0.15s ease-in-out',
          mb: 1,
          '&:hover': {
            borderColor: '#2e7d32',
            background: isUnlimited ? alpha('#2e7d32', 0.04) : 'none',
            '& .unlimited-benefits': {
              opacity: 1,
              transform: 'translateY(0)',
              pointerEvents: 'auto',
            },
          },
        }}
      >
        <Box
          className="unlimited-benefits"
          sx={{
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: -1,
            right: -1,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #e6e6e6',
            borderRadius: '6px',
            padding: '12px',
            opacity: 0,
            transform: 'translateY(-10px)',
            transition: 'all 0.2s ease-in-out',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        >
          <List sx={{ p: 0 }}>
            <ListItem sx={{ p: 0, mb: 0.5 }}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <CheckCircle sx={{ fontSize: '0.9rem', color: '#2e7d32' }} />
              </ListItemIcon>
              <Typography variant="body2">Unlimited Ask AI</Typography>
            </ListItem>
            <ListItem sx={{ p: 0, mb: 0.5 }}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <CheckCircle sx={{ fontSize: '0.9rem', color: '#2e7d32' }} />
              </ListItemIcon>
              <Typography variant="body2">Unlimited AI Generations</Typography>
            </ListItem>
            <ListItem sx={{ p: 0, mb: 0.5 }}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <CheckCircle sx={{ fontSize: '0.9rem', color: '#2e7d32' }} />
              </ListItemIcon>
              <Typography variant="body2">Unlimited Transcriptions</Typography>
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemIcon sx={{ minWidth: 28 }}>
                <CheckCircle sx={{ fontSize: '0.9rem', color: '#2e7d32' }} />
              </ListItemIcon>
              <Typography variant="body2">Unlimited Library Size</Typography>
            </ListItem>
          </List>
        </Box>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: `${theme.spacing(2)} !important`,
            paddingLeft: 0,
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', flex: 1, minWidth: 0 }}
          >
            <Checkbox
              checked={isUnlimited}
              onChange={handleUnlimitedChange}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '1.5rem',
                  color: theme.palette.secondary.dark,
                },
                '&.Mui-checked .MuiSvgIcon-root': {
                  color: '#2e7d32',
                },
                pointerEvents: 'none',
                mr: 1,
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 'fit-content',
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Go Unlimited
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  variant="body1"
                  fontWeight="medium"
                  component="span"
                >
                  +$
                  {planPricesPerMonth.BUSINESS[selectedTerm] -
                    planPricesPerMonth.GROWTH[selectedTerm]}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="regular"
                  sx={{ opacity: 0.5, ml: 0.5 }}
                >
                  /month/user
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ml: 2,
              minWidth: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              fontWeight="medium"
              sx={{
                color: '#1e4620',
                textAlign: 'center',
                backgroundColor: alpha('#2e7d32', 0.1),
                borderRadius: '3px',
                padding: '2px 6px',
                fontSize: '0.75rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Popular
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        {renderPromoSection()}
      </Box>
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'subscription',
          amount: totalAmount * 100,
          currency: 'usd',
          setupFutureUsage: 'off_session',
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#1E90FF',
              fontFamily: '"Inter", sans-serif',
              borderRadius: '8px',
              spacingUnit: '4px',
            },
            rules: {
              '.Input': {
                padding: '8px',
                boxShadow: 'none',
                fontSize: '0.875rem',
                borderRadius: '8px',
              },
              '.Label': {
                marginBottom: '4px',
                fontSize: '0.875rem',
              },
              '.PaymentElement': {
                boxShadow: 'none',
              },
              '.Block': {
                boxShadow: 'none',
                borderRadius: '8px',
              },
              '.PickerItem--selected': {
                boxShadow: 'none',
              },
            },
          },
        }}
      >
        <PaymentForm
          totalAmount={totalAmount}
          plan={selectedPlan as keyof typeof planPricesPerMonth}
          term={selectedTerm}
          promoCode={promoCode}
          annualSaving={annualSaving}
        />
      </Elements>
    </Box>
  );
};

export default BillingOptions;
