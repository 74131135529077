import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  LinearProgress,
  Grid,
  Typography,
  Skeleton,
  Card,
  CardContent,
  ListItem,
  Avatar,
} from '@mui/material';
import { CloudUpload } from 'lucide-mui';
import { RouteParams } from '../library/types';
import { RootState } from '../../reducers';

interface PendingFilesProps {
  view?: 'grid' | 'list';
}

const GridViewItem = ({
  fileId,
  progress,
  fileName,
}: {
  fileId: string;
  progress: number;
  fileName: string;
}) => (
  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={fileId}>
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        transform: 'scale(1)',
        '&:hover': {
          transform: 'scale(1.02)',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          animation="wave"
          sx={{
            paddingTop: 'calc(9 / 16 * 100%)', // 16:9 aspect ratio
            borderRadius: '8px',
            backgroundColor: 'background.paper',
            '&::after': {
              background:
                'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.08), transparent)',
              animation: 'wave 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
            },
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'secondary.main',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <CloudUpload
              sx={{
                fontSize: '3rem',
                animation: 'float 2s ease-in-out infinite',
                '@keyframes float': {
                  '0%, 100%': { transform: 'translateY(0)' },
                  '50%': { transform: 'translateY(-5px)' },
                },
                opacity: 0.9,
                color: 'secondary.main',
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                opacity: 0.9,
                color: 'secondary.dark',
              }}
            >
              {Math.round(progress)}%
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          py: '16px',
          px: 0,
          '&:last-child': { pb: '16px' },
          backgroundColor: 'transparent',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              backgroundColor: 'secondary.main',
              transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          >
            <CloudUpload sx={{ fontSize: '1.2rem' }} />
          </Avatar>
          <Box sx={{ ml: 2, flexGrow: 1, overflow: 'hidden' }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                lineHeight: '1.5em',
                minHeight: '1.5em',
                maxHeight: '2.8em',
              }}
            >
              {fileName}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <LinearProgress
                variant={progress >= 99 ? 'indeterminate' : 'determinate'}
                value={progress}
                sx={{
                  height: 2,
                  borderRadius: 1,
                  backgroundColor: 'action.hover',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 1,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

const ListViewItem = ({
  fileId,
  progress,
  fileName,
}: {
  fileId: string;
  progress: number;
  fileName: string;
}) => (
  <ListItem
    key={fileId}
    disablePadding
    sx={{
      borderBottom: 1,
      borderColor: 'secondary.light',
      position: 'relative',
      py: 1.5,
      pr: 2,
      opacity: 0.8,
    }}
  >
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'minmax(200px, 1fr) 80px',
          sm: 'minmax(200px, 1.5fr) 120px',
          md: 'minmax(200px, 1.5fr) 200px',
          lg: 'minmax(250px, 1.5fr) 400px',
        },
        alignItems: 'center',
        width: '100%',
        gap: { xs: 1, sm: 2 },
        pl: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 0 }}>
        <Avatar
          sx={{
            width: { xs: 24, sm: 32 },
            height: { xs: 24, sm: 32 },
            backgroundColor: 'secondary.main',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CloudUpload sx={{ fontSize: '1.2rem' }} />
        </Avatar>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            minWidth: 0,
            flex: 1,
          }}
        >
          {fileName}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '2px', alignSelf: 'center' }}>
        <LinearProgress
          variant={progress >= 99 ? 'indeterminate' : 'determinate'}
          value={progress}
        />
      </Box>
    </Box>
  </ListItem>
);

const PendingFiles = ({ view = 'list' }: PendingFilesProps) => {
  const { upload } = useSelector((state: RootState) => state);
  const { folderId } = useParams<RouteParams>();
  const { activeUploads } = upload;

  const filteredFiles = activeUploads.filter((file) => {
    if (
      folderId === 'recents' ||
      folderId === 'private' ||
      folderId === 'favorites'
    )
      return true;
    return file.folderId === folderId;
  });

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (filteredFiles.length > 0) {
        event.preventDefault();
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [filteredFiles]);

  if (filteredFiles.length === 0) return null;

  if (view === 'grid') {
    return (
      <>
        {filteredFiles.map(({ fileId, progress, fileName }) => (
          <GridViewItem
            key={fileId}
            fileId={fileId}
            progress={progress}
            fileName={fileName}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {filteredFiles.map(({ fileId, progress, fileName }) => (
        <ListViewItem
          key={fileId}
          fileId={fileId}
          progress={progress}
          fileName={fileName}
        />
      ))}
    </>
  );
};

export default PendingFiles;
