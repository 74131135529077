import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  Box,
  IconButton,
  DialogActions,
} from '@mui/material';
import { Upload, X } from 'lucide-mui';
import { RouteParams } from '../library/types';
import { importUrl, reset } from './importSlice';
import { uploadFiles } from '../upload/uploadSlice';
import ALLOWED_TYPES from '../upload/AllowedTypes';
import { RootState } from '../../reducers';
import useLimits from '../plan/hooks/limits';

// Basic URL validation regexp
const URL_PATTERN = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+|localhost)(:\d+)?(\/\S*)?$/;

const ImportModal = ({ onClose }: { onClose: () => void }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { teamId, folderId } = useParams<RouteParams>();
  const { status } = useSelector((state: RootState) => ({
    open: state.dashboard.drawerState === 'open',
    status: state.import.status,
  }));
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();
  const [mainModalOpen, setMainModalOpen] = useState(true);
  const guardLimits = useLimits();
  const [importing, setImporting] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);

  // Handle state changes based on import status
  useEffect(() => {
    if (status === 'failed') {
      setShowFailModal(true);
      setMainModalOpen(false);
      setImporting(false);
    } else if (status === 'succeeded') {
      // On success, close all modals
      setMainModalOpen(false);
      setShowFailModal(false);
      setImporting(false);
      onClose();
    }
  }, [status, onClose]);

  const handleImport = () => {
    if (!url.trim()) {
      setError('Enter a URL to import media');
      setOpenSnackbar(true);
      return;
    }

    // Basic URL validation
    if (!URL_PATTERN.test(url)) {
      setError('Please enter a valid URL');
      setShowFailModal(true);
      setMainModalOpen(false);
      return;
    }

    if (teamId && folderId) {
      guardLimits('importUrlButton', async () => {
        setImporting(true);
        await dispatch(importUrl({ teamId, folderId, url }));
      });
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    // Clear errors when user starts typing again
    if (error) {
      setError('');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const { files } = event.dataTransfer;
    if (teamId && folderId && files) {
      const filesArray = Array.from(files as ArrayLike<File>);
      const filteredFiles = filesArray.filter((file) =>
        ALLOWED_TYPES.includes(file.type)
      );
      dispatch(uploadFiles({ teamId, folderId, files: filteredFiles }));
      onClose();
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (teamId && folderId && event.target.files) {
      const filesArray = Array.from(event.target.files as ArrayLike<File>);
      const filteredFiles = filesArray.filter((file) =>
        ALLOWED_TYPES.includes(file.type)
      );
      dispatch(uploadFiles({ teamId, folderId, files: filteredFiles }));
      onClose();
    }
  };

  const handleUploadClick = () => {
    guardLimits('importModalUploadButton', () => {
      document.getElementById('file-input')?.click();
    });
  };

  // Close both modals and reset import state
  const handleErrorClose = () => {
    setShowFailModal(false);
    setMainModalOpen(false);
    dispatch(reset());
    onClose();
  };

  return (
    <>
      <Dialog
        open={mainModalOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth={isSmallScreen}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            width: isSmallScreen ? '100%' : '32rem',
            margin: isSmallScreen ? theme.spacing(2) : undefined,
            position: 'relative',
            bgcolor: theme.palette.background.paper,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: isSmallScreen ? 12 : 16,
            top: isSmallScreen ? 12 : 16,
            color: theme.palette.text.secondary,
            padding: isSmallScreen ? '8px' : '6px',
            zIndex: 1,
            '&:active': {
              transform: 'translateY(1px)',
            },
          }}
        >
          <X sx={{ width: 20, height: 20 }} />
        </IconButton>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
            padding: theme.spacing(4, 3, 3),
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontWeight: 500,
                mb: 1,
                color: theme.palette.text.primary,
              }}
            >
              Add media
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ maxWidth: '440px', mx: 'auto' }}
            >
              Import media from YouTube, Vimeo, or any direct media link. You
              can also upload files from your device.
            </Typography>
          </Box>

          <TextField
            label="Media URL"
            variant="outlined"
            value={url}
            onChange={handleUrlChange}
            placeholder="Paste a YouTube, Vimeo, or media URL"
            fullWidth
            error={!!error}
            helperText={error}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleImport}
            disabled={!teamId || !folderId || importing}
            fullWidth
            size="large"
            sx={{
              py: 1.5,
              transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
            }}
          >
            {importing ? 'Importing...' : 'Import URL'}
          </Button>
          <Box sx={{ textAlign: 'center', my: 1 }}>
            <Typography variant="body2" color="textSecondary">
              or
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              minHeight: '180px',
              border: '3px dashed',
              borderColor: isDragging
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
              backgroundColor: isDragging
                ? theme.palette.action.hover
                : 'transparent',
              transform: isDragging ? 'scale(1.02)' : 'scale(1)',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.action.hover,
                '& .MuiSvgIcon-root': {
                  transform: 'translateY(-3px)',
                },
              },
            }}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onClick={handleUploadClick}
          >
            <Upload
              sx={{
                fontSize: '3.5rem',
                color: isDragging
                  ? theme.palette.primary.main
                  : theme.palette.secondary.dark,
                mb: 2,
                transition: 'all 0.2s ease-in-out',
                transform: isDragging ? 'translateY(-5px)' : 'none',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: isDragging
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
                fontWeight: isDragging ? 500 : 400,
                mb: 2,
              }}
            >
              {isDragging ? 'Release to upload' : 'Drag files here'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: 3,
                transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                },
              }}
              data-testid="upload-file-button-upload-modal"
            >
              Upload files
            </Button>
          </Box>
          <input
            id="file-input"
            data-testid="file-input"
            type="file"
            multiple
            accept={ALLOWED_TYPES.join(',')}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showFailModal}
        onClose={handleErrorClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: theme.spacing(2),
          },
        }}
      >
        <DialogContent sx={{ py: 1, px: 1 }}>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              mb: 1,
              padding: 0,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Import Failed
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            URL not recognized. Please try again, or use the upload feature
            instead.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorClose}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ImportModal;
