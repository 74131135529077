import React from 'react';
import { Alert, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlertMessage } from './dashboardSlice';
import { RootState } from '../../reducers';

const AlertMessage = () => {
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state: RootState) => state);
  const { alertMessageStack } = dashboard;
  const lastMessage = alertMessageStack[alertMessageStack.length - 1];
  const handleOnClick = () => {
    dispatch(removeAlertMessage());
  };
  if (!lastMessage) return null;
  const { message, type, buttonText, link } = lastMessage;
  return (
    <Alert
      severity={type}
      sx={{
        borderRadius: 0, // Remove border radius
        '& .MuiSvgIcon-root': {
          fontSize: 'unset', // This unsets the SVG icon font size
        },
      }}
      action={
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleOnClick}
          href={link}
        >
          {buttonText}
        </Button>
      }
    >
      {message}
    </Alert>
  );
};

export default AlertMessage;
