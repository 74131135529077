import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Dashboard from '../dashboard';
import Files from './files';
import Actions from './actions';
import { importUrl as importFromUrl } from '../import/importSlice';
import { useQuery, useUpdateQuery } from '../../hooks/urlHooks';
import { URLParamsType } from '../../types';
import useLimits from '../plan/hooks/limits';
import useTags from '../../hooks/useTags';
import TeamDeletionCover from '../team/TeamDeletingCover';
import { RootState } from '../../reducers';
import MultiAskAI from './files/MultiAskAI';
import { pushModal } from '../globalModals/globalModalsSlice';
import UploadDropZone from './UploadDropZone';

import {
  ONBOARDING_STEP_TAG_KEY,
  ONBOARDING_STEP_TAG_VALUE,
} from '../../constants/tags';

export const Library = () => {
  const { teamId, folderId } = useParams<URLParamsType>();
  const { importUrl: importUrlFromQuery } = useQuery();
  const updateQuery = useUpdateQuery();
  const dispatch = useDispatch();
  const guardLimit = useLimits();
  const { getTag } = useTags();
  const { team } = useSelector((state: RootState) => state);
  const teamDeleteRequested = !!team.selectedTeam?.deleteRequestedAt;
  const { files } = useSelector((state: RootState) => state.library);
  const selectedFolder = useSelector(
    (state: RootState) => state.library.selectedFolder
  );
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  // Update page title
  useEffect(() => {
    document.title = `${selectedFolder?.name || 'Library'} - ScreenApp`;
    return () => {
      document.title = 'ScreenApp';
    };
  }, [selectedFolder?.name]);

  // Handle actions based on query params
  useEffect(() => {
    if (importUrlFromQuery && teamId && folderId) {
      dispatch(importFromUrl({ teamId, folderId, url: importUrlFromQuery }));
    }
    if (
      files.status === 'succeeded' &&
      getTag(ONBOARDING_STEP_TAG_KEY) !== ONBOARDING_STEP_TAG_VALUE
    ) {
      dispatch(pushModal({ type: 'onboarding-modal' }));
    }
    if (files.data.length > 1) {
      guardLimit('login', () => {});
    }
    updateQuery({ importUrl: undefined });
  }, [files.data]);

  if (teamDeleteRequested) {
    return (
      <Dashboard>
        <TeamDeletionCover />
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <UploadDropZone>
        <Box sx={{ height: 164, mt: 0.5 }}>
          <Actions />
          {templateModalOpen && (
            <MultiAskAI
              open={templateModalOpen}
              onClose={() => setTemplateModalOpen(false)}
            />
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Files />
        </Box>
      </UploadDropZone>
    </Dashboard>
  );
};

export default Library;
