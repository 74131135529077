/* eslint no-param-reassign: "error" */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RecorderSettings } from '../../types/state';
import {
  forgetPasswordV2,
  resetPasswordURLV2,
  userSignInURLV2,
  userSignUpURLV2,
  scheduleDeleteAccountURL,
  unscheduleDeleteAccountURL,
} from '../../services/authService';

export interface IUpdatePluginSettingsData {
  type: 'plugin' | 'record';
  data: RecorderSettings;
  source?: string;
}

export interface ResetPasswordPayload {
  password: string;
  confirm: string;
  token: string;
}

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: ResetPasswordPayload, { rejectWithValue }) => {
    try {
      const response = await resetPasswordURLV2(data);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('Failed to reset password');
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'Failed to reset password'
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordV2({ email });
      if (response?.status === 200) {
        return response.data.data;
      }
      throw new Error();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSignInRequest = createAsyncThunk(
  'auth/userSignInRequest',
  async (options: Parameters<typeof userSignInURLV2>[0]) => {
    const token = await userSignInURLV2(options);

    if (token) {
      return token;
    }

    throw new Error('Failed to sign in');
  }
);

export const userSignUpRequest = createAsyncThunk(
  'auth/userSignUpRequest',
  async (options: Parameters<typeof userSignUpURLV2>[0]) => {
    const response = await userSignUpURLV2(options);
    const { status, data } = response || {};
    if (!data) {
      throw new Error('Failed to sign up');
    }

    const { accessToken } = data.data || {};
    if (status === 201 && accessToken) {
      return accessToken;
    }

    const { message } = data || {};
    throw new Error(message || 'Failed to sign up');
  }
);

export const scheduleDeleteAccount = createAsyncThunk(
  'auth/scheduleDeleteAccount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await scheduleDeleteAccountURL();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          error.response?.message ||
          'Failed to schedule account deletion'
      );
    }
  }
);

export const unscheduleDeleteAccount = createAsyncThunk(
  'auth/unscheduleDeleteAccount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await unscheduleDeleteAccountURL();
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          error.response?.message ||
          'Failed to unschedule account deletion'
      );
    }
  }
);
