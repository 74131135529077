import { combineReducers } from 'redux';
import app from '../features/app/appSlice';
import auth from '../features/auth/authSlice';
import selectedVideo from '../features/selectedVideo/selectedVideoSlice';
import {
  pluginReducer as plugin,
  pluginRecordingReducer as pluginRecording,
} from '../features/plugin/pluginSlice';
import globalModals from '../features/globalModals/globalModalsSlice';
import invite from '../features/team/invite/inviteSlice';
import library from '../features/library/librarySlice';
import dashboard from '../features/dashboard/dashboardSlice';
import settings from '../features/settings/settingsSlice';
import team from '../features/team/teamSlice';
import plan from '../features/plan/planSlice';
import upload from '../features/upload/uploadSlice';
import importReducer from '../features/import/importSlice';
import templates from '../features/selectedVideo/templateSlice';
import search from '../features/search/searchSlice';

const appReducer = combineReducers({
  auth,
  app,
  selectedVideo,
  plugin,
  pluginRecording,
  globalModals,
  plan,
  invite,
  library,
  dashboard,
  settings,
  team,
  upload,
  import: importReducer,
  templates,
  search,
});

const rootReducer = (state: any, action: any) => {
  const upState = { ...state };
  return appReducer(upState, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
